import React from 'react';
import faq_Data from 'data/FAQ/faqData.json';
import Xpansion from 'components/Xpansion';
import { TagSearch } from 'mdi-material-ui';
import { Typography } from '@material-ui/core';

const FAQComponent = ({ tags }: { tags?: string }) => {
  const filteredFAQs = tags
    ? faq_Data.filter((faq) => faq.tags.some((tag) => tags.includes(tag)))
    : faq_Data;

  return (
    <div>
      {/* <Typography variant="h6" gutterBottom>
        Top Frequently Asked Questions
      </Typography> */}
      <div>
        {filteredFAQs.length === 0 ? (
          <Typography variant="body1" color="textPrimary">
            No FAQs available for this section.
          </Typography>
        ) : (
          filteredFAQs.map((faq, index) => (
            <Xpansion
              key={index}
              summary={faq.question}
              selected={false}
              icon={null}
              style={{ marginBottom: '1px' }}
              defaultExpanded={false}
              className="faq-item">
              <p>{faq.answer}</p>
            </Xpansion>
          ))
        )}
      </div>
    </div>
  );
};
export default FAQComponent;
