import {
  Button,
  Collapse,
  IconButton,
  Paper,
  TextField,
  useTheme,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Grid,
  Typography,
  Tooltip
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { ICustomTreeData, ICustomTreeDataChildren } from '.';
import SafeguardChip from './components/SafeguardChip';
import { EStatusKey, safeguardsConfig } from './components/SafeguardConfig';
import {
  IConditionalObjectDefinitionIds,
  IObjectIds,
  IObjectIdsBase,
  ISafeguard,
  IStatus
} from './interface';
import { getFieldInstanceId, getHideShowId } from './functions';
import NestedOptions from './components/NestedOptions';
import { notify } from 'components/Notifications/HotToastNotifications';

const INIT_STATUS: IStatus = {
  isGDPR_Only_Required: false,
  isRegulated_Only_Required: false,
  isNon_Regulated_Only_Required: false,
  isNon_Regulated_Field: false,
  isField_Instance: false,
  isRegulated_Field: false,
  isGDPR_Required: false,
  isRegulated: false,
  isNonRegulated: false,
  isHideShow: false,
  isObjectDefinitionRegAndNonRegulated: false
};

const INIT_IDS: IObjectIds = {
  objectDefinitionId: 0,
  fieldInstanceId: {
    hideShowId: 0,
    fieldInstanceId: 0
  },
  gdprStepId: 0
};

export const isObjectDefinitionIds = (
  obj: IObjectIds
): obj is IObjectIdsBase & {
  objectDefinitionIds: IConditionalObjectDefinitionIds;
} => {
  return 'objectDefinitionIds' in obj;
};

export const isSingleObjectDefinitionId = (
  obj: IObjectIds
): obj is IObjectIdsBase & { objectDefinitionId: number } => {
  return 'objectDefinitionId' in obj;
};

const TreeNode = ({
  treeNode,
  handleAddIdToRule,
  handleRemoveIdFromRule,
  handleEditIdInRule
}: {
  treeNode: ICustomTreeData;
  handleAddIdToRule: (
    parentId: string,
    newId: IObjectIds,
    action: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    status: IStatus
  ) => void;
  handleRemoveIdFromRule: (parentId: string, idToRemove: string) => void;
  handleEditIdInRule: (
    ruleId: string,
    uniqueId: string,
    updatedId: IObjectIds,
    updatedAction: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    updatedStatus: IStatus
  ) => void;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!treeNode.children) return <div />;
  return (
    <div>
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={toggleExpand}>
        <IconButton size="small">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <div style={{ fontWeight: 'bold' }}>{treeNode.name}</div>
      </div>
      <Collapse in={isExpanded}>
        {treeNode?.children.map((child: ICustomTreeDataChildren) => (
          <RuleIDs
            key={child.ruleId}
            child={child}
            onAddId={handleAddIdToRule}
            onRemoveId={handleRemoveIdFromRule}
            onEditId={handleEditIdInRule}
          />
        ))}
      </Collapse>
    </div>
  );
};

const RuleIDs = ({
  child,
  onAddId,
  onRemoveId,
  onEditId
}: {
  child: ICustomTreeDataChildren;
  onAddId: (
    parentId: string,
    newId: IObjectIds,
    action: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    status: IStatus
  ) => void;
  onRemoveId: (parentId: string, idToRemove: string) => void;
  onEditId: (
    ruleId: string,
    uniqueId: string,
    updatedId: IObjectIds,
    updatedAction: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    updatedStatus: IStatus
  ) => void;
}) => {
  const theme = useTheme();

  const [action, setAction] = useState<
    'Sent' | 'Starred' | 'Accepted' | 'Completed'
  >('Completed');

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [status, setStatus] = useState<IStatus>(INIT_STATUS);
  const [idToAdd, setIdToAdd] = useState<IObjectIds>(INIT_IDS);

  const addId = () => {
    if (status.isGDPR_Required && !idToAdd.gdprStepId) {
      notify.error(`Please write a GDPR Step Number for ${child.ruleId}`);
      return;
    }

    console.log({ idToAdd });
    if (idToAdd) {
      onAddId(child.ruleId, idToAdd, action, status);
      setIdToAdd(INIT_IDS);
      setAction('Completed');
      setStatus(INIT_STATUS);
    }
  };

  const initializeIdToAdd = (
    currentIdToAdd: IObjectIds,
    isObjectDefinitionRegAndNonRegulated: boolean
  ): IObjectIds => {
    if (isObjectDefinitionRegAndNonRegulated) {
      return {
        ...currentIdToAdd,
        objectDefinitionIds: {
          objectDefinitionIdReg: 0,
          objectDefinitionIdNonReg: 0
        }
      };
    } else {
      return {
        ...currentIdToAdd,
        objectDefinitionId: 0
      };
    }
  };

  /**
   * Handles changes to the SingleObjectDefinitionId input field.
   * Updates the `ObjectDefinitionId` in the `idToAdd` state.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChangeSingleId = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSingleObjectDefinitionId(idToAdd)) {
      setIdToAdd({
        ...idToAdd,
        objectDefinitionId: e.target.value ? parseInt(e.target.value) : 0
      });
    }
  };

  /**
   * Handles changes to the ObjectDefinitionId for regulated fields.
   * Updates the `ObjectDefinitionIdReg` in the `ObjectDefinitionIds` of the `idToAdd` state.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChangeRegId = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isObjectDefinitionIds(idToAdd)) {
      setIdToAdd({
        ...idToAdd,
        objectDefinitionIds: {
          ...idToAdd.objectDefinitionIds,
          objectDefinitionIdReg: e.target.value ? parseInt(e.target.value) : 0
        }
      });
    }
  };

  /**
   * Handles changes to the ObjectDefinitionId for non-regulated fields.
   * Updates the `ObjectDefinitionIdNonReg` in the `ObjectDefinitionIds` of the `idToAdd` state.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleChangeNonRegId = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isObjectDefinitionIds(idToAdd)) {
      setIdToAdd({
        ...idToAdd,
        objectDefinitionIds: {
          ...idToAdd.objectDefinitionIds,
          objectDefinitionIdNonReg: e.target.value
            ? parseInt(e.target.value)
            : 0
        }
      });
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChange =
    (changedKey: EStatusKey) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newStatus = { ...status };

      if (
        changedKey === EStatusKey.isGDPR_Only_Required ||
        changedKey === EStatusKey.isRegulated_Only_Required ||
        changedKey === EStatusKey.isNon_Regulated_Only_Required
      ) {
        newStatus = INIT_STATUS;
      }

      newStatus[changedKey] = event.target.checked;

      if (
        changedKey === EStatusKey.isNon_Regulated_Field ||
        changedKey === EStatusKey.isField_Instance ||
        changedKey === EStatusKey.isNonRegulated ||
        changedKey === EStatusKey.isRegulated_Field ||
        changedKey === EStatusKey.isGDPR_Required ||
        changedKey === EStatusKey.isRegulated ||
        changedKey === EStatusKey.isHideShow
      ) {
        if (event.target.checked) {
          newStatus.isGDPR_Only_Required = false;
          newStatus.isRegulated_Only_Required = false;
          newStatus.isNon_Regulated_Only_Required = false;
        }
      }

      if (
        changedKey === EStatusKey.isNon_Regulated_Field &&
        event.target.checked
      ) {
        newStatus.isRegulated_Field = false;
      } else if (
        changedKey === EStatusKey.isRegulated_Field &&
        event.target.checked
      ) {
        newStatus.isNon_Regulated_Field = false;
      }

      if (changedKey === EStatusKey.isNonRegulated && event.target.checked) {
        newStatus.isRegulated = false;
      } else if (
        changedKey === EStatusKey.isRegulated &&
        event.target.checked
      ) {
        newStatus.isNonRegulated = false;
      }

      if (!status.isHideShow) {
        if (changedKey === EStatusKey.isObjectDefinitionRegAndNonRegulated) {
          const setNewID = initializeIdToAdd(idToAdd, event.target.checked);
          setIdToAdd(setNewID);
        }
      } else {
        if (changedKey === EStatusKey.isObjectDefinitionRegAndNonRegulated) {
          let newFieldValue;

          if (typeof idToAdd.fieldInstanceId === 'object') {
            if (status.isObjectDefinitionRegAndNonRegulated) {
              newFieldValue = {
                ...idToAdd.fieldInstanceId,
                nestedChildId: {
                  objectDefinitionIdReg: 0,
                  objectDefinitionIdNonReg: 0
                }
              };
            } else {
              newFieldValue = {
                ...idToAdd.fieldInstanceId,
                nestedChildId: 0
              };
            }
          }

          const setNewId: IObjectIds = {
            ...idToAdd,
            fieldInstanceId: newFieldValue
          };

          setIdToAdd(setNewId);
        }
      }

      setStatus(newStatus);
    };

  const isLocked = child.safeGuards.some(
    (sg) =>
      sg.status.isGDPR_Only_Required ||
      sg.status.isNon_Regulated_Only_Required ||
      sg.status.isRegulated_Only_Required
  );

  return (
    <div
      style={{
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        width: '100%'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%'
        }}
        onClick={toggleExpand}>
        <IconButton size="small">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
          <div
            style={{
              fontWeight: 'bold',
              marginRight: '8px'
            }}>{`${child.ruleName}`}</div>
          <div
            style={{
              fontWeight: 'bold',
              flex: 1,
              display: 'flex',
              whiteSpace: 'nowrap',
              overflow: 'auto'
            }}>
            {child.safeGuards && child.safeGuards.length > 0 ? (
              <div style={{ flex: 1 }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {`- Safeguard Rules Connected To This Rule: (${child.safeGuards.length})`}
                </Typography>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <Collapse in={isExpanded}>
        <Paper style={{ padding: theme.spacing(1) }}>
          {child.safeGuards.length > 0 && (
            <div
              style={{
                paddingBottom: theme.spacing(1),
                fontWeight: 'bold'
              }}>
              Current IDs:
              <Grid container direction="row" spacing={2}>
                {child.safeGuards.map((safeguard: ISafeguard) => (
                  <Grid
                    item
                    key={safeguard.uniqueId}
                    style={{ paddingTop: theme.spacing(2) }}>
                    <SafeguardChip
                      safeguard={safeguard}
                      onRemoveId={onRemoveId}
                      onEditId={onEditId}
                      ruleId={child.ruleId}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {!isLocked && (
            <>
              <Grid container>
                {[1, 2, 3, 4].map((row) => (
                  <Grid container item xs={12} key={row}>
                    {/* <Grid item xs={12} style={{ padding: theme.spacing(1) }}>
                      {row === 1 && (
                        <Alert severity="info">
                          <AlertTitle>
                            Important Information About Required ONLY Fields
                          </AlertTitle>
                          <Typography variant="body1" color="textSecondary">
                            {`These rules should be used only if there are no other
                          rules. For example, use these for cases where only
                          regulated fields are required but you do not want to
                          hide the rule.`}
                          </Typography>
                        </Alert>
                      )}
                      {row === 2 && (
                        <Alert severity="info">
                          <AlertTitle>
                            Important Information About FieldInstances
                          </AlertTitle>
                          <Typography variant="h6" color="textSecondary">
                            {`These fields ensure that FieldInstances can be found
                            within CompleteObjectInstance. They can also be used
                            to specify 'Sent' or 'Accepted' statuses that are
                            only required in regulated or non-regulated deal
                            flows.`}
                          </Typography>
                        </Alert>
                      )}
                      {row === 3 && (
                        <Alert severity="info">
                          <AlertTitle>
                            Important Information About IsRegulated /
                            Non-Regulated
                          </AlertTitle>
                          <Typography variant="h6" color="textSecondary">
                            {`Hiding rules based on regulated or non-regulated
                          status. Note: This will hide the rule. If there are
                          other rules, they must also depend on this option. To
                          avoid this logic, use 'IsRegulatedField' or
                          'IsNonRegulatedField'.`}
                          </Typography>
                        </Alert>
                      )}
                    </Grid> */}
                    {safeguardsConfig
                      .filter((config) => config.column === row)
                      .map(({ key, label, tooltip }) => (
                        <Grid item xs={3} key={key}>
                          <FormControlLabel
                            control={
                              <Tooltip title={tooltip}>
                                <Checkbox
                                  checked={status[key]}
                                  onChange={handleChange(key)}
                                  color="primary"
                                />
                              </Tooltip>
                            }
                            label={label}
                          />
                        </Grid>
                      ))}
                  </Grid>
                ))}
              </Grid>

              {!status.isNon_Regulated_Only_Required &&
                !status.isRegulated_Only_Required && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1)
                    }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          value={idToAdd.gdprStepId.toString()}
                          variant="outlined"
                          onChange={(e) =>
                            setIdToAdd({
                              ...idToAdd,
                              gdprStepId: e.target.value
                                ? parseInt(e.target.value)
                                : 0
                            })
                          }
                          label="GPDR Step ID"
                          style={{ marginRight: theme.spacing(1) }}
                          type="number"
                          disabled={
                            !status.isGDPR_Required &&
                            !status.isGDPR_Only_Required
                          }
                        />

                        {!status.isGDPR_Only_Required && !status.isHideShow && (
                          <TextField
                            value={getHideShowId(
                              idToAdd.fieldInstanceId
                            ).toString()}
                            variant="outlined"
                            onChange={(e) =>
                              setIdToAdd({
                                ...idToAdd,
                                fieldInstanceId: e.target.value
                                  ? parseInt(e.target.value)
                                  : 0
                              })
                            }
                            label="FieldDefinition ID"
                            style={{ marginRight: theme.spacing(1) }}
                            type="number"
                            disabled={!status.isField_Instance}
                          />
                        )}

                        {!status.isGDPR_Only_Required && (
                          <>
                            {((!status.isObjectDefinitionRegAndNonRegulated &&
                              isSingleObjectDefinitionId(idToAdd)) ||
                              (status.isHideShow &&
                                isSingleObjectDefinitionId(idToAdd))) && (
                              <TextField
                                value={idToAdd.objectDefinitionId.toString()}
                                variant="outlined"
                                onChange={handleChangeSingleId}
                                style={{ marginRight: theme.spacing(1) }}
                                type="number"
                                label={
                                  action === 'Sent' || action === 'Accepted'
                                    ? 'StepDefinition ID'
                                    : 'ObjectDefinition ID'
                                }
                              />
                            )}

                            {status.isObjectDefinitionRegAndNonRegulated &&
                              !status.isHideShow &&
                              isObjectDefinitionIds(idToAdd) && (
                                <>
                                  <TextField
                                    value={idToAdd.objectDefinitionIds.objectDefinitionIdReg.toString()}
                                    variant="outlined"
                                    onChange={handleChangeRegId}
                                    style={{ marginRight: theme.spacing(1) }}
                                    type="number"
                                    label="ObjectDefinition ID Reg"
                                  />
                                  <TextField
                                    value={idToAdd.objectDefinitionIds.objectDefinitionIdNonReg.toString()}
                                    variant="outlined"
                                    onChange={handleChangeNonRegId}
                                    style={{ marginRight: theme.spacing(1) }}
                                    type="number"
                                    label="ObjectDefinition ID Non-Reg"
                                  />
                                </>
                              )}
                          </>
                        )}

                        {!status.isGDPR_Only_Required && (
                          <Select
                            value={action}
                            variant="outlined"
                            onChange={(e) =>
                              setAction(
                                e.target.value as
                                  | 'Sent'
                                  | 'Starred'
                                  | 'Accepted'
                                  | 'Completed'
                              )
                            }
                            style={{ marginRight: theme.spacing(1) }}>
                            <MenuItem value="Completed">Completed</MenuItem>
                            <MenuItem value="Sent">Sent</MenuItem>
                            <MenuItem value="Starred">Starred</MenuItem>
                            <MenuItem value="Accepted">Accepted</MenuItem>
                          </Select>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {status.isHideShow && (
                          <NestedOptions
                            setIdToAdd={setIdToAdd}
                            idToAdd={idToAdd}
                            status={status}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                )}

              <Button variant="contained" color="primary" onClick={addId}>
                Add Safe Guard
              </Button>
              <div style={{ paddingBottom: theme.spacing(1) }} />
              <Alert severity="info">
                <Typography style={{ fontWeight: 'bold' }}>
                  {`
                  Information About "Sent", "Starred", "Accepted" and
                  "Completed"
                  `}
                </Typography>
                <Typography>
                  {`
                  When the action is "Sent" or "Accepted" the Id needs the
                  ProcessStepId, when doing "Starred" or "Completed" the Id
                  needs to be the ObjectDefinition`}
                </Typography>
              </Alert>
            </>
          )}
        </Paper>
      </Collapse>
    </div>
  );
};

export const Tree = ({
  treeData,
  handleAddIdToRule,
  handleRemoveIdFromRule,
  handleEditIdInRule
}: {
  treeData: ICustomTreeData[];
  handleAddIdToRule: (
    parentId: string,
    newId: IObjectIds,
    action: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    status: IStatus
  ) => void;
  handleRemoveIdFromRule: (parentId: string, idToRemove: string) => void;
  handleEditIdInRule: (
    ruleId: string,
    uniqueId: string,
    updatedId: IObjectIds,
    updatedAction: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    updatedStatus: IStatus
  ) => void;
}) => {
  return (
    <div>
      {treeData.map((node) => (
        <TreeNode
          key={node.id}
          treeNode={node}
          handleAddIdToRule={handleAddIdToRule}
          handleRemoveIdFromRule={handleRemoveIdFromRule}
          handleEditIdInRule={handleEditIdInRule}
        />
      ))}
    </div>
  );
};
