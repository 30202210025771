import React, {
  createContext,
  useRef,
  useState,
  MutableRefObject,
  useEffect
} from 'react';
import { useTypedSelector } from 'redux/reducers';
import { UserInstance } from 'types/interfaces';
import { firebase } from 'redux/firebase';
import {
  ERecurringEvent,
  IEvent,
  IEventFormValues,
  INIT_FORM
} from '../interface';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useToken } from 'components/MessageHub/hooks/useToken';
import { generateRRule, mapEventData, sendEventEmail } from '../functions';
import { ITimelineEventsState } from '..';

import { useDispatch } from 'react-redux';
import { useProcess } from 'hooks';
import { notify } from 'components/Notifications/HotToastNotifications';

interface ICalendarContextType {
  calendarRef: MutableRefObject<any | null>;
  selectedEventInfo: any;
  handleEventOpen: (selectInfo: any) => void;
  handleClose: () => void;
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eventDialogOpen: EventDialogState;
  setEventDialogOpen: React.Dispatch<React.SetStateAction<EventDialogState>>;
  formValues: IEventFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<IEventFormValues>>;
  isSelectedDate: (date: Date) => boolean;
  key: number;
  allEvents: IEvent[];
  setAllEvents: React.Dispatch<React.SetStateAction<IEvent[]>>;
  refreshKey: () => void;
  handleCalendarUploading: () => void;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  error: {
    reason: string;
    mark: boolean;
    component: 'Date' | 'Title' | 'None';
  };
  setError: React.Dispatch<
    React.SetStateAction<{
      reason: string;
      mark: boolean;
      component: 'Date' | 'Title' | 'None';
    }>
  >;
  handleCalendarDeletion: () => void;
  timelineEvents?: { UserInstance: UserInstance | undefined; Event: IEvent[] };
}

const defaultContext: ICalendarContextType = {
  calendarRef: { current: null },
  handleEventOpen: (selectInfo: any) => {},
  selectedEventInfo: null,
  handleClose: () => {},
  dialogOpen: false,
  setDialogOpen: () => {},
  eventDialogOpen: { event: {}, open: false },
  setEventDialogOpen: () => {},
  formValues: INIT_FORM,
  setFormValues: () => {},
  isSelectedDate: (date: Date) => false,
  key: 0,
  allEvents: [],
  setAllEvents: () => [],
  refreshKey: () => {},
  handleCalendarUploading: () => {},
  edit: false,
  setEdit: () => {},
  error: { reason: '', mark: false, component: 'None' },
  setError: () => {},
  handleCalendarDeletion: async () => {}
};

type EventDialogState = {
  event: any | undefined;
} & {
  [key: string]: boolean | any | undefined;
};

export const CalendarContext =
  createContext<ICalendarContextType>(defaultContext);

export const CalendarProvider = ({
  children,
  timelineEvents,
  setTimelineEvents,
  refreshTimelineEvents,
  isTimeline = false,
  defaultFormValues = INIT_FORM
}: {
  children: React.ReactNode;
  setTimelineEvents?: React.Dispatch<
    React.SetStateAction<ITimelineEventsState | undefined>
  >;
  timelineEvents?: { UserInstance: UserInstance | undefined; Event: IEvent[] };
  refreshTimelineEvents?: () => void;
  isTimeline?: boolean;
  defaultFormValues?: IEventFormValues;
}) => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuth = useIsAuthenticated();
  const { currentDeal } = useProcess();

  const calendarRef = useRef<any>(null);
  const user: UserInstance = useTypedSelector((s) => s.user.user);

  const [selectedEvent, setSelectedEvent] = useState<string[]>([]);
  const [selectedEventInfo, setSelectedEventInfo] = useState<any>();

  const [allEvents, setAllEvents] = useState<any[]>([]);
  const [formValues, setFormValues] = useState(defaultFormValues || INIT_FORM);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [eventDialogOpen, setEventDialogOpen] = useState<EventDialogState>({
    event: undefined
  });

  const [edit, setEdit] = useState<boolean>(false);
  const [error, setError] = useState<{
    reason: string;
    mark: boolean;
    component: 'Date' | 'Title' | 'None';
  }>({
    reason: '',
    mark: false,
    component: 'None'
  });

  const [key, setKey] = useState(Date.now());

  const refreshKey = () => {
    setKey(Date.now());
  };

  const handleCalendarDeletion = async () => {
    const eventId: string = eventDialogOpen.event.id;
    const eventsRef = firebase.firestore().collection('calendar');
    const docRef = eventsRef.doc(eventId);

    setEventDialogOpen({
      ...eventDialogOpen,
      event: undefined,
      [eventId]: false
    });

    await docRef.delete();
    notify.success(`Successfully Deleted Event`);

    if (isTimeline) refreshTimelineEvents && refreshTimelineEvents();
    else fetchEvents();
  };

  const handleCalendarUploading = async () => {
    const eventId = eventDialogOpen.event && eventDialogOpen.event.id;

    if (eventId) {
      await handleUpdate();
      const eventsRef = firebase.firestore().collection('calendar');
      if (eventId) {
        const singleEventSnapshot = await eventsRef.doc(eventId).get();
        const updatedEvent = mapEventData(singleEventSnapshot);

        const eventType = isTimeline ? timelineEvents?.Event : allEvents;
        if (eventType) {
          const updatedEvents = eventType.map((event) =>
            event.id === eventId ? updatedEvent : event
          );

          if (!isTimeline) setAllEvents(updatedEvents);
          else {
            setTimelineEvents &&
              setTimelineEvents({
                UserInstance: timelineEvents?.UserInstance || undefined,
                Event: updatedEvents
              });
          }
          setFormValues(INIT_FORM);
          setEdit(false);

          setEventDialogOpen((overrideState) => ({
            ...overrideState,
            [eventId]: false
          }));
        }
      }
    } else {
      if (formValues.title === '') {
        setError({
          reason: 'Title Is Required For Events',
          mark: true,
          component: 'Title'
        });
      } else await handleSubmit();
    }

    refreshKey();
  };

  const handleEventOpen = (selectInfo: any) => {
    setFormValues(INIT_FORM);

    if (dialogOpen) {
      handleClose();
    }

    let calendarApi = calendarRef.current.getApi();
    calendarApi.unselect();

    const startDate = new Date(selectInfo.startStr);
    const endDate = new Date(selectInfo.endStr);

    let datesInRange: string[] = [];
    for (
      let dt = new Date(startDate);
      dt < endDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      const dateString = `${dt.getFullYear()}-${String(
        dt.getMonth() + 1
      ).padStart(2, '0')}-${String(dt.getDate()).padStart(2, '0')}`;
      datesInRange.push(dateString);
    }

    setSelectedEvent((prevSelectedEvent) => [
      ...prevSelectedEvent,
      ...datesInRange
    ]);

    setFormValues({
      ...formValues,
      startDate: selectInfo.start,
      endDate: selectInfo.end
    });

    setSelectedEventInfo(selectInfo);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setSelectedEvent([]);
    setDialogOpen(false);
    setFormValues(INIT_FORM);
    setEdit(false);
    setEventDialogOpen({
      ...eventDialogOpen,
      event: undefined
    });
  };

  const isSelectedDate = (date) => {
    if (!dialogOpen) {
      return false;
    }

    const dateString = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return selectedEvent.includes(dateString);
  };

  /**Functions That HandleSubmit Need Are "handleBody" & "handleCleanEvent"
   * @param {void} handleBody - Takes values from "FormValues" and creates an Object which we return
   * @param {void} handleCleanEvent - Will stringify our object then clean any undefined or empty values
   * @param {void} handleSubmit - Will Handle Creating Events
   * @param {void} handleUpdate - Will Handle Updating Events
   */

  // #region Event Creation / Updating
  const handleSubmit = async () => {
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });

    const { event } = handleBody(1);
    const cleaned = handleCleanEvent(event);

    const eventsRef = firebase.firestore().collection('calendar');
    const newDocRef = eventsRef.doc();
    cleaned.id = newDocRef.id;

    if (isAuth && accessToken) {
      const guests = cleaned.extendedProps.guests;
      if (guests) {
        sendEventEmail({ accessToken, event: cleaned });
      }
    }

    await newDocRef.set(cleaned);
    if (isTimeline) refreshTimelineEvents && refreshTimelineEvents();
    else fetchEvents();
    handleClose();
  };

  const handleUpdate = async () => {
    const updatedSequence =
      (eventDialogOpen.event.extendedProps.sequence || 0) + 1;

    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });

    const { event: preUpdatedEvent, rrule } = handleBody(updatedSequence);
    const cleaned = handleCleanEvent(preUpdatedEvent);
    const eventId = eventDialogOpen.event && eventDialogOpen.event.id;
    const eventsRef = firebase.firestore().collection('calendar');

    const newEvent = {
      ...preUpdatedEvent,
      id: eventId
    };

    if (isAuth && accessToken && preUpdatedEvent.extendedProps.guests.length) {
      sendEventEmail({ accessToken, event: newEvent });
    }

    if (rrule) {
      cleaned.rrule = rrule;
      await eventsRef.doc(eventId).update(cleaned);
    } else if (formValues.recurring === ERecurringEvent.DoesNotRepeat) {
      await eventsRef.doc(eventId).update({
        ...cleaned,
        rrule: firebase.firestore.FieldValue.delete()
      });
    } else {
      await eventsRef.doc(eventId).update(cleaned);
    }

    handleClose();
  };

  const handleCleanEvent = (event: IEvent) => {
    const cleanedEvent = JSON.parse(
      JSON.stringify(event, (key, value) => {
        if (Array.isArray(value) && value.length === 0) return undefined;
        if (typeof value === 'string' && !value.trim()) return undefined;
        return value != null ? value : undefined;
      })
    );

    return cleanedEvent;
  };

  const handleBody = (sequence) => {
    let rrule = generateRRule({
      recurring: formValues.recurring,
      startDate: formValues.startDate,
      endDateRRule: formValues.endDateRRule,
      interval: formValues.interval
    });

    const isAllDayEvent = (startDate: Date, endDate: Date) => {
      const startAtMidnight =
        startDate.getHours() === 0 && startDate.getMinutes() === 0;

      const endAtMidnight =
        endDate.getHours() === 0 && endDate.getMinutes() === 0;

      const differentDays =
        startDate.getDate() !== endDate.getDate() ||
        startDate.getMonth() !== endDate.getMonth() ||
        startDate.getFullYear() !== endDate.getFullYear();

      return startAtMidnight && endAtMidnight && differentDays;
    };

    const event: IEvent = {
      title: formValues.title,
      start: formValues.startDate,
      end: formValues.endDate,
      allDay: isAllDayEvent(formValues.startDate, formValues.endDate),
      extendedProps: {
        calendarOwner: user.Id,
        dealInformation: {
          pdid: currentDeal?.ProcessInstance?.ProcessDefinitionId ?? undefined,
          piid: currentDeal?.ProcessInstance?.Id ?? undefined,
          psdid:
            currentDeal?.ProcessInstance?.ProcessStepDefinitionId ?? undefined
        },
        location: formValues.location,
        guests: formValues.guests,
        invitedUserInstanceIds: formValues.invitedUserInstanceIds,
        attachments: formValues.attachments || [],
        eventType: formValues.eventType,
        address: formValues.address,
        recurring: formValues.recurring,
        sequence: sequence,
        description: formValues.description,
        processInstanceId: currentDeal?.ProcessInstance?.Id ?? 0,
        notification: formValues.notification || false
      },
      ...(rrule && { rrule: rrule })
    };

    return { rrule, event };
  };
  // #endregion

  const fetchEvents = async () => {
    const eventsRef = firebase.firestore().collection('calendar');

    const ownedEventsQuery = eventsRef.where(
      'extendedProps.calendarOwner',
      '==',
      user.Id
    );

    const invitedEventsQuery = eventsRef.where(
      'extendedProps.invitedUserInstanceIds',
      'array-contains',
      user.Id
    );

    const [ownedSnapshot, invitedSnapshot] = await Promise.all([
      ownedEventsQuery.get(),
      invitedEventsQuery.get()
    ]);

    const ownedEvents = ownedSnapshot.docs.map(mapEventData);
    const invitedEvents = invitedSnapshot.docs.reduce((acc, doc) => {
      const data = doc.data() as IEvent;
      const currentUserGuestInfo = data.extendedProps.guests.find(
        (guest) => guest.UserInstanceId === user.Id
      );

      if (currentUserGuestInfo && currentUserGuestInfo.status !== 2) {
        acc.push(mapEventData(doc));
      }

      return acc;
    }, [] as IEvent[]);

    const allRelevantEvents = [...ownedEvents, ...invitedEvents];
    setAllEvents(allRelevantEvents);
  };

  useEffect(() => {
    if (!isTimeline) fetchEvents();
    else refreshTimelineEvents && refreshTimelineEvents();
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.refetchEvents();
    }
  }, [allEvents]);

  const value: ICalendarContextType = {
    calendarRef,
    handleEventOpen,
    handleClose,
    dialogOpen,
    setDialogOpen,
    isSelectedDate,
    refreshKey,
    key,
    setFormValues,
    formValues,
    selectedEventInfo,
    allEvents,
    setAllEvents,
    eventDialogOpen,
    setEventDialogOpen,
    handleCalendarUploading,
    setEdit,
    edit,
    setError,
    error,
    handleCalendarDeletion,
    timelineEvents
  };

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};

const getEvents = async (accessToken: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  try {
    const response = await fetch(
      'https://graph.microsoft.com/v1.0/me/events',
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error fetching events:', errorData);
      return null;
    }

    const eventData = await response.json();
    console.log('Fetched events:', eventData);
    return eventData;
  } catch (error) {
    console.error('Exception while fetching events:', error);
    return null;
  }
};
