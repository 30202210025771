import { ELenderAPIType } from '../../interface';
import FundingCircleProposal from '.';
import { createTypeSafeIds } from '../../utils';

export const getFundingCircleIds = () => {
  return createTypeSafeIds(FundingCircleProposal, (_) => ({
    partner_reference: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: true,

      forcedValue: 'ProcessInstanceId'
    },
    callback_url: {
      FieldDefinitionId: 0,
      isReadonly: true
    },
    broker_email: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'ProcessOwnerEmail'
    },
    broker_commission: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.OptionsList,
        options: [
          { label: '0.5', value: 0.5 },
          { label: '1', value: 1 },
          { label: '2', value: 2 },
          { label: '2.5', value: 2.5 },
          { label: '3', value: 3 },
          { label: '3.5', value: 3.5 },
          { label: '4', value: 4 },
          { label: '4.5', value: 4.5 },
          { label: '5', value: 5 },
          { label: '5.5', value: 5.5 },
          { label: '6', value: 6 }
        ]
      }
    },
    business_reference: {
      companies_house_id: {
        FieldDefinitionId: 18315,
        isReadonly: true,
        required: true
      },
      business_name: {
        FieldDefinitionId: 18317,
        isReadonly: true
      }
    },
    ebo_list: [
      {
        ebo_name: {
          first_name: {
            FieldDefinitionId: 24030,
            isReadonly: false,
            required: true
          },
          last_name: {
            FieldDefinitionId: 18427,
            isReadonly: false,
            required: true
          }
        },
        dob: {
          FieldDefinitionId: 18416,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        current_address: {
          house_number_or_name: {
            FieldDefinitionId: 18431,
            isReadonly: false,
            required: true
          },
          street: {
            FieldDefinitionId: 18433,
            isReadonly: false
          },
          city: {
            FieldDefinitionId: 18435,
            isReadonly: false
          },
          postcode: {
            FieldDefinitionId: 18438,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 12,
                pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                message:
                  "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
              }
            }
          }
        },
        percent_shares_held: {
          FieldDefinitionId: 24034,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
            requirement: {
              minAmount: 0,
              maxAmount: 100
            }
          }
        }
      }
    ],
    customer_info: {
      email: {
        FieldDefinitionId: 18452,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Requirement,
          requirement: {
            maxLength: 255,
            pattern:
              '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            message: 'Please enter a valid email address'
          }
        }
      },
      phone_number: {
        FieldDefinitionId: 18525,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [ELenderAPIType.Phone, ELenderAPIType.Requirement],
          requirement: {
            pattern: '^(?:(?:\\+44)|0)\\d{10}$',
            message:
              'Please enter a valid UK phone number (e.g., 07123456789 or +447123456789)'
          }
        }
      },
      contact_name: {
        first_name: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        last_name: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        }
      }
    },

    business_info: {
      company_type: {
        FieldDefinitionId: 21745,
        isReadonly: false,
        required: true,
        info: 'Please only select Limited Company, they are currently only supported',
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: [
            'Limited Company',
            'Limited Liability Partnership',
            'Partnership (less than 4 partners)',
            'Partnership (4 or more partners)',
            'Sole Trader'
          ]
        }
      },
      self_stated_industry: {
        FieldDefinitionId: 24077,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: [
            'Finance',
            'Agriculture',
            'Automotive',
            'Consumer Services',
            'Retail',
            'Education & Training',
            'Healthcare',
            'Wholesale',
            'Leisure & Hospitality',
            'Arts & Entertainment',
            'I.T and Telecommunications',
            'Professional and Business Support',
            'Property and Construction',
            'Transport and Logistics',
            'Manufacturing and Engineering'
          ]
        }
      },
      full_time_employees: {
        FieldDefinitionId: 24035,
        isReadonly: false,
        required: true,
        info: 'Number of full-time employees, excluding directors',
        FieldDefinition: {
          type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
          requirement: {
            minAmount: 1
          }
        }
      },
      self_stated_turnover: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 24019,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      profit_loss_amount: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 24020,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      overdraft_maximum_available: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 24021,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      overdraft_current_usuage: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 24022,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      other_debt_amount: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 24023,
          isReadonly: false,
          info: 'Amount of new debt taken on in the last 12 months, or 0 if no new debt has been taken on.',
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      self_stated_2019_turnover: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 24024,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      company_established_or_registered_in_northern_ireland: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      company_part_of_group_operating_in_northern_ireland: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      company_provides_specific_service_to_northern_ireland: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      overdraft_current_usage: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      }
    },
    loan_info: {
      term_requested_months: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'Loan term requested in months.',
        FieldDefinition: {
          type: ELenderAPIType.OptionsList,
          options: [
            { label: '6', value: 6 },
            { label: '12', value: 12 },
            { label: '24', value: 24 },
            { label: '36', value: 36 },
            { label: '48', value: 48 },
            { label: '60', value: 60 },
            { label: '72', value: 72 }
          ]
        }
      },
      amount_requested: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'GBP'
        },
        value: {
          FieldDefinitionId: 23996,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Currency, ELenderAPIType.Requirement],
            requirement: {
              minAmount: 10000,
              maxAmount: 750000
            }
          }
        }
      }
    },

    loan_purpose: {
      type: {
        FieldDefinitionId: 24077,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['Working capital', 'Expansion/growth']
        }
      },
      buy_land_property_to_rent: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Whether this loan will be used to buy land or property with the intent of renting or selling it.',
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      change_of_business: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: "Whether this loan will be used to significantly change the business' main activity.",
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      settle_personal_debt: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Whether this loan will be used to settle personal debt.',
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      opportunity_outside_uk: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Whether this loan will be used to fund an opportunity outside the UK.',
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      other_company: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Whether this loan will be used by a company besides the applicant company.',
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      }
    },
    client_consent: {
      client_application_consent: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      client_agreement_privacy_and_tac: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      }
    }
  }));
};
