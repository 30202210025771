import axios, { AxiosRequestConfig } from 'axios';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { notify } from 'components/Notifications/HotToastNotifications';
import { store } from 'redux/store';

/**
 * COMPANY TO IMPORT
 */
export const companyToImport = async (data: any) => {
  const companyNavigator = store.getState().config.companyNavigator;
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/company-navigator/company-to-import`;
  const config: AxiosRequestConfig = {
    method: 'POST',
    url,
    data,
    headers: { token },
    params: { companyNavigator }
  };

  return await axios(config)
    .then((res) => {
      const alreadyExistingRecords =
        res?.data?.filter((i) => i.type === 'ALREADY EXISTS')?.length || 0;
      const failedRecords =
        res?.data?.filter((i) => i.type === 'FAILED')?.length || 0;
      const successfulRecords =
        res?.data?.filter((i) => i.type === 'SUCCESS')?.length || 0;
      const message = `ALREADY EXISTING RECORDS: ${alreadyExistingRecords}, FAILED RECORDS: ${failedRecords}, SUCCESSFUL RECORDS: ${successfulRecords}`;
      gtag.event({
        feature: 'Company Navigator',
        action: 'Checking Company To Create',
        message
      });
      return res;
    })
    .catch((e) => {
      BugTracker(e);

      notify.error(
        `There is a problem with your import, one or more of the directors has a significant number of directorships. Try retrying the import again.`
      );

      return e;
    });
};

/**
 * DIRECTOR TO IMPORT
 */
export const directorToImport = async (data: any) => {
  const companyNavigator = store.getState().config.companyNavigator;
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/company-navigator/director-to-import`;
  const config: AxiosRequestConfig = {
    method: 'POST',
    url,
    data,
    headers: { token },
    params: { companyNavigator }
  };

  return await axios(config)
    .then((res) => {
      const alreadyExistingRecords =
        res?.data?.filter((i) => i.type === 'ALREADY EXISTS')?.length || 0;
      const failedRecords =
        res?.data?.filter((i) => i.type === 'FAILED')?.length || 0;
      const successfulRecords =
        res?.data?.filter((i) => i.type === 'SUCCESS')?.length || 0;
      const message = `ALREADY EXISTING RECORDS: ${alreadyExistingRecords}, FAILED RECORDS: ${failedRecords}, SUCCESSFUL RECORDS: ${successfulRecords}`;
      gtag.event({
        feature: 'Company Navigator',
        action: 'Checking Director To Create',
        message
      });
      return res;
    })
    .catch((e) => {
      BugTracker(e);

      notify.error(
        `There is a problem with your import, one or more of the directors has a significant number of directorships. Try retrying the import again.`
      );

      return e;
    });
};
