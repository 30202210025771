import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import Chip from '@material-ui/core/Chip';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Box,
  ButtonGroup,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  Link,
  TextField
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { theme } from 'theme';
import firebase from 'firebase';
import 'firebase/storage';

import MainCalendar from 'components/Calendar';
import { IEvent } from 'components/Calendar/interface';
import { FbFileRef, UserInstance } from 'types/interfaces';
import { mapEventData } from 'components/Calendar/functions';
import { useTypedSelector } from 'redux/reducers';
import CustomerTypeChip from './components/CustomerType';
import {
  FirestoreDocType,
  HistoryEvent,
  INestedEventType,
  StateType
} from './interface';
import { CustomerType } from 'types/interfaces';
import { IconSelector } from './functions/iconMap';
import FileNotes from './components/FileNotes';
import { handleTypeOrNoteChange } from './functions/handleTypeOrNoteChange';
import FileStoragePicker from 'components/FileStorage/components/FileStoragePicker';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Delete } from '@material-ui/icons';
import { EventNames } from 'components/Marketing/Dashboard/types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: { padding: '6px 16px', position: 'relative' },
  secondaryTail: { backgroundColor: theme.palette.secondary.main },
  filter: { display: 'flex', justifyContent: 'center', width: '100%' },

  editDeleteIcons: {
    position: 'absolute',
    top: 4,
    display: 'flex',
    gap: theme.spacing(1)
  },
  leftAligned: {
    left: 8,
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: 8
  },
  rightAligned: {
    right: 8,
    display: 'flex',
    flexDirection: 'row',
    gap: 8
  },
  attachmentContainer: {
    display: 'flex',
    alignItems: 'flex-center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: '#e0f7fa',
    borderRadius: 8
  },
  leftSide: {
    flexDirection: 'row-reverse'
  },
  rightSide: {
    flexDirection: 'row'
  },
  fileInfoLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(1)
  },
  fileInfoRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: theme.spacing(1)
  },
  deleteButtonGroup: {
    margin: theme.spacing(1),
    marginTop: '30px'
  }
}));

interface ICombinedStateType {
  selectedType: CustomerType;
  fileNotes: string;
  typeHistory: HistoryEvent[];
  fileTypeHistory: HistoryEvent[];
}

const CustomizedTimeline = ({
  data,
  setOpen,
  open,
  loading,
  UserInstance,
  getSystemEvents
}) => {
  const classes = useStyles();
  const user = useTypedSelector((s) => s.user.user);
  const isAdmin = user.SystemAccess >= 10;
  const [firstOpen, setFirstOpen] = React.useState<Boolean>(false);
  const theme = useTheme();
  const [state, setState] = React.useState<StateType>({
    'Customer Type': false,
    'Calendar Event': false,
    'Marketing Campaign': false,
    log: false,
    'File Note': false,
    'Deal Note': false
  });

  // Calendar Events
  const [loadingChips, setLoadingChips] = React.useState<string[]>([]);
  const handleCalendarOpening = () => {
    setFirstOpen(true);
    open ? setOpen(false) : setOpen(true);
    if (!open) {
      Object.keys(state).forEach((key) => {
        setState((s) => ({ ...s, [key]: false }));
      });
    }
  };

  const [eventsData, setEventsData] = useState<FirestoreDocType | null>(null);
  const [combinedState, setCombinedState] = useState<ICombinedStateType>({
    selectedType: CustomerType.None,
    fileNotes: '',
    typeHistory: [],
    fileTypeHistory: []
  });

  const [showTextBox, setShowTextBox] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
  const [editNoteId, setEditNoteId] = useState<number | null>(null);
  const [editText, setEditText] = useState<string>('');
  const textBoxRef = useRef<HTMLDivElement>(null);

  const marketingCampaignEvents = [
    EventNames.Click,
    EventNames.Open,
    EventNames.Delivered,
    EventNames.Bounce,
    EventNames.SpamReport,
    EventNames.Dropped,
    EventNames.Unsubscribe
  ];

  const parentChildChips: Record<string, string[]> = {
    'Calendar Event': [
      'Notes',
      'TODO',
      'Meeting',
      'Call',
      'Birthdays',
      'Deal_Expiry_Date',
      'Other'
    ]
  };

  const handleChipClick = async (key: string) => {
    if (parentChildChips[key]) {
      const newStates = { ...state };
      const childChips = parentChildChips[key];
      const parentIsActive = !state[key];
      childChips.forEach((child) => {
        newStates[child] = parentIsActive;
      });

      setState(newStates);
    }

    if (!state[key]) {
      if (key === 'Calendar Event') {
        setLoadingChips((prevLoadingChips) => [...prevLoadingChips, key]);
        await getSystemEvents();

        setLoadingChips((prevLoadingChips) =>
          prevLoadingChips.filter((k) => k !== key)
        );
      }
    }

    setState((s) => ({ ...s, [key]: !state[key] }));
    setOpen(false);
  };

  const handleTypeChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newType = event.target.value as CustomerType;
    const prevType = combinedState.selectedType;
    const newHistoryEvent: HistoryEvent = {
      name: newType as CustomerType,
      timestamp: Date.now(),
      prevType
    };

    const history = await handleTypeOrNoteChange(
      'Customer Type',
      newType,
      newHistoryEvent,
      UserInstance.Id.toString()
    );

    setCombinedState((prevState) => {
      return {
        ...prevState,
        selectedType: newType as CustomerType,
        typeHistory: history
      };
    });
  };

  const handleNoteChange = async (newNote: string) => {
    const prevNote = combinedState.fileNotes;
    const newHistoryEvent: HistoryEvent = {
      name: newNote,
      timestamp: Date.now(),
      prevType: prevNote
    };
    const history = await handleTypeOrNoteChange(
      'File Note',
      newNote,
      newHistoryEvent,
      UserInstance.Id.toString()
    );

    setCombinedState((prevState) => {
      return {
        ...prevState,
        fileNotes: newNote,
        fileTypeHistory: history
      };
    });
  };

  // to fetch attachments from firebase storage
  const storage = firebase.storage();
  const getFileUrl = async (gsUrl: any) => {
    try {
      const fileRef = storage.refFromURL(gsUrl);
      const url = await fileRef.getDownloadURL();
      return url;
    } catch (error) {
      console.error('Error fetching file URL:', error);
      return null;
    }
  };

  const handleViewFile = async (attachment: FbFileRef) => {
    const fileUrl = await getFileUrl(attachment.link);
    if (!fileUrl) {
      console.error('Failed to fetch file URL');
      return;
    }
    window.open(fileUrl, '_blank');
  };

  // to manage uploading and saving the attachment to file notes
  const handleAttachmentSelect = async (
    noteTimestamp: number,
    {
      url,
      item,
      type
    }: {
      url: string;
      item: FbFileRef;
      type?: 'File Explorer' | 'File Storage';
    }
  ) => {
    const newItem = { ...item, link: url };
    const updatedHistory = combinedState.fileTypeHistory.map((note) => {
      if (note.timestamp === noteTimestamp) {
        const newAttachments = note.attachments
          ? [...note.attachments, newItem]
          : [newItem];
        return { ...note, attachments: newAttachments };
      }
      return note;
    });

    setCombinedState((prev) => ({
      ...prev,
      fileTypeHistory: updatedHistory
    }));

    const docRef = firebase
      .firestore()
      .collection('timeline')
      .doc(UserInstance.Id.toString());

    await docRef.update({
      'File Note.history': updatedHistory
    });
  };

  const handleRemoveAttachment = async (
    noteId: number,
    indexToRemove: number
  ) => {
    const updatedHistory = combinedState.fileTypeHistory.map((note) => {
      if (note.timestamp === noteId) {
        const newAttachments = (note.attachments || []).filter(
          (att, idx) => idx !== indexToRemove
        );
        return { ...note, attachments: newAttachments };
      }
      return note;
    });

    setCombinedState((prev) => ({ ...prev, fileTypeHistory: updatedHistory }));

    const docRef = firebase
      .firestore()
      .collection('timeline')
      .doc(UserInstance.Id.toString());

    await docRef.update({
      'File Note.history': updatedHistory
    });
  };

  // update and put last edit timestamp push up
  const handleEditNote = async (newNote: string, noteId: number | string) => {
    const parsedNoteId = typeof noteId === 'string' ? parseInt(noteId) : noteId;

    const docRef = firebase
      .firestore()
      .collection('timeline')
      .doc(UserInstance.Id.toString());
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data() as FirestoreDocType;
      const currentNote = data['File Note'].history.find(
        (note) => note.timestamp === parsedNoteId
      );
      if (currentNote && currentNote.name === newNote) return;

      const updatedHistory = data['File Note'].history.map((note) =>
        note.timestamp === parsedNoteId
          ? { ...note, name: newNote, timestamp: Date.now() }
          : note
      );

      await docRef.update({
        'File Note.history': updatedHistory,
        'File Note.note': newNote
      });
    }

    setCombinedState((prevState) => ({
      ...prevState,
      fileTypeHistory: prevState.fileTypeHistory.map((note) =>
        note.timestamp === parsedNoteId ? { ...note, name: newNote } : note
      )
    }));
  };

  const handleDeleteNote = async (noteId: number | string) => {
    const parsedNoteId = typeof noteId === 'string' ? parseInt(noteId) : noteId;
    const docRef = firebase
      .firestore()
      .collection('timeline')
      .doc(UserInstance.Id.toString());
    const doc = await docRef.get();
    if (doc.exists) {
      const data = doc.data() as FirestoreDocType;
      const updatedHistory = data['File Note'].history.filter(
        (note) => note.timestamp !== parsedNoteId
      );
      await docRef.update({
        'File Note.history': updatedHistory
      });
    }
    setCombinedState((prevState) => ({
      ...prevState,
      fileNotes: '',
      fileTypeHistory: combinedState.fileTypeHistory.filter(
        (note) => note.timestamp !== parsedNoteId
      )
    }));
  };

  type EventType = 'Customer Type' | 'File Note';
  const updateEventNotes = (note: string, eventType: EventType) => {
    // handling event notes
    const docRef = firebase
      .firestore()
      .collection('timeline')
      .doc(UserInstance.Id.toString());
    docRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data() as FirestoreDocType;

        if (eventType === 'Customer Type' || eventType === 'File Note') {
          if (!data[eventType]) {
            data[eventType] = { history: [], selected: CustomerType.None };
          }
          const historyArray = data[eventType].history || [];
          const indexToUpdate = historyArray.findIndex(
            (event) =>
              typeof selectedEventId === 'string' &&
              `Timestamp:${event.timestamp}-Event:${event.name}` ===
                selectedEventId
          );

          if (indexToUpdate !== -1) {
            historyArray[indexToUpdate].notes = note;

            const updatedData = {
              ...data,
              [eventType]: {
                ...data[eventType],
                history: historyArray
              }
            };

            return docRef.update(updatedData);
          }
        } else {
          if (selectedEventId !== null) {
            const updatedData = {
              ...data,
              [eventType]: {
                ...data[eventType as string],
                [selectedEventId]: note
              }
            };

            return docRef.update(updatedData);
          }
        }
      }
    });

    setShowTextBox(false);
  };

  const handleEventNotes = (note: string, e: any) => {
    let eventType;
    if (isHistoryEvent(e)) {
      if ('name' in e && CustomerType[e.name as CustomerType]) {
        eventType = 'Customer Type';
      } else {
        eventType = 'File Note';
      }
    } else {
      eventType = e.event;
    }

    updateEventNotes(note, eventType);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        textBoxRef.current &&
        !textBoxRef.current.contains(event.target as Node)
      ) {
        setShowTextBox(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [textBoxRef]);

  useEffect(() => {
    if (UserInstance) {
      const docRef = firebase
        .firestore()
        .collection('timeline')
        .doc(UserInstance.Id.toString());

      const unsubscribe = docRef.onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data() as FirestoreDocType;
          setEventsData(data);

          setCombinedState((prevState) => ({
            ...prevState,
            selectedType: data['Customer Type']?.selected || CustomerType.None,
            typeHistory: data['Customer Type']?.history || [],
            fileNotes: data['File Note']?.note || '',
            fileTypeHistory: data['File Note']?.history || []
          }));
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [state]);

  let combinedData = [...data];
  if (state['Customer Type']) {
    combinedData = [
      ...combinedData,
      ...[...combinedState.typeHistory].reverse()
    ];
  }

  if (state['File Note']) {
    combinedData = [
      ...combinedData,
      ...[...combinedState.fileTypeHistory].reverse()
    ];
  }

  if (!firstOpen) setOpen(false);
  return (
    <>
      <div className={classes.filter}>
        {Object.keys(state)
          .filter(
            (key) => !Object.values(parentChildChips).flat().includes(key)
          )
          .map((key, i) => {
            if (key === 'log' && user.SystemAccess < 10) return null;

            const isChipLoading = loadingChips.includes(key);
            if (isChipLoading)
              return (
                <CircularProgress
                  size={30}
                  style={{ margin: theme.spacing(0.5) }}
                />
              );

            return (
              <Chip
                clickable
                color={state[key] ? 'secondary' : 'default'}
                key={i}
                label={
                  key === 'Notes'
                    ? `${key.toUpperCase()}`
                    : `${key.toUpperCase()}S`
                }
                onClick={() => handleChipClick(key)}
                style={{ margin: theme.spacing(0.5) }}
                variant={state[key] ? 'default' : 'outlined'}
              />
            );
          })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: theme.spacing(1)
        }}>
        <Button
          color="primary"
          onClick={() => handleCalendarOpening()}
          variant="contained">
          {open ? 'Hide Calendar' : 'Show Calendar'}
        </Button>
      </div>
      {state['Customer Type'] && (
        <div style={{ margin: theme.spacing(2) }}>
          <CustomerTypeChip
            UserInstance={UserInstance}
            selectedType={combinedState.selectedType}
            handleTypeChange={handleTypeChange}
          />
        </div>
      )}
      {state['File Note'] && (
        <div style={{ margin: theme.spacing(2) }}>
          <FileNotes handleNoteChange={handleNoteChange} />
        </div>
      )}

      {open && firstOpen && <Calendar UserInstance={UserInstance} />}
      <Timeline align="alternate">
        {combinedData.length > 0 &&
          combinedData
            .filter((e) => {
              if ('event' in e) return state[e.event];
              return true;
            })
            .sort((a, b) => b.timestamp - a.timestamp)
            .map((e, i, arr) => {
              const isLeftSide = i % 2 === 0;
              const today = new Date();
              const isFileNoteEvent = combinedState.fileTypeHistory.some(
                (note) => note.timestamp === e.timestamp
              );
              let timestamp;
              if (isHistoryEvent(e)) timestamp = new Date(e.timestamp);
              else timestamp = new Date(e.timestamp * 1000);
              const time = timestamp.toLocaleTimeString();
              const date = timestamp.toDateString();
              const futureEvent = timestamp > today;

              let value;
              if (isHistoryEvent(e)) {
                value = e.prevType ? (
                  <Typography color="primary">
                    Changed from {e.prevType}.
                  </Typography>
                ) : (
                  <Typography color="primary">
                    Create New Customer Type.
                  </Typography>
                );
              } else {
                value =
                  e.event === 'Deal Note'
                    ? JSON.parse(e.singlesend_name)
                    : e.singlesend_name;
              }

              let selectedEvent;
              if (isHistoryEvent(e)) {
                selectedEvent = `Timestamp:${e.timestamp}-Event:${e.name}`;
              } else {
                selectedEvent = `Timestamp:${e.timestamp}-Event:${e.event}-EventSubject:${e.singlesend_name}`;
              }

              return (
                <TimelineItem key={i}>
                  <TimelineOppositeContent>
                    <Typography color="textSecondary" variant="body2">
                      {date}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {time}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      color="secondary"
                      variant={futureEvent ? 'outlined' : 'default'}>
                      <IconSelector
                        type={isHistoryEvent(e) ? e.name : e.event}
                      />
                    </TimelineDot>
                    {i < arr.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      onClick={() => {
                        setShowTextBox(!showTextBox);

                        let selectedEvent;
                        if (isHistoryEvent(e)) {
                          selectedEvent = `Timestamp:${e.timestamp}-Event:${e.name}`;
                        } else {
                          selectedEvent = `Timestamp:${e.timestamp}-Event:${e.event}-EventSubject:${e.singlesend_name}`;
                        }

                        setSelectedEventId(selectedEvent);
                      }}
                      className={classes.paper}
                      elevation={3}
                      style={{
                        paddingBottom: 16,
                        position: 'relative'
                      }}>
                      <Typography component="h1" variant="h6">
                        {isHistoryEvent(e)
                          ? e.name.toUpperCase()
                          : e.event.toUpperCase()}
                      </Typography>
                      {e.event === 'Deal Note' ? (
                        <Grid container direction="column">
                          <Grid item>
                            <Typography
                              style={{ fontWeight: 'bold' }}
                              display="inline">
                              Deal ID:{' '}
                            </Typography>
                            <Typography color="primary" display="inline">
                              {value.dealId
                                ? value.dealId
                                : `Couldn't Find Deal Id`}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography
                              style={{ fontWeight: 'bold' }}
                              display="inline">
                              Deal Type:{' '}
                            </Typography>
                            <Typography color="primary" display="inline">
                              {value.dealType
                                ? value.dealType
                                : `Couldn't Find Deal Type`}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography
                              style={{ fontWeight: 'bold' }}
                              display="inline">
                              Deal Message:{' '}
                            </Typography>
                            <Typography color="primary" display="inline">
                              {value.value !== ''
                                ? ReactHtmlParser(value.value)
                                : 'No Message'}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography color="primary">{value}</Typography>
                      )}

                      {isHistoryEvent(e)
                        ? e.notes && (
                            <Typography
                              color="primary"
                              style={{ fontWeight: 'bold' }}>
                              Note: {e.notes}
                            </Typography>
                          )
                        : eventsData &&
                          eventsData[e.event] &&
                          eventsData[e.event][selectedEvent] && (
                            <Typography
                              color="primary"
                              style={{ fontWeight: 'bold' }}>
                              Note: {eventsData[e.event][selectedEvent]}
                            </Typography>
                          )}
                      {isAdmin && state['File Note'] && isFileNoteEvent && (
                        <div
                          onClick={(event) => event.stopPropagation()}
                          style={{ marginLeft: -10 }}>
                          <FileStoragePicker
                            plugin
                            title="Attach File"
                            onSelect={({ url, item, type }) =>
                              handleAttachmentSelect(e.timestamp, {
                                url,
                                item,
                                type
                              })
                            }
                          />
                        </div>
                      )}

                      {state['File Note'] &&
                        isFileNoteEvent &&
                        e.attachments &&
                        e.attachments.length > 0 && (
                          <div
                            style={{
                              border: '1px solid #ccc',
                              padding: 8,
                              borderRadius: 8,
                              marginTop: 16
                            }}>
                            <Grid container spacing={2}>
                              {e.attachments.map(
                                (att: FbFileRef, index: number) => (
                                  <Grid item xs={12} key={index}>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      padding={1}
                                      style={{
                                        backgroundColor: '#e0f7fa',
                                        borderRadius: 8
                                      }}
                                      className={clsx(
                                        classes.attachmentContainer,
                                        isLeftSide
                                          ? classes.leftSide
                                          : classes.rightSide
                                      )}>
                                      <Box
                                        className={
                                          isLeftSide
                                            ? classes.fileInfoRight
                                            : classes.fileInfoLeft
                                        }>
                                        <Typography variant="h6">
                                          {att.nickName || att.name}
                                        </Typography>
                                        <Typography variant="caption">
                                          Size: {(att.size / 1024).toFixed(2)}{' '}
                                          KB
                                        </Typography>

                                        {att.link && (
                                          <Link
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              handleViewFile(att);
                                            }}
                                            style={{
                                              cursor: 'pointer',
                                              color: 'blue',
                                              textDecoration: 'underline'
                                            }}>
                                            View File
                                          </Link>
                                        )}
                                      </Box>
                                      <ButtonGroup
                                        variant="outlined"
                                        className={classes.deleteButtonGroup}>
                                        <Button
                                          style={{ color: '#E57373' }}
                                          onClick={(clickEvent) => {
                                            clickEvent.stopPropagation();
                                            handleRemoveAttachment(
                                              e.timestamp,
                                              index
                                            );
                                          }}>
                                          <Delete />
                                        </Button>
                                      </ButtonGroup>
                                    </Box>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </div>
                        )}

                      {showTextBox && selectedEventId === selectedEvent && (
                        <div ref={textBoxRef}>
                          <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            placeholder="Write Your Note Here"
                            onClick={(event) => event.stopPropagation()}
                            onBlur={(event) => {
                              const note = event.target.value;
                              handleEventNotes(note, e);
                            }}
                            onKeyPress={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                                const note = (event.target as HTMLInputElement)
                                  .value;

                                handleEventNotes(note, e);
                              }
                            }}
                          />
                        </div>
                      )}
                      {isFileNoteEvent && isAdmin && state['File Note'] && (
                        <>
                          {editNoteId === e.timestamp && (
                            <TextField
                              type="text"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              value={editText}
                              onChange={(e) => setEditText(e.target.value)}
                              onBlur={() => {
                                handleEditNote(editText, e.timestamp);
                                setEditNoteId(null);
                              }}
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  handleEditNote(editText, e.timestamp);
                                  setEditNoteId(null);
                                }
                              }}
                              autoFocus
                            />
                          )}
                          <div
                            className={`${classes.editDeleteIcons} ${
                              isLeftSide
                                ? classes.rightAligned
                                : classes.leftAligned
                            }`}>
                            {isLeftSide ? (
                              <>
                                <IconButton
                                  style={{ color: '#4A90E2' }}
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditText(e.name);
                                    setEditNoteId(e.timestamp);
                                  }}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  style={{ color: '#E57373' }}
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteNote(e.timestamp);
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            ) : (
                              // Right side: Edit icon first, then Delete icon
                              <>
                                <IconButton
                                  style={{ color: '#4A90E2' }}
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditText(e.name);
                                    setEditNoteId(e.timestamp);
                                  }}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  style={{ color: '#E57373' }}
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteNote(e.timestamp);
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        {state['Marketing Campaign'] &&
          data
            .filter((e) => {
              if (marketingCampaignEvents.includes(e.event)) {
                return state['Marketing Campaign'];
              }
              return state[e.event];
            })
            .map((e, i) => {
              let timestamp;
              if (isHistoryEvent(e)) timestamp = new Date(e.timestamp);
              else timestamp = new Date(e.timestamp * 1000);
              const time = timestamp.toLocaleTimeString();
              const date = timestamp.toDateString();
              return (
                <TimelineItem key={i}>
                  <TimelineOppositeContent>
                    <Typography color="textSecondary">
                      <Typography color="textSecondary" variant="body2">
                        {date}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {time}
                      </Typography>
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary">
                      <IconSelector type={e.event} />
                    </TimelineDot>
                    {i < data.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} style={{ padding: '16px' }}>
                      <Typography
                        variant="h6"
                        style={{ textTransform: 'capitalize' }}>
                        Name: {e.singlesend_name}
                      </Typography>
                      <Typography color="primary">Status: {e.event}</Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
      </Timeline>
    </>
  );
};

export default CustomizedTimeline;

const isHistoryEvent = (event: HistoryEvent | Event): event is HistoryEvent => {
  return 'name' in event;
};

const Calendar = ({ UserInstance }: { UserInstance: UserInstance }) => {
  const user = useTypedSelector((s) => s.user.user);
  const [timelineEvents, setTimelineEvents] = useState<{
    UserInstance: UserInstance | undefined;
    Event: IEvent[];
  }>();

  const fetchUserEvents = async () => {
    const userInstanceId = UserInstance.Id;
    const eventsRef = firebase.firestore().collection('calendar');
    const ownedEventsQuery = eventsRef.where(
      'extendedProps.calendarOwner',
      '==',
      user.Id
    );

    const ownedSnapshot = await ownedEventsQuery.get();
    const relevantEvents = ownedSnapshot.docs.reduce((acc, doc) => {
      const data = doc.data() as IEvent;

      const isInvitedGuest =
        data.extendedProps?.invitedUserInstanceIds?.includes(userInstanceId);

      if (isInvitedGuest || UserInstance.Id === user.Id)
        acc.push(mapEventData(doc));
      return acc;
    }, [] as IEvent[]);

    setTimelineEvents({
      UserInstance,
      Event: relevantEvents
    });
  };

  const refreshTimelineEvents = async () => {
    await fetchUserEvents();
  };

  return (
    <div
      style={{
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2)
      }}>
      <MainCalendar
        timelineEvents={timelineEvents}
        setTimelineEvents={setTimelineEvents}
        refreshTimelineEvents={refreshTimelineEvents}
        isTimeline
      />
    </div>
  );
};
