const PremiumCreditProposal = {
  loanCheckReference: '',
  applicationReference: '',
  details: {
    inceptionDate: '',
    depositAmount: 0,
    numberOfCollections: 0,
    collectionAmount: 0,
    netFinancePercentage: 0,
    aprPercentage: 0,
    grossFinancePercentage: 0,
    serviceChargePercentage: 0,
    facilityFeeAmount: 0,
    terms: 0,
    creditAgreementDate: '',
    creditRenewalDate: '',
    loanAmount: 0,
    totalPayableAmount: 0,
    currency: '',
    schemeBranch: '',
    schemeCode: '',
    additionalCoverCode: '',
    schemeBrokerNumber: '',
    clientBusinessType: '',
    calculationBasis: '',
    arrangementType: '',
    onboardingType: '',
    advancedPayment: {
      paymentAmount: 0,
      collectionsCovered: 0,
      pclPaymentMethod: ''
    }
  },
  providerPurchasedProducts: [
    {
      provider: '',
      providerReference: '',
      providerPurchasedProductType: '',
      productStartDate: '',
      productEndDate: '',
      additionalTaxAmount: 0,
      vatAmount: 0,
      productValueAmount: 0,
      productRefundType: '',
      productSequenceNumber: 0,
      introducerSchemeCode: '',
      introducerAmount: 0,
      ppwType: '',
      ppwDueDate: ''
    }
  ],
  collectionDetails: {
    preferredPaymentDate: 0,
    isThirdPartyPayer: false,
    tppReason: '',
    tppRelationship: '',
    bankDetails: {
      sortCode: '',
      accountNumber: '',
      accountName: '',
      bic: '',
      iban: ''
    }
  },
  contactReferences: [
    {
      appointedRoles: [
        {
          roleType: '',
          relatedContactReferenceResourceID: ''
        }
      ],
      contact: {
        individualContact: {
          contactEntityDetails: {
            title: '',
            forename: '',
            middleName: '',
            surname: '',
            dateOfBirth: '',
            gender: '',
            nationality: '',
            dualNationality: '',
            mothersMaidenName: '',
            placeOfBirth: '',
            birthSurname: '',
            identityDocuments: [
              {
                documentCategory: '',
                documentNumber: '',
                dateIssued: '',
                expiryDate: '',
                placeIssued: ''
              }
            ],
            income: {
              annualIncome: 0,
              incomeType: '',
              frequency: ''
            },
            employment: [
              {
                employmentStatus: '',
                employerName: '',
                employerBusinessCategoryCode: '',
                occupation: '',
                lengthOfEmployment: 0,
                employmentRelevancy: '',
                employerAddress: {
                  premises: '',
                  addressLine1: '',
                  addressLine2: '',
                  addressLine3: '',
                  addressLine4: '',
                  postTown: '',
                  postcode: '',
                  country: ''
                }
              }
            ],
            expenditure: {
              annualExpenditure: 0,
              totalCreditCards: 0,
              totalDebitCards: 0,
              monthlyUnsecuredOrHousing: 0,
              monthlyOtherPayments: 0
            }
          },
          contactEntityType: '',
          addresses: [
            {
              addressRelevancy: '',
              dateMovedOut: '',
              postcodeAddress: {
                premises: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                postTown: '',
                postcode: '',
                country: ''
              },
              isBFPO: false,
              dateMovedIn: '',
              addressType: ''
            }
          ],
          phoneNumbers: [
            {
              phoneNumberType: '',
              phoneNumber: '',
              extension: ''
            }
          ],
          emailAddresses: [
            {
              emailAddressType: '',
              emailAddress: ''
            }
          ]
        },
        organisationContact: {
          contactEntityDetails: {
            legalName: '',
            tradingName: '',
            businessName: '',
            companyRegistrationNumber: '',
            yearsInBusiness: 0,
            businessCategoryCode: '',
            industryCodes: [''],
            yearsKnownToProducer: 0,
            vatNumber: '',
            charityNumber: '',
            consumerCreditLicenceNumber: '',
            countriesTradingWith: [''],
            countriesOperationalIn: [''],
            organisationContacts: ['']
          },
          contactEntityType: '',
          addresses: [
            {
              addressRelevancy: '',
              dateMovedOut: '',
              postcodeAddress: {
                premises: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                addressLine4: '',
                postTown: '',
                postcode: '',
                country: ''
              },
              isBFPO: false,
              dateMovedIn: '',
              addressType: ''
            }
          ],
          phoneNumbers: [
            {
              phoneNumberType: '',
              phoneNumber: '',
              extension: ''
            }
          ],
          emailAddresses: [
            {
              emailAddressType: '',
              emailAddress: ''
            }
          ]
        }
      },
      resourceID: ''
    }
  ],
  clientReference: ''
};

export default PremiumCreditProposal;
