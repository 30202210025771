import { PremiumCredit } from '..';
import { ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';

export const getPremiumCreditIds = () => {
  return createTypeSafeIds(PremiumCredit, (_) => ({
    loanCheckReference: {
      FieldDefinitionId: 0,
      isReadonly: false,
      info: 'The decision in principle (DIP) application reference'
    },
    applicationReference: {
      FieldDefinitionId: 0,
      isReadonly: false
    },
    details: {
      inceptionDate: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'YYYY-MM-DD. The date of the underlying policy or contract which is being funded. Where not supplied, the CreditAgreementDate will be used.',
        FieldDefinition: {
          type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
          requirement: {
            pattern: '^\\d{4}-\\d{2}-\\d{2}$'
          }
        }
      },

      depositAmount: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The deposit amount to be paid by the customer.'
      },
      numberOfCollections: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'The confirmed number of collections for this agreement. Maximum: 100 Minimum: 0',
        FieldDefinition: {
          type: [ELenderAPIType.Requirement, ELenderAPIType.Number],
          requirement: {
            minAmount: 0,
            maxAmount: 100
          }
        }
      },
      collectionAmount: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'The expected standard collection amount, excluding rounding and facilities fees. The facility fees will be collected with the first instalment.',
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      netFinancePercentage: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'We recommend using the ServiceChargePercentage field instead of this field. The rate PCL charges excluding the Overrider amount. Overrider: The amount by which a producer can increase or decrease their commission from the standard amount.',
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      aprPercentage: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Calculated APR, adjusted rate calculated if Additional Transaction Fee or Minimum Transaction Fee applies and is chargeable to the customer.',
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      grossFinancePercentage: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'We recommend using the ServiceChargePercentage field instead of this field. The rate PCL charges including the Overrider. Overrider: The amount by which a producer can increase or decrease their commission from the standard amount.',
        FieldDefinition: {
          type: ELenderAPIType.Percentage
        }
      },
      serviceChargePercentage: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The Transaction Fee Rate to be applied. If the Transaction Fee Rate is below the Minimum Service Charge (MSC), PCL will automatically increase the Service Charge Percentage to accommodate MSC.',
        FieldDefinition: {
          type: ELenderAPIType.Percentage
        }
      },
      facilityFeeAmount: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'The amount of the Facility Fee to be applied. This is generally added to the CollectionAmount and included with the first collection.',
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      terms: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'This is the terms of credit (TOC) which is the number of days until a producer or insurer is paid.',
        FieldDefinition: {
          type: ELenderAPIType.Number
        }
      },
      creditAgreementDate: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The date the credit agreement is to commence.',
        FieldDefinition: {
          type: ELenderAPIType.Date
        }
      },
      creditRenewalDate: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The date that the agreement is due for renewal. E.g. for a 12-month agreement term, regardless of the number of collections, this date would be 12 months from the CreditAgreementDate.',
        FieldDefinition: {
          type: ELenderAPIType.Date
        }
      },
      loanAmount: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'The loan amount to be financed by PCL. This amount should be inclusive of fees for a CalculationBasis of Gross or exclusive of fees for a CalculationBasis of Net.',
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      totalPayableAmount: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'The total amount that the customer is liable to pay to PCL, including the TransactionFeeAmount and any FacilityFeeAmount.',
        FieldDefinition: {
          type: ELenderAPIType.Currency
        }
      },
      currency: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'GBP'
      },
      schemeCode: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['XJ500', 'XJ501']
        }
      },
      additionalCoverCode: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The code indicating any additional product options if applicable.'
      },
      schemeBrokerNumber: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Reference number of broker setup with Premium Credit.'
      },
      clientBusinessType: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'Although the business is new to PCL, this field indicates whether the agreement is a NewBusiness or a Renewal to the client.',
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['NewBusiness', 'Renewal']
        }
      },
      calculationBasis: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'As per the selected PCL Scheme,the CalculationBasis indicates whether the BaseTransactionAmount is inclusive of PCL fees and overriders (Gross) or exclusive (Net)',
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['Gross', 'Net']
        }
      },
      arrangementType: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: [
            'Personal',
            'Commercial',
            'Collection',
            'PIC',
            'FMIS',
            'SingleCollection'
          ]
        }
      },
      onboardingType: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['Electronic', 'Paper']
        }
      },
      advancedPayment: {
        paymentAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'The value of the payment being made.',
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        collectionsCovered: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'The number of collections this payment covers.',
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        pclPaymentMethod: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'Broker Cheque',
              'Client Cheque',
              'Broker Credit Transfer',
              'Client Credit Transfer',
              'Client Card Payment. Ireland: Broker Credit Transfer',
              'Client Credit Transfer',
              'Client Card Payment'
            ]
          }
        }
      }
    },
    providerPurchasedProducts: [
      {
        provider: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'The underlying provider code (e.g. for an insurer). Obtain the code from PCL.'
        },
        providerReference: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'The user defined reference provided by the partner for the policy/provider record being entered. E.g. for an insurer record, this could be the insurance policy reference.'
        },
        providerPurchasedProductType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'The underlying product code for the type of product purchased from the provider. E.g. for insurance policies this could be motor cover. Obtain the code from PCL.'
        },
        productStartDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'YYYY-MM-DD. The cover start date for the product purchased. E.g. the date the cover starts for an insurance policy.',
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        productEndDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'YYYY-MM-DD. The cover end date for the product purchased. E.g. the date the cover ends for an insurance policy.',
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        additionalTaxAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'Additional tax amount.',
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        vatAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        productValueAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'Product value amount. E.g. an insurance policy premium.',
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        productRefundType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'Product refund type is MANDATORY for Non-Recourse loans. Possible vlaues: Prorata, Short, MD which applies to the refund type given by the producer.'
        },
        introducerSchemeCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'The scheme producer code or the scheme branch code of the introducer (e.g. the insurer).',
          FieldDefinition: {
            type: [ELenderAPIType.Number, ELenderAPIType.FinanceParent]
          }
        },
        introducerAmount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'The amount to be paid to the introducer.',
          FieldDefinition: {
            type: [
              ELenderAPIType.Currency,
              ELenderAPIType.FinanceDependentRequired
            ]
          }
        },
        ppwType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'Premium Payment Warranty (PPW). Possible values: Premium condition, Premium warranty.',
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['Premium condition', 'Premium warranty']
          }
        },
        ppwDueDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'YYYY-MM-DD. Due date of Premium Payment Warranty (PPW).',
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        }
      }
    ],
    collectionDetails: {
      preferredPaymentDate: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'YYYY-MM-DD. When submitted, this is the preferred day of the month that the collection should be taken. Maximum: 31 Minimum: 1',
        FieldDefinition: {
          type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
          requirement: {
            minAmount: 1,
            maxAmount: 31
          }
        }
      },
      isThirdPartyPayer: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Flag indicating if the party repaying the loan is different to the party that is the holder of the agreement. If IsThirdPartyPayer value is set to true, provide a new contact who has a role type as payer.',
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      tppReason: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The reason there is a third-party payer and is only required if IsThirdPartyPayer is true.'
      },
      tppRelationship: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'The relationship of the third-party payer to the agreement-holder. This is only required if IsThirdPartyPayer is true.'
      },
      bankDetails: {
        sortCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        accountNumber: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        accountName: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: "The bank account holder's name."
        }
      }
    },
    contactReferences: [
      {
        appointedRoles: [
          {
            roleType: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: 'A role type can be either on the agreement, the collection bank details or an organisation.',
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'AgreementHolder',
                  'JointAgreementHolder',
                  'Payer',
                  'Executor',
                  'AgreementSignatory',
                  'PersonalRepresentative',
                  'Guarantor',
                  'PartnerExec',
                  'PartnerAdmin',
                  'AccountHolder',
                  'JointAccountHolder',
                  'BankSignatory',
                  'BeneficialOwner',
                  'AuthorisedSignatory',
                  'Partner',
                  'CommitteeMember',
                  'PrimaryContact',
                  'SecondaryContact'
                ]
              }
            }
          }
        ],
        resourceID: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'When submitted within a transaction request, this value is submitter-defined and must be unique within the collection of ContactReferences within the message.'
        },
        contact: {
          individualContact: {
            contactEntityDetails: {
              title: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'Baron',
                    'Baroness',
                    'Captain',
                    'Count',
                    'Countess',
                    'Dame',
                    'Doctor',
                    'Dr',
                    'Dr.',
                    'Esquire',
                    'Execu',
                    'Father',
                    'Lady',
                    'Lieutenant',
                    'Lord',
                    'Miss',
                    'Miss.',
                    'Mr',
                    'Mr.',
                    'Mrs',
                    'Mrs.',
                    'Ms',
                    'Ms.',
                    'Mx',
                    'Officer',
                    'Professor',
                    'Reverend',
                    'Sir'
                  ]
                }
              },
              forename: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              surname: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              dateOfBirth: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              },
              gender: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['Male', 'Female', 'Unspecified']
                }
              },
              identityDocuments: [
                {
                  documentCategory: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  documentNumber: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  dateIssued: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Date
                    }
                  }
                }
              ],
              income: {
                annualIncome: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                incomeType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Net', 'Gross']
                  }
                },
                frequency: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: [
                      'Fortnightly',
                      'TwiceMonthly',
                      '4Weekly',
                      'Monthly',
                      '2Monthly',
                      '3Monthly',
                      '4Monthly',
                      '6Monthly',
                      'Annually',
                      'Variable, Other',
                      'Unknown',
                      'Weekly',
                      'Withheld'
                    ]
                  }
                }
              },
              employment: [
                {
                  employmentStatus: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: [
                        'Employed',
                        'Unemployed',
                        'SelfEmployed',
                        'HousePerson',
                        'InEducation',
                        'Retired',
                        'NotEmployedDueToDisabilityOrIllness'
                      ]
                    }
                  },
                  occupation: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  employerName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  employerBusinessCategoryCode: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    info: 'The industry code related to the industry the customer operates in. E.g. Accountancy. Refer to the Resources section in the developer portal for the list of business category codes.'
                  },
                  lengthOfEmployment: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'Length of employment (in months).',
                    FieldDefinition: {
                      type: ELenderAPIType.Number
                    }
                  },
                  employmentRelevancy: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['Current', 'Previous']
                    }
                  },
                  employerAddress: {
                    addressLine1: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      required: true
                    },
                    addressLine2: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      required: true
                    },
                    country: {
                      FieldDefinitionId: 0,
                      isReadonly: true,
                      forcedValue: 'UK',
                      info: 'Country ISO code'
                    }
                  }
                }
              ],
              expenditure: {
                annualExpenditure: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Annual expenditure of the contact.',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                totalCreditCards: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Total number of credit cards that the contact holds.',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                totalDebitCards: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Total number of debit cards that the contact holds.',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                monthlyUnsecuredOrHousing: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Monthly unsecured or housing expenses to the nearest pound.',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                monthlyOtherPayments: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Monthly other expenses to the nearest pound.',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                }
              }
            },
            contactEntityType: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'Private-Individual',
                  'Sole-Trader',
                  'Limited-Company',
                  'LLP',
                  'PLC',
                  'Partnership-3-or-less',
                  'Partnership-4-or-more',
                  'Unincorporated-Body',
                  'Trust-or-Foundation',
                  'Place-of-Worship',
                  'Club-Society-Association',
                  'Unlimited-Company'
                ]
              }
            },
            addresses: [
              {
                addressRelevancy: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Current', 'Previous']
                  }
                },
                dateMovedOut: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'YYYY-MM-DD. Only populated if AddressRelevancy is Previous. If only month and year are known, use the first day of the month.',
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                },
                postcodeAddress: {
                  addressLine1: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  addressLine2: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  country: {
                    FieldDefinitionId: 0,
                    isReadonly: true,
                    forcedValue: 'UK',
                    info: 'Country ISO code'
                  }
                },
                isBFPO: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                dateMovedIn: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                },
                addressType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: [
                      'Home',
                      'Correspondance',
                      'Business',
                      'Registered'
                    ]
                  }
                }
              }
            ],
            phoneNumbers: [
              {
                phoneNumberType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: [
                      'PersonalLandline',
                      'BusinessLandline',
                      'PersonalMobile',
                      'BusinessMobile'
                    ]
                  }
                },
                phoneNumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                }
              }
            ],
            emailAddresses: [
              {
                emailAddressType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Personal', 'Business']
                  }
                },
                emailAddress: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 255,
                      pattern:
                        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                      message: 'Please enter a valid email address'
                    }
                  }
                }
              }
            ]
          },
          organisationContact: {
            contactEntityDetails: {
              legalName: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'The legal name of the organisation.'
              },
              tradingName: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'The trading name of the organisation. TradingName is MANDATORY for a Sole-Trader.'
              },
              businessName: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'The business name of the organisation.'
              },
              companyRegistrationNumber: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: "The organization's company registration number. Company registration number is mandatory for Limited-Company, PLC, LLP, Unlimited-Company."
              },
              yearsInBusiness: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              businessCategoryCode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Refer to the Resources section in the developer portal for the list of business category codes.'
              },
              yearsKnownToProducer: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              consumerCreditLicenceNumber: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'If the organisation is involved in consumer credit activities then this field will hold the consumer credit licence number.'
              }
            },
            contactEntityType: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'Private-Individual',
                  'Sole-Trader',
                  'Limited-Company',
                  'LLP',
                  'PLC',
                  'Partnership-3-or-less',
                  'Partnership-4-or-more',
                  'Unincorporated-Body',
                  'Trust-or-Foundation',
                  'Place-of-Worship',
                  'Club-Society-Association',
                  'Unlimited-Company'
                ]
              }
            },
            addresses: [
              {
                addressRelevancy: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Current', 'Previous']
                  }
                },
                dateMovedOut: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'YYYY-MM-DD. Only populated if AddressRelevancy is Previous. If only month and year are known, use the first day of the month.',
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                },
                postcodeAddress: {
                  addressLine1: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  addressLine2: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  country: {
                    FieldDefinitionId: 0,
                    isReadonly: true,
                    forcedValue: 'UK',
                    info: 'Country ISO code'
                  }
                },
                isBFPO: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                dateMovedIn: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                },
                addressType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: [
                      'Home',
                      'Correspondance',
                      'Business',
                      'Registered'
                    ]
                  }
                }
              }
            ],
            phoneNumbers: [
              {
                phoneNumberType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: [
                      'PersonalLandline',
                      'BusinessLandline',
                      'PersonalMobile',
                      'BusinessMobile'
                    ]
                  }
                },
                phoneNumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                }
              }
            ],
            emailAddresses: [
              {
                emailAddressType: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Personal', 'Business']
                  }
                },
                emailAddress: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 255,
                      pattern:
                        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                      message: 'Please enter a valid email address'
                    }
                  }
                }
              }
            ]
          }
        }
      }
    ],
    clientReference: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      info: 'The reference used by the submitting party to uniquely identify the agreement.'
    }
  }));
};
