import React from 'react';
import { BugTracker } from 'Utils/Bugtracker';
import {
  FieldInstance,
  CompleteUserInstance,
  CompleteObjectInstance
} from 'types/interfaces';
import { GetDealColourList } from 'redux/database/Process Definition API';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

interface IProps {
  userInstance: CompleteUserInstance;
}

const OBJECT_DEFINITION_ID = 3464;
const FIELD_DEFINITION_ID = 21745;
export const DIRECTORS_OBJECT_DEFINITION_ID = 2853;

export const checkDirectorFields = (
  CompleteObjectInstanceList: CompleteObjectInstance[],
  amountToCheck: number
) => {
  return CompleteObjectInstanceList.every(
    (CompleteObjectInstance: CompleteObjectInstance) => {
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      const nonEmptyFieldValues = FieldInstanceList.filter(
        (FieldInstance: FieldInstance) => FieldInstance.FieldValue !== ''
      );
      return nonEmptyFieldValues.length >= amountToCheck;
    }
  );
};

export const useProcessEntityType = ({ userInstance }: IProps) => {
  const UserInstanceId =
    userInstance && userInstance.UserInstance && userInstance.UserInstance.Id;
  const [options, setOptions] = React.useState([]);
  const [message, setMessage] = React.useState<null | string>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [openLoading, setOpenLoading] = React.useState(false);

  const getData = async () => {
    if (userInstance) {
      try {
        const getColors = await GetDealColourList({ UserInstanceId });

        if (Object.keys(getColors.data).length > 0) {
          setMessage(null);
          setOptions(getColors.data);
          setOpenLoading(false);
          setLoading(false);

          return;
        } else {
          return setMessage('No available deals');
        }
      } catch (e) {
        BugTracker.notify(e);
      }
    }
  };

  const processEntity = async (
    CompleteUserInstanceDetail: CompleteObjectInstance[]
  ) => {
    if (
      !CompleteUserInstanceDetail ||
      !Array.isArray(CompleteUserInstanceDetail)
    ) {
      return false;
    }

    const hasEntity = CompleteUserInstanceDetail.find(
      (completeObjectInstance: CompleteObjectInstance) =>
        completeObjectInstance.ObjectInstance &&
        completeObjectInstance.ObjectInstance.ObjectDefinitionId ===
          OBJECT_DEFINITION_ID
    );

    if (!hasEntity) {
      await getData();
      return false;
    }

    const getEntity = hasEntity.FieldInstanceList.find(
      (fieldInstance: FieldInstance) =>
        fieldInstance.FieldDefinitionId === FIELD_DEFINITION_ID
    );

    if (!getEntity) {
      await getData();
      return false;
    }

    // if (getEntity.FieldValue === 'Partnership (4+)') {
    //   const getDirector = CompleteUserInstanceDetail.filter(
    //     (completeObjectInstance: CompleteObjectInstance) =>
    //       completeObjectInstance.ObjectInstance &&
    //       completeObjectInstance.ObjectInstance.ObjectDefinitionId ===
    //         DIRECTORS_OBJECT_DEFINITION_ID
    //   );

    //   if (getDirector.length >= 4) {
    //     const getDirectorCheck = checkDirectorFields(getDirector, 4);
    //     if (getDirectorCheck) {
    //       await getData();
    //       return true;
    //     } else {
    //       dispatch(
    //         createNotification(
    //           errorNotif(
    //             `Partnership (4+) Requires At Least 4 Directors That Contain Information.`
    //           )
    //         )
    //       );
    //       setOpenLoading(false);
    //       return false;
    //     }
    //   }

    //   dispatch(
    //     createNotification(
    //       errorNotif(
    //         `Partnership (4+) Requires At Least 4 Directors. Please Add More Directors To The Company.`
    //       )
    //     )
    //   );
    //   setOpenLoading(false);
    //   return false;
    // }

    await getData();
    return true;
  };

  return {
    processEntity,
    options,
    message,
    anchorEl,
    loading,
    openLoading,
    setAnchorEl,
    setLoading,
    setMessage,
    setOptions,
    setOpenLoading
  };
};
