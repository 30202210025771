import { ApolloError } from '@apollo/client';
import {
  IAccountDataResult,
  ICreateDocumentInput,
  IFraudCheckResult
} from 'graphql/FileStorageAPI/interface';
import { store } from 'redux/store';
import { CreateFraudulentDocumentUUID } from '../mutations';
import { BugTracker } from 'Utils/Bugtracker';
import { getDataResults, getFraudResults } from '../queries';
import { notify } from 'components/Notifications/HotToastNotifications';

const dispatchError = (error: ApolloError) => {
  notify.error(error.message);
};

/**
 * Action to create a new document.
 * @param {CreateDocumentInput} input - The input data for creating the document.
 * @returns {Promise<{ uuid: string } | undefined>} - A promise resolving to the created document's UUID or undefined if an error occurs.
 */

const createFraudulentDocumentUUID = async ({
  input
}: {
  input: ICreateDocumentInput;
}): Promise<string | undefined> => {
  try {
    const result = await CreateFraudulentDocumentUUID({ input });

    // if (result instanceof ApolloError) {
    //   dispatchError(result);
    //   return undefined;
    // }

    return result;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    notify.error(errorMessage);

    return undefined;
  }
};

/**
 * Action to fetch fraud check results.
 * @param {string} collectionId - The ID of the collection to check for fraud.
 * @returns {Promise<IFraudCheckResult | undefined>} - A promise resolving to the fraud check results or undefined if an error occurs.
 */
export const getFraudResultsAction = async ({
  collectionId
}: {
  collectionId: string;
}): Promise<IFraudCheckResult | undefined> => {
  try {
    const result = await getFraudResults({ collectionId });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result.data;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    notify.error(errorMessage);

    return undefined;
  }
};

/**
 * Action to fetch account data results.
 * @param {string} collectionId - The ID of the collection to retrieve account data for.
 * @returns {Promise<IAccountDataResult | undefined>} - A promise resolving to the account data results or undefined if an error occurs.
 */
export const getDataResultsAction = async ({
  baseUrl,
  collectionId
}: {
  baseUrl: string;
  collectionId: string;
}): Promise<IAccountDataResult | undefined> => {
  try {
    const result = await getDataResults({ baseUrl, collectionId });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return undefined;
    }

    return result.data;
  } catch (e) {
    BugTracker.notify(e);
    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    notify.error(errorMessage);

    return undefined;
  }
};

export { createFraudulentDocumentUUID };
