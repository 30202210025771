import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  makeStyles
} from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import MainCalendar from 'components/Calendar';
import { useState } from 'react';
import { theme } from 'theme';
import HelpIcon from '@material-ui/icons/Help';
import { CustomDialog } from 'common/Dialog';
import FAQAndGuides from 'components/FAQ/faqAndGuides';

const useStyles = makeStyles((theme) =>
  createStyles({
    greenButton: {
      backgroundColor: theme.palette.primary.light,
      justifyContent: 'flex-start',
      textTransform: 'none',
      height: 35,
      '&:hover': {
        borderRight: `50px solid ${theme.palette.primary.main}`,
        background: theme.palette.grey[300],
        color: theme.palette.grey[800],
        cursor: 'pointer',
        '& > p': {
          color: theme.palette.grey[800]
        },
        '& > svg': {
          color: theme.palette.grey[800]
        }
      },
      color: 'white'
    }
  })
);

const StepperCalendar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}>
        <div style={{ margin: theme.spacing(2) }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 5,
              marginRight: 5,
              marginBottom: 5
            }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setHelpDialogOpen(true)}>
              <HelpIcon style={{ marginRight: 8 }} />
              Help
            </Button>
          </div>
          <CustomDialog
            maxSize="lg"
            handleClose={() => setHelpDialogOpen(false)}
            open={helpDialogOpen}
            staticPosition
            alert={{
              title: 'Help',
              description: `Need help with Calendar? Here are some guides and videos to help you.`,
              type: 'info'
            }}>
            <DialogContent style={{ minHeight: 250, minWidth: 500 }}>
              <FAQAndGuides tags={['Calendar']} folderName={'Calendar'} />
            </DialogContent>
          </CustomDialog>
          <MainCalendar />
        </div>
      </Dialog>

      <Button
        style={{
          width: '100%',
          textTransform: 'none',
          fontWeight: 'bold'
        }}
        startIcon={<CalendarToday style={{ marginLeft: 5 }} />}
        className={classes.greenButton}
        onClick={async () => {
          setOpen(true);
        }}>
        Calendar
      </Button>
    </div>
  );
};

export default StepperCalendar;
