import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Stepper from 'components/Stepper';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { getDealData } from 'redux/actions/processes/getDealData';
import { CopyDeal } from 'redux/database/Process Instance API';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IStepperProvider, StepperContext } from './context';
import { useTypedSelector } from 'redux/reducers';
import { FbFileRef } from 'types/interfaces';
import * as gtag from 'Utils/gtag';
import { DealSettings } from './DealSettings';

import {
  CLEAR_CURRENT_DEAL,
  CLEAR_DEAL_PROCESS,
  SET_DEAL_FILES,
  SET_GLOBAL_HOSTNAME
} from 'redux/actions/types';

import { useDealSummary } from 'hooks/useDealSummary';
import { globalHost } from 'helpers';
import { getAuditLogs, IFirestoreAuditLog } from 'hooks/useDeals/useAuditLog';
import firebase from 'firebase';
import { useRegulated } from 'hooks/useRegulated';
import useRequiredCompletion from 'hooks/useDeals/useRequiredCompletion';
import { useGlobal, useProcess } from 'hooks';
import { IProposalList } from 'redux/database/Lender API/interface';
import { getProposalList } from 'redux/actions/GraphQlActions/LenderAPIActions';
import { notify } from 'components/Notifications/HotToastNotifications';

const useStyles = makeStyles((theme: Theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  root: { display: 'flex', flexDirection: 'column' },
  icon: { color: 'red' },
  processInfoBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  processInfoItem: {
    borderRight: `1px ${theme.palette.grey[300]} solid`,
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const Header = (props: { close: () => void }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { syncProcessInstance } = useDealSummary();

  const [auditLogs, setAuditLogs] = useState<IFirestoreAuditLog[]>([]);
  const [proposalsList, setProposalsList] = useState<IProposalList[]>([]);

  const [dealRefreshing, setDealRefreshing] = useState<boolean>(false);
  const [quotesRefreshing, setQuotesRefreshing] = useState<boolean>(false);

  const parsed = useTypedSelector((s) => s.communication.target);
  const { user } = useTypedSelector((s) => s.user);
  const { currentProcess, currentDeal, currentStepId } = useTypedSelector(
    (s) => s.process
  );
  const { regulated } = useRegulated();
  const { stepdefdict, landingpage } = useProcess();
  const { entityType } = useTypedSelector((s) => s.process);
  const { config } = useGlobal();

  const { ReqCmp, handleUpdateReqCmp, ReqFields } = useRequiredCompletion({
    currentDeal,
    currentProcess,
    parsed,
    regulated,
    entityType,
    currentStepId,
    config,
    CompleteObjectDefinitionDict: stepdefdict?.CompleteObjectDefinitionDict
  });

  const [activeStep, setActiveStep] = useState<number>(0);
  const owner = currentDeal?.ProcessInstance?.UserInstanceId == user?.Id;
  const isSubSystemUser = user?.SystemAccess <= 4;
  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id;
  const ProcessStepDefinitionId =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;

  let location = globalHost(window.location);
  const dealStatus = () => {
    if (ProcessStepDefinitionId > 0) {
      const stepdefdict =
        currentProcess.CompleteProcessStepDefinitionDict[
          ProcessStepDefinitionId
        ];

      if (!stepdefdict) {
        return { status: 'CORRUPTED', type: 'error' };
      }

      const Title = stepdefdict && stepdefdict.ProcessStepDefinition.Title;
      return { status: Title, type: 'warning' };
    }

    if (ProcessStepDefinitionId === -1) {
      return { status: 'PAID OUT', type: 'success' };
    }

    if (ProcessStepDefinitionId === -2) {
      return { status: 'DECLINED', type: 'error' };
    }

    if (ProcessStepDefinitionId === -3) {
      return { status: 'DEAL NOT TAKEN UP', type: 'error' };
    }

    return { status: 'ACTIVE DEAL', type: 'primary' };
  }; //END dealStatus

  const handleDealRefreshUI = () => {
    if (isSubSystemUser) {
      if (owner) return true;
      else return false;
    }
    return true;
  };

  const duplicate = async () => {
    const res = await CopyDeal(ProcessInstanceId);
    if (res.status === 200) {
      const { Id } = res.data;
      const msg = `Deal ${ProcessInstanceId} Copied to ${Id}`;
      notify.success(msg);
      return gtag.event({ action: `Deal Copy`, feature: 'Deal', message: msg });
    } else {
      notify.error(`Deal copy failed`);
    }
    // Repopulate data with new deal potentially ... see what users think desired outcome here is
  };

  // Lets make this a function that is passed down in a useContext to all child components
  const getData = async ({
    activeStep,
    move
  }: {
    activeStep?: number;
    move?: boolean;
  }) => {
    setDealRefreshing(true);
    setActiveStep(1);

    let hasCurrentDeal: boolean;
    if (Object.values(currentDeal).length === 0) hasCurrentDeal = false;
    else hasCurrentDeal = true;

    const dealData = await getDealData({
      ProcessInstanceId: hasCurrentDeal ? ProcessInstanceId : parsed.piid,
      ProcessStepDefinitionId: hasCurrentDeal
        ? move
          ? activeStep
          : ProcessStepDefinitionId
        : parsed.psdid,
      ProcessDefinitionId: hasCurrentDeal
        ? currentDeal.ProcessInstance.ProcessDefinitionId
        : parsed.pdid
    });

    if (dealData) {
      setDealRefreshing(false);
      setActiveStep(0);
      notify.success(`Deal Data Refreshed`);
    }
  };

  const refreshDealData = async () => {
    const ProcessDefinitionId =
      currentDeal?.ProcessInstance?.ProcessDefinitionId;

    return getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });
  };

  const isMyProcessInstance =
    currentDeal?.ProcessInstance?.UserInstanceId === user.Id ||
    user.SystemAccess >= 10;
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const INIT_DealFilesForRedux = async () => {
      if (currentDeal?.ProcessInstance?.Id) {
        const filesRef = firebase
          .firestore()
          .collection('deal')
          .doc(currentDeal.ProcessInstance.Id.toString())
          .collection('files');

        const filesSnapshot = await filesRef.get();
        if (!filesSnapshot.empty) {
          const files = filesSnapshot.docs.map((doc) => {
            const data = doc.data() as FbFileRef;
            return data;
          });

          dispatch({ type: SET_DEAL_FILES, payload: files });
        } else return [];
      }
    };

    const INIT_ProposalList = async () => {
      if (currentDeal && currentDeal.ProcessInstance && !landingpage) {
        const response = await getProposalList(
          currentDeal.ProcessInstance.Id.toString()
        );

        if (response && response.length > 0) {
          setProposalsList(response);
        }
      }
    };

    const INIT_syncData = async () => {
      // Sync the deal with Deal Summary Information
      await syncProcessInstance();
      const auditLogs = await getAuditLogs(currentDeal.ProcessInstance.Id);

      setAuditLogs(auditLogs);
    };

    INIT_DealFilesForRedux();
    INIT_syncData();
    INIT_ProposalList();
    return () => {
      if (currentDeal && currentDeal.ProcessInstance) {
        dispatch({ type: CLEAR_CURRENT_DEAL, payload: {} });
        dispatch({ type: CLEAR_DEAL_PROCESS, payload: {} });
      }
    };
  }, []);

  // Used for Closed Deals
  const isDealClosed = ProcessStepDefinitionId < 0;

  // Used for Brokers with System Access 5 For Transferring to Introducers and seeing a ReadOnly
  const isDealTransferred = user.SystemAccess <= 5 && !owner;

  const stepperValue: IStepperProvider = {
    getData,
    duplicate,
    refreshDealData,
    close: props.close,
    setQuotesRefreshing,
    quotesRefreshing,
    isDealClosed,
    isDealTransferred,
    auditLogs,
    ReqCmp,
    handleUpdateReqCmp,
    ReqFields,
    proposalsList,
    setProposalsList
  };

  return (
    <StepperContext.Provider value={stepperValue}>
      <div className={classes.root}>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-start"
          style={{ padding: theme.spacing(1) }}>
          <>
            <Grid
              className={classes.processInfoBar}
              item
              style={{ width: mobile ? '50vw' : '73.8vw' }}>
              <Grid container direction="row" spacing={0} alignItems="center">
                <Grid className={classes.processInfoItem} item>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: '2em',
                      fontSize: '13px'
                    }}
                    variant="h5">
                    DEAL ID
                  </Typography>
                  <div
                    style={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      fontSize: '13px'
                    }}
                  />
                  <Typography
                    style={{
                      color: theme.palette.primary.light,
                      fontSize: '13px'
                    }}
                    variant="h5">
                    {ProcessInstanceId}
                  </Typography>
                </Grid>

                <Grid className={classes.processInfoItem} item>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: '2em',
                      fontSize: '13px'
                    }}
                    variant="h5">
                    DEAL STATUS
                  </Typography>

                  <div
                    style={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      fontSize: '13px'
                    }}
                  />

                  <Chip
                    label={dealStatus()?.status}
                    style={{
                      background: `linear-gradient(to bottom, ${
                        theme.palette[dealStatus()?.type].main
                      }88 0%, ${theme.palette[dealStatus().type].main} 100%)`,
                      color: 'white',
                      fontSize: '13px'
                    }}
                    size="small"
                  />
                </Grid>
                <Grid className={classes.processInfoItem} item>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: '2em',
                      fontSize: '13px'
                    }}
                    variant="h5">
                    PRODUCT
                  </Typography>

                  <div
                    style={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      fontSize: '13px'
                    }}
                  />

                  <Typography
                    data-cy="header-product-title"
                    style={{
                      color: theme.palette.primary.light,
                      fontSize: '13px'
                    }}
                    variant="body2">
                    {currentProcess?.ProcessDefinition?.Title}
                  </Typography>
                </Grid>

                <Grid className={classes.processInfoItem} item>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      marginLeft: '2em',
                      fontSize: '13px'
                    }}
                    variant="h5">
                    DEAL NAME
                  </Typography>

                  <div
                    style={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      fontSize: '13px'
                    }}
                  />

                  <Typography
                    style={{
                      color: theme.palette.primary.light,
                      fontSize: '13px'
                    }}
                    variant="body2">
                    {currentDeal?.ProcessInstance?.Title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>

          {handleDealRefreshUI() && (
            <Grid item style={{ marginRight: theme.spacing(2) }}>
              <Tooltip title="Refresh deal information">
                <Button
                  color="primary"
                  onClick={async () => {
                    setDealRefreshing(true);
                    const refresh = refreshDealData();
                    if (await refresh) {
                      notify.success('Successfully Refreshed Deal Data');

                      setDealRefreshing(false);
                    }
                  }}
                  startIcon={
                    dealRefreshing ? (
                      <CircularProgress size={20} />
                    ) : (
                      <RefreshIcon fontSize="medium" />
                    )
                  }
                  size="small">
                  Refresh Deal
                </Button>
              </Tooltip>
            </Grid>
          )}

          {isMyProcessInstance && (
            <Grid item>
              <Tooltip title="Deal settings">
                <DealSettings />
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Tooltip title="Close deal">
              <Button
                data-cy="close-icon-btn"
                color="primary"
                onClick={() => {
                  dispatch({ type: SET_GLOBAL_HOSTNAME, payload: location });

                  props.close();
                }}
                size="small">
                <CloseIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>

        {/* We have removed the Old Deal Process Overview and Instead Create ReadOnly */}
        <Stepper
          {...props}
          dealRefreshing={dealRefreshing}
          activeStep={activeStep}
          refreshDealData={getData}
        />
      </div>
    </StepperContext.Provider>
  );
};

//! This code is for the old Deal Process Overview
// // {ProcessStepDefinitionId < 0 && currentProcess ? (
// //     <Container>
// //       <div
// //         style={{
// //           padding: theme.spacing(2),
// //           height: '100%',
// //           display: 'flex',
// //           flexDirection: 'column',
// //           alignItems: 'center',
// //           justifyContent: 'center'
// //         }}>
// //         <div>{CustomStatusRender({ value: currentDealProcess })}</div>
// //         <h1>
// //           {dealStatus()?.status} -{' '}
// //           {regulated ? '(Regulated)' : '(Non-Regulated)'}
// //         </h1>
// //         <Grid
// //           item
// //           container
// //           direction="row"
// //           spacing={2}
// //           style={{
// //             padding: theme.spacing(2),
// //             flexDirection: 'row',
// //             justifyContent: 'center'
// //           }}>
// //           <Grid item>
// //             <DealProgressOverview
// //               fullScreen={false}
// //               Icon={(props) => <MdStorage {...props} />}
// //               title="File Storage">
// //               <FileStorageView hideControls />
// //             </DealProgressOverview>
// //           </Grid>
// //           <Grid item>
// //             <DealProgressOverview
// //               fullScreen={false}
// //               Icon={(props) => <Info {...props} />}
// //               title="Document Preview">
// //               <AttachmentsLog loadDocuments />
// //             </DealProgressOverview>
// //           </Grid>
// //         </Grid>
// //         <DetailView />
// //       </div>
// //     </Container>
// //   ) : (
// //     <Stepper
// //       {...props}
// //       dealRefreshing={dealRefreshing}
// //       activeStep={activeStep}
// //       refreshDealData={getData}
// //     />
// //   )}

// // React.useEffect(() => {
// //   const closed = [-1, -2, -3];
// //   if (!closed.includes(currentStepId) && entityType) {
// //     if (Regulated.includes(entityType)) {
// //       setRegulated(true);
// //     }
// //   } else if (ProcessStepDefinitionId < 0 && currentProcess) {
// //     const processInstanceFieldsId = processInstanceFields[ProcessInstanceId];
// //     const getEntityType = processInstanceFieldsId.find(
// //       (FieldInstance: FieldInstance) => {
// //         const EntityId = 21745;
// //         return FieldInstance.FieldDefinitionId === EntityId;
// //       }
// //     );

// //     if (getEntityType) {
// //       if (Regulated.includes(getEntityType.FieldValue)) {
// //         setRegulated(true);
// //       }
// //     }
// //   }
// // }, [ProcessStepDefinitionId, currentProcess]);

//! Used for Syncing Live Users Which we Removed ATM
// // let unSubscribe: () => void;
// // if (currentDeal && currentDeal.ProcessInstance) {
// //   const { Id } = currentDeal.ProcessInstance;
// //   const ref = firebase.firestore().collection('deal').doc(Id.toString());

// //   unSubscribe = ref.onSnapshot(
// //     (doc) => {
// //       if (doc.exists) {
// //         // update db with live user
// //         ref.update({
// //           liveUsers: firebase.firestore.FieldValue.arrayUnion(user)
// //         });
// //         const data = doc.data() as firebase.firestore.DocumentData;
// //         const liveUsers = data.liveUsers as UserInstance[] | undefined;
// //         if (liveUsers) setLiveUsers(liveUsers);
// //       } else {
// //         // if deal doest exist in db create it
// //         ref.set({ liveUsers: [user] });
// //       }
// //     },
// //     (error) => BugTracker.notify(error)
// //   );
// // }

//! The Unmounting of Live Users
// // const { Id } = currentDeal.ProcessInstance;
// // const ref = firebase.firestore().collection('deal').doc(Id.toString());
// // remove user from deal liveUser array
// // ref.update({
// //   liveUsers: firebase.firestore.FieldValue.arrayRemove(user)
// // });
// // unSubscribe();

//! JSX.Element for Live Users

// // {process.env.NODE_ENV === 'development' && (
// //     <Grid item style={{ marginLeft: '1em' }}>
// //       {liveUsers?.map((user) => (
// //         <StyledBadge
// //           key={user.Id}
// //           overlap="circular"
// //           anchorOrigin={{
// //             vertical: 'bottom',
// //             horizontal: 'right'
// //           }}
// //           variant="dot">
// //           <Tooltip title={user.UserInstanceEmail}>
// //             <Avatar
// //               alt={user.Title}
// //               src="/static/images/avatar/1.jpg"
// //               className={classes.small}
// //             />
// //           </Tooltip>
// //         </StyledBadge>
// //       ))}
// //     </Grid>
// //   )}

//! Styling for JSX.Element for Live Users
// // const StyledBadge = withStyles((theme: Theme) =>
// //   createStyles({
// //     badge: {
// //       backgroundColor: '#44b700',
// //       color: '#44b700',
// //       boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
// //       '&::after': {
// //         position: 'absolute',
// //         top: 0,
// //         left: 0,
// //         width: '100%',
// //         height: '100%',
// //         borderRadius: '50%',
// //         animation: '$ripple 1.2s infinite ease-in-out',
// //         border: '1px solid currentColor',
// //         content: '""'
// //       }
// //     },
// //     '@keyframes ripple': {
// //       '0%': {
// //         transform: 'scale(.8)',
// //         opacity: 1
// //       },
// //       '100%': {
// //         transform: 'scale(2.4)',
// //         opacity: 0
// //       }
// //     }
// //   })
// // )(Badge);
