import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Button,
  useTheme,
  IconButton,
  Select,
  CircularProgress,
  Menu,
  Dialog
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { quickProcessUpdate } from 'redux/actions/processes';
import { useProcess } from 'hooks';
import * as gtag from 'Utils/gtag';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import useDealAnalytics from 'hooks/useDeals/useDealAnalytics';
import { CustomDialog } from 'common/Dialog';
import { Check, Settings } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import { ProcessInstance } from 'types/interfaces';
import { notify } from 'components/Notifications/HotToastNotifications';
import DealPaidOutCalendarDialog from './DealPaidOutCalendar';

export default function FormDialog({
  type,
  handleCloseMenu
}: {
  type:
    | 'Deal Configuration'
    | 'Deal Not Taken Up'
    | 'Decline Deal'
    | 'Re-Activate Deal'
    | 'Deal Paid Out';
  handleCloseMenu: () => void;
}) {
  const theme = useTheme();
  const { sendGTag } = useDealAnalytics();
  const { currentDeal, currentProcess } = useProcess();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  //? Type is a Number from  -3 to -1,  -3 = "Not Taken Up"  -2 = "Decline", -1 = "Paid Out"
  const initStep = currentProcess?.ProcessStepDefinitionSequence[0];
  const [openDialog, setOpenDialog] = React.useState<{
    open: boolean;
    type: number;
    title: string;
  }>({ open: false, type: initStep, title: '' });
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [dialogContent, setDialogContent] = useState<'Date' | 'Event' | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState(currentDeal.ProcessInstance);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setData(currentDeal.ProcessInstance);
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    history.push('/deals');
    const success = await quickProcessUpdate({ data });
    if (success) handleClose();
    setLoading(false);
  };

  const handleAccept = async ({
    title,
    value,
    date
  }: {
    title: string;
    value: number;
    date?: Moment | null;
  }) => {
    const stringDate = date?.toISOString();
    const newData = handleChange({ value, date: stringDate });
    handleSubmit(newData);
    notify.success(title);

    sendGTag();
    return gtag.event({
      action: title,
      feature: 'Deals',
      message: ''
    });
  };

  const handleChange = ({
    name = 'ProcessStepDefinitionId',
    value,
    date
  }: {
    name?: string;
    value: number | string;
    date?: string;
  }): ProcessInstance => {
    const newData = Object.assign({}, data);
    newData[name] = value;
    if (date) newData['ClosedOn'] = date;

    setData(newData);
    return newData;
  };

  const handleClick = async () => {
    await handleTypeAction();
    handleCloseMenu();
  };

  const handleTypeAction = async () => {
    if (open) return;
    switch (type) {
      case 'Decline Deal':
        {
          setOpenDialog({
            ...openDialog,
            open: true,
            type: -2,
            title: 'Decline Deal'
          });
          handleClose();
        }
        break;

      case 'Deal Not Taken Up':
        {
          setOpenDialog({
            ...openDialog,
            open: true,
            type: -3,
            title: 'Deal Not Taken Up'
          });
          handleClose();
        }
        break;

      case 'Deal Paid Out':
        {
          setOpenDialog({
            ...openDialog,
            open: true,
            type: -1,
            title: 'Deal Paid Out'
          });
          handleClose();
        }
        break;

      case 'Re-Activate Deal':
        {
          await handleAccept({ title: 'Re-Activate Deal', value: initStep });
          handleClose();
        }
        break;

      default:
        return handleClickOpen();
    }
  };

  if (openDialog.open) {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CustomDialog
          maxSize="sm"
          open={openDialog.open}
          alert={{
            title: `Your About to Change the State of the Deal to ${openDialog.title}`,
            type: 'warning',
            description: 'Are You Sure You Wish to do This?'
          }}
          handleClose={() => setOpenDialog({ ...openDialog, open: false })}>
          <DialogContent>
            <Typography variant="body1">
              You are about to change the state of the deal. Please confirm if
              you want to proceed with the following state change:
            </Typography>
            <Typography
              variant="h6"
              style={{ marginTop: '10px', fontWeight: 'bold' }}>
              - {openDialog.title}
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              If you choose <b>With Date</b>, the current date and time will be
              recorded along with the state change. If you choose{' '}
              <b>Without Date</b>, only the state change will be recorded.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await handleAccept({
                  title: openDialog.title,
                  value: openDialog.type
                });
                setOpenDialog({ ...openDialog, open: false });
              }}>
              {openDialog.title} Without Date
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={(event) =>
                setAnchorEl(event.currentTarget as HTMLElement)
              }>
              {openDialog.title} With Date & Event
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <MenuItem
                onClick={() => {
                  setDialogContent('Date');
                  setAnchorEl(null);
                }}>
                {openDialog.title} out With Date
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDialogContent('Event');
                  setAnchorEl(null);
                }}>
                {openDialog.title} out With Calendar Event
              </MenuItem>
            </Menu>
          </DialogActions>
          <Dialog
            open={dialogContent === 'Date'}
            onClose={() => setDialogContent(null)}>
            <DialogContent>
              <DatePicker
                autoOk
                variant="dialog"
                label="Select Date"
                maxDate={new Date()}
                value={selectedDate}
                onChange={(date: MaterialUiPickersDate) =>
                  setSelectedDate(date)
                }
                onAccept={async (date: MaterialUiPickersDate) => {
                  await handleAccept({
                    title: openDialog.title,
                    value: openDialog.type,
                    date
                  });

                  setDialogContent(null);
                  setOpenDialog({ ...openDialog, open: false });
                }}
                onClose={() => setDialogContent(null)}
                open
              />
            </DialogContent>
          </Dialog>
          {dialogContent === 'Event' && (
            <DealPaidOutCalendarDialog
              open
              onClose={() => setDialogContent(null)}
              onAccept={(date) => {
                handleAccept({
                  title: openDialog.title,
                  value: openDialog.type,
                  date
                });
                setDialogContent(null);
                setOpenDialog({ ...openDialog, open: false });
              }}
            />
          )}
        </CustomDialog>
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <MenuItem data-cy="deal-action-menu" onClick={handleClick}>
      <>
        <ListItemIcon>
          {type === 'Deal Configuration' && <Settings />}
          {type === 'Deal Paid Out' && (
            <TrendingUpIcon style={{ color: theme.palette.success.main }} />
          )}
          {type === 'Deal Not Taken Up' && (
            <TrendingFlatIcon style={{ color: theme.palette.warning.main }} />
          )}
          {type === 'Decline Deal' && (
            <TrendingDownIcon style={{ color: theme.palette.error.main }} />
          )}
          {type === 'Re-Activate Deal' && <PlayArrowIcon />}
        </ListItemIcon>
        <Typography color="primary">{type}</Typography>
      </>

      <CustomDialog
        handleClose={handleClose}
        open={open}
        maxSize="sm"
        alert={{
          title: 'Deal Configuration',
          description:
            'Configure your deal by renaming it and reviewing the current Value / Tag with ease.',
          type: 'info'
        }}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                label="Deal Name"
                variant="outlined"
                margin="dense"
                onChange={(e) =>
                  handleChange({ name: 'Title', value: e.target.value })
                }
                value={data?.Title}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <NumericFormat
                customInput={TextField}
                label="Deal Value"
                fullWidth
                variant="outlined"
                margin="dense"
                required
                placeholder="Enter Deal Value"
                value={data?.Value}
                onValueChange={(values) => {
                  handleChange({ name: 'Value', value: values.value });
                }}
                thousandSeparator
                prefix="£"
                decimalScale={2}
                allowNegative={false}
                fixedDecimalScale
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                required
                variant="outlined"
                value={data?.Tag}
                fullWidth
                disabled
                margin="dense"
                style={{
                  marginTop: theme.spacing(1)
                }}>
                <MenuItem value={ERegulatedStatus.REGULATED}>
                  {ERegulatedStatus.REGULATED}
                </MenuItem>
                <MenuItem value={ERegulatedStatus.NON_REGULATED}>
                  {ERegulatedStatus.NON_REGULATED}
                </MenuItem>
              </Select>
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.success.main,
              color: 'white'
            }}
            onClick={() => handleSubmit(data)}
            startIcon={loading ? <CircularProgress size={20} /> : <Check />}>
            Submit
          </Button>
        </DialogActions>
      </CustomDialog>
    </MenuItem>
  );
}
