import { useEffect, useState, ChangeEvent } from 'react';
import {
  Input,
  MenuItem,
  Button,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  IconButton,
  useTheme,
  Chip,
  LinearProgress,
  DialogActions,
  Tooltip
} from '@material-ui/core';
import { validateEmail } from 'helpers/validateEmail';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import uuid from 'react-uuid';
import CheckIcon from '@material-ui/icons/Check';
import { useCompaniesHouse } from 'hooks/useCompaniesHouse';
import { IDontDeal, useDealList } from 'hooks/useDealList';
import { useTypedSelector } from 'redux/reducers';
import { useRedFlagGQLAdaptor } from 'components/OffkeyCompanyAndDirectorSearch/adaptors';
import { RedflagClient } from 'App';
import {
  OffkeyCompany,
  OffkeyCompanyLite
} from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import {
  checkAndRetrieveToken,
  mapItemsToOptions,
  mergeCompanyLists
} from './functions';
import { CompanySearchResourceItem } from 'types/companiesHouseInterfaces';
import { CustomDialog } from 'common/Dialog';
import { SuccessButton } from 'common/Button';
import { Skeleton } from '@material-ui/lab';
import Redflag from 'components/RedFlag';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { CompleteObjectInstance, FieldInstance } from 'types/interfaces';
import { CreateUserButton } from 'components/User/CreateAsUserButton';
import { INotificationLog } from 'components/User/NewUserButton/functions/interfaces';
import NewUserButton from 'components/User/NewUserButton';
import { IOption } from 'automations/NewSupplierContactAutomation';
import { notify } from 'components/Notifications/HotToastNotifications';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    skeletonItem: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    skeletonChip: {
      display: 'inline-block',
      width: 50,
      height: 20,
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1)
    },
    skeletonText: {
      marginBottom: theme.spacing(1)
    },
    dialogContent: {
      padding: theme.spacing(1),
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 200px)'
    },
    stickyActions: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.divider}`,
      zIndex: 1
    }
  })
);

export interface IEntityOption extends IOption {
  company_number?: string;
  redflagId?: string;
  badCompany?: boolean;
  type?: string;
}

interface Props {
  existingOptionIdList: IEntityOption; // a list of already selected option ids
  onCreate: (email: string, title: string) => void;
  onSelect: (option: IEntityOption) => Promise<void>;
  options: IEntityOption[]; // are menu items
}

export const CreatableTextSearch_Entity = ({
  existingOptionIdList,
  onCreate,
  onSelect,
  options
}: Props) => {
  const graphqlCrmState = useTypedSelector((s) => s.graphqlCrm);
  const { companyNavigator } = useTypedSelector((s) => s.config);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { setQ, data, loading } = useCompaniesHouse();
  const { getRFGQLCompanyList, getRFGQLCompany } =
    useRedFlagGQLAdaptor(RedflagClient);

  // company list including bad companies
  const { doNotDealList } = useDealList('');

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState('');
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);

  const [mergedOptions, setMergedOptions] = useState([] as IEntityOption[]);
  const [redflagSelectionData, setRedflagSelectionData] = useState<{
    open: boolean;
    option: IEntityOption;
    company: OffkeyCompany;
  }>({
    open: false,
    option: {} as IEntityOption,
    company: {} as OffkeyCompany
  });

  const [redflagGraphData, setRedflagGraphData] = useState<OffkeyCompanyLite[]>(
    []
  );

  // bad companies currently under extreme caution
  const [badCompanyList, setBadCompanyList] = useState<IDontDeal[]>([]);

  useEffect(() => {
    // insert bad companies within state
    if (doNotDealList && doNotDealList.length > 0) {
      const filteredBadCompanies = doNotDealList.filter(
        (item): item is IDontDeal => 'company_number' in item
      );
      setBadCompanyList(filteredBadCompanies);
    }
  }, [doNotDealList]);

  const filteredCompanies = mergedOptions.filter((company: IEntityOption) => {
    const searchValue = title.toLowerCase();
    if (company.company_number) {
      return (
        company.company_number.toLowerCase().includes(searchValue) ||
        company.title.toLowerCase().includes(searchValue)
      );
    } else {
      return (
        company.label.toLowerCase().includes(searchValue) &&
        existingOptionIdList.value !== company.value
      );
    }
  });

  const handleChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setValue(value);
  };

  const handleTitleChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setTitle(value);
  };

  const disabled = !validateEmail(value);
  const tooltip = disabled ? 'Search Email' : 'Create new user';

  /**
   * Handles the selection of a company option.
   * @param {TOption} option - The selected option.
   */
  const handleSelect = async (el: IEntityOption) => {
    if (el.type === 'New Company') {
      await handleNewCompanySelection(el);
    } else {
      await handleExistingCompanySelection(el);
    }
  };

  /**
   * Handles the selection of a new company option.
   * @param {TOption} option - The selected option.
   */
  const handleNewCompanySelection = async (el: IEntityOption) => {
    if (companyNavigator === 'RedflagGRaphQL') {
      const token = await checkAndRetrieveToken(graphqlCrmState);
      const company = await getRFGQLCompany(el.redflagId || '', token);
      if (company) {
        setRedflagSelectionData({ open: true, option: el, company });
      }
    } else {
      onSelect(el);
      setOpen(false);
    }
  };

  const handleCompanySelection = async (option: IEntityOption) => {
    await onSelect(option);
    handleClose();
  };

  const handleClose = () => {
    setTitle('');
    setValue('');

    setRedflagSelectionData({
      ...redflagSelectionData,
      open: false,
      option: {} as IEntityOption,
      company: {} as OffkeyCompany
    });
    setOpen(false);
  };

  /**
   * Handles the selection of an existing company option.
   * @param {TOption} el - The selected option.
   */
  const handleExistingCompanySelection = async (el: IEntityOption) => {
    const UserInstanceId = Number(el?.value);
    const getUser = (await getCompleteUserInstanceDetail({
      baseUrl,
      UserInstanceId,
      action: 'CompleteObjectInstanceList'
    })) as CompleteObjectInstance[];

    if (getUser) {
      const CompanyDetailsId = 3413;
      const CompanyDetailsFieldId = 21307;

      const getCompanyDetailsObject = getUser.find(
        (instance: CompleteObjectInstance) =>
          instance.ObjectInstance.ObjectDefinitionId === CompanyDetailsId
      );

      if (getCompanyDetailsObject) {
        const getCompanyDetailsField =
          getCompanyDetailsObject.FieldInstanceList.find(
            (field: FieldInstance) =>
              field.FieldDefinitionId === CompanyDetailsFieldId
          );

        if (getCompanyDetailsField) {
          await checkBadCompanyAndHandleSelection(
            el,
            getCompanyDetailsField.FieldValue
          );
          return;
        }
      }
    }

    onSelect(el);
    setOpen(false);
  };

  /**
   * Checks if the selected company is in the "Do Not Deal" list and handles the selection.
   * @param {IEntityOption} el - The selected option.
   * @param {string} companyNumber - The company number to check.
   */
  const checkBadCompanyAndHandleSelection = async (
    el: IEntityOption,
    companyNumber: string
  ) => {
    const isBadCompany = badCompanyList.some(
      (badCompany: IDontDeal) => badCompany.company_number === companyNumber
    );

    if (isBadCompany) {
      const updatedMergedOptions = mergedOptions.map((option: IEntityOption) =>
        option.value === el.value ? { ...option, badCompany: true } : option
      );

      setMergedOptions(updatedMergedOptions);
      notify.warning(
        `${el.title} is listed as "Do Not Deal" and has been disabled for selection.`
      );
    } else {
      onSelect(el);
      setOpen(false);
    }
  };

  const handleCreate = () => {
    let email = value === '' ? uuid() : value;
    if (title === '') email = '';
    else {
      onCreate(email, title);
      setOpen(false);
    }
  };

  useEffect(() => {
    const getCompanyList = async () => {
      setLoadingList(true);
      //* Replacing Company House With Redflag
      if (title.length > 1) {
        if (companyNavigator === 'RedflagGRaphQL') {
          //* Test to make sure it's a number or name
          let nameOrNumber: {
            company_number?: { eq: string };
            company_name?: { eq: string };
          };
          if (/^\d+$/.test(title)) {
            nameOrNumber = { company_number: { eq: title } };
          } else {
            nameOrNumber = { company_name: { eq: title } };
          }

          const token = await checkAndRetrieveToken(graphqlCrmState);
          const response = await getRFGQLCompanyList({
            query: nameOrNumber,
            token
          });
          setRedflagGraphData(response);
        }
        //* We will be using Companies House
        else {
          setQ(title);
        }
      }

      setLoadingList(false);
    };

    getCompanyList();
  }, [title]);

  useEffect(() => {
    let merged: IEntityOption[] = [];

    if (companyNavigator === 'RedflagGRaphQL' && redflagGraphData) {
      const CompaniesList = mapItemsToOptions(redflagGraphData, 'New Company');
      const companyList = mergeCompanyLists(CompaniesList, badCompanyList);

      merged = [...options, ...companyList];
    } else if (data?.items) {
      const { items } = data;
      const company = items as CompanySearchResourceItem[];
      const CompanyHouseList = mapItemsToOptions(company, 'New Company');
      const companyHouseList = mergeCompanyLists(
        CompanyHouseList,
        badCompanyList
      );

      merged = [...options, ...companyHouseList];
    }

    setMergedOptions(merged);
  }, [data, redflagGraphData]);

  const SearchBtn = (props: any) => (
    <Button
      data-cy="entity-search-btn"
      {...props}
      onClick={() => setOpen(true)}
      variant="contained"
      color="primary">
      <SearchIcon style={{ marginRight: 8 }} />
    </Button>
  );

  if (redflagSelectionData && redflagSelectionData.open) {
    const { title } = redflagSelectionData.option;

    const handleClosing = () => {
      setRedflagSelectionData({ ...redflagSelectionData, open: false });
      setOpen(false);
    };

    return (
      <NewUserButton
        automationValues={{
          handleClose,
          company: redflagSelectionData.company,
          importer: handleCompanySelection,
          UserDefinitionId: 26,
          UserDefinitionTitle: 'Supplier',
          isOpen: redflagSelectionData.open
        }}
      />
    );
  }

  if (!open) {
    if ('label' in existingOptionIdList) {
      const text = existingOptionIdList.title ? 'Email / Id' : 'Title';
      return (
        <Paper style={{ padding: 10 }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            {existingOptionIdList.title && (
              <Grid item xs={4}>
                <Typography variant="caption">Title</Typography>
                <Typography>{existingOptionIdList.title}</Typography>
              </Grid>
            )}

            <Grid item xs={4}>
              <Typography variant="caption">{text}</Typography>
              <Typography>
                {existingOptionIdList.value > 0
                  ? existingOptionIdList.value.toString()
                  : existingOptionIdList.label}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <SearchBtn fullWidth />
            </Grid>
          </Grid>
        </Paper>
      );
    } else {
      return <SearchBtn fullWidth />;
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Paper style={{ padding: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Input
              data-cy="entity-input"
              style={{ margin: 2 }}
              value={title}
              onChange={handleTitleChange}
              fullWidth
              placeholder="Search Company Name or Company Number"
            />
          </Grid>

          <Grid item xs={5}>
            <Input
              style={{ margin: 2 }}
              value={value}
              onChange={handleChange}
              fullWidth
              placeholder="Enter Valid Email"
            />
          </Grid>

          <Grid item xs={2} style={{ display: 'flex' }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <Tooltip title={'Please Enter a Title & Email To Create Supplier'}>
              <IconButton
                disabled={title === '' && value === ''}
                onClick={handleCreate}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>

      <div
        style={{
          margin: 10,
          color: theme.palette.success.main
        }}>
        {loading && <LinearProgress />}
      </div>

      {loadingList
        ? Array.from(new Array(10)).map((_, idx) => (
            <Paper key={idx} elevation={2}>
              <div className={classes.skeletonItem}>
                <Skeleton
                  variant="text"
                  height={30}
                  width="100%"
                  className={classes.skeletonText}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="rect" className={classes.skeletonChip} />
                  <Skeleton variant="text" height={30} width="100%" />
                </div>
              </div>
            </Paper>
          ))
        : filteredCompanies.map((company: IEntityOption, idx: number) => {
            return (
              <div key={idx} style={{ paddingBottom: theme.spacing(1) }}>
                <Paper elevation={2}>
                  <MenuItem
                    onClick={() => handleSelect(company)}
                    disabled={company.badCompany ? true : false}
                    className={classes.menuItem}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography data-cy="company-title">
                          {company.company_number
                            ? company.title
                            : company.label}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={
                            company.badCompany
                              ? 'Extreme Caution'
                              : !company.company_number
                              ? 'Existing Data'
                              : 'New'
                          }
                          style={{
                            color: 'white',
                            marginRight: 8,
                            background: company.badCompany
                              ? theme.palette.error.main
                              : !company.company_number
                              ? theme.palette.primary.main
                              : theme.palette.success.main
                          }}
                          size="small"
                        />

                        <Typography
                          variant="caption"
                          style={{
                            color: company.badCompany
                              ? theme.palette.error.main
                              : !company.company_number
                              ? theme.palette.warning.main
                              : theme.palette.success.main
                          }}>
                          {company.company_number
                            ? companyNavigator === 'RedflagGRaphQL'
                              ? 'from Company Navigator'
                              : 'from Company House'
                            : 'from Bips System'}
                        </Typography>
                      </Grid>
                    </Grid>

                    {company.company_number ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end'
                        }}>
                        <Typography>{company.company_number}</Typography>
                        <Typography
                          variant="caption"
                          style={{
                            color: company.badCompany
                              ? theme.palette.error.main
                              : theme.palette.success.main
                          }}>
                          Company Number
                        </Typography>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end'
                        }}>
                        <Typography>{company.value}</Typography>
                        <Typography
                          variant="caption"
                          style={{
                            color: company.badCompany
                              ? theme.palette.error.main
                              : theme.palette.warning.main
                          }}>
                          Supplier Id
                        </Typography>
                      </div>
                    )}
                  </MenuItem>
                </Paper>
              </div>
            );
          })}
    </div>
  );
}; //END CreatableTextSearch_Entity
