import firebase from 'firebase';
import { useForm } from 'hooks/useForm';
import { useGlobal } from 'hooks/useGlobal';
import { useProcess } from 'hooks/useProcess';
import {
  ObjectDefinition,
  CompleteObjectInstance,
  FieldInstance,
  CompleteObjectInstanceDict,
  FieldDefinition,
  UserInstance
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { updateObject } from 'redux/actions/objectUpdater';
import { useTypedSelector } from 'redux/reducers';
import { StepperContext } from 'components/Stepper/context';
import { useContext, useState } from 'react';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { updateField } from 'redux/actions/fieldUpdater';
import { notify } from 'components/Notifications/HotToastNotifications';

export const useSaving = () => {
  const token = useTypedSelector((s) => s.user.auth.token);
  const { getData, refreshDealData } = useContext(StepperContext);
  const { groups } = useForm();
  const { config } = useGlobal();
  const { currentDeal, user, currentStep, currentProcess } = useProcess();

  const [loading, setLoading] = useState<boolean>(false);

  const extractGroupIdentifier = (description: string): string | null => {
    const matches = description.match(/group#([^,]*)/);
    return matches ? matches[1].trim() : null;
  };

  const extractIdFromSaveableObject = (
    saveableObject: string
  ): number | null => {
    const parts = saveableObject.split('-');
    return parts.length > 1 ? parseInt(parts[1], 10) : null;
  };

  const getFieldInstance = ({
    CompleteObjectInstance,
    ObjectDefinitionId,
    FieldDefinitionId
  }: {
    CompleteObjectInstance:
      | CompleteObjectInstance[]
      | CompleteObjectInstanceDict;
    ObjectDefinitionId: number;
    FieldDefinitionId: number;
  }): FieldInstance | undefined => {
    const CompleteObjectInstanceList: CompleteObjectInstance = Object.values(
      CompleteObjectInstance
    ).find(
      (CompleteObjectInstance: CompleteObjectInstance) =>
        CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
        ObjectDefinitionId
    );

    if (!CompleteObjectInstanceList) return undefined;
    const FieldInstanceList = getFieldInstances(CompleteObjectInstanceList);

    return FieldInstanceList.find(
      (FieldInstance: FieldInstance) =>
        FieldInstance.FieldDefinitionId === FieldDefinitionId
    );
  };

  const updateAllObjectsSequentially = async (documentData): Promise<void> => {
    const updatePromises = Object.values(documentData).map(
      async (CompleteObjectInstance: any) => {
        const newObject: CompleteObjectInstance = JSON.parse(
          JSON.stringify(CompleteObjectInstance)
        );

        const ObjectExists: CompleteObjectInstance = Object.values(
          currentDeal.CompleteObjectInstanceDict
        ).find(
          (CompleteObjectInstance: CompleteObjectInstance) =>
            CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
            newObject.ObjectInstance.ObjectDefinitionId
        );

        if (ObjectExists) {
          const FieldInstanceList = getFieldInstances(ObjectExists);

          FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
            FieldInstance.ProcessInstanceId = currentDeal.ProcessInstance.Id;
            FieldInstance.Id = 0;
            FieldInstance.ObjectInstanceId = ObjectExists.ObjectInstance.Id;
          });

          let newFieldInstanceList: FieldInstance[] = [];
          FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
            const existingFieldInstance: FieldInstance =
              CompleteObjectInstance.FieldInstanceList.find(
                (FieldInstanceList: FieldInstance) =>
                  FieldInstanceList.FieldDefinitionId ===
                  FieldInstance.FieldDefinitionId
              );
            if (!existingFieldInstance) return;

            const FieldValue = FieldInstance.FieldValue;
            if (FieldValue !== existingFieldInstance.FieldValue) {
              const newFieldInstance = {
                ...FieldInstance,
                FieldValue: existingFieldInstance.FieldValue,
                ObjectInstanceId: existingFieldInstance.ObjectInstanceId,
                Id: 0
              };

              newFieldInstanceList.push(newFieldInstance);
            }
          });

          const newObject = {
            ...ObjectExists,
            FieldInstanceList: newFieldInstanceList
          };

          await updateObject({
            token,
            data: newObject,
            props: { ProcessInstanceId: currentDeal.ProcessInstance.Id },
            fetchPolicy: 'network-only'
          });
        } else {
          const newFieldInstanceList = newObject.FieldInstanceList.map(
            (FieldInstance: FieldInstance) => {
              FieldInstance.ProcessInstanceId = currentDeal.ProcessInstance.Id;
              FieldInstance.Id = 0;
              FieldInstance.ObjectInstanceId = 0;
              return FieldInstance;
            }
          );

          newObject.ObjectInstance.ProcessInstanceId =
            currentDeal.ProcessInstance.Id;
          newObject.ObjectInstance.Id = 0;
          newObject.ObjectInstance.Selected = false;
          newObject.FieldInstanceDict = {};
          newObject.FieldInstanceList = newFieldInstanceList;

          await updateObject({
            token,
            data: newObject,
            props: { ProcessInstanceId: currentDeal.ProcessInstance.Id },
            fetchPolicy: 'network-only'
          });
        }
      }
    );

    await Promise.all(updatePromises);
  };

  let extractedId: number[] = [];
  config?.SaveableObjects?.forEach((saveableObject) => {
    const id = extractIdFromSaveableObject(saveableObject);
    if (id) extractedId.push(id);
  });

  const UserInstanceId = user.Id.toString();
  let CustomerUserInstanceId: string | null = null;
  if (currentStep && currentStep.UserInstanceDictForCurrentStep) {
    const keys = Object.keys(currentStep.UserInstanceDictForCurrentStep);
    if (keys.length > 0) {
      CustomerUserInstanceId = keys[0].toString();
    }
  }

  const handleLoading = async (ObjectDefinition: ObjectDefinition) => {
    setLoading(true);
    const groupIdentifier = extractGroupIdentifier(
      ObjectDefinition.ObjectDescription
    );

    if (groupIdentifier && CustomerUserInstanceId) {
      try {
        const docRef = firebase
          .firestore()
          .collection('userAccount')
          .doc(UserInstanceId)
          .collection(groupIdentifier)
          .doc(CustomerUserInstanceId);

        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
          const documentData = docSnapshot.data() as CompleteObjectInstance[];

          if (documentData) {
            await updateAllObjectsSequentially(documentData);

            const FieldInstanceDocument = getFieldInstance({
              CompleteObjectInstance: documentData,
              ObjectDefinitionId: 3622,
              FieldDefinitionId: 22743
            });

            const FieldInstance: FieldInstance | undefined = getFieldInstance({
              CompleteObjectInstance: currentDeal.CompleteObjectInstanceDict,
              ObjectDefinitionId: 3622,
              FieldDefinitionId: 22743
            });

            if (FieldInstance && FieldInstanceDocument) {
              const FieldValues =
                FieldInstanceDocument.FieldValue !== FieldInstance.FieldValue;

              if (groupIdentifier === 'Fact Find Form' && FieldValues) {
                await getData({});
              } else {
                await refreshDealData();
              }
            } else await getData({});

            setLoading(false);
            notify.success('Successfully Loaded Fact Find Form');
          }
        } else {
          setLoading(false);
          notify.warning(`Cannot Find Customer ${groupIdentifier} Document`);
          return null;
        }
      } catch (error) {
        BugTracker.notify(error);
        return null;
      }
    }
  };

  const handleSaving = (ObjectDefinition: ObjectDefinition) => {
    const filterGroupsBySaveableObject = config.SaveableObjects.filter(
      (saveableObject: string) => {
        const groupName = saveableObject.split('-')[0];
        return Object.keys(groups).includes(groupName);
      }
    ).flatMap((saveableObject: string) => {
      const groupName = saveableObject.split('-')[0];
      return groups[groupName];
    });

    const groupIdentifier = extractGroupIdentifier(
      ObjectDefinition.ObjectDescription
    );

    if (groupIdentifier) {
      const filterSelectedGroups = filterGroupsBySaveableObject.filter(
        (completeObjectDefinition) =>
          completeObjectDefinition.ObjectDefinition.ObjectDescription.includes(
            groupIdentifier
          )
      );

      if (filterSelectedGroups) {
        const listOfCompleteObjectDefinitionIds = filterSelectedGroups.map(
          (CompleteObjectDefinition) =>
            CompleteObjectDefinition.ObjectDefinition.Id
        );

        const CompleteObjectInstance: CompleteObjectInstance[] = Object.values(
          currentDeal.CompleteObjectInstanceDict
        ).filter((CompleteObjectInstance: CompleteObjectInstance) =>
          listOfCompleteObjectDefinitionIds.includes(
            CompleteObjectInstance.ObjectInstance.ObjectDefinitionId
          )
        );

        if (CompleteObjectInstance.length >= 1 && CustomerUserInstanceId) {
          try {
            const CompleteObjectInstanceObject = CompleteObjectInstance.reduce(
              (obj, item, index) => {
                obj[index] = item;
                return obj;
              },
              {}
            );

            firebase
              .firestore()
              .collection('userAccount')
              .doc(UserInstanceId)
              .collection(groupIdentifier)
              .doc(CustomerUserInstanceId)
              .set(CompleteObjectInstanceObject)
              .then(() => {
                notify.success(`Successfully Saved Fact Find Form`);
              });
          } catch (e) {
            BugTracker.notify(e);
          }
        }
      }
    }
  };

  return {
    handleSaving,
    handleLoading,
    extractGroupIdentifier,
    loading,
    extractedId
  };
};
