import {
  GetProductTypes,
  GetAllProcesses,
  CreateProcess,
  GetProcessesByUser,
  GetProcessById,
  SubmitProcessStep,
  QuickProcessUpdate,
  GetProcessDefinition,
  GetProcessDefinitionLite,
  GetStepUsers,
  GetProcessFields,
  CopyProcessToStep,
  GetDocument,
  GetProcessesByUserDefinition
} from 'redux/database';
import { store } from 'redux/store';
import {
  SET_PROCESS_DEFINITIONS,
  SET_PROCESS_DEFINITIONS_SUMMARY,
  SET_CURRENT_PROCESS_DEFINITION,
  SET_PROCESS_SUMMARIES,
  UPDATE_LOADING
} from 'redux/actions/types';

import { IGetProcessFields } from 'types/databaseInterfaces';

import * as gtag from 'Utils/gtag';
import { ProcessDefinition, CompleteProcessInstance } from 'types/interfaces';
import { useDealOwnershipHistory } from '../../../hooks/useDealOwnershipHistory';

const { dispatch } = store;
import { notify } from 'components/Notifications/HotToastNotifications';

export const getProcessDefinitions = async ({ action }) => {
  const res = await GetProductTypes({ action });
  if (res && res.status === 200) {
    if (!action) dispatch({ type: SET_PROCESS_DEFINITIONS, payload: res.data });
    if (action)
      dispatch({
        type: SET_PROCESS_DEFINITIONS_SUMMARY,
        payload: res.data
      });
    return res;
  } else {
    dispatch({ type: UPDATE_LOADING, payload: false });
  }
};

export const getProcessDefinitionLite = async ({ processDefinitionId }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  const res = await GetProcessDefinitionLite({ processDefinitionId });

  if (res && res.status === 200) {
    dispatch({ type: UPDATE_LOADING, payload: false });

    if (res && res.status === 200) {
      await setCurrentProcess({ product: res.data });
    } else {
      return;
    }
  }
  if (!res.headers.auth) {
    return dispatch({ type: UPDATE_LOADING, payload: false });
  }
  return res.headers.auth;
};

export const setCurrentProcess = async ({ product }) => {
  dispatch({ type: SET_CURRENT_PROCESS_DEFINITION, payload: product });

  const processDefinitions = store.getState().process.processDefinitions;
  const Id = product.ProcessDefinition.Id;
  if (!processDefinitions[Id]) {
    let payload = JSON.parse(JSON.stringify(processDefinitions));
    payload[Id] = product;

    return dispatch({ type: SET_PROCESS_DEFINITIONS, payload });
  } else {
    return;
  }
};

export const getAllProcesses = async () => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  return GetAllProcesses()
    .then((res) => {
      Promise.all([
        dispatch({ type: UPDATE_LOADING, payload: false }),
        dispatch({ type: SET_PROCESS_SUMMARIES, payload: res.data })
      ]);
      return res.headers.auth;
    })
    .catch((e) => {
      dispatch({ type: UPDATE_LOADING, payload: false });
      return e;
    });
};

export const getProcessesByUser = async ({ Id }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });

  const res = await GetProcessesByUser({ Id });
  if (res && res.status === 200) {
    Promise.all([dispatch({ type: UPDATE_LOADING, payload: false })]);

    if (res && res.status === 200) {
      dispatch({ type: 'SET_MY_PROCESSES', payload: res.data });
      return res;
    }
  }
};

interface ICreateDealResponse {
  success: boolean;
  processInstance?: { Id: number };
  error?: string;
}

export const createDeal = async ({
  deal,
  UserInstanceId,
  definition,
  userId
}: {
  deal: {
    ProcessInstance: {
      Title: string;
      ProcessDefinitionId: number;
      ProcessStepDefinitionId: number;
      Value: number;
      Tag: string;
    };
  };
  UserInstanceId: number;
  definition: ProcessDefinition;
  userId?: number;
}): Promise<ICreateDealResponse> => {
  try {
    const res = await CreateProcess({ deal, UserInstanceId });
    const { createNewDealOwnership } = await useDealOwnershipHistory();

    if (!res.headers.auth) {
      dispatch({ type: UPDATE_LOADING, payload: false });
      return {
        success: false,
        error: 'Auth error - account in use on another device'
      };
    }

    if (res?.status === 200) {
      const createdDeal = res.data as CompleteProcessInstance;

      if (userId) {
        await createNewDealOwnership(createdDeal.ProcessInstance.Id, userId);
      }

      gtag.event({
        action: 'Deal Started',
        feature: definition.Id,
        message: `${definition.Title}, ${createdDeal.ProcessInstance.Id}`
      });

      await Promise.all([
        dispatch({ type: 'SET_CURRENT_DEAL', payload: res.data }),
        await getAllProcesses()
      ]).then(async () => {
        await getStepUsers({
          ProcessInstanceId: res.data.ProcessInstance.Id,
          ProcessStepDefinitionId:
            res.data.ProcessInstance.ProcessStepDefinitionId
        });
      });

      return {
        success: true,
        processInstance: res.data.ProcessInstance
      };
    }

    return {
      success: false,
      error: 'Request failed'
    };
  } catch (error: unknown) {
    if (error instanceof Error) {
      return {
        success: false,
        error: error.message
      };
    }
    return {
      success: false,
      error: 'An unknown error occurred'
    };
  }
};

export const getProcessById = async ({
  Id,
  ProcessStepDefinitionId
}: {
  Id: number;
  ProcessStepDefinitionId: number;
}) => {
  const res = await GetProcessById({ Id, ProcessStepDefinitionId });
  console.log({ res });
  if (res && res.status === 200) {
    dispatch({ type: 'SET_CURRENT_DEAL', payload: res.data });
    return res;
  }
};

export const submitProcessStep = async ({ token, submitDeal }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });

  const res = await SubmitProcessStep({ submitDeal });
  if (res && res.status === 200) {
    // If its a - 1 don't set the deal
    if (res.data.ProcessInstance.ProcessStepDefinitionId === -1) {
      // console.log('LAST STEP');
      dispatch({ type: UPDATE_LOADING, payload: false });
    } else {
      await dispatch({ type: 'SET_CURRENT_DEAL', payload: res.data });
      await getAllProcesses();
      await dispatch({ type: UPDATE_LOADING, payload: false });
      await getStepUsers({
        ProcessInstanceId: res.data.ProcessInstance.Id,
        ProcessStepDefinitionId:
          res.data.ProcessInstance.ProcessStepDefinitionId // It needs to be the next step
      });
    }

    return res;
  }

  if (!res.headers.auth) {
    dispatch({ type: UPDATE_LOADING, payload: false });
    // console.log('Your account is being used on another device');
  }
};

export const quickProcessUpdate = async ({ data }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  const res = await QuickProcessUpdate({ data });

  if (res && res.status === 200) {
    await Promise.all([
      dispatch({ type: 'SET_CURRENT_INSTANCE', payload: res.data }),
      await getAllProcesses(),
      dispatch({ type: UPDATE_LOADING, payload: false })
    ]);
    return Boolean(res.data);
  } else {
    dispatch({ type: UPDATE_LOADING, payload: false });
  }
};

export const getStepUsers = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId
}) => {
  const res = await GetStepUsers({
    ProcessInstanceId,
    ProcessStepDefinitionId
  });
  if (res && res.status === 200) {
    dispatch({ type: 'SET_CURRENT_STEP', payload: res.data });
    return res.data;
  }
};

/**
 * LANDING PAGE
 */

export const getProcessDefinition = async ({
  token,
  ProcessDefinitionId,
  ProcessStepDefinitionId
}: {
  token?: string;
  ProcessDefinitionId: string;
  ProcessStepDefinitionId?: number | undefined;
}) => {
  const processDefinitions = store.getState().process.processDefinitions;

  dispatch({ type: UPDATE_LOADING, payload: true });
  const res = await GetProcessDefinition({
    ProcessDefinitionId,
    ProcessStepDefinitionId // Optional
  });
  if (res && res.status === 200) {
    dispatch({ type: UPDATE_LOADING, payload: false });
    if (res && res.status === 200) {
      const updatedProcessDefinitions = JSON.parse(
        JSON.stringify(processDefinitions)
      );
      updatedProcessDefinitions[ProcessDefinitionId] = res.data;

      Promise.all([
        dispatch({
          type: 'SET_PROCESS_DEFINITION_FULL',
          payload: updatedProcessDefinitions
        }),

        dispatch({ type: 'SET_PROCESS_DEFINITION', payload: res.data })
      ]);
      return res.headers.auth;
    }
  }

  if (!res.headers.auth) {
    dispatch({ type: UPDATE_LOADING, payload: false });
  }

  return token;
}; //END getProcessDefinition

export const copyProcessToStep = async (props) => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  const res = await CopyProcessToStep(props);
  if (res && res.status === 200) {
    Promise.all([dispatch({ type: UPDATE_LOADING, payload: false })]);

    if (res && res.status === 200) {
      return res;
    }
  }
};

export const getDocument = async (props) => {
  const res = await GetDocument(props);
  if (res && res.status === 200) return res;
};

export const getProcessesByUserDefinition = async (props: {
  token: string;
  UserDefinitionId: string;
}) => {
  const res = await GetProcessesByUserDefinition(props);
  if (res && res.status === 200) {
    return res;
  } else {
    notify.error(res?.headers?.Error);
  }
};

export const getProcessFields = async (props: IGetProcessFields) => {
  const res = await GetProcessFields({ ...props });
  if (res && res.status === 200) {
    setCurrentOverViewFromProcessFields({ ...props, res });
    return res;
  }
  return;
};

const setCurrentOverViewFromProcessFields = async ({
  ProcessDefinitionId,
  ProcessInstanceId,
  res
}) => {
  const { currentProcess, currentDeal } = store.getState().process;
  const processInstanceFields = store.getState().process.processInstanceFields;
  const updateProcessInstanceFields = JSON.parse(
    JSON.stringify(processInstanceFields)
  );

  updateProcessInstanceFields[ProcessInstanceId] = res.data;
  let payload = updateProcessInstanceFields;
  await dispatch({ type: 'SET_PROCESS_FIELDS', payload });

  const ProcessStepDefinitionId =
    currentDeal &&
    currentDeal.ProcessInstance &&
    currentDeal.ProcessInstance.ProcessStepDefinitionId;

  const ProcessStepDefinitionTitle =
    currentProcess?.CompleteProcessStepDefinitionDict?.[ProcessStepDefinitionId]
      ?.ProcessStepDefinition?.Title;

  payload = {
    ProcessInstanceId,
    ProcessInstanceTitle: currentDeal?.ProcessInstance?.Title,
    UserInstanceId: currentDeal?.ProcessInstance?.Id,
    ProcessDefinitionId,
    ProcessDefinitionTitle: currentProcess?.ProcessDefinition?.Title,
    ProcessStepDefinitionId,
    ProcessStepDefinitionTitle,
    LastModified: currentDeal?.ProcessInstance?.LastModified,
    Guid: currentDeal?.ProcessInstance?.Guid
  };

  dispatch({ type: 'SET_CURRENT_OVERVIEW', payload });
};
