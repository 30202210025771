import axios from 'axios';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { IDesign } from '../types';

export const fetchDesigns = async ({
  isAFS,
  isSynergy,
  isLocal
}: {
  isAFS: boolean;
  isSynergy: boolean;
  isLocal: boolean;
}): Promise<IDesign[]> => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/designs`;
  // Staging environment (shared by both AFS and Synergy):
  const isDual = isAFS && isSynergy;
  // name of the current individual AFS or Synergy Instance
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

  try {
    const res = await axios.get(url, { headers: { token } });

    const designs: IDesign[] = res.data.result.filter((e) => {
      // return all designs:
      if (isLocal) return true;

      // NOTE: When we want to share designs we tag them as "public"
      const publicCategory = e.categories.includes('public');
      const afsCategory = e.categories.includes('afs');
      const synergyCategory = e.categories.includes('synergy');
      const currentInstanceCategory = e.categories.includes(hostname);

      // If design is tagged for a specific instance (and has been made public) return it:
      if (currentInstanceCategory) {
        return publicCategory && currentInstanceCategory;
      }

      // Staging:
      if (isDual) return publicCategory && (afsCategory || synergyCategory);
      // Production:
      if (isAFS) return publicCategory && afsCategory;
      if (isSynergy) return publicCategory && synergyCategory;
    });

    return designs;
  } catch (e) {
    console.error(e);

    BugTracker.notify(e);
    return [];
  }
};
