import React, { useEffect } from 'react';
import { useTypedSelector } from 'redux/reducers';

import _ from 'lodash';

import { DateTimePicker } from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';
import { format } from 'fecha';
import TypeSelect from './TypeSelect';
import {
  ListItemText,
  DialogContent,
  Typography,
  Grid,
  TextField,
  Button,
  List,
  Dialog,
  DialogTitle,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import { BugTracker } from 'Utils/Bugtracker';
import * as gtag from 'Utils/gtag';
import { DateType } from '@date-io/type';

import RelationshipSelect from './RelationshipSelect';
import { UserDefinition, UserInstance } from 'types/interfaces';
import {
  getQuickLiteUser,
  getRelationship
} from 'redux/actions/GraphQlActions';
import {
  IRelationshipData,
  IUpdateUserInstance,
  RelatedUserPermission
} from 'redux/actions/GraphQlActions/interface';
import { notify } from 'components/Notifications/HotToastNotifications';

const cryptography = (props) => {
  let data = null;
  const { type, start, end, desc, primary, ...UserInstance } = props;
  const hash = `type=${type},start=${start},end=${end},desc=${desc},primary=${primary},created=${new Date()}`;
  UserInstance.UserInstanceEmail = hash;
  data = UserInstance;
  return data;
};

const useStyles = makeStyles((theme) => ({
  plugins: {
    width: '90%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  RelationshipBox: {
    width: 200,
    minHeight: theme.spacing(12),
    maxHeight: 195,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'scroll'
  },
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

interface IUserInstance {
  desc: string;
  type: string;
  start: string;
  end: string;
  Title: string;
  primary: number;
  UserDefinitionId: number | string;
  UserInstanceEmail: string;
  UserInstancePassword: string;
}

const CreateCalendarUserTypeDialog = ({
  UserDefinitionId,
  selectedUser,
  open,
  setOpen,
  defaults,
  handleClose,
  getData,
  onSuccess,
  setRefreshID,
  TimelineRelationship
}: {
  UserDefinitionId: number | string;
  selectedUser: string | any;
  open: boolean;
  defaults: {
    end: Date;
    start: Date;
    open: boolean;
  };
  TimelineRelationship: {
    UserDefinition: UserDefinition;
    UserInstance: UserInstance;
  };
  onSuccess: any;
  getData: () => void;
  setOpen: (open: boolean) => void;
  handleClose: () => void;
  setRefreshID: (number: number) => void;
}) => {
  const classes = useStyles();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const loggedInUser = useTypedSelector((s) => s.user.user);

  const options: string[] = ['Note', 'Meeting', 'Call', 'Todo'];
  const [loadnewuser, setLoadnewuser] = React.useState<boolean>(false);
  const [relationship, setRelationship] = React.useState<any>([]);
  const [charLimit, setCharLimit] = React.useState(0);
  const [error, setError] = React.useState(false);

  const INIT_USERINSTANCE: IUserInstance = {
    desc: '',
    type: 'Note',
    start:
      defaults && defaults.start
        ? format(defaults.start, 'YYYY-MM-DDTHH:mm')
        : format(new Date(), 'YYYY-MM-DDTHH:mm'),
    end:
      defaults && defaults.end
        ? format(defaults.end, 'YYYY-MM-DDTHH:mm')
        : format(new Date(), 'YYYY-MM-DDTHH:mm'),
    Title: '',
    primary: loggedInUser?.Id,
    UserDefinitionId,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  const [newUser, setNewUser] =
    React.useState<IUserInstance>(INIT_USERINSTANCE);

  useEffect(() => {
    setNewUser(INIT_USERINSTANCE);
  }, [defaults]);

  const updateSubmissionData = (p) => {
    setRelationship([...relationship, p.relationship]);
  };

  const removeGuests = (i) => {
    setRelationship(relationship.slice(0, i).concat(relationship.slice(i + 1)));
  };

  useEffect(() => {
    setRelationship([]);
  }, []);

  const handleCloseLocal = async () => {
    if (handleClose) {
      handleClose();
      setCharLimit(0);
      setRelationship([]);
      // Refreshes Timeline Data
      if (
        TimelineRelationship &&
        Object.keys(TimelineRelationship).length !== 0
      )
        setRefreshID(1);
    } else {
      setOpen(false);
      setLoadnewuser(false);
      setNewUser(INIT_USERINSTANCE);
    }
  };

  const handleChange = (name, value) => {
    setNewUser({ ...newUser, [name]: value, UserDefinitionId });
  };

  interface ICryptography {
    Title: string;
    UserDefinitionId: string;
    UserInstanceEmail: string;
    UserInstancePassword: string;
  }

  const createUser = async () => {
    const data: ICryptography | any = cryptography(newUser);

    if (data?.Title === '') {
      notify.error('Please add a title');
    }

    setLoadnewuser(true);
    try {
      const typedData: IUpdateUserInstance = {
        ...data,
        UserDefinitionId: parseInt(data.UserDefinitionId.toString()),
        IsPublished: true
      };

      const response = await getQuickLiteUser({
        baseUrl,
        ProcessDefinitionId: 0,
        data: typedData,
        action: 'UPDATE'
      });

      const UserInstance = response?.UserInstance;
      if (UserInstance) {
        gtag.event({
          action: 'Event added',
          feature: 'CALENDAR',
          message: 'Activity event added'
        });

        gtag.event({
          action: 'Event Type',
          feature: 'CALENDAR',
          message: `Event Type: ${newUser.type}`
        });

        createRelationship({
          baseUrl,
          relationship,
          activity: UserInstance
        });
      } else {
        notify.error('Failed to create user');
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const createRelationship = async ({ relationship, baseUrl, activity }) => {
    // user = activity
    // relationship = Target = 1st relation either user selected or selectedUser
    // loggedInUser = Logged in user = 2nd relation
    try {
      if (
        TimelineRelationship &&
        Object.values(TimelineRelationship).length !== 0
      ) {
        HandleTimelineRelationship({
          baseUrl,
          activity,
          TimelineRelationship,
          newUser,
          loggedInUser
        });
      } else {
        const primaryData: IRelationshipData = {
          Title: newUser.start,
          PrimaryUserInstanceId: activity.Id,
          RelatedUserInstanceId: loggedInUser.Id,
          Status: 0,
          RelatedUserPermission: RelatedUserPermission.Standard
        };

        await getRelationship({
          action: 'CREATE',
          data: primaryData,
          baseUrl,
          UserDefinitionId: parseInt(newUser.UserDefinitionId.toString()),
          UserInstanceId: parseInt(activity.Id.toString())
        });

        const relatedData: IRelationshipData = {
          Title: newUser.start,
          PrimaryUserInstanceId: loggedInUser.Id,
          RelatedUserInstanceId: activity.Id,
          Status: 0,
          RelatedUserPermission: RelatedUserPermission.Standard
        };

        await getRelationship({
          action: 'CREATE',
          data: relatedData,
          baseUrl,
          UserDefinitionId: parseInt(newUser.UserDefinitionId.toString()),
          UserInstanceId: parseInt(activity.Id.toString())
        });
      }

      gtag.event({
        action: 'Relationship added between logged in User and the Event',
        feature: 'CALENDAR',
        message: `Two way relationship added between ${activity.Id} and ${loggedInUser.Id}`
      });
    } catch (e) {
      BugTracker.notify(e);
    }

    // 2. Then if there is a target relationship use that as the second relationship.
    for (const key in relationship.length) {
      const keyValue = relationship[key];
      const userId: number = selectedUser
        ? selectedUser.UserInstanceId
        : keyValue.UserInstance.value;

      try {
        const primaryData: IRelationshipData = {
          Title: newUser.start,
          PrimaryUserInstanceId: activity.Id,
          RelatedUserInstanceId: userId,
          Status: 0,
          RelatedUserPermission: RelatedUserPermission.Standard
        };
        await getRelationship({
          action: 'CREATE',
          data: primaryData,
          baseUrl,
          UserDefinitionId: parseInt(newUser.UserDefinitionId.toString()),
          UserInstanceId: parseInt(activity.Id.toString())
        });

        const relatedData: IRelationshipData = {
          Title: newUser.start,
          PrimaryUserInstanceId: userId,
          RelatedUserInstanceId: activity.Id,
          Status: 0,
          RelatedUserPermission: RelatedUserPermission.Standard
        };

        await getRelationship({
          action: 'CREATE',
          data: relatedData,
          baseUrl,
          UserDefinitionId: parseInt(newUser.UserDefinitionId.toString()),
          UserInstanceId: parseInt(activity.Id.toString())
        });

        gtag.event({
          action: 'Relationship added between logged in User and Related user',
          feature: 'CALENDAR',
          message: `Two way relationship added between ${activity.Id} and ${userId}`
        });
      } catch (e) {
        BugTracker.notify(e);
      }
    }

    // Refreshing
    handleCloseLocal();
    getData && getData();

    setCharLimit(0);
    onSuccess && onSuccess(activity);
    setLoadnewuser(false);
  };

  const DateError = ({ e }) => {
    if (e.includes('Date should not be before minimal date')) {
      return setError(true);
    } else return setError(false);
  };

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} scroll="body">
      <DialogTitle className={classes.root}>
        <Typography variant="h6">Create Activity</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseLocal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <div className={classes.plugins}>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  spacing={2}>
                  <Grid item>
                    <TypeSelect
                      handleChange={handleChange}
                      options={options}
                      value={newUser.type}
                    />
                  </Grid>

                  <Grid item>
                    <DateTimePicker
                      InputLabelProps={{ shrink: true }}
                      label="Start"
                      inputVariant="outlined"
                      value={newUser.start}
                      onChange={(date: DateType | null) => {
                        if (date) {
                          const newDate = new Date(date?.format());
                          handleChange('start', newDate);
                        }
                      }}
                      disablePast
                    />
                  </Grid>

                  <Grid item>
                    <DateTimePicker
                      InputLabelProps={{ shrink: true }}
                      label="End"
                      inputVariant="outlined"
                      value={newUser.end}
                      onChange={(date: DateType | null) => {
                        if (date) {
                          const newDate = new Date(date?.format());
                          handleChange('end', newDate);
                        }
                      }}
                      disablePast
                      onError={(e) => DateError({ e })}
                    />
                  </Grid>
                  <Grid item>
                    {!_.isEmpty(relationship) && (
                      <div>
                        <List className={classes.RelationshipBox}>
                          <Typography
                            style={{
                              textDecoration: 'underline',
                              paddingBottom: 5
                            }}>
                            Currently Invited:
                          </Typography>
                          {relationship.map((item: any, index: number) => (
                            <div key={index}>
                              <div style={{ display: 'flex' }}>
                                <IconButton
                                  color="secondary"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    right: 5
                                  }}
                                  onClick={() => {
                                    removeGuests(index);
                                  }}>
                                  <DeleteIcon
                                    style={{
                                      width: 20,
                                      height: 20
                                    }}
                                  />
                                </IconButton>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  Guest - {index + 1}
                                  <ListItemText
                                    primary={
                                      item?.UserDefinition?.label +
                                      '-' +
                                      item?.UserInstance?.label
                                    }
                                  />
                                </Typography>
                              </div>
                            </div>
                          ))}
                        </List>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  spacing={2}>
                  <Grid item>
                    <TextField
                      margin="dense"
                      name="Title"
                      onChange={(e) => handleChange('Title', e.target.value)}
                      placeholder="Title..."
                      type="text"
                      value={newUser.Title}
                      variant="outlined"
                      style={{ width: 210 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      helperText={
                        'Remaining Characters: ' + charLimit + '/ 100'
                      }
                      margin="dense"
                      multiline
                      name="Description"
                      onChange={(e) => {
                        handleChange('desc', e.target.value);
                        setCharLimit(e.target.value.length);
                      }}
                      placeholder="Optional Description..."
                      maxRows={4}
                      minRows={4}
                      inputProps={{ maxLength: 100 }}
                      type="text"
                      value={newUser.desc}
                      variant="outlined"
                      style={{ width: 210 }}
                    />
                  </Grid>
                  <Grid item>
                    {!selectedUser && (
                      <Grid item>
                        <RelationshipSelect
                          updateSubmissionData={updateSubmissionData}
                          relationship={relationship}
                          newUser={newUser}
                          TimelineRelationship={TimelineRelationship}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item>
          {loadnewuser ? (
            <CircularProgress />
          ) : (
            <div>
              {error ? (
                <Alert severity="warning" variant="outlined">
                  <Typography>
                    <b>Cannot Create:</b> Please Review The Dates Selected
                  </Typography>
                </Alert>
              ) : (
                <Button
                  color="primary"
                  onClick={() => createUser()}
                  variant="contained">
                  Create
                </Button>
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <br />
    </Dialog>
  );
};

export default CreateCalendarUserTypeDialog;

const HandleTimelineRelationship = async ({
  baseUrl,
  activity,
  loggedInUser,
  newUser,
  TimelineRelationship
}) => {
  const postRelationship = async (primaryId: number, relatedId: number) => {
    const relationshipData: IRelationshipData = {
      Title: newUser.start,
      PrimaryUserInstanceId: primaryId,
      RelatedUserInstanceId: relatedId,
      Status: 0,
      RelatedUserPermission: RelatedUserPermission.Standard
    };

    await getRelationship({
      action: 'CREATE',
      data: relationshipData,
      baseUrl,
      UserDefinitionId: parseInt(newUser.UserDefinitionId.toString()),
      UserInstanceId: primaryId
    });
  };

  await postRelationship(activity.Id, TimelineRelationship.UserInstance.Id);
  await postRelationship(activity.Id, loggedInUser.Id);
  await postRelationship(TimelineRelationship.UserInstance.Id, activity.Id);
  await postRelationship(loggedInUser.Id, activity.Id);
};
