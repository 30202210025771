import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import FAQComponent from './Faq';
import GuidesAndVideos from './guidesAndVideos';
import faq_Data from 'data/FAQ/faqData.json';
import guide_and_video_data from 'data/FAQ/guideAndVideos.json';

const FAQAndGuides = ({
  tags,
  folderName
}: {
  tags?: string[];
  folderName?: string;
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const getFilteredFAQs = () => {
    if (!tags) return faq_Data;
    return faq_Data.filter((faq) => faq.tags.some((tag) => tags.includes(tag)));
  };

  // Get relevant folders including both direct matches and displayUnder
  const getRelevantFolders = () => {
    if (!folderName) return [];
    return guide_and_video_data.folders.filter(
      (folder) =>
        folder.folderName === folderName || folder.displayUnder === folderName
    );
  };

  const getAllVideos = () => {
    const folders = getRelevantFolders();
    return folders.flatMap((folder) => folder.videos || []);
  };

  const faqsAvailable = getFilteredFAQs().length > 0;
  const videosAvailable = getAllVideos().length > 0;

  React.useEffect(() => {
    // Determine default tab based on availability
    if (!faqsAvailable && videosAvailable) {
      setSelectedTab(1); // Switch to Videos if no FAQ
    } else {
      setSelectedTab(0); // Otherwise default to FAQ
    }
  }, [faqsAvailable, videosAvailable]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      {/* <Typography variant="h4" gutterBottom>
        Help Center
      </Typography> */}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        centered>
        <Tab label="Top FAQs" disabled={!faqsAvailable && !videosAvailable} />
        <Tab
          label="Guides & Videos"
          disabled={!faqsAvailable && !videosAvailable}
        />
      </Tabs>
      <Box mt={2}>
        {selectedTab === 0 && <FAQComponent tags={tags?.join(', ')} />}
        {selectedTab === 1 && (
          <GuidesAndVideos folderName={folderName} videos={getAllVideos()} />
        )}
      </Box>
    </Box>
  );
};

export default FAQAndGuides;
