import axios from 'axios';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { notify } from 'components/Notifications/HotToastNotifications';

export const deleteCampaign = async (id: string): Promise<void> => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();

    const url = `${process.env.REACT_APP_PROXY}/marketing/campaign/${id}`;
    const config = { headers: { token } };

    await axios.delete(url, config);
    notify.success(`Campaign Deleted`);
  } catch (e) {
    BugTracker.notify(e);
    notify.error(`Failed to delete Campaign`);
  }
};
