import axios from 'axios';
import { store } from 'redux/store';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { notify } from 'components/Notifications/HotToastNotifications';

export const upsertContacts = async ({ list_ids, contacts }) => {
  const { dispatch } = store;

  const data = { list_ids, contacts };
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/lists/contacts`;

  return axios
    .post(url, data, { headers: { token } })
    .then((res) => {
      notify.info('Contacts added to list, This can take a few minutes');

      gtag.event({
        feature: 'Marketing',
        action: 'Contacts Upserted to List',
        message: list_ids
      });
      return res;
    })
    .catch((e) => {
      BugTracker.notify(e);

      const { errors } = e.response.data.response.body;
      errors.forEach((error) => {
        notify.error(error.message);
      });
    });
};
