import { notify } from 'components/Notifications/HotToastNotifications';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

interface UpdateDomain {
  key: string;
  hostname: string;
  originalHostname: string;
}

// NOTE: this is currenly only being used to toggle / update hostnames to global
export const updateDomain = async ({
  key,
  hostname,
  originalHostname
}: UpdateDomain): Promise<UpdateDomain | void> => {
  try {
    await firebase
      .firestore()
      .collection('domain')
      .doc(key)
      // set originalHostname for older domains that didnt have it on creation
      .update({ hostname, originalHostname });

    notify.success(`Domain hostname updated to: ${hostname}`);

    return {
      key,
      hostname,
      originalHostname
    };
  } catch (error) {
    BugTracker.notify(error);
    notify.error(`Something went wrong, please try again.`);
  }
};
