import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Box } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { MdDomainVerification } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FormControl from '@material-ui/core/FormControl';
import Xpansion from 'common/Xpansion';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DeleteDomainDialog from './deleteDomainDialog';
import { SuccessButton } from 'common/Button';
import { CustomTooltip } from '../components';
import {
  fetchDomains,
  deleteDomain,
  createDomain,
  verifyDomain,
  updateDomain
} from './functions';
import { useInstance } from 'hooks/useInstance';
import { useTypedSelector } from 'redux/reducers';
import { Domain, DNS_TYPES } from './types';
import { QueryKeys } from '../types';
import Paginator from 'components/Marketing/components/Paginator';
import { validateDomain, validateSubDomain } from './utils';

import './index.css';
import { notify } from 'components/Notifications/HotToastNotifications';

const grey = '#68696b';
const green = '#66CDAA';

const useStyles = makeStyles((theme: Theme) => {
  const grey100 = theme.palette.grey[100];
  const grey300 = theme.palette.grey[300];
  const grey400 = theme.palette.grey[400];
  const green = '#66CDAA';

  const fieldset = {
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center' as const,
    border: `2px solid ${grey400}`
  };

  return createStyles({
    container: {
      maxWidth: 900,
      marginBottom: 50,
      margin: '0 auto',
      border: `1px solid ${grey300}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: grey100,
      padding: 20
    },
    table: {
      width: '95%',
      padding: 10,
      margin: '0 auto'
    },
    copyable: {
      textAlign: 'center',
      padding: '0px 8px',
      '&:hover': {
        background: `${theme.palette.primary.light}66`,
        border: `1px ${theme.palette.primary.main} dashed`,
        borderRadius: theme.shape.borderRadius,
        opacity: 0.5,
        cursor: 'pointer'
      }
    },
    errorBox: {
      background: 'orange'
    },
    dnsFieldset: {
      ...fieldset,
      marginTop: 10,
      paddingBottom: 10
    },
    formContainer: {
      border: `1px dashed lightgrey`,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxWidth: 900,
      margin: '20px auto'
    },
    notGlobal: {
      backgroundColor: 'lightgrey',
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: green,
        color: theme.palette.common.white
      }
    },
    global: {
      backgroundColor: green,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'lightgrey',
        color: theme.palette.common.white
      }
    }
  });
});

const DomainAuthentication = ({ testDomain }: { testDomain?: string }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { isAFS, isSynergy, isDual } = useInstance();
  const user = useTypedSelector((s) => s.user.user);

  const isAdmin = user.SystemAccess === 10;
  const isSuperAdmin = user.SystemAccess === 15;

  const [expanded, setExpanded] = React.useState(false);
  const [newDomain, setNewDomain] = React.useState(testDomain || '');
  const [newSubDomain, setNewSubDomain] = React.useState(testDomain || '');
  const [domainError, setDomainError] = React.useState('');
  const [subdomainError, setSubdomainError] = React.useState('');

  const { data: domains = [], isLoading } = useQuery(
    [QueryKeys.FetchDomains],
    () =>
      fetchDomains({
        isAFS,
        isSynergy,
        isSuperAdmin,
        isAdmin,
        userInstanceId: user.Id
      }),
    {
      enabled: true,
      //disable cache
      refetchOnMount: true
    }
  );

  // render global domains first
  const sortedDomains = React.useMemo(() => {
    return domains.sort((a, b) => {
      if (a.hostname === 'global' && b.hostname !== 'global') return -1;
      if (a.hostname !== 'global' && b.hostname === 'global') return 1;
      return 0;
    });
  }, [domains]);

  const [paginatedDomains, setPaginatedDomains] =
    useState<Domain[]>(sortedDomains);

  const { mutate: create, isLoading: authenticateLoading } = useMutation(
    createDomain,
    {
      onSuccess: () => {
        setNewDomain('');
        setNewSubDomain('');
        queryClient.invalidateQueries(QueryKeys.FetchDomains);
      }
    }
  );

  const { mutate: remove, isLoading: deleteLoading } = useMutation(
    deleteDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const { mutate: verify, isLoading: verifyLoading } = useMutation(
    verifyDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const { mutate: update, isLoading: updateLoading } = useMutation(
    updateDomain,
    {
      onSuccess: (updatedData) => {
        if (!updatedData?.key) return;
        // since updating the hostname changes the order of domains, we want to preserve the order and just re-render with new values rather than invalidating the query and reordering (causing confusion)
        setPaginatedDomains((prevDomains) =>
          prevDomains.map((domain) =>
            domain.key === updatedData.key
              ? { ...domain, ...updatedData }
              : domain
          )
        );
      }
    }
  );

  const loading =
    isLoading ||
    authenticateLoading ||
    deleteLoading ||
    verifyLoading ||
    updateLoading;

  const validateDomainRow = (val) => {
    if (val) return <VerifiedUserIcon style={{ color: green }} />;
    return <ClearIcon style={{ color: 'red' }} />;
  };

  const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();

    setNewDomain(value);

    if (!value) return setDomainError('');

    const error = validateDomain(value);
    if (error) setDomainError(error);
    else setDomainError('');
  };

  const handleSubDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setNewSubDomain(value);

    if (!value) return setSubdomainError('');

    const error = validateSubDomain(value);
    if (error) setSubdomainError(error);
    else setSubdomainError('');
  };

  const previewDomain = newSubDomain
    ? `(${newSubDomain}.${newDomain})`
    : newDomain
    ? `(${newDomain})`
    : '';

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          opacity: 0.8
        }}>
        <MdDomainVerification
          style={{
            marginRight: 10,
            fontSize: 50,
            color: 'grey'
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: 'grey'
          }}>
          Email Domain Authentication
        </Typography>
      </div>

      <Alert severity="info">
        <Typography variant="h5">
          In order to send marketing emails you must authenticate your domain
          first:
        </Typography>
        <Typography>
          <ol>
            <li>
              Enter your domain name in the <b>Domain*</b> field (e.g.{' '}
              <b>my-business.com</b>).
            </li>
            <li>
              In most cases this is all you will need, press <b>Submit</b>{' '}
              (Unless you need to also authenticate a specific subdomain which
              can be done in the Advanced section).
            </li>
            <li>
              Once you have received your <b>CNAME</b> records, you will need to
              enter these into your email hosting providers DNS configuration.
            </li>
            <li>
              Hit the refresh button to test the validity of your records.
            </li>
          </ol>
        </Typography>
      </Alert>
      <br />

      <Grid container className={classes.formContainer}>
        <Typography variant="h4">
          Add New Domain: <span style={{ color: 'grey' }}>{previewDomain}</span>
        </Typography>

        <CustomTooltip
          enterNextDelay={100}
          placement="right-end"
          title="Enter your domain name (e.g., my-business.com)">
          <FormControl variant="outlined" margin="dense" fullWidth required>
            <TextField
              data-testid="domain-input"
              label="Domain"
              variant="outlined"
              fullWidth
              value={newDomain}
              onChange={handleDomainChange}
              error={Boolean(domainError)}
              helperText={domainError}
              placeholder="Enter your domain (e.g., my-business.com)"
            />
          </FormControl>
        </CustomTooltip>
        {isSuperAdmin && (
          <Xpansion
            summary={'Advanced'}
            style={{ margin: 2 }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}>
            <CustomTooltip
              enterNextDelay={100}
              placement="right-end"
              title="The subdomain to use for this authenticated domain.">
              <div style={{ width: '100%' }}>
                <TextField
                  style={{ width: '100%' }}
                  label="Subdomain"
                  margin="dense"
                  variant="outlined"
                  value={newSubDomain}
                  onChange={handleSubDomainChange}
                  placeholder="i.e. news"
                  error={Boolean(subdomainError)}
                  helperText={subdomainError}
                />
              </div>
            </CustomTooltip>
          </Xpansion>
        )}

        <Box style={{ paddingTop: 4 }}>
          <SuccessButton
            data-testid={`create-domain`}
            fullWidth
            size="large"
            color="primary"
            onClick={() =>
              create({
                domain: newDomain,
                subdomain: newSubDomain,
                userInstanceId: user.Id
              })
            }
            variant="outlined">
            {loading ? (
              <span style={{ color: 'white ' }}>
                <CircularProgress color="inherit" size={20} />
              </span>
            ) : (
              'Submit'
            )}
          </SuccessButton>
        </Box>
      </Grid>

      <br />
      <>
        {paginatedDomains.map((domainData, i: number) => {
          const {
            dns,
            key,
            id,
            domain,
            subdomain,
            isCustomSubdomain,
            hostname,
            valid
          } = domainData;

          // for old domains that didnt have originalHostname, default to its current hostname
          const originalHostname = domainData.originalHostname || hostname;

          const dnsData: any[] = DNS_TYPES.map((d, i) => {
            const dnsType = dns[d];
            if (!dnsType) return;
            return dnsType;
          }).filter(Boolean);

          const isGlobal = hostname === 'global';
          const isAfsGlobal = isGlobal && domain.includes('afs');
          const isSynergyGlobal = isGlobal && domain.includes('synergy');

          // determine if the global domain is specifically for all AFS instances or all Synergy instances (or both)
          const globalHostname = isAfsGlobal
            ? `global (AFS)`
            : isSynergyGlobal
            ? `global (Synergy)`
            : 'global';

          const hostnameToDisplay = isGlobal ? globalHostname : hostname;

          const domainToDisplay = isCustomSubdomain
            ? `${subdomain}.${domain}`
            : domain;

          return (
            <Box key={i} className={classes.container} data-testid={domain}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: -10
                }}>
                <div>{validateDomainRow(valid)}</div>
                <div
                  style={{
                    padding: 8,
                    flexGrow: 1
                  }}>
                  <Typography variant="h4" style={{ color: grey }}>
                    {domainToDisplay}
                  </Typography>
                </div>
                {
                  <>
                    <ToggleButtonGroup
                      exclusive
                      onChange={() => {
                        const toggleHostname = isGlobal
                          ? originalHostname
                          : 'global';

                        update({
                          key,
                          hostname: toggleHostname,
                          originalHostname
                        });
                      }}>
                      <ToggleButton
                        value={'global'}
                        disabled={!isSuperAdmin}
                        className={
                          !isSuperAdmin
                            ? ''
                            : isGlobal
                            ? classes.global
                            : classes.notGlobal
                        }>
                        Global
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <div>
                      <CustomTooltip
                        placement="top"
                        title="Refresh Domain Status">
                        <IconButton
                          data-testid={`verify-domain-${id}`}
                          onClick={() => verify(domainData)}
                          disabled={!isSuperAdmin && isGlobal}>
                          <RefreshIcon />
                        </IconButton>
                      </CustomTooltip>
                    </div>

                    <div>
                      <DeleteDomainDialog
                        domainId={id}
                        handleDeleteDomain={() => remove(domainData)}
                        disabled={!isSuperAdmin && isGlobal}
                      />
                    </div>
                  </>
                }
              </div>
              <Box>
                <Typography variant={'h6'} style={{ marginTop: 20 }}>
                  Hostname:{' '}
                  <span style={{ color: 'grey' }}>{hostnameToDisplay}</span>
                </Typography>
              </Box>
              {!isSuperAdmin && isGlobal ? null : (
                <fieldset className={classes.dnsFieldset}>
                  <legend>
                    <h3 style={{ padding: 10 }}>DNS records</h3>
                  </legend>

                  <table className={classes.table}>
                    <tr id="dns">
                      <th>Status</th>
                      <th>Type</th>
                      <th>Host</th>
                      <th>Value</th>
                    </tr>

                    {dnsData.map((d, i) => {
                      return (
                        <Fragment key={i}>
                          <tr id="dns" style={{ fontWeight: 500, color: grey }}>
                            <td id="dns">{validateDomainRow(d.valid)}</td>
                            <td id="dns">{d.type}</td>
                            <td
                              className={classes.copyable}
                              id="dns"
                              onClick={() => copy(d.host)}>
                              {d.host}
                            </td>
                            <td
                              className={classes.copyable}
                              id="dns"
                              onClick={() => copy(d.data)}>
                              {d.data}
                            </td>
                          </tr>
                          {d.reason && (
                            <tr id="dns">
                              <td colSpan={12} id="dns">
                                <Alert severity="error" variant="outlined">
                                  {d.reason}
                                </Alert>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                  </table>
                </fieldset>
              )}
            </Box>
          );
        })}
        <Paginator
          allItems={sortedDomains}
          setPaginatedItems={setPaginatedDomains}
          itemsPerPage={6}
        />
      </>
    </>
  );
};

export default DomainAuthentication;

const copy = (value: string) => {
  const fakeElement = document.createElement('input');
  fakeElement.value = value;
  document.body.appendChild(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  document.body.removeChild(fakeElement);
  notify.success(`Copied to clipboard`);
  return;
};
