import { FileAttachment, Message } from '@microsoft/microsoft-graph-types';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import {
  IEmailComposition,
  IEmailCompositionBody,
  IFile
} from 'components/MessageHub/interfaces';
import React, { createContext, useContext, useState } from 'react';
import { IEmailContext, IEmailCreationContext } from './interface';
import { BugTracker } from 'Utils/Bugtracker';
import * as gtag from 'Utils/gtag';
import { notify } from 'components/Notifications/HotToastNotifications';

export const EmailCompositionContext = createContext<IEmailContext>({
  showBcc: false,
  setShowBcc: () => {},
  showCc: false,
  setShowCc: () => {}
});

export const EmailCompositionProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [showBcc, setShowBcc] = useState(false);
  const [showCc, setShowCc] = useState(false);

  return (
    <EmailCompositionContext.Provider
      value={{ showBcc, setShowBcc, showCc, setShowCc }}>
      {children}
    </EmailCompositionContext.Provider>
  );
};

export const EmailCompositionCreationContext =
  createContext<IEmailCreationContext>({
    composeWindows: [],
    setComposeWindows: () => {},
    allEmailBodies: [],
    setAllEmailBodies: () => {},
    closeComposeEmail: () => {},
    addComposeEmail: () => {},
    removeComposeEmail: () => {},
    sendEmail: () => {},
    saveDraft: () => {}
  });

const MAX_COMPOSE_WINDOWS = 2;
export const EmailCompositionCreationProvider = ({
  children,
  validAccessToken: validAccessTokenProp,
  getRefreshEmails: getRefreshEmailsProp
}: {
  children: React.ReactNode;
  validAccessToken?: () => Promise<string>;
  getRefreshEmails?: (() => Promise<void>) | boolean;
}) => {
  const contextValues = useContext(MessageHubContext);

  const validAccessToken =
    validAccessTokenProp || contextValues.validAccessToken;

  let getRefreshEmails: (() => Promise<void>) | boolean | undefined;
  if (typeof getRefreshEmailsProp === 'boolean') {
    getRefreshEmails = getRefreshEmailsProp;
  } else {
    getRefreshEmails = getRefreshEmailsProp || contextValues.getRefreshEmails;
  }

  const [composeWindows, setComposeWindows] = useState<IEmailComposition[]>([]);
  const [allEmailBodies, setAllEmailBodies] = useState<IEmailCompositionBody[]>(
    []
  );

  const closeComposeEmail = (id: number) => {
    setComposeWindows((prev) => prev.filter((window) => window.id !== id));

    setAllEmailBodies((prevBodies) =>
      prevBodies.filter((body) => body.id !== id)
    );
  };

  const addComposeEmail = (draft?: Message, toEmail?: string[]) => {
    const newId = generateUniqueId();

    setComposeWindows((prev) => {
      if (prev.length >= MAX_COMPOSE_WINDOWS) {
        notify.error('You can only compose two emails at a time.');

        return prev;
      }

      return [
        ...prev,
        {
          id: newId,
          isMinimized: false
        }
      ];
    });

    setAllEmailBodies((prevBodies: IEmailCompositionBody[]) => {
      const updatedBodies = [...prevBodies];
      const toRecipients = draft?.toRecipients || [];
      const ccRecipients = draft?.ccRecipients || [];
      const bccRecipients = draft?.bccRecipients || [];

      const toEmailAddresses = toRecipients
        .map((recipient) => recipient?.emailAddress?.address)
        .filter(Boolean) as string[];

      const ccEmailAddresses = ccRecipients
        .map((recipient) => recipient?.emailAddress?.address)
        .filter(Boolean) as string[];

      const bccEmailAddresses = bccRecipients
        .map((recipient) => recipient?.emailAddress?.address)
        .filter(Boolean) as string[];

      const convertToFileAttachmentToIFile = (attachment): IFile => {
        return {
          name: attachment.name,
          type: attachment.contentType,
          size: attachment.contentBytes ? attachment.contentBytes.length : 0,
          lastModified: Date.now(),
          lastModifiedDate: new Date(),
          webkitRelativePath: ''
        };
      };

      updatedBodies.push({
        id: newId,
        subject: draft?.subject || '',
        body: draft?.bodyPreview || '',
        to: draft ? toEmailAddresses : toEmail ? toEmail : [],
        cc: ccEmailAddresses,
        bcc: bccEmailAddresses,
        attachments: draft?.attachments
          ? draft?.attachments.map(convertToFileAttachmentToIFile)
          : []
      });

      return updatedBodies;
    });
  };

  const removeComposeEmail = (index: number) => {
    setComposeWindows((prev) => prev.filter((_, i) => i !== index));
    setAllEmailBodies((prev) => prev.filter((_, i) => i !== index));
  };

  const sendEmail = async (panelIndex: number) => {
    const accessToken = await validAccessToken();
    if (!accessToken) return;

    const attachments: FileAttachment[] = [];
    const files = allEmailBodies[panelIndex]?.attachments || [];

    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) binary += String.fromCharCode(bytes[i]);
      return window.btoa(binary);
    };

    await Promise.all(
      files.map(
        (file: any) =>
          new Promise<void>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (event) => {
              const result = event.target?.result;
              if (result instanceof ArrayBuffer) {
                const attachment = {
                  '@odata.type': '#microsoft.graph.fileAttachment',
                  name: file.name,
                  contentType: file.type,
                  contentBytes: arrayBufferToBase64(result)
                };
                attachments.push(attachment);
              }
              resolve();
            };
            fileReader.onerror = () => {
              reject(new Error('Failed to read file'));
            };
          })
      )
    );

    const fullEmailBody = allEmailBodies[panelIndex];
    const message = {
      subject: fullEmailBody.subject,
      body: {
        contentType: 'HTML',
        content: fullEmailBody.body
      },
      toRecipients: fullEmailBody.to
        ? fullEmailBody.to.map((email) => ({
            emailAddress: { address: email }
          }))
        : [],
      ccRecipients: fullEmailBody.cc
        ? fullEmailBody.cc.map((email) => ({
            emailAddress: { address: email }
          }))
        : [],
      bccRecipients: fullEmailBody.bcc
        ? fullEmailBody.bcc.map((email) => ({
            emailAddress: { address: email }
          }))
        : [],
      attachments
    };

    const mail = {
      message: message,
      saveToSentItems: true
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(mail)
    };

    try {
      const toEmails = fullEmailBody.to || [];
      const storedEmails = JSON.parse(
        localStorage.getItem('sentEmailAddresses') || '[]'
      );
      const newStoredEmails = [...new Set([...storedEmails, ...toEmails])];
      localStorage.setItem(
        'sentEmailAddresses',
        JSON.stringify(newStoredEmails)
      );

      fetch(
        'https://graph.microsoft.com/v1.0/me/sendMail',
        requestOptions
      ).then(async () => {
        notify.error('Successfully Sent Email');
        removeComposeEmail(panelIndex);

        gtag.event({
          feature: 'Inbox',
          action: 'Email Sent',
          message: 'Email Sent Successfully'
        });

        if (typeof getRefreshEmails === 'function') {
          await getRefreshEmails();
        }
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const saveDraft = async ({ draft }: { draft: Message }) => {
    const accessToken = await validAccessToken();
    if (!accessToken) return;

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(draft)
    };

    fetch('https://graph.microsoft.com/v1.0/me/messages', requestOptions)
      .then(() => {
        notify.error('Successfully Saved Draft');
      })
      .catch((error) => console.error('Error saving draft:', error));
  };

  return (
    <EmailCompositionCreationContext.Provider
      value={{
        composeWindows,
        setComposeWindows,
        allEmailBodies,
        setAllEmailBodies,
        closeComposeEmail,
        addComposeEmail,
        removeComposeEmail,
        sendEmail,
        saveDraft
      }}>
      {children}
    </EmailCompositionCreationContext.Provider>
  );
};

const generateUniqueId = (): number => {
  return Math.floor(Math.random() * 1000000);
};
