import { ELender, IProposalStructure } from '../interface';
import {
  Aldermore,
  CAS,
  DLL,
  Fleximize,
  FundingCircle,
  Investec,
  Northridge,
  Novuna,
  Praetura,
  PremiumCredit,
  UltimateFinance,

  // Ids
  getAldermoreIds,
  getFleximizeIds,
  getFundingCircleIds,
  getInvestecIds,
  getCASIds,
  getUltimateFinanceIds
} from '../consts/index';
import { getNorthridgeIds } from '../consts/Northridge/MappedProposal';
import { getPraeturaIds } from '../consts/Praetura/MappedProposal';
import { getDLLIds } from '../consts/DLL/MappedProposal';
import { getNovunaIds } from '../consts/Novuna/MappedProposal';
import { getPremiumCreditIds } from '../consts/PremiumCredit/MappedProposal';

import firebase from 'firebase';

type TLenderNameToId = {
  [key in ELender]: number[];
};

export const proposalConfig: Record<number, IProposalStructure> = {
  28: {
    defaultValues: Aldermore,
    lenderTitle: ELender.Aldermore,
    mappedIds: getAldermoreIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 1,
      minAddress: 1
    },
    allowedDealFlow: [466]
  },

  7965: {
    defaultValues: Novuna,
    lenderTitle: ELender.Novuna,
    mappedIds: getNovunaIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAddress: 1,
      minAssets: 1
    },
    allowedDealFlow: [466],
    fieldVisibility: [
      // Individual types
      {
        field: 'proposal.customer.company',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },

      {
        field: 'proposal.customer.tradingas',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.customer.industrytype',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.customer.farm',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.customer.otherparties',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },

      // Show Balloon when HP
      {
        field: 'proposal.financials.balloonpayment',
        dependsOn: 'proposal.financials.producttype',
        showWhen: [
          'Motor HP Reg',
          'Fixed Rate HP',
          'Fixed Rate HP',
          'Fixed Rate HP Reg',
          'Sale HP Back',
          'Sale HP Back Reg'
        ]
      },

      // Hide AdvancedRentals Amount when it's a Loan
      {
        field: 'proposal.financials.advancedrentalamount',
        dependsOn: 'proposal.financials.producttype',
        hideWhen: [
          'Loan Commercial',
          'Loan Commercial Reg',
          'Motor HP Reg',
          'Fixed Rate HP',
          'Fixed Rate HP',
          'Fixed Rate HP Reg',
          'Sale HP Back',
          'Sale HP Back Reg'
        ]
      },
      {
        field: 'proposal.financials.numberofadvancedrentals',
        dependsOn: 'proposal.financials.producttype',
        hideWhen: [
          'Loan Commercial',
          'Loan Commercial Reg',
          'Motor HP Reg',
          'Fixed Rate HP',
          'Fixed Rate HP',
          'Fixed Rate HP Reg',
          'Sale HP Back',
          'Sale HP Back Reg'
        ]
      },

      {
        field: 'proposal.assets[0].asset.deposit',
        dependsOn: 'proposal.financials.producttype',
        hideWhen: ['Loan Commercial', 'Loan Commercial Reg']
      },

      // Show Repurchaser & Residual When Lease Product is selected
      {
        field: 'proposal.repurchaser',
        dependsOn: 'proposal.financials.producttype',
        hideWhen: [
          'Motor HP Reg',
          'Fixed Rate HP',
          'Fixed Rate HP',
          'Fixed Rate HP Reg',
          'Sale HP Back',
          'Sale HP Back Reg',
          'VR Balanced Payments',
          'Loan Commercial',
          'Loan Commercial Reg'
        ]
      },

      {
        field: 'proposal.assets[0].asset.residualvalue',
        dependsOn: 'proposal.financials.producttype',
        hideWhen: [
          'Motor HP Reg',
          'Fixed Rate HP',
          'Fixed Rate HP',
          'Fixed Rate HP Reg',
          'Sale HP Back',
          'Sale HP Back Reg',
          'VR Balanced Payments',
          'Loan Commercial',
          'Loan Commercial Reg'
        ]
      },

      // Make privateaddress explicitly available for individual types
      {
        field: 'proposal.customer.privateaddress',
        dependsOn: 'proposal.customer.companytype',
        showWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },

      // Partnership types
      {
        field: 'proposal.customer.otherparties',
        dependsOn: 'proposal.customer.companytype',
        showWhen: ['Partnership 3 or less', 'Partnership 4 +']
      },
      {
        field: 'proposal.customer.privateaddress',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: ['Partnership 3 or less', 'Partnership 4 +']
      },
      {
        field: 'proposal.customer.farm',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: ['Partnership 3 or less', 'Partnership 4 +']
      },

      // Company types
      {
        field: 'proposal.customer.privateaddress',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Limited Company',
          'Unlimited Company',
          'PLC',
          'Government Body',
          'Non-Profit',
          'Local Authority',
          'Other Regulated',
          'Other Non-Regulated',
          'Unincorporated Business',
          'Limited Liability Partnership'
        ]
      },
      {
        field: 'proposal.customer.otherparties',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Limited Company',
          'Unlimited Company',
          'PLC',
          'Government Body',
          'Non-Profit',
          'Local Authority',
          'Other Regulated',
          'Other Non-Regulated',
          'Unincorporated Business',
          'Limited Liability Partnership'
        ]
      },
      {
        field: 'proposal.customer.farm',
        dependsOn: 'proposal.customer.companytype',
        hideWhen: [
          'Limited Company',
          'Unlimited Company',
          'PLC',
          'Government Body',
          'Non-Profit',
          'Local Authority',
          'Other Regulated',
          'Other Non-Regulated',
          'Unincorporated Business',
          'Limited Liability Partnership'
        ]
      },

      // Supplier specific rules
      {
        field: 'proposal.assets[0].asset.supplier.company',
        dependsOn: 'proposal.assets[0].asset.supplier.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.assets[0].asset.supplier.tradingas',
        dependsOn: 'proposal.assets[0].asset.supplier.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.assets[0].asset.supplier.industrytype',
        dependsOn: 'proposal.assets[0].asset.supplier.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.assets[0].asset.supplier.privateaddress',
        dependsOn: 'proposal.assets[0].asset.supplier.companytype',
        showWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.assets[0].asset.supplier.otherparties',
        dependsOn: 'proposal.assets[0].asset.supplier.companytype',
        hideWhen: ['Partnership 3 or less', 'Partnership 4 +']
      },

      // Repurchaser specific rules
      {
        field: 'proposal.repurchaser.company',
        dependsOn: 'proposal.repurchaser.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.repurchaser.tradingas',
        dependsOn: 'proposal.repurchaser.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.repurchaser.industrytype',
        dependsOn: 'proposal.repurchaser.companytype',
        hideWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.repurchaser.privateaddress',
        dependsOn: 'proposal.repurchaser.companytype',
        showWhen: [
          'Sole Trader',
          'Private Individual',
          'Private Individual - High Net Worth'
        ]
      },
      {
        field: 'proposal.repurchaser.otherparties',
        dependsOn: 'proposal.repurchaser.companytype',
        hideWhen: ['Partnership 3 or less', 'Partnership 4 +']
      },

      // Personal Guarantee - hide company-related fields
      {
        field: 'proposal.guarantors.[0].guarantor.company',
        dependsOn: 'proposal.guarantors.[0].guarantor.companytype',
        hideWhen: ['Personal Guarantee']
      },
      {
        field: 'proposal.guarantors[0].guarantor.tradingas',
        dependsOn: 'proposal.guarantors[0].guarantor.companytype',
        hideWhen: ['Personal Guarantee']
      },
      {
        field: 'proposal.guarantors[0].guarantor.industrytype',
        dependsOn: 'proposal.guarantors[0].guarantor.companytype',
        hideWhen: ['Personal Guarantee']
      },

      // Business Guarantee - hide individual-related fields
      {
        field: 'proposal.guarantors[0].guarantor.individual',
        dependsOn: 'proposal.guarantors[0].guarantor.companytype',
        hideWhen: ['Business Guarantee']
      },

      // Always hide otherparties for both guarantor types
      {
        field: 'proposal.guarantors[0].guarantor.otherparties',
        dependsOn: 'proposal.guarantors[0].guarantor.companytype',
        hideWhen: ['Personal Guarantee', 'Business Guarantee']
      }
    ]
  },

  73: {
    defaultValues: CAS,
    lenderTitle: ELender.CAS,
    mappedIds: getCASIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466, 474]
  },

  421: {
    defaultValues: Fleximize,
    lenderTitle: ELender.Fleximize,
    mappedIds: getFleximizeIds(),
    status: false,
    uploadDocuments: true,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 0
    },
    allowedDealFlow: [534, 535],
    allowedEntities: ['Limited Company']
  },
  92: {
    defaultValues: Fleximize,
    lenderTitle: ELender.Fleximize,
    mappedIds: getFleximizeIds(),
    status: false,
    uploadDocuments: true,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 0
    },
    allowedDealFlow: [474],
    allowedEntities: ['Limited Company']
  },

  111: {
    defaultValues: Investec,
    lenderTitle: ELender.Investec,
    mappedIds: getInvestecIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 1
    },
    allowedDealFlow: [466, 474],
    fieldVisibility: [
      {
        field: 'client.soleTrader',
        dependsOn: 'client.companyType',
        showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
      },

      {
        field: 'client.company.partner',
        dependsOn: 'client.companyType',
        showWhen: ['PARTNERSHIP']
      },
      {
        field: 'client.company.director',
        dependsOn: 'client.companyType',
        showWhen: ['LIMITEDCOMPANY']
      }
    ]
  },

  // 160: {
  //   defaultValues: Investec,
  //   lenderTitle: ELender.Quantum,
  //   mappedIds: getInvestecIds(),
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 1,
  //     minShareholders: 1,
  //     minAssets: 1
  //   },
  //   allowedDealFlow: [466],
  //   fieldVisibility: [
  //     {
  //       field: 'client.soleTrader',
  //       dependsOn: 'client.companyType',
  //       showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
  //     },

  //     {
  //       field: 'client.company.partner',
  //       dependsOn: 'client.companyType',
  //       showWhen: ['PARTNERSHIP']
  //     },
  //     {
  //       field: 'client.company.director',
  //       dependsOn: 'client.companyType',
  //       showWhen: ['LIMITEDCOMPANY']
  //     }
  //   ]
  // },

  130: {
    defaultValues: Northridge,
    lenderTitle: ELender.Northridge,
    mappedIds: getNorthridgeIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466]
  },

  160: {
    defaultValues: DLL,
    lenderTitle: ELender.DLL,
    mappedIds: getDLLIds(),
    status: false,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0,
      minContacts: 1,
      minProposals: 1
    },
    allowedDealFlow: [466],
    fieldVisibility: [
      {
        field: 'lessee.externalIdentifiers',
        dependsOn: 'financeProduct',
        showWhen: ['Nothing']
      },
      {
        field: 'lessee.partners[0].externalIdentifiers',
        dependsOn: 'financeProduct',
        showWhen: ['Nothing']
      },
      {
        field: 'proposals[0].financialProduct',
        dependsOn: 'financeProduct',
        showWhen: ['Nothing']
      }
    ]
  },

  // 8035: {
  //   defaultValues: FundingCircle,
  //   lenderTitle: ELender.FundingCircle,
  //   mappedIds: getFundingCircleIds(),
  //   status: true,
  //   uploadDocuments: true,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [534, 535],
  //   allowedEntities: ['Limited Company']
  // },

  26: {
    defaultValues: FundingCircle,
    lenderTitle: ELender.FundingCircle,
    mappedIds: getFundingCircleIds(),
    status: false,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [474, 534, 535],
    allowedEntities: ['Limited Company']
  },

  154: {
    defaultValues: Praetura,
    lenderTitle: ELender.Praetura,
    mappedIds: getPraeturaIds(),
    status: false,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0,
      minApplicants: 1
    },
    allowedDealFlow: [466],
    allowedEntities: [
      'Limited Company',
      'Sole Trader',
      'Partnership',
      'Limited Liability Partnership'
    ]
  },

  // 8745: {
  //   defaultValues: PremiumCredit,
  //   lenderTitle: ELender.PremiumCredit,
  //   mappedIds: {},
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [534, 535]
  // },
  // 157: {
  //   defaultValues: PremiumCredit,
  //   lenderTitle: ELender.PremiumCredit,
  //   mappedIds: {},
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466, 474]
  // },

  176: {
    defaultValues: UltimateFinance,
    lenderTitle: ELender.UltimateFinance,
    mappedIds: getUltimateFinanceIds(),
    status: false,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466, 474]
  },

  8745: {
    defaultValues: PremiumCredit,
    lenderTitle: ELender.PremiumCredit,
    mappedIds: getPremiumCreditIds(),
    status: false,
    uploadDocuments: false,
    requirements: {},
    allowedDealFlow: [534, 535]
  },

  157: {
    defaultValues: PremiumCredit,
    lenderTitle: ELender.PremiumCredit,
    mappedIds: getPremiumCreditIds(),
    status: false,
    uploadDocuments: false,
    requirements: {},
    allowedDealFlow: [466]
  }
};

/**
 * Retrieves the proposal structure for a given lender ID.
 * Checks if the lender ID exists in both proposalConfig and allowedLenderIds from Redux.
 *
 * @param {number} lenderId - The numeric ID of the lender.
 * @param {number[]} allowedLenderIds - Array of allowed lender IDs from Redux.
 * @returns {IProposalStructure | undefined} The matching proposal structure or undefined if not found.
 */
export const getProposalStructureById = (
  lenderId: number,
  allowedLenderIds: string[]
): IProposalStructure | undefined => {
  try {
    if (!allowedLenderIds.includes(lenderId.toString())) {
      return undefined;
    }

    return proposalConfig[lenderId];
  } catch (error) {
    console.error('Error retrieving proposal structure:', error);
    return undefined;
  }
};

/**
 * Retrieves the proposal structure for a given lender ID and name.
 * Checks if the lender ID is allowed and if its title matches the expected lender name.
 *
 * @param {number} lenderId - The numeric ID of the lender from form data.
 * @param {ELender} lenderName - The enum name of the lender from metadata.
 * @param {number[]} allowedLenderIds - Array of allowed lender IDs from Redux.
 * @returns {IProposalStructure | undefined} The matching proposal structure or undefined if not found.
 */
export const getLenderProposalStructure = (
  lenderId: number,
  lenderName: ELender,
  allowedLenderIds: string[]
): IProposalStructure | undefined => {
  try {
    if (!allowedLenderIds.includes(lenderId.toString())) {
      return undefined;
    }

    const structure = proposalConfig[lenderId];
    if (!structure || structure.lenderTitle !== lenderName) {
      return undefined;
    }

    return structure;
  } catch (error) {
    console.error('Error in getLenderProposalStructure:', error);
    return undefined;
  }
};

/**
 * Helper function to use in your useEffect for loading allowed lender structures.
 *
 * @param {number[]} allowedLenderIds - Array of allowed lender IDs from Redux.
 * @returns {Record<number, IProposalStructure>} Map of allowed lender IDs to their structures
 */
export const getAllowedLenderStructures = (
  allowedLenderIds: string[]
): Record<number, IProposalStructure> => {
  try {
    const structures: Record<number, IProposalStructure> = {};
    for (const lenderId of allowedLenderIds) {
      const lenderNum = parseInt(lenderId);

      const structure = proposalConfig[lenderNum];
      if (structure) {
        structures[lenderNum] = structure;
      }
    }

    return structures;
  } catch (error) {
    console.error('Error getting allowed lender structures:', error);
    return {};
  }
};

//! Brian has Removed the Frontend need to add Whitelisting therefore we will need a new function to handle it.
// /**
//  * Retrieves the proposal structure for a given lender ID or name with URL-based visibility.
//  *
//  * @param {number} lenderId - The numeric ID of the lender.
//  * @param {ELender} lenderName - The enum name of the lender.
//  * @param {firebase.firestore.DocumentReference} configRef - Firestore reference to the lender config document.
//  * @returns {Promise<IProposalStructure | undefined>} The matching proposal structure or undefined if not found.
//  */
// export const getLenderProposalStructure = async (
//   lenderId: number,
//   lenderName: ELender,
//   configRef: firebase.firestore.DocumentReference
// ): Promise<IProposalStructure | undefined> => {
//   try {
//     let environment: 'production' | 'staging' | 'pre-production';
//     if (process.env.REACT_APP_FB_PROJECTID === 'financial--manager') {
//       environment =
//         window.location.origin === 'https://apilivelender.bips.tech'
//           ? 'pre-production'
//           : 'production';
//     } else if (process.env.REACT_APP_FB_PROJECTID === 'finbro-tech') {
//       environment = 'staging';
//     } else {
//       environment = 'staging';
//     }

//     const currentOrigin = window.location.origin;
//     const doc = await configRef.get();
//     const data = doc.data();

//     if (!data?.lenderConfigs) return undefined;

//     const matchingConfig = data.lenderConfigs.find(
//       (config: ILenderConfig) =>
//         config.id === lenderId &&
//         config.lenderName === lenderName &&
//         config.environment === environment &&
//         (config.url === undefined || config.url === currentOrigin)
//     );

//     if (!matchingConfig) return undefined;

//     const structure = proposalConfig[lenderId];
//     if (!structure) return undefined;

//     return structure;
//   } catch (error) {
//     console.error('Error in getLenderProposalStructure:', error);
//     return undefined;
//   }
// };
// /**
//  * Gets the mapping of lender names to their IDs, filtering by current URL if specified.
//  *
//  * @param {firebase.firestore.DocumentReference} configRef - Firestore reference to the lender config document.
//  * @returns {Promise<TLenderNameToId>} Mapping of lender names to their IDs.
//  */
// export const getLenderMapping = async (
//   configRef: firebase.firestore.DocumentReference
// ): Promise<TLenderNameToId> => {
//   try {
//     let environment: 'production' | 'staging' | 'pre-production';
//     if (process.env.REACT_APP_FB_PROJECTID === 'financial--manager') {
//       environment =
//         window.location.origin === 'https://apilivelender.bips.tech'
//           ? 'pre-production'
//           : 'production';
//     } else if (process.env.REACT_APP_FB_PROJECTID === 'finbro-tech') {
//       environment = 'staging';
//     } else {
//       environment = 'staging';
//     }

//     const currentOrigin = window.location.origin;
//     const doc = await configRef.get();
//     const data = doc.data();

//     if (!data?.lenderConfigs) return {} as TLenderNameToId;
//     const filteredConfigs = data.lenderConfigs.filter(
//       (config: ILenderConfig) =>
//         config.environment === environment &&
//         (config.url === undefined || config.url === currentOrigin)
//     );

//     // Group configs by lender name and collect their IDs
//     const mapping = filteredConfigs.reduce((acc, config) => {
//       if (!acc[config.lenderName]) {
//         acc[config.lenderName] = [];
//       }
//       if (!acc[config.lenderName].includes(config.id)) {
//         acc[config.lenderName].push(config.id);
//       }
//       return acc;
//     }, {} as TLenderNameToId);

//     return mapping;
//   } catch (error) {
//     console.error('Error fetching lender mapping:', error);
//     return {} as TLenderNameToId;
//   }
// };

// interface ILenderConfig {
//   id: number;
//   environment: 'production' | 'staging' | 'pre-production';
//   lenderName: ELender;
//   url?: string;
// }

// /**
//  * Retrieves the proposal structure for a given lender ID, with visibility controlled by environment and URL.
//  *
//  * @param {number} lenderId - The numeric ID of the lender to match.
//  * @param {firebase.firestore.DocumentReference} configRef - Firestore reference to the lender config document.
//  * @returns {Promise<IProposalStructure | undefined>} The matching proposal structure or undefined if not found or hidden.
//  *
//  * @description
//  * This function checks if a lender should be visible based on:
//  * 1. The current environment (production, staging, pre-production)
//  * 2. URL restriction (if specified in lender config)
//  *
//  * For URL restriction: If a URL is specified in the lender config, the lender will only be
//  * visible if the current window.location.origin matches that URL. If no URL is specified,
//  * the lender is visible to all origins within the matching environment.
//  */
// export const getProposalStructureById = async (
//   lenderId: number,
//   configRef: firebase.firestore.DocumentReference
// ): Promise<IProposalStructure | undefined> => {
//   try {
//     const doc = await configRef.get();
//     const data = doc.data();

//     if (!data?.lenderConfigs) return undefined;
//     let environment: 'production' | 'staging' | 'pre-production';
//     if (process.env.REACT_APP_FB_PROJECTID === 'financial--manager') {
//       environment =
//         window.location.origin === 'https://apilivelender.bips.tech'
//           ? 'pre-production'
//           : 'production';
//     } else if (process.env.REACT_APP_FB_PROJECTID === 'finbro-tech') {
//       environment = 'staging';
//     } else {
//       environment = 'staging';
//     }

//     const currentOrigin = window.location.origin;
//     const matchingConfig = data.lenderConfigs.find(
//       (config: ILenderConfig) =>
//         config.id === lenderId &&
//         config.environment === environment &&
//         // If URL is defined, it must match; if not defined, any URL is allowed
//         (config.url === undefined || config.url === currentOrigin)
//     );

//     return matchingConfig ? proposalConfig[lenderId] : undefined;
//   } catch (error) {
//     console.error('Error fetching lender configuration:', error);
//     return undefined;
//   }
// };
