import { useState, ChangeEvent } from 'react';
import { Input, MenuItem, Button, Tooltip } from '@material-ui/core';
import { validateEmail } from 'helpers/validateEmail';

type Option = { label: string; value: number };
interface Props {
  options: Option[] | null;
  contactIds: string[];
  onCreate: (email: string, title: string) => Promise<boolean | undefined>;
  onSelect: (option: Option) => void;
}

export const CreatableTextSearch = ({
  options,
  onCreate,
  onSelect,
  contactIds
}: Props) => {
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const isCreateButtonDisabled = !validateEmail(email) || !title;
  const tooltip = isCreateButtonDisabled
    ? 'Please enter a valid email'
    : 'Create new user';

  return (
    <div style={{ flexGrow: 1 }}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>
        <div style={{ marginRight: '10px', flex: 1 }}>
          <Input
            value={title}
            onChange={handleTitleChange}
            fullWidth
            placeholder="Enter Name"
            style={{ margin: '2px 0', marginTop: '10px' }}
          />
        </div>
        <div style={{ flex: 2 }}>
          <Input
            value={email}
            onChange={handleEmailChange}
            fullWidth
            placeholder="Enter Valid Email"
            endAdornment={
              <Tooltip title={tooltip}>
                <div>
                  <Button
                    style={{ width: 100 }}
                    disabled={isCreateButtonDisabled}
                    onClick={async () => {
                      await onCreate(email, title);
                    }}>
                    + Create
                  </Button>
                </div>
              </Tooltip>
            }
          />
        </div>
      </div>
      {options
        ?.filter((el) => !contactIds.includes(el.value.toString()))
        .filter((el) => el.label.toLowerCase().includes(email.toLowerCase()))
        ?.map((el) => {
          const disabled = !validateEmail(el.label);
          return (
            <Tooltip
              key={el.value}
              title={
                disabled
                  ? 'You cannot add a user with an invalid email address'
                  : ''
              }>
              <div>
                <MenuItem onClick={() => onSelect(el)} disabled={disabled}>
                  {el.label}
                </MenuItem>
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
};
