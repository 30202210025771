import {
  OffkeyCompanyLite,
  OffkeyCompany,
  IDirectorSearchCriteria,
  ICompanySearchCriteria,
  OffkeyDirectorLite,
  IDirectorCompany
} from '../interfaces';
import { firebase } from 'redux/firebase';
import { store } from 'redux/store';

import {
  SEARCH_COMPANIES,
  GET_COMPANY_REPORT,
  GET_GENERATED_COMPANY_PDF
} from 'graphql/queries';
import { GENERATE_COMPANY_PDF } from 'graphql/CompanyNavigator/mutations';
import { Company } from 'components/GraphqlCRM/interfaces';
import { BugTracker } from 'Utils/Bugtracker';

import { IRedflagAPIData, IRedFlagObject } from 'types/redflagInterfaces';
import axios, { AxiosRequestConfig } from 'axios';
import {
  SEARCH_COMPANIES_FROM_DIRECTOR,
  SEARCH_DIRECTORS,
  SEARCH_DIRECTORSHIPS
} from 'graphql/CompanyNavigator/queries';
import {
  createJsonFromDirector,
  IRedflagDirectorArray,
  IRedFlagDirectorship,
  mapShareholders
} from '../helper';

interface ICondition {
  contains?: string;
  eq?: string | boolean;
  some?: any;
}

interface IVariables {
  where: {
    [key: string]: ICondition;
  };
  take?: number;
}

export const useRedFlagGQLAdaptor = (client: any) => {
  const graphqlCrmState = store.getState().graphqlCrm;
  const context = {
    headers: { Authorization: `Bearer ${graphqlCrmState.access_token}` }
  };

  const getRFGQLDirectorList = async (
    query: IDirectorSearchCriteria
  ): Promise<IRedflagDirectorArray> => {
    const variables = Object.keys(query).reduce<IVariables>(
      (acc, key) => {
        const queryValue = query[key];
        if (queryValue) {
          const nationality: keyof IDirectorSearchCriteria = 'nationality';
          if (key === nationality) {
            if (queryValue.eq) {
              acc.where[key] = { eq: queryValue.eq };
            } else if (queryValue.contains) {
              acc.where[key] = { eq: queryValue.contains };
            }
          } else {
            if (queryValue.contains) {
              acc.where[key] = { contains: queryValue.contains };
            } else if (queryValue.eq) {
              acc.where[key] = { contains: queryValue.eq };
            }
          }
        }
        return acc;
      },
      { where: {} }
    );

    let directors = [] as OffkeyDirectorLite[];
    await client
      .query({ query: SEARCH_DIRECTORS, variables, context })
      .then((res) => {
        const rfDirectors = res.data.directors.items as OffkeyDirectorLite[];

        directors = rfDirectors.map((dir: OffkeyDirectorLite) => ({
          ...dir
        }));
      })
      .catch((e) =>
        console.log('Error Retrieving Director List', {
          variables,
          e
        })
      );

    return createJsonFromDirector(directors) as IRedflagDirectorArray;
  };

  const getRFGQLDirector = async (
    id: string
  ): Promise<IRedFlagDirectorship> => {
    let director = {} as OffkeyDirectorLite;

    const variables = {
      where: {
        id: { eq: id }
      }
    };

    await client
      .query({ query: SEARCH_DIRECTORS, variables, context })
      .then((res) => {
        const dir = res.data.directors.items[0] as OffkeyDirectorLite;
        director = {
          ...dir
        };
      });

    return createJsonFromDirector(director) as IRedFlagDirectorship;
  };

  const getRFGQLCompanyList: ({
    query,
    token
  }: {
    query: ICompanySearchCriteria;
    token?: string;
  }) => Promise<OffkeyCompanyLite[]> = async ({
    query,
    token
  }: {
    query: ICompanySearchCriteria;
    token?: string;
  }) => {
    let companies = [] as OffkeyCompanyLite[];
    console.log({ query });
    const variables = Object.keys(query).reduce<IVariables>(
      (acc, key) => {
        const queryValue = query[key];
        if (queryValue) {
          const postcode: keyof ICompanySearchCriteria = 'postcode';
          if (key === postcode) {
            acc.where['addresses'] = {
              some: {
                postcode: {
                  contains: queryValue.eq
                }
              }
            };
          } else {
            if (queryValue.contains) {
              acc.where[key] = { contains: queryValue.contains };
            } else if (queryValue.eq) {
              const isBoolean =
                queryValue.eq === 'true' || queryValue.eq === 'false';
              // boolean fields should not be converted to use "contains"
              if (isBoolean) {
                acc.where[key] = { eq: queryValue.eq === 'true' };
              } else acc.where[key] = { eq: queryValue.eq };
            } else {
              acc.where = {
                eq: queryValue.eq
              };
            }
          }
        }
        return acc;
      },
      {
        where: {},
        take: 20
      }
    );

    console.log({ variables });

    let newContext;
    if (graphqlCrmState.access_token === '' && token) {
      newContext = {
        headers: { Authorization: `Bearer ${token}` }
      };
    } else newContext = context;

    await client
      .query({ query: SEARCH_COMPANIES, variables, context: newContext })
      .then((res) => {
        const rfCompanies = res.data.companies
          .items as Company['companyReport'][];

        companies = rfCompanies.map((comp: Company['companyReport']) => ({
          title: comp.company_name,
          company_status: comp.legal_status,
          address_snippet: '',
          company_type: comp.company_type,
          company_number: comp.company_number,
          description: '',
          credit_safe_id: '',
          redflag_GraphQL_id: comp.company_id
        }));
      })
      .catch((e) =>
        console.log('Error retrieving company list', { variables, e })
      );

    return companies;
  };

  const getRFGQLCompanyDirector = async (
    idString: string
  ): Promise<IDirectorCompany[]> => {
    const parts = idString?.split('-');
    let personId = '';
    const person_Id = parts[2];

    if (person_Id) {
      personId = person_Id;
    } else {
      const person_Id_one = parts[0];
      const directorVariables = {
        where: {
          director: {
            person_id: {
              contains: person_Id_one
            }
          }
        }
      };

      try {
        const directorResponse = await client.query({
          query: SEARCH_DIRECTORSHIPS,
          variables: directorVariables,
          context
        });

        const newId = directorResponse.data.people.items[0].id;
        if (newId) {
          console.log('New ID', newId);
          return await getRFGQLCompanyDirector(newId);
        }
      } catch (e) {
        console.log('Error Retrieving Director List', { e });
      }
    }

    const companyVariables = {
      where: {
        director: {
          person_id: {
            eq: personId
          }
        }
      }
    };

    let companies = [] as IDirectorCompany[];
    try {
      const companyResponse = await client.query({
        query: SEARCH_COMPANIES_FROM_DIRECTOR,
        variables: companyVariables,
        context
      });

      const uniqueCompanies = {};
      companyResponse.data.people.items.forEach((item) => {
        const company = item.company;
        uniqueCompanies[company.company_id] = company;
      });

      companies = Object.values(uniqueCompanies);
    } catch (e) {
      console.log('Error Retrieving Company List', { e });
    }

    return companies;
  };

  const getRFGQLCompany = async (
    redflag_GraphQL_id: string,
    token?: string
  ) => {
    let company = {} as OffkeyCompany;

    const variables = {
      reportRequest: {
        archiveId: '',
        countryCode: 'GBR',
        fetchLatest: false,
        id: redflag_GraphQL_id,
        reasonCode: 'DEBT_COLLECTION'
      }
    };

    let newContext;
    if (graphqlCrmState.access_token === '' && token) {
      newContext = {
        headers: { Authorization: `Bearer ${token}` }
      };
    } else newContext = context;

    await client
      .query({ query: GET_COMPANY_REPORT, variables, context: newContext })
      .then(async (res) => {
        const companyReport = res.data
          .companyReport as Company['companyReport'];

        if (companyReport && companyReport.company_number) {
          // Call saveCompanyData but don't await - let it run in background
          saveCompanyData(companyReport).catch((err) => {
            console.log('Error saving company data:', err);
          });
        }

        const mapped = await convertRedFlagGQLToRedflagOG(companyReport);
        const mappedShareholders = mapShareholders(mapped);

        company = {
          ...mapped,
          third_party_apis: {
            ...mapped.third_party_apis,
            redflagOG: {
              ...mapped.third_party_apis.redflagOG,
              shareholders: mappedShareholders
            }
          }
        };
      })
      .catch((e) => {
        console.log('there has been an error retrieving the company', { e });
      });

    return company;
  };

  const getCompanyByCompanyNumber = async (company_number, token?: string) => {
    const companies = await getRFGQLCompanyList({
      query: {
        company_number: {
          eq: company_number
        }
      },
      token
    });

    const { redflag_GraphQL_id } = companies?.[0];
    const res2 = await getRFGQLCompany(redflag_GraphQL_id, token);
    return res2?.third_party_apis?.redflagOG;
  };

  const getGeneratedPDFGQL = async (selected: Partial<IRedFlagObject>) => {
    const id = selected.id;
    const variables = {
      fileExportRequest: {
        relativePath: `/app/check/report/pdf/${id}/GBR/`,
        description: `${selected.attributes?.name} Report`,
        exportType: 'PDF',
        totalResults: 1
      }
    };

    const { data } = await client.mutate({
      mutation: GENERATE_COMPANY_PDF,
      variables,
      context
    });

    return data.fileExportRequest.id;
  };

  interface PollStatusResult {
    completed: boolean;
    eta: number | null;
    fileExport?: any;
  }

  const MAX_ATTEMPTS = 20;
  const getCompanyPDFGQL = async (
    selected: Partial<IRedFlagObject>,
    onPoll: (eta: number | null) => void
  ): Promise<PollStatusResult> => {
    try {
      const file_id = await getGeneratedPDFGQL(selected);
      const variables = { where: { id: { eq: file_id } } };

      const pollForPDFStatus = async (): Promise<PollStatusResult> => {
        let attempts = 0;
        let fileExport;

        do {
          const { data, errors } = await client.query({
            query: GET_GENERATED_COMPANY_PDF,
            variables,
            context,
            fetchPolicy: 'network-only'
          });

          if (errors) {
            throw new Error('Error Fetching PDF Status');
          }

          fileExport = data.fileExports.items[0];
          if (fileExport.status === 'COMPLETED') {
            return { completed: true, eta: null, fileExport };
          }

          onPoll(fileExport.etaSeconds || null);
          const pollingInterval = fileExport.etaSeconds
            ? fileExport.etaSeconds * 1000
            : 10000;

          await new Promise((resolve) => setTimeout(resolve, pollingInterval));

          attempts++;
        } while (attempts < MAX_ATTEMPTS);

        return { completed: false, eta: fileExport.etaSeconds || null };
      };

      return await pollForPDFStatus();
    } catch (e) {
      console.error('Error Fetching PDF', e);
      throw e;
    }
  };

  return {
    getRFGQLCompanyList,
    getRFGQLCompany,
    getCompanyPDFGQL,
    getCompanyByCompanyNumber,
    getRFGQLDirectorList,
    getRFGQLDirector,
    getRFGQLCompanyDirector
  };
};

const convertRedFlagGQLToRedflagOG: (
  companyReport: Company['companyReport']
) => Promise<OffkeyCompany> = async (companyReport) => {
  const companyNavigator = store.getState().config.companyNavigator;
  let company = {} as OffkeyCompany;
  let third_party_apis = {
    creditSafe_company: {},
    redflagOG: {} as IRedflagAPIData,
    redflagOG_Source: {} as IRedflagAPIData
  } as OffkeyCompany['third_party_apis'];

  // Go and get redflagOG for this company here
  try {
    if (companyNavigator === 'redflagOG') {
      const token = await firebase.auth().currentUser?.getIdToken();
      const url = `${process.env.REACT_APP_PROXY}/company-navigator/redflagOG_company_report`;
      const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        data: { company_number: companyReport.company_number },
        headers: { token }
      };
      const redflagOGSource = await axios(config);
      third_party_apis.redflagOG_Source = redflagOGSource.data;
    }

    const mapArrayAttributes = (newType: string) => {
      let oldType = newType;
      if (newType === 'active_directors') oldType = 'directors';
      if (newType === 'contacts') oldType = 'contact';

      const type = companyReport[`${newType}`];
      const typeCheck = type && type.length > 0;

      if (typeCheck) {
        return (third_party_apis.redflagOG[`${oldType}`] = companyReport[
          `${newType}`
        ].map((attributes: any) => ({ attributes, type: oldType, id: '' })));
      } else {
        return console.log(
          `failed... ${newType}; likely was returned as a null value`
        );
      }
    };

    const categories = [
      'addresses',
      'balance_sheet',
      'cashflow',
      'ccjs',
      'contacts',
      'active_directors',
      'filing_history',
      'profit_loss',
      'ratio_analysis',
      'shareholders',
      'corporate_structure',
      'sic07'
    ];
    categories.forEach((item) => mapArrayAttributes(item));

    // SicCodes & Long Description
    let grabSicCodes = '';
    let grabSicDescriptions = '';
    if (companyReport.sic07.length > 0) {
      grabSicCodes = companyReport.sic07
        .map((sic: any) => sic?.code)
        .filter(
          (code: string, index: number, array: string[]) =>
            array.indexOf(code) === index
        )
        .join(', ');

      grabSicDescriptions = companyReport?.sic07?.[0]?.description;
    }

    third_party_apis.redflagOG['data'] = {
      attributes: {
        name: companyReport.company_name,
        company_number: companyReport.company_number,
        company_type: companyReport.company_type,
        estimated_turnover: companyReport.estimated_turnover,
        estimated_employees: companyReport.employees,
        incorporation_date: companyReport?.incorporation_date?.split('T')[0],
        turnover: companyReport.turnover,
        website: companyReport.website,
        legal_status: companyReport.legal_status,
        sic07_codes: grabSicCodes,
        rfa_rating: {
          credit_limit: companyReport.credit_limit,
          credit_score: companyReport.rfa_rating_score,
          long_description: grabSicDescriptions,
          short_code: companyReport.generated_rfa_rating
        }
      },
      id: companyReport.company_id,
      type: 'company'
    };

    if (third_party_apis.redflagOG.directors) {
      third_party_apis.redflagOG.directors =
        third_party_apis.redflagOG.directors.map(cleanDateFields);
    }
  } catch (err) {
    console.log('Problem in convertRedFlagGQLToRedflagOG', { err });
  }

  company.third_party_apis = third_party_apis;
  return company;
};

const cleanDateFields = (data: any) => {
  const newData = JSON.parse(JSON.stringify(data));
  if (newData?.attributes?.birthdate?.includes('T')) {
    newData.attributes.birthdate =
      newData?.attributes?.birthdate?.split('T')[0];
  }
  if (newData?.attributes?.date_appointed?.includes('T')) {
    newData.attributes.date_appointed =
      newData?.attributes?.date_appointed?.split('T')[0];
  }
  return newData;
};

export const saveCompanyData = async (companyData: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/company-data`;
  const config: AxiosRequestConfig = {
    method: 'POST',
    url,
    data: {
      companyNumber: companyData.company_number,
      ...companyData
    },
    headers: { token }
  };

  return await axios(config)
    .then((res) => {
      console.log(
        `Company data ${res.data.status}: ${companyData.company_number}`
      );
      return res;
    })
    .catch((e) => {
      console.error('Error saving company data:', e);
      BugTracker.notify(e);
      return e;
    });
};
