import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import LoomVideo from './LoomVideo';
import guide_and_video_data from 'data/FAQ/guideAndVideos.json';
import FolderCard from './folderCard';

const useStyles = makeStyles({
  videoCard: {
    borderRadius: '16px',
    boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
    transition: 'transform 0.2s, box-shadow 0.2s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '6px 6px 15px rgba(0, 0, 0, 0.3)'
    }
  },
  media: {
    height: 160
  },
  cardContent: {
    padding: '16px'
  },
  uploaderInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px'
  },
  videoTitle: {
    fontWeight: 'bold'
  },
  metaData: {
    fontSize: '14px',
    color: '#757575'
  }
});

const GuidesAndVideos = ({
  folderName,
  videos: providedVideos
}: {
  folderName?: string;
  videos?: Array<{ title: string; url: string }>;
}) => {
  const classes = useStyles();
  const videoFolders = guide_and_video_data.folders;
  const [currentFolder, setCurrentFolder] = React.useState<{
    folderName: string;
    videos: { title: string; url: string }[];
  } | null>(null);

  // React.useEffect(() => {
  //   if (folderName) {
  //     const folder = videoFolders.find(
  //       (folder) => folder.folderName === folderName
  //     );
  //     setCurrentFolder(folder || null);
  //   }
  // }, [folderName]);
  React.useEffect(() => {
    if (folderName) {
      if (providedVideos) {
        setCurrentFolder({
          folderName: folderName,
          videos: providedVideos
        });
      } else {
        // Fall back to original behavior
        const folder = videoFolders.find(
          (folder) => folder.folderName === folderName
        );
        setCurrentFolder(folder || null);
      }
    }
  }, [folderName, providedVideos]);

  if (folderName && (!currentFolder || currentFolder.videos.length === 0)) {
    return (
      <Typography variant="body1" color="textPrimary">
        No videos available for this section.
      </Typography>
    );
  }

  return (
    <Box>
      {!currentFolder ? (
        <>
          <Typography variant="h5" gutterBottom>
            Folders
          </Typography>
          <Grid container spacing={2}>
            {videoFolders.map((folder, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FolderCard
                  folderName={folder.folderName}
                  videoCount={folder.videos.length}
                  onClick={() => setCurrentFolder(folder)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          {!folderName && (
            <Button
              onClick={() => setCurrentFolder(null)}
              variant="outlined"
              style={{ marginBottom: '16px' }}>
              Back to Folders
            </Button>
          )}

          <Typography variant="h5" style={{ marginBottom: '16px' }}>
            {currentFolder.folderName} Videos
          </Typography>
          <Grid container spacing={2}>
            {currentFolder.videos.map((video, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card className={classes.videoCard}>
                  <LoomVideo key={index} videoUrl={video.url} />
                  <CardContent className={classes.cardContent}>
                    <Typography variant="body1" className={classes.videoTitle}>
                      {video.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default GuidesAndVideos;
