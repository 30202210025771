import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import _, { forEach } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { BugTracker } from 'Utils/Bugtracker';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';

import { VRMUI } from './VRMUI';
import {
  VRM_API,
  FindingAssetDetails
} from 'components/VRM/functions/APIHelper';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance,
  ObjectInstance
} from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { notify } from 'components/Notifications/HotToastNotifications';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const VRMSearch = ({
  FieldInstance,
  FieldDefinition,
  FieldDefinitionList,
  CompleteObjectInstance,
  setMessage,
  refresh,
  setAlert
}: {
  FieldInstance?: FieldInstance;
  FieldDefinition?: FieldDefinition;
  FieldDefinitionList?: FieldDefinition[];
  CompleteObjectInstance?: CompleteObjectInstance;
  setMessage?: (text: string) => void;
  setAlert?: (text: string) => void;
  refresh?: () => void;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const token = useTypedSelector((s) => s.user.auth.token);

  const [query, setQuery] = React.useState('');
  const [data, setData] = React.useState<any>();
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [savedLoading, setSavedLoading] = React.useState<Boolean>(false);
  const [noMot, setNoMot] = React.useState<Boolean>(false);
  const [open, setOpen] = React.useState<Boolean>(false);
  const [currentlySaved, setCurrentlySaved] = React.useState<Boolean>(false);
  const handleClose = () => setOpen(false);
  const handleChange = (e) => setQuery(e.target.value);
  const dispatch = useDispatch();

  const submit = async () => {
    setLoading(true);
    axios(await VRM_API({ query })).then((vrmData) => {
      if (vrmData.data?.name !== 'Error') {
        setData(vrmData.data);
        setNoMot(false);
        setLoading(false);

        if (
          !_.isEmpty(FieldInstance && FieldInstance.FieldValue) &&
          FieldInstance
        ) {
          const JSONFieldInstance = JSON.parse(FieldInstance.FieldValue);
          if (
            !_.isEqual(
              JSONFieldInstance.regNumber,
              vrmData.data.Ves.registrationNumber
            )
          ) {
            settingAlertMessage({ saved: false });
            setCurrentlySaved(false);
          }
        }
      } else {
        notify.error('Search Failed');
        setData('');
        setNoMot(true);
        setLoading(false);
      }
    });
  };

  const settingAlertMessage = ({ saved }) => {
    if (_.isEqual(saved, true)) {
      setMessage && setMessage('Current Version Saved');
      setAlert && setAlert('success');
    } else {
      setMessage &&
        setMessage('If You Wish To Save The Selected Vehicle Click Save');
      setAlert && setAlert('info');
    }
  };

  const save = async () => {
    if (!currentlySaved) {
      setSavedLoading(true);
      if (!_.isEmpty(query) && !_.isEmpty(data)) {
        const VRM = {
          regNumber: query,
          Ves: data.Ves,
          MOTHistory: data.MOTHistory
        };
        const VRMString = JSON.stringify(VRM);

        if (!FieldInstance) return;
        FieldInstance.FieldValue = VRMString;

        const saved = await updateFieldInstance({
          baseUrl,
          data: FieldInstance,
          ProcessInstanceId: FieldInstance && FieldInstance.ProcessInstanceId
        });

        if (saved && FieldDefinitionList && CompleteObjectInstance) {
          FindingAssetDetails({
            object_one: data.Ves,
            object_two: data.MOTHistory[0],
            CompleteObjectInstance,
            FieldDefinitionList,
            dispatch
          });

          notify.success('Saved Success');

          // Refresh the dialog.
          settingAlertMessage({ saved: true });
          setSavedLoading(false);
          if (refresh) {
            refresh();
          }
          return setCurrentlySaved(true);
        } else {
          notify.error('Saved Failed');
          setSavedLoading(false);
        }
      }
    } else {
      settingAlertMessage({ saved: true });
    }
  };

  React.useEffect(() => {
    if (
      !_.isEmpty(FieldInstance && FieldInstance.FieldValue) &&
      FieldInstance
    ) {
      const JSONFieldInstance = JSON.parse(FieldInstance.FieldValue);
      settingAlertMessage({ saved: true });
      setCurrentlySaved(true);
      setQuery(JSONFieldInstance.regNumber);
      setData(JSONFieldInstance);
    } else {
      if (FieldInstance) settingAlertMessage({ saved: false });
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid data-cy="error message" container direction="column" spacing={1}>
        {noMot && (
          <Grid item>
            <Alert severity="warning">
              <Typography>
                Could Not Find Registration Number, Please Double Check The
                Spelling And Try Again.
              </Typography>
            </Alert>
          </Grid>
        )}

        <Grid item>
          <Grid
            alignItems="center"
            container
            justifyContent="flex-start"
            spacing={1}>
            <Grid item style={{ flexGrow: 1, padding: 0 }}>
              <TextField
                data-cy="vrm-search-bar"
                fullWidth
                margin="dense"
                onChange={handleChange}
                onKeyDown={(e) => e.key === 'Enter' && submit()}
                placeholder={
                  _.isEmpty(query) ? 'Enter Registration Number ...' : query
                }
                type="search"
                value={query}
                variant="outlined"
              />
            </Grid>
            {_.isEqual(FieldDefinition && FieldDefinition.FieldType, 'VRM') &&
              !_.isEmpty(data) &&
              !currentlySaved && (
                <Grid item>
                  {savedLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      data-cy="vrm-save-btn"
                      color="primary"
                      onClick={save}
                      variant="contained">
                      Save
                    </Button>
                  )}
                </Grid>
              )}

            {/* search submit buton */}
            <Grid item>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  data-cy="vrm-search-btn"
                  color="primary"
                  onClick={submit}
                  variant="contained">
                  Search
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!loading && (
        <div>
          {!_.isEmpty(data) && (
            <div>
              <br />
              <VRMUI
                history={data.MOTHistory[0]}
                ves={data.Ves}
                theme={theme}
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VRMSearch;
