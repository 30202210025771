import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const MoreOptions = ({ onSaveDraft, onViewSavedDrafts }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSaveDraftClick = () => {
    onSaveDraft && onSaveDraft();
    handleMenuClose();
  };

  const handleViewDraftsClick = () => {
    onViewSavedDrafts && onViewSavedDrafts();
    handleMenuClose();
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon style={{ color: 'blue' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <MenuItem onClick={handleSaveDraftClick}>Save Draft</MenuItem>
        <MenuItem onClick={handleViewDraftsClick}>View Saved Drafts</MenuItem>
      </Menu>
    </>
  );
};

export default MoreOptions;
