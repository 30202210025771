import { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  CircularProgress
} from '@material-ui/core';
import { CustomDialog } from 'common/Dialog';
import { Alert } from '@material-ui/lab';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { CompleteObjectInstance } from 'types/interfaces';
import { Asset } from 'types/calculatorInterfaces';
import { StepperContext } from 'components/Stepper/context';
import TotalInfo from './components/AssetSummary';
import QuoteSummaryParent from './components/QuoteSummaryText';
import { quickProcessUpdate } from 'redux/actions/processes';

import { useProcess } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';

import * as gtag from 'Utils/gtag';
import { TransferDealOwnership } from 'components/Stepper/components/Dialog/TransferDealOwner';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import IndicativeQuoteSummary from './components/IndicativeQuoteSummary';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CommissionInvoiceSummary from './components/CommissionInvoiceSummary';

import FileStoragePicker from 'components/FileStorage/components/FileStoragePicker';
import MessageHub from 'components/Stepper/components/MessageHub';
import { useDealSummary } from 'hooks/useDealSummary';

import { useDealOwnershipHistory } from 'hooks/useDealOwnershipHistory';
import { IUserForProcess } from 'redux/actions/GraphQlActions/interface';
import { notify } from 'components/Notifications/HotToastNotifications';

interface ICustomersValues {
  selected?: IUserForProcess[];
  assigned?: IUserForProcess[];
  all?: IUserForProcess[];
}

type MainKey =
  | 'customers'
  | 'accounts'
  | 'assets'
  | 'brokers'
  | 'commissionInvoice'
  | 'contacts'
  | 'indicativeQuotes'
  | 'introducers'
  | 'lenders'
  | 'primaryContact'
  | 'quotes'
  | 'suppliers';

const orderedKeys: Array<MainKey> = [
  'customers',
  'assets',
  'quotes',
  'indicativeQuotes',
  'lenders',
  'commissionInvoice',
  'accounts',
  'brokers',
  'contacts',
  'introducers',
  'primaryContact',
  'suppliers'
];

const useStyles = makeStyles((theme) =>
  createStyles({
    greenButton: {
      backgroundColor: theme.palette.primary.light,
      justifyContent: 'flex-start',
      textTransform: 'none',
      height: 35,
      '&:hover': {
        borderRight: `50px solid ${theme.palette.primary.main}`,
        background: theme.palette.grey[300],
        color: theme.palette.grey[800],
        cursor: 'pointer',
        '& > p': {
          color: theme.palette.grey[800]
        },
        '& > svg': {
          color: theme.palette.grey[800]
        }
      },
      color: 'white'
    }
  })
);

export const DealSummary = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentDeal, user, currentProcess } = useProcess();
  const { status, syncProcessInstance, deal } = useDealSummary();

  const stepper = useContext(StepperContext);
  const dealSummary = useTypedSelector((s) => s.dealSummary.deal);

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openMessageHub, setOpenMessageHub] = useState<boolean>(false);
  const toggle = () => {
    setOpen(!open);
  };

  const { getDealOwnershipHistory, createNewDealOwnership } =
    useDealOwnershipHistory();

  const subSystemUser = user.SystemAccess <= 4;

  useEffect(() => {
    const updatePreviousOwner = async () => {
      // if deal has no ownership property, it will now include one
      await createNewDealOwnership(
        currentDeal.ProcessInstance.Id,
        currentDeal.ProcessInstance.UserInstanceId
      );
    };

    updatePreviousOwner();
  }, []);

  if (Object.values(dealSummary).length < 0 && status === 'pending') {
    return <CircularProgress />;
  }

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openMessageHub}
        onClose={() => setOpenMessageHub(false)}>
        <MessageHub />
      </Dialog>

      {!subSystemUser && (
        <Button
          data-cy="deal-summary-btn"
          style={{
            width: '100%',
            textTransform: 'none',
            fontWeight: 'bold'
          }}
          startIcon={!loading && <LocalLibraryIcon style={{ marginLeft: 5 }} />}
          className={classes.greenButton}
          onClick={async () => {
            setLoading(true);
            gtag.event({
              action: 'Deal Summary',
              feature: 'Deals',
              message: 'Opening Deal Summary'
            });

            await syncProcessInstance();

            setOpen(!open);
            setLoading(false);
          }}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}>
              <CircularProgress
                style={{
                  color: 'white'
                }}
                size={24}
              />
            </div>
          ) : (
            'Deal Summary'
          )}
        </Button>
      )}

      <CustomDialog
        maxSize="md"
        open={open}
        handleClose={toggle}
        alert={{
          title: 'Deal Summary Overview',
          description: 'Key Insights and Highlights of Your Deal',
          type: 'info'
        }}>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}>
            <ButtonGroup
              variant="contained"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                border: theme.palette.success.light
              }}>
              <Button
                style={{ flex: '0.5' }}
                onClick={() => setOpenMessageHub(true)}>
                Message Hub
              </Button>

              <FileStoragePicker
                title={'Deal Documents'}
                plugin={false}
                isDealSummary
              />
            </ButtonGroup>
          </div>

          <div style={{ paddingTop: theme.spacing(2) }} />
          {Object.values(dealSummary).length < 0 && status === 'pending' ? (
            <CircularProgress />
          ) : (
            <>
              {Object.values(dealSummary).length === 1 ? (
                <Alert
                  style={{
                    width: '100%',
                    borderRadius: theme.shape.borderRadius,
                    justifyContent: 'center'
                  }}
                  severity="info"
                  variant="outlined">
                  <Typography variant="h6">
                    Your Deal Summary is currently empty. To see a comprehensive
                    overview, please complete additional fields.
                  </Typography>
                </Alert>
              ) : (
                <Grid
                  container
                  spacing={2}
                  style={{ paddingBottom: theme.spacing(2) }}>
                  {dealSummary &&
                    orderedKeys.map((mainKey) => {
                      const nestedObj = dealSummary[mainKey];
                      if (isNestedObjEmpty(nestedObj)) {
                        return null;
                      }

                      switch (mainKey) {
                        case 'customers': {
                          const customersValues = nestedObj as ICustomersValues;
                          if (
                            customersValues &&
                            customersValues.selected &&
                            customersValues.selected.length <= 0
                          ) {
                            return <div />;
                          }

                          return (
                            <div
                              style={{
                                padding: theme.spacing(1),
                                width: '100%'
                              }}>
                              <Paper
                                style={{
                                  width: '100%',
                                  padding: theme.spacing(1)
                                }}>
                                <Typography
                                  variant="h5"
                                  style={{
                                    paddingBottom: theme.spacing(1),
                                    paddingTop: theme.spacing(1)
                                  }}>
                                  Borrower
                                </Typography>
                                {customersValues.selected &&
                                  customersValues.selected.length > 0 && (
                                    <Typography
                                      component={'div'}
                                      key={customersValues?.selected[0]?.Id}>
                                      <QuickUserFromId
                                        UserInstanceId={
                                          customersValues?.selected[0]?.Id.toString() ||
                                          ''
                                        }
                                      />
                                    </Typography>
                                  )}
                              </Paper>
                            </div>
                          );
                        }
                        case 'lenders': {
                          const lendersValues = nestedObj as ICustomersValues;
                          if (
                            lendersValues &&
                            lendersValues.selected &&
                            lendersValues.selected.length <= 0
                          ) {
                            return <div />;
                          }

                          return (
                            <div
                              style={{
                                padding: theme.spacing(1),
                                width: '100%'
                              }}>
                              <Paper
                                style={{
                                  width: '100%',
                                  padding: theme.spacing(1)
                                }}>
                                <Typography
                                  variant="h5"
                                  style={{
                                    paddingBottom: theme.spacing(1),
                                    paddingTop: theme.spacing(1)
                                  }}>
                                  Selected Funder
                                </Typography>
                                {lendersValues.selected &&
                                  lendersValues.selected.length > 0 && (
                                    <Typography
                                      component={'div'}
                                      key={lendersValues?.selected[0]?.Id}>
                                      <QuickUserFromId
                                        UserInstanceId={
                                          lendersValues?.selected[0]?.Id.toString() ||
                                          ''
                                        }
                                      />
                                    </Typography>
                                  )}
                              </Paper>
                            </div>
                          );
                        }
                        case 'assets': {
                          const assetsValues = nestedObj as Asset[];
                          return (
                            <div
                              style={{
                                padding: theme.spacing(1),
                                width: '100%'
                              }}>
                              <Paper
                                style={{
                                  width: '100%',
                                  padding: theme.spacing(1)
                                }}>
                                <TotalInfo assetsValues={assetsValues} />
                              </Paper>
                            </div>
                          );
                        }

                        case 'indicativeQuotes': {
                          const indicativeQuotes =
                            nestedObj as CompleteObjectInstance[];
                          return (
                            <div
                              style={{
                                padding: theme.spacing(1),
                                width: '100%'
                              }}>
                              <IndicativeQuoteSummary
                                indicativeQuoteValues={indicativeQuotes}
                              />
                            </div>
                          );
                        }

                        case 'quotes': {
                          if (Array.isArray(nestedObj)) {
                            const quoteValues =
                              nestedObj as CompleteObjectInstance[];
                            return (
                              <div
                                style={{
                                  padding: theme.spacing(1),
                                  width: '100%'
                                }}>
                                <QuoteSummaryParent quoteValues={quoteValues} />
                              </div>
                            );
                          }
                          break;
                        }

                        case 'commissionInvoice': {
                          const commissionInvoice =
                            nestedObj as CompleteObjectInstance[];
                          return (
                            <div
                              style={{
                                padding: theme.spacing(1),
                                width: '100%'
                              }}>
                              <CommissionInvoiceSummary
                                commissionInvoiceValues={commissionInvoice}
                              />
                            </div>
                          );
                        }

                        default: {
                          return <div key={mainKey} />;
                        }
                      }
                    })}
                </Grid>
              )}
            </>
          )}
        </DialogContent>
        {currentDeal.ProcessInstance.UserInstanceId === user.Id && (
          <DialogActions>
            <ButtonGroup
              variant="contained"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                border: theme.palette.success.light
              }}>
              {/* <Button
                style={{ flexGrow: 1 }}
                onClick={() => stepper.duplicate()}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                <Typography color="primary">Copy Deal</Typography>
              </Button> */}

              <TransferDealOwnership
                closeDeal={stepper.close}
                handleCloseMenu={() => {}}
                isSummary
              />

              <Button
                style={{
                  flexGrow: 1,
                  border: `1px solid ${theme.palette.error.light}`
                }}
                onClick={async () => {
                  const ProcessInstance = currentDeal.ProcessInstance;
                  const data = Object.assign({}, ProcessInstance);
                  data.ProcessStepDefinitionId = -2;

                  const success = await quickProcessUpdate({ data });
                  if (success) {
                    notify.success(`Deal Declined`);

                    return gtag.event({
                      action: 'Deal Declined',
                      feature: 'Deals',
                      message: ''
                    });
                  }
                }}>
                <ListItemIcon>
                  <TrendingDownIcon
                    style={{ color: theme.palette.error.main }}
                  />
                </ListItemIcon>
                <Typography color="primary">Decline Deal</Typography>
              </Button>
            </ButtonGroup>
          </DialogActions>
        )}
      </CustomDialog>
    </div>
  );
};

const isEmptyField = (field: any): boolean => {
  if (Array.isArray(field)) {
    return field.length === 0;
  } else if (typeof field === 'object') {
    return !field || Object.keys(field).length === 0;
  } else {
    return !field;
  }
};

const isNestedObjEmpty = (nestedObj: any): boolean => {
  if (Array.isArray(nestedObj)) {
    return nestedObj.length === 0;
  } else if (typeof nestedObj === 'object') {
    return Object.values(nestedObj).every(isEmptyField);
  }
  return !nestedObj;
};

const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

{
  /*
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <List>
              {Object.keys(ruleGroups).map((name) => {
                const rules = ruleGroups[name];
                return rules.map((rule) => (
                  <ListItem key={rule.Id}>
                    <Button
                      onClick={() => {
                        handleRuleClick(rule.Id);
                      }}
                      variant="contained"
                      color="secondary">
                      {rule.Title}
                    </Button>
                  </ListItem>
                ));
              })}
            </List>
          </Popover> */
}

// <Typography>
//   Here is the content: {status} 1. Sync definition data 2. Sync Instance
//   data 3. Get all assigned user and then lite versions of profiles Get
//   Customer Id Get Lender Ids Get Supplier Ids Get
// </Typography>;

// const RenderRow = ({ title, data, isGreenBorder }) => (
//   <Paper
//     style={{
//       padding: theme.spacing(2),
//       margin: theme.spacing(1),
//       border: isGreenBorder
//         ? `2px ${theme.palette.success.main} solid`
//         : 'none',
//       backgroundColor: isGreenBorder ? theme.palette.success.light : ''
//     }}>
//     <Typography variant="h6">{title}:</Typography>
//     {Object.entries(data).map(([key, value]) => (
//       <Typography component={'div'} key={key}>
//         <QuickUserFromId UserInstanceId={key} />
//       </Typography>
//     ))}
//   </Paper>
// );

//  const nestedObj = dealSummary[mainKey];
//  const summary = formatString(mainKey);

//  if (isNestedObjEmpty(nestedObj)) {
//    return null;
//  }

//  const nonEmptyCount = [
//    nestedObj.all,
//    nestedObj.assigned,
//    nestedObj.selected
//  ].filter((obj) => obj && Object.keys(obj).length > 0).length;

//  let gridSize: GridSize = 'auto';
//  const possibleGridSizes: number[] = [1, 2, 3, 4, 6, 12];
//  const calculatedSize = 12 / nonEmptyCount;

//  if (possibleGridSizes.includes(calculatedSize)) {
//    gridSize = calculatedSize as GridSize;
//  }

//  const isExpanded = ['Brokers'].includes(summary);
//  if (mainKey === 'assets' && Array.isArray(nestedObj)) {
//    const assetValues = nestedObj as Asset[];
//    return <Assets nestedObj={assetValues} mainKey={mainKey} />;
//  }

//  if (mainKey === 'quotes' && Array.isArray(nestedObj)) {
//    const quoteValues = nestedObj as CompleteObjectInstance[];
//    return <Quotes nestedObj={quoteValues} mainKey={mainKey} />;
//  }

//  const isSelectedSingle =
//    nestedObj.selected && Object.keys(nestedObj.selected).length === 1;
//  const selectedSummary =
//    nestedObj.selected && Object.values(nestedObj.selected).length > 0
//      ? `Selected ${summary} Is ${Object.values(nestedObj.selected).join(', ')}`
//      : summary;

//  if (mainKey === 'brokers') {
//    const SingleSelect = nestedObj as {
//      selected?: IKeyMap;
//      assigned?: IKeyMap;
//      all?: IKeyMap;
//    };

//    return (
//      <SingleSelectedRender
//        nestedObj={SingleSelect}
//        isSelectedSingle={isSelectedSingle}
//        selectedSummary={selectedSummary}
//        isExpanded={isExpanded}
//        mainKey={mainKey}
//      />
//    );
//  }

//  if (mainKey === 'customers') {
//    const SingleSelect = nestedObj as {
//      selected?: IKeyMap;
//      assigned?: IKeyMap;
//      all?: IKeyMap;
//    };

//    return (
//      <SingleSelectedRender
//        nestedObj={SingleSelect}
//        isSelectedSingle={isSelectedSingle}
//        selectedSummary={selectedSummary}
//        isExpanded={isExpanded}
//        mainKey={mainKey}
//      />
//    );
//  }

//  if (mainKey === 'primaryContact') {
//    const UserInstance = nestedObj as UserInstance;

//    if (!UserInstance) return <div key={mainKey} />;
//    return (
//      <XpansionIsolated
//        key={mainKey}
//        summary={selectedSummary}
//        expanded={isExpanded}
//        style={{
//          backgroundColor: UserInstance ? theme.palette.success.light : '',
//          border: UserInstance ? `1px ${theme.palette.success.main} solid` : ''
//        }}>
//        <Grid container direction="row" spacing={2}>
//          <Grid item xs={12}>
//            <Paper
//              style={{
//                padding: theme.spacing(1),
//                margin: theme.spacing(1),
//                border: UserInstance
//                  ? `2px ${theme.palette.success.main} solid`
//                  : 'none',
//                backgroundColor: UserInstance ? theme.palette.success.light : ''
//              }}>
//              <Typography variant="h6">Selected:</Typography>
//              <Typography component={'div'}>
//                <QuickUserFromId UserInstanceId={UserInstance.Id.toString()} />
//              </Typography>
//            </Paper>
//          </Grid>
//        </Grid>
//      </XpansionIsolated>
//    );
//  }
//  return (
//    <XpansionIsolated
//      key={mainKey}
//      summary={selectedSummary}
//      expanded={isExpanded}
//      style={{
//        backgroundColor: isSelectedSingle ? theme.palette.success.light : '',
//        border: isSelectedSingle ? `1px ${theme.palette.success.main} solid` : ''
//      }}>
//      <Grid container direction="row" spacing={2}>
//        {nestedObj.selected && Object.keys(nestedObj.selected).length > 0 && (
//          <Grid item xs={gridSize}>
//            <RenderRow
//              title="Selected"
//              data={nestedObj.selected}
//              isGreenBorder={true}
//            />
//          </Grid>
//        )}
//        {nestedObj.assigned && Object.keys(nestedObj.assigned).length > 0 && (
//          <Grid item xs={gridSize}>
//            <RenderRow
//              title="Assigned"
//              data={nestedObj.assigned}
//              isGreenBorder={false}
//            />
//          </Grid>
//        )}
//        {nestedObj.all && Object.keys(nestedObj.all).length > 0 && (
//          <Grid item xs={gridSize}>
//            <RenderRow title="All" data={nestedObj.all} isGreenBorder={false} />
//          </Grid>
//        )}
//      </Grid>
//    </XpansionIsolated>
//  );

// const formatString = (str: string) => {
//   return str
//     .replace(/([A-Z])/g, ' $1')
//     .replace(/^./, (char) => char.toUpperCase());
// };

// const SingleSelectedRender = ({
//   mainKey,
//   selectedSummary,
//   isExpanded,
//   isSelectedSingle,
//   nestedObj
// }) => (
//   <XpansionIsolated
//     key={mainKey}
//     summary={selectedSummary}
//     expanded={isExpanded}
//     style={{
//       backgroundColor: isSelectedSingle ? theme.palette.success.light : '',
//       border: isSelectedSingle ? `1px ${theme.palette.success.main} solid` : ''
//     }}>
//     {nestedObj.selected && Object.keys(nestedObj.selected).length > 0 && (
//       <Grid item xs={12}>
//         <RenderRow
//           title="Selected"
//           data={nestedObj.selected}
//           isGreenBorder={true}
//         />
//       </Grid>
//     )}
//   </XpansionIsolated>
// );
