import React from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import { Folder } from '@material-ui/icons';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    borderRadius: '16px',
    boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '6px 6px 15px rgba(0, 0, 0, 0.3)'
    }
  },
  folderIcon: {
    color: '#757575'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

const FolderCard = ({ folderName, videoCount, onClick }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" onClick={onClick} className={classes.card}>
      <CardActionArea>
        <CardContent>
          <Box display="flex" alignItems="center">
            <Folder className={classes.folderIcon} />
          </Box>
          <Typography variant="h6">{folderName}</Typography>
          <Typography variant="body2">{videoCount} videos</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default FolderCard;
