const DLLProposal = {
  externalReference: '',
  financeProduct: '',
  lessee: {
    id: '',
    legalEntity: {
      id: ''
    },
    preferredLocale: '',
    uniqueIdentifier: '',
    uniqueIdentifierType: '',
    countryOfRegistration: '',
    businessType: '',
    businessActivity: '',
    externalIdentifiers: [
      {
        type: '',
        id: ''
      }
    ],
    phoneNumbers: [
      {
        type: '',
        areaCode: '',
        number: ''
      }
    ],
    email: '',
    organization: {
      name: '',
      establishmentDate: '',
      cityOfRegistration: ''
    },
    individual: {
      givenName: '',
      familyName: '',
      middleName: '',
      birthDate: '',
      placeOfBirth: '',
      sex: ''
    },
    address: {
      street: '',
      houseNumber: '',
      city: '',
      postalCode: '',
      postTown: '',
      region: '',
      country: '',
      buildingName: ''
    },
    invoiceAddress: {
      street: '',
      houseNumber: '',
      city: '',
      postalCode: '',
      postTown: '',
      region: '',
      country: '',
      buildingName: '',
      email: ''
    },
    previousAddress: {
      street: '',
      houseNumber: '',
      city: '',
      postalCode: '',
      postTown: '',
      region: '',
      country: '',
      buildingName: ''
    },
    contacts: [
      {
        name: '',
        email: '',
        phoneNumbers: [
          {
            type: '',
            areaCode: '',
            number: ''
          }
        ]
      }
    ],
    bankDetails: {
      accountNumber: '',
      accountNumberType: '',
      bankIdentifier: '',
      bankIdentifierType: '',
      paymentMethod: ''
    },
    partners: [
      {
        givenName: '',
        familyName: '',
        middleName: '',
        birthDate: '',
        mobileNumber: '',
        address: {
          street: '',
          houseNumber: '',
          city: '',
          postalCode: '',
          postTown: '',
          region: '',
          country: '',
          buildingName: ''
        },
        externalIdentifiers: [
          {
            type: '',
            id: ''
          }
        ]
      }
    ],
    directors: [
      {
        familyName: '',
        middleName: '',
        givenName: '',
        birthDate: ''
      }
    ]
  },
  proposals: [
    {
      description: '',
      startDate: '',
      financialProduct: {
        id: '',
        contractType: '',
        vatFinancing: true
      },
      preferred: true,
      calculation: {
        financeInput: {
          financeStructure: {
            numberOfMonths: 0,
            numberOfPayments: 0,
            delayPayment: [
              {
                type: '',
                initialPause: 0,
                initialPauseUnit: '',
                startDate: ''
              }
            ],
            terminalPauseRegularPayments: 0,
            paymentFrequency: '',
            paymentTiming: '',
            paymentMethod: '',
            delayVatPayment: 0,
            finance: {
              financeAmount: 0,
              initialPaymentMultiplier: 0,
              initialPaymentAmount: 0,
              balloon: 0,
              netDeposit: {
                amount: 0,
                downPayment: 0,
                settlement: 0,
                settlementContractType: '',
                tradeIn: 0
              },
              payments: {
                custom: [
                  {
                    paymentDate: '',
                    type: '',
                    amount: 0,
                    multiplier: 0,
                    percentage: 0,
                    percentageOf: ''
                  }
                ]
              },
              rates: {
                effectiveCustomerRate: 0,
                calculate: '',
                variableNominalInterestRate: true
              },
              paymentProfile: {
                type: '',
                id: ''
              },
              commissions: [
                {
                  amount: 0,
                  id: ''
                }
              ],
              subsidies: [
                {
                  type: '',
                  amount: 0,
                  id: ''
                }
              ]
            }
          },
          fees: [
            {
              amount: 0,
              id: '',
              description: ''
            }
          ],
          insurances: [
            {
              amount: 0,
              insuranceTaxPercentage: 0,
              id: '',
              description: ''
            }
          ],
          maintenance: [
            {
              amount: 0,
              quantity: 0,
              id: '',
              description: ''
            }
          ],
          assets: [
            {
              id: '',
              modelIdentifier: {
                masterAssetTypeName: '',
                categoryName: '',
                typeName: '',
                brandName: '',
                modelName: ''
              },
              description: '',
              quantity: 0,
              salePrice: 0,
              listPrice: 0,
              vatPercentage: 0,
              vatAmount: 0,
              condition: '',
              yearOfManufacturing: '',
              subLeased: true,
              meterRead: 0,
              usageUnitsPerPeriod: 0,
              usageEnvironment: '',
              residualValue: {
                value: 0,
                id: ''
              },
              repurchaseAmount: {
                amount: 0,
                id: ''
              },
              excessiveUsageLimit: 0,
              excessiveUsagePenaltyAmount: 0,
              fees: [
                {
                  amount: 0,
                  id: '',
                  description: ''
                }
              ],
              subsidies: [
                {
                  type: '',
                  amount: 0,
                  id: ''
                }
              ],
              insurances: [
                {
                  amount: 0,
                  insuranceTaxPercentage: 0,
                  replacement: true,
                  excessAmount: 0,
                  id: '',
                  description: ''
                }
              ],
              maintenance: [
                {
                  amount: 0,
                  quantity: 0,
                  id: '',
                  description: ''
                }
              ],
              instances: [
                {
                  serialNumber: '',
                  chassisNumber: '',
                  licenseNumber: '',
                  ipAddress: '',
                  location: {
                    street: '',
                    houseNumber: '',
                    city: '',
                    postalCode: '',
                    postTown: '',
                    country: '',
                    buildingName: ''
                  }
                }
              ]
            }
          ]
        },
        financeCalculation: {
          financeAmount: '',
          financeDeviation: 0,
          netPresentValue: 0,
          futureValue: 0,
          rates: {
            nominalInterestRate: '',
            effectiveInterestRate: '',
            nominalCustomerRate: 0,
            nominalCustomerRateIncludingFees: 0,
            effectiveCustomerRate: 0,
            effectiveCustomerRateIncludingFees: 0,
            compoundingFrequency: ''
          }
        },
        consolidatedPayments: [
          {
            numberOfPayments: '',
            amount: '',
            type: '',
            startDate: ''
          }
        ],
        commissions: [
          {
            amount: 0,
            id: ''
          }
        ],
        subsidies: [
          {
            type: '',
            amount: 0,
            id: '',
            assetModelIdentifier: {
              masterAssetTypeName: '',
              categoryName: '',
              typeName: '',
              brandName: '',
              modelName: ''
            }
          }
        ],
        payments: [
          {
            paymentDate: '',
            balanceOutstanding: 0,
            rentalPayment: {
              amount: 0,
              principal: 0,
              interestAmount: 0
            },
            vatPayment: {
              amount: 0
            },
            fees: [
              {
                amount: 0,
                vatAmount: 0,
                id: '',
                assetModelIdentifier: {
                  masterAssetTypeName: '',
                  categoryName: '',
                  typeName: '',
                  brandName: '',
                  modelName: ''
                }
              }
            ],
            insurances: [
              {
                amount: 0,
                vatAmount: 0,
                insuranceTaxAmount: 0,
                id: '',
                assetModelIdentifier: {
                  masterAssetTypeName: '',
                  categoryName: '',
                  typeName: '',
                  brandName: '',
                  modelName: ''
                }
              }
            ],
            maintenance: [
              {
                amount: 0,
                vatAmount: 0,
                id: '',
                assetModelIdentifier: {
                  masterAssetTypeName: '',
                  categoryName: '',
                  typeName: '',
                  brandName: '',
                  modelName: ''
                }
              }
            ]
          }
        ]
      }
    }
  ],
  notes: [
    {
      note: ''
    }
  ]
};

export default DLLProposal;
