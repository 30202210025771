import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import {
  ELender,
  ELenderStatus,
  IProposal,
  TLenderAPIInterfaces,
  IProposalObjects,
  TAttachedFile,
  IProposalAttachedFiles
} from '../interface';
import { useLenderAPIStyles } from '../styles';
import { prepareForApiUpload } from '../functions';
import { useTypedSelector } from 'redux/reducers';
import ProposalForm from './ProposalForm';
import {
  flattenCompleteObjectInstanceDict,
  createInternalStructure,
  createEmptyInternalStructure
} from '../configuration/proposalCreation';
import { CompleteObjectInstance, FbFileRef } from 'types/interfaces';
import { globalIds } from 'helpers/globalIdConfig';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import ProposalHeader from './ProposalHeader';
import { removeTypenames } from 'redux/database/User Instance API/mutations';
import {
  getProposalStatus,
  getDocumentTypes,
  getProposalList,
  createProposal
} from 'redux/actions/GraphQlActions/LenderAPIActions';
import {
  isFileExplorer,
  isFileStorage,
  normalizeDocumentType
} from '../helper';
import ProposalSubmissionProgress from './ProposalSubmissionProgress';
import useFieldValidation from '../hooks/useFieldValidation';
import useProgressStatus from '../hooks/useProgressStatus';
import { StepperContext } from 'components/Stepper/context';
import { ICreateProposal } from 'redux/database/Lender API/interface';
import { Alert, AlertTitle } from '@material-ui/lab';
import { notify } from 'components/Notifications/HotToastNotifications';

import { useQuery } from '@apollo/client';
import { GET_COMPLETE_USER_INSTANCE_DETAIL } from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { apolloClient } from 'graphql/apolloClient';

const RenderForm = ({
  proposal,
  ObjectInstanceList,
  disabled,
  onDelete,
  updateProposal
}: {
  proposal: IProposal;
  ObjectInstanceList: IProposalObjects;
  disabled: boolean;
  onDelete: (proposalId: string) => void;
  updateProposal: (updatedProposal: IProposal) => void;
}) => {
  const classes = useLenderAPIStyles();
  const theme = useTheme();
  const {
    showProgress,
    updateProgress,
    statusMessage,
    isCompleted,
    resetProgress
  } = useProgressStatus();
  const { setProposalsList } = useContext(StepperContext);

  const { baseURL } = useTypedSelector((s) => s.config);
  const { user } = useTypedSelector((s) => s.user);

  const { data } = useQuery(GET_COMPLETE_USER_INSTANCE_DETAIL, {
    variables: {
      baseUrl: baseURL,
      userInstanceId: parseInt(user.Id.toString())
    },
    client: apolloClient,
    skip: !user.Id
  });

  const { currentDeal, entityType } = useTypedSelector((s) => s.process);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [expand, setExpanded] = useState<boolean>(false);
  const toggle = () => setExpanded(!expand);

  const [status, setStatus] = useState<ELenderStatus>(proposal.requestedStatus);

  const [files, setFiles] = useState<FbFileRef[]>(
    proposal.files === null ? [] : proposal.files
  );

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<IProposalAttachedFiles[]>(
    []
  );

  const [internalData, setInternalData] = React.useState(() => {
    if (proposal?.isImported)
      return createEmptyInternalStructure({
        selectedCONST: proposal.defaultValues,
        mappedIds: proposal.mappedIds
      });

    const selectedCONST: TLenderAPIInterfaces = proposal.defaultValues;
    const selectedMappedIds = proposal.mappedIds;

    const flattenedFieldInstances = flattenCompleteObjectInstanceDict([
      currentDeal.CompleteObjectInstanceDict,
      data?.GetUserInstanceDetail?.CompleteObjectInstanceList
    ]);

    return createInternalStructure({
      selectedCONST,
      mappedIds: selectedMappedIds,
      flattenedFieldInstances,
      currentDeal,
      user,
      entityType,
      dealSummary: deal
    });
  });

  const { control, handleSubmit, setValue } = useForm<TLenderAPIInterfaces>({
    defaultValues: internalData
  });

  const { totalRequired, completed, errors } = useFieldValidation(
    control,
    proposal
  );

  /**
   * Handles  submission by preparing data and invoking the appropriate API functions.
   *
   * @param {TLenderAPIInterfaces} formData - The form data to submit.
   */
  const onFormSubmit = handleSubmit(async (formData: TLenderAPIInterfaces) => {
    updateProgress('Preparing form data...', false, true);

    const apiData = prepareForApiUpload(formData, proposal);
    updateProgress('Form data prepared. Processing quotes...', false, true);

    const getCompleteObjectInstanceDict =
      currentDeal.CompleteObjectInstanceDict;

    // Process quotes
    const getQuotes: CompleteObjectInstance[] | undefined = Object.values(
      getCompleteObjectInstanceDict
    ).filter((CompleteObjectInstance: CompleteObjectInstance) => {
      return globalIds.customer.quotes.QuotesObjectDefinition.includes(
        CompleteObjectInstance.ObjectInstance.ObjectDefinitionId
      );
    });

    let newQuotes: Partial<CompleteObjectInstance>[] = [];
    getQuotes?.forEach((CompleteObjectInstance: CompleteObjectInstance) => {
      const newCompleteObjectInstance = removeTypenames(CompleteObjectInstance);
      const getFieldInstanceList = getFieldInstances(newCompleteObjectInstance);

      newQuotes.push({
        ObjectInstance: newCompleteObjectInstance.ObjectInstance,
        FieldInstanceList: getFieldInstanceList
      });
    });

    updateProgress('Quotes processed. Processing assets...', false, true);

    // Process assets
    const getAssets: CompleteObjectInstance[] | undefined = Object.values(
      getCompleteObjectInstanceDict
    ).filter((CompleteObjectInstance: CompleteObjectInstance) => {
      return globalIds.customer.assets.AssetsObjectDefinition.includes(
        CompleteObjectInstance.ObjectInstance.ObjectDefinitionId
      );
    });

    let newAssets: Partial<CompleteObjectInstance>[] = [];
    getAssets?.forEach((CompleteObjectInstance: CompleteObjectInstance) => {
      const newCompleteObjectInstance = removeTypenames(CompleteObjectInstance);
      const getFieldInstanceList = getFieldInstances(newCompleteObjectInstance);

      newAssets.push({
        ObjectInstance: newCompleteObjectInstance.ObjectInstance,
        FieldInstanceList: getFieldInstanceList
      });
    });

    updateProgress('Assets processed. Preparing proposal data...', false, true);

    const newCompleteObjectInstance = removeTypenames(
      proposal.CompleteObjectInstance
    );

    const getFieldInstanceList = getFieldInstances(newCompleteObjectInstance);
    const newLenderAndProposal = {
      ObjectInstance: newCompleteObjectInstance.ObjectInstance,
      FieldInstanceList: getFieldInstanceList
    };

    // Selected Quote into Quotes
    const input: ICreateProposal = {
      ProposalMetadata: {
        Quotes: newQuotes,
        Assets: newAssets,
        TargetObjectInstance: newLenderAndProposal,
        meta: {
          _dealId: currentDeal.ProcessInstance.Id.toString(),
          _status: ELenderStatus.TEST,
          _decision: proposal.decision,
          _lender: proposal.lenderTitle,
          _userId: proposal.lenderId.toString(),
          _baseURL: baseURL,
          _action: null,
          _targetObjectInstanceId: null
        }
      },
      proposal: apiData as TLenderAPIInterfaces
    };

    updateProgress('Proposal data prepared. Submitting to API...', false, true);
    const createProposalResponse = await createProposal(input);

    if (Array.isArray(createProposalResponse)) {
      setValidationErrors(createProposalResponse);
      updateProgress(
        'Validation failed. Please check the form for errors.',
        true,
        true
      );
      return;
    }

    if (
      createProposalResponse &&
      'thirdPartyId' in createProposalResponse &&
      'meta' in createProposalResponse
    ) {
      updateProgress(
        'API submission successful. Creating internal structure...',
        false,
        true
      );

      const newInternalData = createEmptyInternalStructure({
        selectedCONST: apiData as TLenderAPIInterfaces,
        mappedIds: proposal.mappedIds
      });

      const updatedProposal: IProposal = {
        ...proposal,
        lenderTitle: createProposalResponse.meta._lender,
        requestedStatus: createProposalResponse.meta._status,
        isImported: true,
        decision: createProposalResponse.meta._decision,
        id: createProposalResponse.thirdPartyId,
        defaultValues: apiData as TLenderAPIInterfaces
      };

      updateProposal(updatedProposal);
      setInternalData(newInternalData);

      const proposals = await getProposalList(
        currentDeal.ProcessInstance.Id.toString(),
        true
      );

      if (proposals) setProposalsList(proposals);
      notify.success(createProposalResponse.message);

      updateProgress('Process completed successfully!', true, true);
    } else {
      updateProgress(
        'Error: Failed to create proposal. Please try again.',
        true,
        true
      );
    }
  });

  /**
   * Checks and updates the proposal status by querying the API.
   */
  const handleStatusChange = async () => {
    const response = await getProposalStatus({
      lender: proposal.lenderTitle as ELender,
      proposalId: proposal.id.toString(),
      dealId: currentDeal.ProcessInstance.Id.toString()
    });

    // Here we update the Proposal
    if (response) {
      const updatedProposal: IProposal = {
        ...proposal,
        requestedStatus: response._status,
        uniqueId: proposal.uniqueId,
        decision: response._decision
      };

      setStatus(response._status);
      updateProposal(updatedProposal);
      notify.success('Successfully Updated Status');
    }
  };

  /**
   * Handles the file upload process, including validation against allowed file types for the lender.
   *
   * @param {Object} params - Parameters object.
   * @param {string} params.url - The URL of the uploaded file.
   * @param {TAttachedFile} params.item - The file object to be uploaded.
   */
  const handleFileUpload = async ({
    url,
    item
  }: {
    url: string;
    item: TAttachedFile;
  }) => {
    let allowedTypes;
    if (proposal.lenderTitle === ELender.Fleximize) {
      allowedTypes = await getDocumentTypes({
        lender: proposal.lenderTitle
      });

      if (!allowedTypes) {
        notify.error('Failed to Retrieve Allowed Document Types.');
        return;
      }
    }

    let normalizedUploadedType;
    if (isFileExplorer(item)) {
      normalizedUploadedType = normalizeDocumentType('Other');
    } else if (isFileStorage(item)) {
      if (item.documentStatus && item.documentStatus.documentType) {
        normalizedUploadedType = normalizeDocumentType(
          item.documentStatus.documentType
        );
      } else {
        normalizedUploadedType = normalizeDocumentType('Other');
      }
    }

    if (proposal.lenderTitle === ELender.Fleximize) {
      const isAllowedType = allowedTypes.some((typeObj) =>
        normalizeDocumentType(typeObj.type).includes(normalizedUploadedType)
      );

      if (!isAllowedType) {
        notify.error('This File Type Is Not Allowed For The Selected Lender.');

        return;
      }
    }

    const newFile: IProposalAttachedFiles = {
      name: item.name,
      fileId: isFileStorage(item) ? item.fileId : undefined,
      key: Math.random().toString(36).slice(2, 11),
      url: url,
      type: item
    };

    setAttachedFiles((prevFiles) => [...prevFiles, newFile]);
  };

  /**
   * Removes a file from the attached files list based on its key.
   *
   * @param {string} keyToRemove - The unique key of the file to remove.
   */
  const handleFileRemove = (keyToRemove: string) => {
    setAttachedFiles((prevFiles) =>
      prevFiles.filter((file) => file.key !== keyToRemove)
    );
  };

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const isLargeFormatLender =
    proposal.lenderTitle === ELender.DLL ||
    proposal.lenderTitle === ELender.Northridge ||
    proposal.lenderTitle === ELender.Novuna ||
    proposal.lenderTitle === ELender.PremiumCredit;

  const renderProposalContent = () => {
    if (isLargeFormatLender) {
      return (
        <>
          <div style={{ paddingBottom: theme.spacing(2) }}>
            <Alert severity="info">
              <AlertTitle>Enhanced View Recommended</AlertTitle>
              <Typography variant="h6" color="textSecondary">
                Due to the complexity of this proposal, we recommend viewing it
                in an expanded format for better readability and navigation.
                Please use the button below to open in full view.
              </Typography>
            </Alert>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
            fullWidth>
            Open proposal in larger format
          </Button>

          <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth>
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <span>{proposal.lenderTitle} Proposal</span>
                <IconButton onClick={handleCloseDialog}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <ProposalForm
                proposal={proposal}
                control={control}
                setValue={setValue}
                files={files}
              />
            </DialogContent>
          </Dialog>
        </>
      );
    }

    return (
      <ProposalForm
        proposal={proposal}
        control={control}
        setValue={setValue}
        files={files}
      />
    );
  };

  return (
    <Fade in timeout={300}>
      <div
        className={`${classes.root} ${classes.proposal} ${
          classes[`proposal${status}`]
        }`}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
          width: '100%'
        }}>
        <Accordion
          expanded={expand}
          onChange={(e) => {
            e.stopPropagation();
            toggle();
          }}
          style={{ width: '100%' }}>
          <AccordionSummary
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: theme.palette.background.paper,
              zIndex: 10,
              borderRadius: theme.shape.borderRadius
            }}
            expandIcon={
              <Tooltip title="More detail">
                <ExpandMore />
              </Tooltip>
            }>
            <Box width="100%">
              {showProgress && (
                <Box mb={2}>
                  <ProposalSubmissionProgress
                    statusMessage={statusMessage}
                    isCompleted={isCompleted}
                    onRemove={resetProgress}
                  />
                </Box>
              )}

              <ProposalHeader
                proposal={{
                  ...proposal,
                  disabled: totalRequired - completed !== 0 || errors !== 0
                }}
                attachedFiles={attachedFiles}
                setAttachedFiles={setAttachedFiles}
                ObjectInstanceList={ObjectInstanceList}
                onSubmit={onFormSubmit}
                onDelete={onDelete}
                handleStatusChange={handleStatusChange}
                updateProposal={updateProposal}
                handleFileUpload={handleFileUpload}
                handleFileRemove={handleFileRemove}
                setStatus={setStatus}
                status={status}
                setFiles={setFiles}
                files={files}
                validationErrors={validationErrors}
              />
            </Box>
          </AccordionSummary>
          <CardContent>{renderProposalContent()}</CardContent>
        </Accordion>
      </div>
    </Fade>
  );
};

export default RenderForm;
