import { MdMarkEmailRead } from 'react-icons/md';
import { BsCalculatorFill, BsFileEarmarkPdfFill } from 'react-icons/bs';
import { GiWhistle } from 'react-icons/gi';
import { AiFillWechat, AiTwotoneFolderOpen } from 'react-icons/ai';
import { BsPinAngleFill } from 'react-icons/bs';
import { FaCarSide } from 'react-icons/fa';
import { FaQuestion } from 'react-icons/fa';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import StorageIcon from '@material-ui/icons/Storage';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Dangerous from '@material-ui/icons/Warning';

export const PluginList = [
  {
    path: 'bips-weekly',
    icon: <MeetingRoomIcon />,
    name: 'Bips Weekly',
    env: 'bips_weekly'
  },
  {
    path: 'message-hub',
    icon: <AllInboxIcon />,
    name: 'Inbox',
    env: 'message_hub'
  },
  {
    path: 'fca',
    icon: <GiWhistle fontSize={24} />,
    name: 'FCA',
    env: 'fca'
  },
  {
    path: 'vrm',
    icon: <FaCarSide fontSize={24} />,
    name: 'VRM Lookup',
    env: 'vrm'
  },
  {
    path: 'calculator',
    icon: <BsCalculatorFill fontSize={24} />,
    name: 'Calculator',
    env: 'calculator'
  },
  {
    path: 'marketing',
    icon: <MdMarkEmailRead fontSize={24} />,
    name: 'Marketing Campaigns',
    env: 'marketing'
  },
  // {
  //   path: 'graphql-crm',
  //   icon: <VscGraphScatter fontSize={24} />,
  //   name: 'Graphql CRM',
  //   env: 'rfgql'
  // },
  {
    path: 'chatter',
    icon: <AiFillWechat fontSize={24} />,
    name: 'Chatter',
    env: 'chatter'
  },
  {
    path: 'file-explorer',
    icon: <BsFileEarmarkPdfFill fontSize={24} />,
    name: 'File Explorer',
    env: 'afs_cribsheets'
  },
  {
    path: 'legacy-deals-viewer',
    icon: <AiTwotoneFolderOpen fontSize={24} />,
    name: 'Legacy Deals Viewer',
    env: 'afs_legacydealviewer'
  },
  {
    path: 'test-flight',
    icon: <FlightTakeoffIcon />,
    name: 'Test Flight',
    env: 'test_flight'
  },
  {
    path: 'address/lookup',
    icon: <LocationSearchingIcon />,
    name: 'Address lookup',
    env: 'address_lookup'
  },
  {
    path: 'storage',
    icon: <StorageIcon />,
    name: 'File Storage',
    env: 'storage'
  },
  {
    path: 'noticeboard-Builder',
    icon: <BsPinAngleFill fontSize={24} />,
    name: 'Bips Noticeboard',
    env: 'noticeboard_instance'
  },
  {
    path: 'extreme-caution',
    icon: <Dangerous />,
    name: 'Extreme Caution List',
    env: 'extreme_caution'
  },
  {
    path: 'faq',
    icon: <FaQuestion fontSize={20} />,
    name: 'FAQ',
    env: 'faq'
  }
];
