import React, { createContext, useContext, useState } from 'react';

import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { callMsGraph } from 'services/microsoft/graph';
import { BugTracker } from 'Utils/Bugtracker';
import { ISelectionContext } from './interface';
import { IConversation } from 'components/MessageHub/interfaces';
import { determineParentCategory } from 'components/MessageHub/functions';
import { handleMovementRefresh } from '../functions';
import { notify } from 'components/Notifications/HotToastNotifications';

const DRAFTS_URL =
  'https://graph.microsoft.com/v1.0/me/mailFolders/Drafts/messages';

export const SelectionContext = createContext<ISelectionContext>({
  handleDeleteMessages: () => {},
  getRefreshDrafts: () => Promise.resolve(),
  loadDraftsFromServer: () => Promise.resolve(),
  fetchMessagesFromFolder: () => Promise.resolve(),
  createNewFolder: () => Promise.resolve(),
  createNewSubFolder: () => Promise.resolve(),
  deleteFolder: () => Promise.resolve(),
  moveThreadToFolder: () => Promise.resolve(),
  folderLoading: { parentFolder: false, childFolder: false }
});

const deleteMessageById = async (accessToken, id) => {
  const url = `${DRAFTS_URL}/${id}`;

  await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const SelectionProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    validAccessToken,
    setData,
    selectedCategory,
    folderIds,
    customFolder
  } = useContext(MessageHubContext);

  const handleDeleteMessages = async ({ messageId }: { messageId: string }) => {
    try {
      const accessToken = await validAccessToken();
      await deleteMessageById(accessToken, messageId);
      await getRefreshDrafts();
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const [folderLoading, setFolderLoading] = useState<{
    parentFolder: boolean;
    childFolder: boolean;
  }>({
    parentFolder: false,
    childFolder: false
  });

  const createNewFolder = async (title: string) => {
    try {
      setFolderLoading({ ...folderLoading, parentFolder: true });
      const accessToken = await validAccessToken();
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          displayName: title
        })
      };

      const response = await fetch(
        'https://graph.microsoft.com/v1.0/me/mailFolders',
        requestOptions
      );

      const data = await response.json();
      if (response.ok) {
        const parentCategory = determineParentCategory(
          data.parentFolderId,
          folderIds
        );

        setData((prevData: any) => {
          let updatedFolderIds = { ...prevData.folderIds };

          if (parentCategory === 'customFolders') {
            updatedFolderIds.customFolders = [
              ...(updatedFolderIds.customFolders || []),
              data
            ];
          }

          return {
            ...prevData,
            folderIds: updatedFolderIds
          };
        });

        notify.success(`Success! The Folder '${title}' Has been Created.`);
      } else {
        notify.error(`Unable To Create The Folder '${title}'. Reason: ${data}`);
      }

      setFolderLoading({ ...folderLoading, parentFolder: false });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const createNewSubFolder = async (title: string, parentId: string) => {
    try {
      setFolderLoading({ ...folderLoading, childFolder: true });
      const accessToken = await validAccessToken();
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          displayName: title
        })
      };

      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/mailFolders/${parentId}/childFolders`,
        requestOptions
      );

      const data = await response.json();
      if (response.ok) {
        setData((prevData) => {
          if (!prevData.folderIds) return prevData;
          let updatedFolderIds = { ...prevData.folderIds };

          const parentCategory = determineParentCategory(
            parentId,
            prevData.folderIds
          );
          if (!parentCategory) return prevData;
          let updatedCategoryFolders = JSON.parse(
            JSON.stringify(updatedFolderIds[parentCategory])
          );

          if (updatedCategoryFolders && Array.isArray(updatedCategoryFolders)) {
            let parentFolderIndex = updatedCategoryFolders.findIndex(
              (folder) => folder.id === parentId
            );

            if (parentFolderIndex !== -1) {
              if (!updatedCategoryFolders[parentFolderIndex].child) {
                updatedCategoryFolders[parentFolderIndex].child = [];
              }
              updatedCategoryFolders[parentFolderIndex].child.push(data);
            }

            updatedFolderIds[parentCategory] = updatedCategoryFolders;
          } else if (
            updatedCategoryFolders &&
            updatedCategoryFolders.id === parentId
          ) {
            if (!updatedCategoryFolders.child) {
              updatedCategoryFolders.child = [];
            }
            updatedCategoryFolders.child.push(data);
            updatedFolderIds[parentCategory] = updatedCategoryFolders;
          }

          return {
            ...prevData,
            folderIds: updatedFolderIds
          };
        });

        notify.success(`Subfolder: ${title} Has Been Created`);
      } else {
        notify.error(`Failed To Create Subfolder: ${title}, Reason: ${data}`);
      }
      setFolderLoading({ ...folderLoading, childFolder: false });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const deleteFolder = async (
    folderId: string,
    parentFolderId: string,
    type?: boolean
  ) => {
    try {
      const accessToken = await validAccessToken();
      const requestOptions = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };

      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/mailFolders/${folderId}`,
        requestOptions
      );

      if (response.ok) {
        setData((prevData: any) => {
          if (!prevData.folderIds || !prevData.folderIds.customFolders)
            return prevData;

          if (type) {
            let updatedCustomFolders = [...prevData.folderIds.customFolders];

            if (parentFolderId && parentFolderId !== '') {
              const parentFolderIndex = updatedCustomFolders.findIndex(
                (folder) => folder.id === parentFolderId
              );
              if (
                parentFolderIndex !== -1 &&
                updatedCustomFolders[parentFolderIndex].child
              ) {
                updatedCustomFolders[parentFolderIndex].child =
                  updatedCustomFolders[parentFolderIndex].child.filter(
                    (child) => child.id !== folderId
                  );
              }
            } else {
              updatedCustomFolders = updatedCustomFolders.filter(
                (folder) => folder.id !== folderId
              );
            }

            return {
              ...prevData,
              folderIds: {
                ...prevData.folderIds,
                customFolders: updatedCustomFolders
              }
            };
          } else {
            let updatedFolderIds = { ...prevData.folderIds };
            if (parentFolderId && parentFolderId !== '') {
              for (const key in updatedFolderIds) {
                if (updatedFolderIds[key]?.child) {
                  updatedFolderIds[key].child = updatedFolderIds[
                    key
                  ].child.filter((child) => child.id !== folderId);
                }
              }
            } else {
              updatedFolderIds.customFolders =
                updatedFolderIds.customFolders.filter(
                  (folder) => folder.id !== folderId
                );
            }

            return { ...prevData, folderIds: updatedFolderIds };
          }
        });

        notify.success(`Folder has been Deleted Successfully`);
      } else {
        const data = await response.json();
        notify.error(`Failed to Delete Folder, Reason: ${data}`);
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const moveThreadToFolder = async ({
    conversation,
    folderId,
    folderName
  }: {
    conversation: IConversation | null;
    folderId: string;
    folderName: string;
  }) => {
    if (!conversation) return;
    try {
      const accessToken = await validAccessToken();
      const allMessages = [conversation.parentMessage, ...conversation.replies];

      const movePromises = allMessages.map((message: any) =>
        fetch(
          `https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              destinationId: folderId
            })
          }
        )
      );

      const responses = await Promise.all(movePromises);
      if (responses.every((response) => response.ok)) {
        if (!folderIds) return;

        await handleMovementRefresh({
          conversation,
          folderIds,
          selectedCategory,
          accessToken,
          setData
        });

        notify.success(
          `Thread ${conversation.parentMessage.subject} Has Been Moved To ${folderName}`
        );
      } else {
        const data = await Promise.all(responses.map((res) => res.json()));
        notify.error(
          `Failed to Move Some or All Messages, Reason: ${JSON.stringify(data)}`
        );
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const loadDraftsFromServer = async () => {
    try {
      const accessToken = await validAccessToken();
      if (!accessToken) return;

      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      };

      const response = await fetch(DRAFTS_URL, requestOptions);
      const data = await response.json();

      setData((prevData) => ({ ...prevData, drafts: data }));
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const fetchMessagesFromFolder = async (folderId: string, type: string) => {
    try {
      const accessToken = await validAccessToken();
      if (!accessToken) return;

      const messages = await callMsGraph({
        accessToken,
        action: 'ParentFolders',
        parentFolderId: folderId,
        queryParams: {
          $filter: `isDraft eq false`,
          $expand: 'attachments'
        }
      });

      setData((prevData) => ({ ...prevData, [type]: messages }));
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const getRefreshDrafts = async () => {
    try {
      const accessToken = await validAccessToken();
      const drafts = await callMsGraph({
        accessToken,
        action: 'messages',
        queryParams: { $filter: 'isDraft eq true' }
      });

      setData((prevData) => ({ ...prevData, drafts }));
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  return (
    <SelectionContext.Provider
      value={{
        handleDeleteMessages,
        getRefreshDrafts,
        loadDraftsFromServer,
        fetchMessagesFromFolder,
        createNewFolder,
        createNewSubFolder,
        deleteFolder,
        moveThreadToFolder,
        folderLoading
      }}>
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionProvider;
