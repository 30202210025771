import { UserInstance } from 'types/interfaces';
import Calendar from './components';
import { CalendarProvider } from './context/CalendarContext';
import { CalendarEventProvider } from './context/EventContext';
import { IEvent, IEventFormValues } from './interface';

export interface ITimelineEventsState {
  UserInstance: UserInstance | undefined;
  Event: IEvent[];
}

const MainCalendar = ({
  timelineEvents,
  setTimelineEvents,
  refreshTimelineEvents,
  isTimeline = false,
  validRange,
  defaultFormValues,
  hideCalendarTimeBody
}: {
  setTimelineEvents?: React.Dispatch<
    React.SetStateAction<ITimelineEventsState | undefined>
  >;
  timelineEvents?: { UserInstance: UserInstance | undefined; Event: IEvent[] };
  refreshTimelineEvents?: () => void;
  isTimeline?: boolean;
  validRange?: { start: Date; end: Date };
  defaultFormValues?: IEventFormValues;
  hideCalendarTimeBody?: boolean;
}) => {
  const timelineData = timelineEvents ? timelineEvents : undefined;
  return (
    <CalendarProvider
      timelineEvents={timelineData}
      setTimelineEvents={setTimelineEvents}
      isTimeline={isTimeline}
      refreshTimelineEvents={refreshTimelineEvents}
      defaultFormValues={defaultFormValues}>
      <CalendarEventProvider>
        <Calendar
          timelineEvents={timelineData}
          validRange={validRange}
          hideCalendarTimeBody={hideCalendarTimeBody}
        />
      </CalendarEventProvider>
    </CalendarProvider>
  );
};

export default MainCalendar;
