import React, { useEffect, useState } from 'react';

declare global {
  interface Window {
    loomEmbedSDK: any;
  }
}

const LoomVideo = ({ videoUrl }: { videoUrl: string }) => {
  const [embedHtml, setEmbedHtml] = useState<string | null>(null);
  useEffect(() => {
    const fetchEmbed = async () => {
      try {
        if (window.loomEmbedSDK) {
          console.log('Loom Embed SDK found', window.loomEmbedSDK);
          const { html } = await window.loomEmbedSDK.oembed(videoUrl);
          setEmbedHtml(html);
        } else {
          console.error('Loom Embed SDK not found');
          console.log('Loom Embed SDK not found', window.loomEmbedSDK);
        }
      } catch (e) {
        console.error('Error fetching Loom embed', e);
      }
    };
    fetchEmbed();
  }, [videoUrl]);
  if (!embedHtml) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
        }}>
        Loading...
      </div>
    );
  }
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: embedHtml }} />;
};

export default LoomVideo;
