import { ImportRedFlagData } from '../database';
import { notify } from 'components/Notifications/HotToastNotifications';

export const importRedFlagData = async ({ data }) => {
  const res = await ImportRedFlagData({ data });
  if (res.status === 200) {
    notify.success('Updated with Redflag Data');
    return res;
  } else {
    notify.warning('Update Unsuccessful');
  }
};
