import axios from 'axios';

import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { ISender } from '../types';
import { validateSenderDomains } from 'components/Marketing/Senders/functions';
import { notify } from 'components/Notifications/HotToastNotifications';

export const updateSender = async (sender: ISender) => {
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  sender.hostname = hostname;

  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/senders/${sender.id}`;

  const valid = await validateSenderDomains({
    senderFromEmail: sender.from_email,
    senderReplyToEmail: sender.reply_to
  });

  if (!valid) return false;

  return axios
    .patch(url, sender, { headers: { token } })
    .then((res) => {
      notify.success(`Sender Info Updated`);
      gtag.event({
        feature: 'Marketing',
        action: 'Marketing: DeleteSender',
        message: 'Success'
      });
    })
    .catch((e) => {
      BugTracker.notify(e);
      notify.error(`Error Updating Sender`);

      const errors = e?.response?.data?.response?.body?.errors || [];

      if (errors.length) {
        errors.forEach((error) => {
          const existingEmailError =
            error.field === 'from_email' && error.message === 'already exists';

          if (existingEmailError) {
            notify.error(
              `A Sender already exists with this exact same from_email. Consider using a different subdomain`
            );

            return;
          }

          notify.error(`"${error.field}" ${error.message}`);
          BugTracker.notify(error);
        });
      }

      throw errors;
    });
};
