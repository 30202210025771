import axios from 'axios';

import { firebase } from 'redux/firebase';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import { Status, Contact, List } from '../../types';
import { notify } from 'components/Notifications/HotToastNotifications';

interface RemoveListContacts {
  contactsToRemove: { id: string; email: string }[];
  totalToDelete: number;
  list: List;
  contacts: Contact[];
}

export const removeListContacts = async ({
  contactsToRemove,
  totalToDelete,
  list,
  contacts
}: RemoveListContacts): Promise<Contact[]> => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/lists/${list.id}/contacts`;
  const ids = contactsToRemove.map((c) => c.id);

  let updatedContacts: Contact[] = [];

  try {
    await axios.delete(url, {
      data: { contacts: contactsToRemove, list_id: list.id },
      headers: { token }
    });

    updatedContacts = contacts.map((c) => {
      if (!ids.includes(c.id)) return c;
      return { ...c, status: Status.Deleting };
    });

    notify.success(
      `${totalToDelete} Contact(s) scheduled to be removed from ${list.name}`
    );

    gtag.event({
      feature: 'Marketing',
      action: 'Remove Contacts From List: Success',
      message: `${totalToDelete} Contact(s) scheduled to be removed from ${list.name}`
    });
  } catch (e) {
    BugTracker.notify(e);
  } finally {
    return updatedContacts;
  }
};
