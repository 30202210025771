import { Theme } from '@material-ui/core/styles/createTheme';
import toast, {
  Toast,
  ToastPosition,
  ToastOptions,
  Toaster
} from 'react-hot-toast';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { theme } from 'theme';

/**
 * Extended notification options interface that includes
 * all react-hot-toast options plus additional custom options.
 * @interface INotificationOptions
 * @extends {Partial<ToastOptions>}
 */
interface INotificationOptions extends Partial<ToastOptions> {
  /** Duration in milliseconds the toast should remain visible */
  duration?: number;
  /** Position on the screen where the toast should appear */
  position?: ToastPosition;
  /** Maximum height of the toast message content (in pixels) */
  maxHeight?: number;
}

/**
 * Types of notification that can be displayed.
 * @typedef {'success' | 'error' | 'warning' | 'info'} TNotificationType
 */
type TNotificationType = 'success' | 'error' | 'warning' | 'info';

/**
 * Styles for the toast components using Material-UI's makeStyles.
 */
const useStyles = makeStyles((theme: Theme) => ({
  toastContainer: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: '40%',
    boxShadow: theme.shadows[3],
    transition: 'all 0.3s ease-in-out'
  },
  sidebar: {
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentArea: {
    padding: theme.spacing(1.5, 2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  messageContainer: {
    maxWidth: 'calc(100% - 40px)'
  },
  messageText: {
    fontWeight: 'bold',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    overflowY: 'auto',
    wordBreak: 'break-word'
  },
  counter: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: theme.shape.borderRadius * 3,
    padding: theme.spacing(0.5, 1),
    minWidth: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1)
  },
  counterText: {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: 'white'
  },
  toasterContainer: {
    position: 'fixed',
    zIndex: theme.zIndex.snackbar
  },
  closeButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    opacity: 0.7,
    '&:hover': {
      opacity: 1
    }
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(1)
  }
}));

/**
 * Map to store and track active toasts for each notification type.
 * This allows for grouping identical messages and showing a counter.
 * @type {Object<string, Map<string, { id: string; count: number }>>}
 */
const activeToasts = {
  success: new Map<string, { id: string; count: number }>(),
  error: new Map<string, { id: string; count: number }>(),
  warning: new Map<string, { id: string; count: number }>(),
  info: new Map<string, { id: string; count: number }>()
};

/**
 * Default configuration options for all toast notifications.
 * @type {INotificationOptions}
 */
const defaultOptions: INotificationOptions = {
  duration: 3000,
  position: 'top-right',
  maxHeight: 150 // Default maximum height for message content
};

/**
 * Styling configuration for each notification type.
 * Defines background colors, sidebar colors, and text colors.
 * @type {Record<TNotificationType, { background: string; sidebarColor: string; contentColor: string }>}
 */
const toastStyles: Record<
  TNotificationType,
  { background: string; sidebarColor: string; contentColor: string }
> = {
  success: {
    background: theme.palette.success.light,
    sidebarColor: theme.palette.success.main,
    contentColor: theme.palette.text.primary
  },
  error: {
    background: theme.palette.warning.light,
    sidebarColor: theme.palette.warning.main,
    contentColor: theme.palette.text.primary
  },
  warning: {
    background: theme.palette.warning.light,
    sidebarColor: theme.palette.warning.main,
    contentColor: theme.palette.text.primary
  },
  info: {
    background: theme.palette.info.light,
    sidebarColor: theme.palette.info.main,
    contentColor: theme.palette.text.primary
  }
};

/**
 * Icons to display for each notification type.
 * @type {Record<TNotificationType, JSX.Element>}
 */
const toastIcons = {
  success: <CheckIcon style={{ color: '#fff', fontSize: 20 }} />,
  error: <ErrorIcon style={{ color: '#fff', fontSize: 20 }} />,
  warning: <WarningIcon style={{ color: '#fff', fontSize: 20 }} />,
  info: <InfoIcon style={{ color: '#fff', fontSize: 20 }} />
};

/**
 * Component that renders a single toast notification message.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.message - The message text to display
 * @param {number} [props.count] - Number of times this message has been triggered (for grouping)
 * @param {TNotificationType} props.type - Type of notification (success, error, warning, info)
 * @param {Function} [props.onClose] - Callback function when close button is clicked
 * @param {number} [props.maxHeight] - Maximum height for the message content area
 * @param {Toast} props.t - Toast object from react-hot-toast containing state information
 * @returns {JSX.Element} The rendered toast notification
 */
const ToastMessage = ({
  message,
  count,
  type,
  onClose,
  maxHeight = defaultOptions.maxHeight,
  t
}: {
  message: string;
  count?: number;
  type: TNotificationType;
  onClose?: () => void;
  maxHeight?: number;
  t: Toast;
}) => {
  const classes = useStyles();
  const progress = t.visible ? 1 : 0;

  const animationStyle = {
    transform: `translateX(${t.visible ? 0 : 20}px)`,
    opacity: progress,
    transition: `all ${t.visible ? 0.4 : 0.3}s ${
      t.visible ? 'cubic-bezier(0.4, 0, 0.2, 1)' : 'cubic-bezier(0.4, 0, 1, 1)'
    }`
  };

  return (
    <Box className={classes.toastContainer} style={animationStyle}>
      <Box
        className={classes.sidebar}
        style={{ backgroundColor: toastStyles[type].sidebarColor }}>
        <Box className={classes.iconContainer}>{toastIcons[type]}</Box>
      </Box>
      <Box
        className={classes.contentArea}
        style={{ backgroundColor: toastStyles[type].background }}>
        <Box className={classes.messageContainer}>
          <Typography
            className={classes.messageText}
            style={{
              color: toastStyles[type].contentColor,
              maxHeight: `${maxHeight}px`,
              overflowY: 'auto',
              paddingRight: '8px'
            }}>
            {message}
          </Typography>
        </Box>
        <Box className={classes.controlsContainer}>
          {count && count > 1 && (
            <Box
              className={classes.counter}
              style={{ backgroundColor: toastStyles[type].sidebarColor }}>
              <Typography className={classes.counterText}>{count}</Typography>
            </Box>
          )}
          {onClose && (
            <CloseIcon
              className={classes.closeButton}
              fontSize="small"
              onClick={onClose}
              style={{ color: toastStyles[type].contentColor }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

/**
 * Handles creating or updating toast notifications.
 * If a notification with the same message and type already exists,
 * it will update the counter instead of creating a new notification.
 *
 * @param {TNotificationType} type - The type of notification
 * @param {string} message - The message content
 * @param {INotificationOptions} [options] - Optional configuration options
 * @returns {string} The ID of the created or updated toast
 */
const handleNotification = (
  type: TNotificationType,
  message: string,
  options?: INotificationOptions
): string => {
  const activeMap = activeToasts[type];
  const existingToast = activeMap.get(message);
  const maxHeight = options?.maxHeight || defaultOptions.maxHeight;

  if (existingToast) {
    // Update existing toast with incremented counter
    const newCount = existingToast.count + 1;
    activeMap.set(message, { ...existingToast, count: newCount });

    return toast.custom(
      (t) => (
        <ToastMessage
          message={message}
          count={newCount}
          type={type}
          maxHeight={maxHeight}
          onClose={type === 'error' ? () => toast.dismiss(t.id) : undefined}
          t={t}
        />
      ),
      {
        id: existingToast.id,
        ...defaultOptions,
        ...options,
        duration: options?.duration || defaultOptions.duration
      }
    );
  } else {
    // Create a new toast notification
    const id = toast.custom(
      (t) => (
        <ToastMessage
          message={message}
          count={1}
          type={type}
          maxHeight={maxHeight}
          onClose={type === 'error' ? () => toast.dismiss(t.id) : undefined}
          t={t}
        />
      ),
      {
        ...defaultOptions,
        ...options,
        duration:
          type === 'error'
            ? Infinity
            : options?.duration || defaultOptions.duration
      }
    );

    activeMap.set(message, { id, count: 1 });
    return id;
  }
};

/**
 * Custom Toaster component for configuring the global toast container.
 * This sets up a transparent background for toast messages and positions them.
 *
 * @component
 * @returns {JSX.Element} Configured Toaster component
 */
export const CustomToaster = () => (
  <Toaster
    position={defaultOptions.position as ToastPosition}
    toastOptions={{
      className: '',
      style: {
        background: 'transparent',
        boxShadow: 'none'
      }
    }}
  />
);

/**
 * Notification utility object with methods for different notification types.
 * Use this to trigger notifications in your application.
 *
 * @example
 * Display a success notification
 * notify.success('User profile updated successfully');
 *
 * Display an error notification with custom duration and max height
 * notify.error('Failed to save changes', { duration: 5000, maxHeight: 200 });
 *
 * @namespace
 */
export const notify = {
  /**
   * Shows a success notification.
   * @param {string} message - The message to display
   * @param {INotificationOptions} [options] - Optional configuration options
   * @returns {string} The ID of the created toast
   */
  success: (message: string, options?: INotificationOptions): string => {
    return handleNotification('success', message, options);
  },

  /**
   * Shows an error notification. Note that error notifications don't auto-dismiss
   * and require manual dismissal by the user.
   * @param {string} message - The message to display
   * @param {INotificationOptions} [options] - Optional configuration options
   * @returns {string} The ID of the created toast
   */
  error: (message: string, options?: INotificationOptions): string => {
    return handleNotification('error', message, options);
  },

  /**
   * Shows a warning notification.
   * @param {string} message - The message to display
   * @param {INotificationOptions} [options] - Optional configuration options
   * @returns {string} The ID of the created toast
   */
  warning: (message: string, options?: INotificationOptions): string => {
    return handleNotification('warning', message, options);
  },

  /**
   * Shows an info notification.
   * @param {string} message - The message to display
   * @param {INotificationOptions} [options] - Optional configuration options
   * @returns {string} The ID of the created toast
   */
  info: (message: string, options?: INotificationOptions): string => {
    return handleNotification('info', message, options);
  }
};
