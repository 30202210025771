import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  GET_RELATIONSHIPS_LIST,
  GET_RELATIONSHIPS_LIST_FOR_KEYWORD,
  GET_RELATIONSHIPS_RELATED_TO_ASSIGNED
} from 'graphql/RelationshipAPI/queries';
import { UserInstance } from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';

/**
 * RELATIONSHIPS API
 */

export type RelationShipActions =
  | 'LISTFORKEYWORD'
  | 'DELETE'
  | 'POST'
  | 'LIST_ALL'
  | 'LIST'
  | 'RELATEDTOASSIGNED'
  | 'CREATE';

interface IRelationshipInput {
  PrimaryUserInstanceId: number;
  RelatedUserInstanceId: number;
  RelatedUserPermission: TRelatedUserPermission;
  Status: number;
  Title: string;
}

export type TRelatedUserPermission = { Standard; Read; Write };
export interface RelationshipsAPIInterface {
  action: RelationShipActions;
  baseUrl: string;
  AssignedUserDefinitionId?: number;
  ProcessInstanceId?: number;
  RelatedUserDefinitionId?: number;
  UserInstanceId?: number;
  UserDefinitionId?: number;
  PrimaryUserInstanceId?: number;
  RelatedUserInstanceId?: number;
  keyword?: string | null;
  Title?: string | null;
  data?: IRelationshipInput[];
  Status?: number | null;
}

/**
 * Retrieves a list of UserInstances related to a specific keyword.
 *
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} action - Action type, expected to be 'LISTFORKEYWORD'.
 * @param {number} UserInstanceId - The UserInstanceId for initiating the request.
 * @param {string} keyword - The keyword used to filter UserInstances.
 * @returns {Promise<UserInstance[] | ApolloError>} A promise that resolves with an array of UserInstances or an ApolloError in case of failure.
 */

const GetListForKeywordRelationship = async ({
  baseUrl,
  action,
  UserInstanceId,
  keyword
}: {
  baseUrl: string;
  action: 'LISTFORKEYWORD';
  UserInstanceId: number;
  keyword: string;
}): Promise<UserInstance[] | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: GET_RELATIONSHIPS_LIST_FOR_KEYWORD,
      variables: {
        baseUrl: baseUrl,
        action,
        userInstanceId: UserInstanceId,
        keyword: keyword
      }
    });

    if (error) return new ApolloError({ errorMessage: error.message });

    return data.GetRelationships.Items as UserInstance[];
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Fetches UserInstances that have a specified relationship between assigned and related UserDefinition.
 *
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} action - Action type, expected to be 'RELATEDTOASSIGNED'.
 * @param {number} ProcessInstanceId - The ProcessInstanceId associated with the request.
 * @param {number} AssignedUserDefinitionId - The UserDefinitionId for the assigned user.
 * @param {number} RelatedUserDefinitionId - The UserDefinitionId for the related user.
 * @returns {Promise<UserInstance[] | ApolloError>} A promise that resolves with an array of UserInstances or an ApolloError in case of failure.
 */

const GetRelatedToAssignedRelationship = async ({
  baseUrl,
  action,
  ProcessInstanceId,
  AssignedUserDefinitionId,
  RelatedUserDefinitionId,
  fetchPolicy
}: {
  baseUrl: string;
  action: 'RELATEDTOASSIGNED';
  ProcessInstanceId: number;
  AssignedUserDefinitionId: number;
  RelatedUserDefinitionId: number;
  fetchPolicy?: boolean;
}): Promise<UserInstance[] | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: GET_RELATIONSHIPS_RELATED_TO_ASSIGNED,
      variables: {
        baseUrl,
        action,
        processInstanceId: ProcessInstanceId,
        assignedUserDefinitionId: AssignedUserDefinitionId,
        relatedUserDefinitionId: RelatedUserDefinitionId
      },
      fetchPolicy: fetchPolicy ? 'network-only' : 'cache-first'
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    return data.GetRelationships.Items as UserInstance[];
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Retrieves a list of UserInstances based on the UserInstancesId and UserDefinitionId.
 *
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} action - Action type, expected to be 'LIST'.
 * @param {number} UserInstanceId - The UserInstanceId initiating the request.
 * @param {number} UserDefinitionId - The UserDefinitionId used to filter UserInstances.
 * @returns {Promise<UserInstance[] | ApolloError>} A promise that resolves with an array of UserInstances or an ApolloError in case of failure.
 */

const GetRelationshipList = async ({
  baseUrl,
  action,
  UserInstanceId,
  UserDefinitionId,
  fetchPolicy
}: {
  baseUrl: string;
  action: 'LIST' | 'LIST_ALL';
  UserInstanceId: number;
  UserDefinitionId: number;
  fetchPolicy?: boolean;
}): Promise<UserInstance[] | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: GET_RELATIONSHIPS_LIST,
      variables: {
        baseUrl,
        action,
        userInstanceId: UserInstanceId,
        userDefinitionId: UserDefinitionId
      },
      fetchPolicy: fetchPolicy ? 'network-only' : 'cache-first'
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    return data.GetRelationships.Items as UserInstance[];
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

export {
  GetListForKeywordRelationship,
  GetRelatedToAssignedRelationship,
  GetRelationshipList
};
