import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PercentageField, CurrencyField } from '../Utils/inputFields';
import MainField from '../components/MainField';
import { _NetAdvance, _Commission } from 'types/calculatorInterfaces';

import { useCalculator } from '../Calculator/hooks/useCalculator';
import { Tools } from '../Calculator/interfaces';

const Commission = (tools: Tools) => {
  const { state, config, setState } = tools;
  const {
    isforAmount,
    handleChange,
    brokerCommissionAmount,
    setBrokerCommissionAmount
  } = useCalculator(tools);

  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const commissionVis = config && config.CommissionAmount.show;
  const calculate = () => null;

  const shouldOpen = () => {
    return setOpen(true);
  };

  //! First requested to Removed DEFER_VAT
  //? Removed "DEFER_VAT" Because the want it calculated on NetAdvance Not GrossAdvance
  const isGrossAdvance = state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET';

  /**
   * Unified broker commission calculation effect
   *
   * This effect handles all broker commission calculations, whether:
   * - Calculating commission amount from rate (percentage-based)
   * - Calculating rate from commission amount (fixed amount-based)
   * - Using gross or net advance as the calculation base
   *
   * It will:
   * 1. Calculate the appropriate advance amount (gross or net)
   * 2. Perform calculations based on commission type (Amount or Rate/N/A)
   * 3. Update the corresponding values (commission amount, rate, VAT)
   *
   * @param {Object} state - The current state object containing commission and advance data
   * @param {boolean} isGrossAdvance - Flag indicating whether to use gross or net advance
   * @param {string} brokerCommissionAmount - Commission type ('Amount', 'Rate', or 'N/A')
   * @param {Function} handleChange - Change handler function for updating state
   */
  useEffect(() => {
    // Calculate gross advance consistently for both scenarios
    const vatTotal: number =
      state._NetAdvance.Purchase_Vat +
      state._NetAdvance.Settlement_Vat -
      state._NetAdvance.Deposit_Vat -
      state._NetAdvance.Part_Exchange_Vat -
      state._NetAdvance.Subsidy_Vat;
    const grossAdvance = state._NetAdvance.NetAdvance + vatTotal;

    // Determine which advance amount to use
    const advanceAmount = isGrossAdvance
      ? grossAdvance
      : state._NetAdvance.NetAdvance;

    const hasValidAdvance = advanceAmount >= 1;
    if (!hasValidAdvance) return;

    const brokerRate = state._Commission.Broker_Rate;
    const brokerCommission = (brokerRate / 100) * advanceAmount;

    // Update state with rate-based calculation
    const myEventTargetObject = {
      target: {
        value: brokerCommission
      }
    };

    handleChange('Broker_Commission')(myEventTargetObject);
  }, [state._NetAdvance.NetAdvance, isGrossAdvance]);

  useEffect(() => {
    const isLease = (product: string) =>
      product.toLowerCase().startsWith('lease');
    if (!isLease(state.FinanceProduct)) {
      const myEventTargetObject = {
        target: {
          value: 0
        }
      };

      handleChange('Broker_VatRate')(myEventTargetObject);
    }
  }, [state.FinanceProduct]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MainField
          disabled={!commissionVis}
          name="Commission"
          onClick={shouldOpen}
          required={false}
          value={state._Commission.TotalPayable || state.CommissionAmount}
          valueToChange={state._Commission.Broker_Commission}
          handleChange={handleChange}
          type={'Broker_Commission'}
          calculate={calculate}
        />

        <Dialog fullWidth={fullWidth} maxWidth="md" open={open}>
          <DialogTitle>Commission</DialogTitle>
          <DialogContent>
            <Grid
              alignItems="flex-start"
              container
              direction="column"
              justifyContent="center">
              <Grid item style={{ width: '100%' }}>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={brokerCommissionAmount}
                    onChange={() =>
                      setBrokerComissionAmount(!brokerCommissionAmount)
                    }
                  />
                }
                label="Commission Type"
              /> */}

                <Grid container spacing={1}>
                  <Grid item sm={3} xs={12}>
                    <CurrencyField
                      InputLabelProps={undefined}
                      adornment={undefined}
                      calculate={calculate}
                      classes={undefined}
                      disabled={undefined}
                      error={undefined}
                      name="Broker Commission"
                      // onBlur={comissionBlur('Broker_Commission')}
                      onChange={(event) => {
                        setBrokerCommissionAmount('Amount');
                        handleChange('Broker_Commission')(event);
                      }}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      startAdornment={undefined}
                      value={state._Commission.Broker_Commission}
                      onBlur={undefined}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <PercentageField
                      InputLabelProps={undefined}
                      calculate={calculate}
                      classes={undefined}
                      // Requested By Shamila Wants Cost to Have Commission Rate
                      // disabled={isforAmount || !brokerCommissionAmount}
                      disabled={undefined}
                      name="Commission Rate %"
                      // onBlur={comissionBlur('Broker_Rate')}
                      onChange={(event) => {
                        setBrokerCommissionAmount('Rate');
                        handleChange('Broker_Rate', 'Rate')(event);
                      }}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      style={undefined}
                      type={undefined}
                      value={state._Commission.Broker_Rate}
                      onBlur={undefined}
                    />
                  </Grid>
                  {/* <div style={{ flexGrow: 1 }} /> */}
                  <Grid item sm={3} xs={12}>
                    <PercentageField
                      InputLabelProps={undefined}
                      calculate={calculate}
                      classes={undefined}
                      disabled={undefined}
                      name="VAT Rate %"
                      onBlur={undefined}
                      onChange={handleChange('Broker_VatRate')}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      style={undefined}
                      type={undefined}
                      value={state._Commission.Broker_VatRate}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <CurrencyField
                      InputLabelProps={undefined}
                      adornment={undefined}
                      calculate={undefined}
                      classes={undefined}
                      disabled
                      error={undefined}
                      name="VAT"
                      onBlur={undefined}
                      onChange={undefined}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      startAdornment={undefined}
                      value={state._Commission.Broker_Vat}
                    />
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Introducer Fee"
                        // onBlur={handleBlur(
                        //   state._Commission.Broker_Commission,
                        //   state._Commission.Introducer_Fee,
                        //   'Introducer_Fee',
                        //   // setState,
                        //   // state
                        // )}
                        onChange={handleChange('Introducer_Fee')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Introducer_Fee}
                        onBlur={undefined}
                      />
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT Rate %"
                        onBlur={undefined}
                        onChange={handleChange('Introducer_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._Commission.Introducer_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Introducer_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Funder Fee"
                        onBlur={undefined}
                        onChange={handleChange('Funder_Fee')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Funder_Fee}
                      />
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT Rate %"
                        onBlur={undefined}
                        onChange={handleChange('Funder_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._Commission.Funder_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Funder_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Partner Scheme Fee"
                        onBlur={undefined}
                        onChange={handleChange('Evo_Upsell_Fee')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Evo_Upsell_Fee}
                      />
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT Rate %"
                        onBlur={undefined}
                        onChange={handleChange('Evo_Upsell_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._Commission.Evo_Upsell_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Evo_Upsell_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="Total Payable"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.TotalPayable}
                      />
                    </Grid>

                    <div style={{ flexGrow: 1 }} />

                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="Total Payable Inc. VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.TotalPayable_IncVat}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*  <Typography>
            N.B. VAT on Commission is always included upfront regardless of VAT
            deferral choice
          </Typography>*/}
          </DialogContent>

          <DialogActions>
            {/* <Button
            color="primary"
            // onClick={() => setState(INIT_COMMISSION)}
          >
            Clear All
          </Button> */}
            <div style={{ flexGrow: 1 }} />
            {/* <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button> */}
            <Button
              data-cy="comission-done"
              color="primary"
              onClick={() => setOpen(false)}
              variant="contained">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Commission;
