import {
  SET_DRAW,
  SET_GLOBAL_HOSTNAME,
  SET_INSTANCE_SETTINGS,
  SET_SETTINGS,
  THEME_NAME,
  UPDATE_DARK_MODE,
  UPDATE_LOADING,
  UPDATED_THEME,
  SET_PUBLIC_SETTINGS,
  SET_INSTANCE_NOTICEBOARD,
  SET_INSTANCE_NOTICEBOARD_TIME,
  SET_GLOBAL_NOTICEBOARD,
  SET_GLOBAL_NOTICEBOARD_TIME,
  SET_INSTANCE_NOTICEBOARD_TITLE,
  SET_BRAND_NOTICEBOARD,
  SET_BRAND_NOTICEBOARD_TIME,
  SET_BRAND_NOTICEBOARD_TITLE
} from '../actions/types';
import { theme } from 'theme';
import { UserDefinition } from '../../types/interfaces';
import { Theme } from '@material-ui/core';

interface Location {
  hostname: string;
  baseURL: string;
  globalHostName: string;
  logo: string;
}

export interface ISettings {
  Logo: string;
  InstanceNoticeBoard: string;
  InstanceNoticeBoardTitle: string;
  InstanceNoticeBoardLastModified: string;
  GlobalNoticeBoard: string;
  GlobalNoticeBoardLastModified: string;
  BrandNoticeBoard: string;
  BrandNoticeBoardLastModified: string;
  BrandNoticeBoardTitle: string;
  SiteFooter: string;
  SupportEmail: string;
  SupportPhone: string;
  Theme: string;
  Title: string;
  UserDefinitionList: UserDefinition[];
  BrandList: string;
  PlatformInstanceId: string;
  ApiConfig: string[];
}

const emptySettings: () => ISettings = () => ({
  Logo: '',
  InstanceNoticeBoard: '',
  InstanceNoticeBoardTitle: '',
  InstanceNoticeBoardLastModified: '',
  GlobalNoticeBoard: '',
  GlobalNoticeBoardLastModified: '',
  BrandNoticeBoard: '',
  BrandNoticeBoardLastModified: '',
  BrandNoticeBoardTitle: '',
  SiteFooter: '',
  SupportEmail: '',
  SupportPhone: '',
  Theme: '',
  Title: '',
  UserDefinitionList: [] as UserDefinition[],
  BrandList: '',
  PlatformInstanceId: '',
  ApiConfig: []
});

const initialState = {
  loading: false,
  hostname: {
    hostname: '',
    baseURL: '',
    globalHostName: '',
    logo: ''
  },
  instanceSettings: {
    AdminURL: '',
    InstanceName: '',
    PrimaryColor: '0fe',
    ProcessesName: 'Deal',
    UserDefinitions: [] as UserDefinition[],
    BrandList: ''
  },
  navStatus: { isMobile: false, isOpen: true },
  settings: emptySettings() as ISettings,
  publicSettings: emptySettings() as ISettings,
  theme: theme,
  themeName: '',
  baseURL: '',
  companyNavigator: 'RedflagGRaphQL' as companyNavigatorAdaptor
};

type companyNavigatorAdaptor = 'CreditSafe' | 'RedflagOG' | 'RedflagGRaphQL';

interface SetDraw {
  type: typeof SET_DRAW;
  payload: {
    isMobile: boolean;
    isOpen: boolean;
  };
}

interface SetGlobalHostname {
  type: typeof SET_GLOBAL_HOSTNAME;
  payload: Location;
}

interface SetLoading {
  type: typeof UPDATE_LOADING;
  payload: boolean;
}

interface SetInstanceSettings {
  type: typeof SET_INSTANCE_SETTINGS;
  payload: any;
}

interface SetMode {
  type: typeof UPDATE_DARK_MODE;
  payload: 'light' | 'dark';
}

interface SetSettings {
  type: typeof SET_SETTINGS;
  payload: ISettings;
}

interface SetGlobalNoticeBoard {
  type: typeof SET_GLOBAL_NOTICEBOARD;
  payload: string;
}

interface SetGlobalNoticeBoardLastModified {
  type: typeof SET_GLOBAL_NOTICEBOARD_TIME;
  payload: string;
}

interface SetInstanceNoticeBoard {
  type: typeof SET_INSTANCE_NOTICEBOARD;
  payload: string;
}

interface SetInstanceNoticeBoardTitle {
  type: typeof SET_INSTANCE_NOTICEBOARD_TITLE;
  payload: string;
}

interface SetInstanceNoticeBoardLastModified {
  type: typeof SET_INSTANCE_NOTICEBOARD_TIME;
  payload: string;
}

interface SetBrandNoticeBoard {
  type: typeof SET_BRAND_NOTICEBOARD;
  payload: string;
}

interface SetBrandNoticeBoardLastModified {
  type: typeof SET_BRAND_NOTICEBOARD_TIME;
  payload: string;
}

interface SetBrandNoticeBoardTitle {
  type: typeof SET_BRAND_NOTICEBOARD_TITLE;
  payload: string;
}

interface SetTheme {
  type: typeof UPDATED_THEME;
  payload: Theme;
}

interface SetThemeName {
  type: typeof THEME_NAME;
  payload: 'AFS' | string;
}

interface SetPublicSettings {
  type: typeof SET_PUBLIC_SETTINGS;
  payload: any; // TODO Define Type
}

type ConfigAction =
  | SetDraw
  | SetGlobalHostname
  | SetInstanceSettings
  | SetLoading
  | SetMode
  | SetSettings
  | SetTheme
  | SetThemeName
  | SetPublicSettings
  | SetInstanceNoticeBoard
  | SetInstanceNoticeBoardLastModified
  | SetGlobalNoticeBoard
  | SetGlobalNoticeBoardLastModified
  | SetInstanceNoticeBoardTitle
  | SetBrandNoticeBoard
  | SetBrandNoticeBoardLastModified
  | SetBrandNoticeBoardTitle;

const configReducer = (state = initialState, action: ConfigAction) => {
  switch (action.type) {
    case SET_PUBLIC_SETTINGS:
      return { ...state, publicSettings: action.payload };
    case SET_DRAW:
      return { ...state, navStatus: action.payload };
    case SET_GLOBAL_HOSTNAME:
      return {
        ...state,
        hostname: action.payload,
        baseURL: action.payload['baseURL']
      };
    case SET_INSTANCE_SETTINGS:
      return { ...state, instanceSettings: action.payload };
    case SET_SETTINGS:
      return { ...state, settings: action.payload };

    case SET_GLOBAL_NOTICEBOARD: {
      return {
        ...state,
        settings: {
          ...state.settings,
          GlobalNoticeBoard: action.payload
        }
      };
    }

    case SET_GLOBAL_NOTICEBOARD_TIME: {
      return {
        ...state,
        settings: {
          ...state.settings,
          GlobalNoticeBoardLastModified: action.payload
        }
      };
    }

    case SET_INSTANCE_NOTICEBOARD: {
      return {
        ...state,
        settings: {
          ...state.settings,
          InstanceNoticeBoard: action.payload
        }
      };
    }

    case SET_INSTANCE_NOTICEBOARD_TITLE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          InstanceNoticeBoardTitle: action.payload
        }
      };
    }

    case SET_INSTANCE_NOTICEBOARD_TIME: {
      return {
        ...state,
        settings: {
          ...state.settings,
          InstanceNoticeBoardLastModified: action.payload
        }
      };
    }

    case SET_BRAND_NOTICEBOARD: {
      return {
        ...state,
        settings: {
          ...state.settings,
          BrandNoticeBoard: action.payload
        }
      };
    }

    case SET_BRAND_NOTICEBOARD_TIME: {
      return {
        ...state,
        settings: {
          ...state.settings,
          BrandNoticeBoardLastModified: action.payload
        }
      };
    }

    case SET_BRAND_NOTICEBOARD_TITLE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          BrandNoticeBoardTitle: action.payload
        }
      };
    }

    case THEME_NAME:
      return { ...state, themeName: action.payload };
    case UPDATE_DARK_MODE:
      return {
        ...state,
        theme: {
          ...state.theme,
          palette: {
            ...state.theme.palette,
            type: action.payload
          }
        }
      };
    case UPDATE_LOADING:
      return { ...state, loading: action.payload };
    case UPDATED_THEME:
      return {
        ...state,
        theme: {
          ...state.theme,
          palette: action.payload.palette,
          typography: action.payload.typography,
          overrides: action.payload.overrides
        }
      };
    default:
      return state;
  }
};

export default configReducer;
