import React, { createContext, useContext } from 'react';
import { TEventType } from 'components/Calendar/interface';

interface CalendarConfig {
  allowedEventTypes?: TEventType[];
}

const CalendarConfigContext = createContext<CalendarConfig>({});

export const useCalendarConfig = () => useContext(CalendarConfigContext);

export default CalendarConfigContext;
