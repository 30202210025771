import React from 'react';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton,
  Button,
  Box
} from '@material-ui/core';
import { ExpandMore, Warning, Error, Delete, Add } from '@material-ui/icons';
import { Control, Path, useFieldArray, UseFormSetValue } from 'react-hook-form';
import {
  ELenderAPIType,
  IProposal,
  IStructuredField,
  TLenderAPIInterfaces
} from '../interface';
import {
  generateLabel,
  isStructuredField,
  countRequiredFields,
  determineFieldRequirement
} from '../functions';
import { computeHiddenFields } from '../helper';
import RenderField from './ProposalField';
import { theme } from 'theme';
import { useProposalFormStyles } from './styles';
import useFieldValidation from '../hooks/useFieldValidation';
import { FbFileRef } from 'types/interfaces';
import SimplifiedDocumentCard from 'components/FileStorage/components/SimplifiedDocumentCard';

const RenderSectionContent = ({
  proposal,
  fieldName,
  fieldData,
  control,
  setValue,
  financeParentValue,
  hiddenFields
}: {
  proposal: IProposal;
  fieldName: string;
  fieldData: TLenderAPIInterfaces;
  control: Control<TLenderAPIInterfaces, object>;
  setValue: UseFormSetValue<TLenderAPIInterfaces>;
  financeParentValue: string;
  hiddenFields: Set<string>;
}) => {
  const classes = useProposalFormStyles();

  const fieldArrays = Object.entries(fieldData).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = useFieldArray({
        control,
        name: `${fieldName}.${key}` as any
      });
    }
    return acc;
  }, {} as Record<string, ReturnType<typeof useFieldArray>>);

  // Helper function to normalize path to array bracket notation
  const normalizePathToBrackets = (path: string) => {
    return path.replace(/\.(\d+)\./g, '[$1].');
  };

  // Helper function to normalize path to dot notation
  const normalizePathToDots = (path: string) => {
    return path.replace(/\[(\d+)\]/g, '.$1.');
  };

  // Helper function to check if a field path should be hidden
  const isFieldHidden = (path: string) => {
    const normalizedPath = normalizePathToBrackets(path);

    return Array.from(hiddenFields).some((hiddenPath) => {
      const normalizedHiddenPath = normalizePathToBrackets(hiddenPath);
      return (
        normalizedPath === normalizedHiddenPath ||
        normalizedPath.startsWith(normalizedHiddenPath + '.') ||
        normalizedPath.startsWith(normalizedHiddenPath + '[')
      );
    });
  };

  const renderContent = () => {
    const simpleFields: React.ReactNode[] = [];
    const arrayFields: React.ReactNode[] = [];
    const objectFields: React.ReactNode[] = [];
    const addButtons: React.ReactNode[] = [];

    Object.entries(fieldData).forEach(([key, value]) => {
      const fullFieldName = `${fieldName}.${key}` as Path<TLenderAPIInterfaces>;

      if (isStructuredField(value)) {
        // Hidden Fields from Array
        if (isFieldHidden(fullFieldName)) return;

        const isRequired = determineFieldRequirement(value, financeParentValue);
        simpleFields.push(
          <div style={{ width: '100%' }} key={fullFieldName}>
            <RenderField
              name={fullFieldName}
              control={control}
              structuredField={value}
              watchedFields={fieldData}
              isRequired={isRequired}
            />
          </div>
        );
      } else if (Array.isArray(value)) {
        const { fields, remove, append } = fieldArrays[key];
        const canAddMore = hasAddMoreType(value, true);
        const childKey = value[0] ? Object.keys(value[0])[0] : key;

        arrayFields.push(
          <div key={fullFieldName} className={classes.arraySection}>
            {fields.map((field, index) => {
              const itemValue = canAddMore
                ? value[index][childKey]
                : value[index];

              if (hiddenFields.has(fullFieldName)) return;

              // Build the base path for this array item
              const baseArrayPath = canAddMore
                ? `${fullFieldName}[${index}].${childKey}`
                : `${fullFieldName}[${index}]`;

              const { total, completed, errors } = countRequiredFields({
                data: itemValue,
                hiddenFields,
                financeParentValue
              });

              const canDelete = () => {
                const currentCount = fields.length;
                const requirements = proposal.requirements;

                const minRequirements = {
                  directors: requirements.minDirectors,
                  shareholders: requirements.minShareholders,
                  assets: requirements.minAssets,
                  assetDetails: requirements.minAssets,
                  addressDetails: requirements.minAddress,
                  applicants: requirements.minApplicants,
                  contacts: requirements.minContacts
                };

                const minRequired = minRequirements[key];
                return !minRequired || currentCount > minRequired;
              };

              return (
                <Accordion key={field.id} className={classes.arrayItem}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <div className={classes.arrayItemHeader}>
                      <Typography className={classes.heading}>
                        <RequiredFieldsWarning
                          total={total}
                          completed={completed}
                          errors={errors}
                        />
                        {`${generateLabel(canAddMore ? childKey : key)} ${
                          index + 1
                        }`}
                      </Typography>
                      {!proposal?.isImported && (
                        <Tooltip
                          title={
                            canDelete()
                              ? 'Delete'
                              : 'Cannot Delete Due To Minimum Requirement'
                          }>
                          <span>
                            <IconButton
                              size="small"
                              className={classes.deleteButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (canDelete()) {
                                  remove(index);
                                  const updatedValue = [...value];
                                  updatedValue.splice(index, 1);
                                  setValue(fullFieldName, updatedValue as any);
                                }
                              }}
                              disabled={!canDelete()}>
                              <Delete />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.arrayItemContent}>
                    <RenderSectionContent
                      fieldName={baseArrayPath}
                      fieldData={itemValue}
                      control={control}
                      setValue={setValue}
                      proposal={proposal}
                      financeParentValue={financeParentValue}
                      hiddenFields={hiddenFields}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        );

        if (canAddMore && !proposal?.isImported) {
          addButtons.push(
            <div key={`add-${key}`} style={{ padding: theme.spacing(1) }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Add />}
                onClick={() => {
                  const lastItem = value[value.length - 1];
                  const newItem = {
                    [childKey]: JSON.parse(JSON.stringify(lastItem[childKey]))
                  };

                  const resetValues = (obj: any) => {
                    Object.keys(obj).forEach((key) => {
                      if (obj[key]?.value !== undefined) {
                        obj[key].value = null;
                        obj[key].error = null;
                      } else if (
                        typeof obj[key] === 'object' &&
                        obj[key] !== null
                      ) {
                        resetValues(obj[key]);
                      }
                    });
                  };

                  resetValues(newItem[childKey]);
                  append(newItem);

                  const updatedValue = [...value, newItem];
                  setValue(fullFieldName, updatedValue as any);
                }}>
                Add {generateLabel(childKey)}
              </Button>
            </div>
          );
        }
      } else if (typeof value === 'object' && value !== null) {
        if (isFieldHidden(fullFieldName)) return;

        const { total, completed, errors } = countRequiredFields({
          data: value,
          hiddenFields,
          financeParentValue
        });

        objectFields.push(
          <div
            style={{ paddingBottom: theme.spacing(0.5) }}
            key={fullFieldName}>
            <div className={classes.objectSection}>
              <Typography className={classes.heading}>
                <RequiredFieldsWarning
                  total={total}
                  completed={completed}
                  errors={errors}
                />
                {`${generateLabel(key)}`}
              </Typography>
              <RenderSectionContent
                fieldName={fullFieldName}
                fieldData={value as TLenderAPIInterfaces}
                control={control}
                setValue={setValue}
                proposal={proposal}
                financeParentValue={financeParentValue}
                hiddenFields={hiddenFields}
              />
            </div>
          </div>
        );
      }
    });

    const calculateGridSize = (fieldCount: number) => {
      if (fieldCount === 1) return 12; // One field takes full width
      if (fieldCount === 2) return 6; // Two fields take half width each
      if (fieldCount === 3) return 4; // Three fields take one-third width each
      return 4; // Default to one-third width for 3+ fields
    };

    return (
      <>
        <Grid container spacing={2}>
          {simpleFields.map((field, index) => (
            <Grid
              item
              xs={12}
              sm={calculateGridSize(simpleFields.length)}
              key={index}>
              {field}
            </Grid>
          ))}
        </Grid>
        {objectFields}
        {arrayFields}
        {addButtons.length > 0 && <Box display="flex">{addButtons}</Box>}
      </>
    );
  };

  return <div className={classes.content}>{renderContent()}</div>;
};

const ProposalForm = ({
  proposal,
  control,
  setValue,
  files
}: {
  proposal: IProposal;
  control: Control<TLenderAPIInterfaces, object>;
  setValue: UseFormSetValue<TLenderAPIInterfaces>;
  files: FbFileRef[];
}) => {
  const classes = useProposalFormStyles();
  const { watchedForm, financeParentValue } = useFieldValidation(
    control,
    proposal
  );

  const hiddenFields = computeHiddenFields(watchedForm, proposal);
  const renderField = (sectionName: string, sectionData: any) => {
    if (Array.isArray(sectionData)) {
      const { total, completed, errors } = countRequiredFields({
        data: sectionData,
        hiddenFields,
        financeParentValue
      });

      return (
        <Accordion key={sectionName} className={classes.accordion}>
          <AccordionSummary
            expandIcon={
              <Tooltip title="More detail">
                <ExpandMore />
              </Tooltip>
            }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RequiredFieldsWarning
                total={total}
                completed={completed}
                errors={errors}
              />
              <Typography className={classes.heading}>
                {generateLabel(sectionName)}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {(() => {
              const { fields, append, remove } = useFieldArray({
                control,
                name: sectionName as any
              });

              return (
                <div className={classes.arraySection}>
                  {fields.map((field, index) => {
                    const itemData = sectionData[index] || {};
                    const {
                      total: itemTotal,
                      completed: itemCompleted,
                      errors: itemErrors
                    } = countRequiredFields({
                      data: itemData,
                      hiddenFields,
                      financeParentValue
                    });

                    const canDelete = () => {
                      const currentCount = fields.length;
                      const requirements = proposal.requirements || {};
                      const minRequired =
                        requirements[
                          `min${
                            sectionName.charAt(0).toUpperCase() +
                            sectionName.slice(1)
                          }`
                        ];
                      return !minRequired || currentCount > minRequired;
                    };

                    return (
                      <Accordion key={field.id} className={classes.arrayItem}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <div className={classes.arrayItemHeader}>
                            <Typography className={classes.heading}>
                              <RequiredFieldsWarning
                                total={itemTotal}
                                completed={itemCompleted}
                                errors={itemErrors}
                              />
                              {`${generateLabel(sectionName)} ${index + 1}`}
                            </Typography>
                            {!proposal?.isImported && (
                              <Tooltip
                                title={
                                  canDelete()
                                    ? 'Delete'
                                    : 'Cannot Delete Due To Minimum Requirement'
                                }>
                                <span>
                                  <IconButton
                                    size="small"
                                    className={classes.deleteButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (canDelete()) {
                                        remove(index);
                                      }
                                    }}
                                    disabled={!canDelete()}>
                                    <Delete />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.arrayItemContent}>
                          <RenderSectionContent
                            fieldName={`${sectionName}[${index}]`}
                            fieldData={itemData}
                            control={control}
                            setValue={setValue}
                            proposal={proposal}
                            financeParentValue={financeParentValue}
                            hiddenFields={hiddenFields}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              );
            })()}
          </AccordionDetails>
        </Accordion>
      );
    }

    const { total, completed, errors } = countRequiredFields({
      data: sectionData,
      hiddenFields,
      financeParentValue
    });

    if (
      typeof sectionData === 'object' &&
      sectionData !== null &&
      !('value' in sectionData)
    ) {
      return (
        <Accordion key={sectionName} className={classes.accordion}>
          <AccordionSummary
            expandIcon={
              <Tooltip title="More detail">
                <ExpandMore />
              </Tooltip>
            }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RequiredFieldsWarning
                total={total}
                completed={completed}
                errors={errors}
              />
              <Typography className={classes.heading}>
                {generateLabel(sectionName)}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <RenderSectionContent
              proposal={proposal}
              fieldName={sectionName}
              fieldData={sectionData}
              control={control}
              setValue={setValue}
              financeParentValue={financeParentValue}
              hiddenFields={hiddenFields}
            />
          </AccordionDetails>
        </Accordion>
      );
    } else {
      const fullName = sectionName as Path<TLenderAPIInterfaces>;
      const isTestField = sectionName.toLowerCase() === 'is_test';
      if (isTestField) return <div key={sectionName} />;

      const isRequired = determineFieldRequirement(
        sectionData,
        financeParentValue
      );

      return (
        <div key={sectionName} style={{ paddingBottom: theme.spacing(1) }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: theme.spacing(0.5)
            }}>
            <RequiredFieldsWarning
              total={total}
              completed={completed}
              errors={errors}
            />
          </div>
          <RenderField
            name={fullName}
            control={control}
            structuredField={sectionData as IStructuredField}
            watchedFields={watchedForm}
            isRequired={isRequired}
          />
        </div>
      );
    }
  };

  const accordions: React.ReactNode[] = [];
  const otherFields: React.ReactNode[] = [];

  Object.entries(watchedForm).forEach(([sectionName, sectionData]) => {
    const renderedField = renderField(sectionName, sectionData);
    if (
      Array.isArray(sectionData) ||
      (typeof sectionData === 'object' &&
        sectionData !== null &&
        !('value' in sectionData))
    ) {
      accordions.push(renderedField);
    } else {
      otherFields.push(renderedField);
    }
  });

  return (
    <form className={classes.root}>
      {files.map((file: FbFileRef, index: number) => {
        if (!file.fileId) return <div key={index} />;
        return (
          <div key={index} style={{ paddingBottom: theme.spacing(1) }}>
            <SimplifiedDocumentCard item={file} proposalForm />
          </div>
        );
      })}
      {otherFields}
      {accordions}
    </form>
  );
};

export default ProposalForm;

const RequiredFieldsWarning = ({
  total,
  completed,
  errors
}: {
  total: number;
  completed: number;
  errors: number;
}) => {
  const classes = useProposalFormStyles();
  const remaining = total - completed;

  if (remaining <= 0 && errors === 0) return null;

  let text = '';
  let icon;

  if (errors > 0) {
    text = `${errors} ${errors === 1 ? 'Field' : 'Fields'} with ${
      errors === 1 ? 'Error' : 'Errors'
    }`;
    icon = <Error className={classes.errorIcon} />;
  } else if (remaining > 0) {
    text = `${remaining} Required ${
      remaining === 1 ? 'Field' : 'Fields'
    } Remaining`;
    icon = <Warning className={classes.warningIcon} />;
  }

  return (
    <Typography component="span" className={classes.warningContainer}>
      <span className={errors > 0 ? classes.errorText : classes.warningText}>
        {text}
      </span>
      <span className={classes.iconContainer}>({icon})</span>
    </Typography>
  );
};

const hasAddMoreType = (obj: any, checkParent = false): boolean => {
  if (!obj) return false;

  // For parent objects like 'attachments', check their immediate children
  if (checkParent) {
    const firstChild = Array.isArray(obj) && obj[0];
    if (firstChild) {
      // Get the first key of the child object (e.g., 'attachment', 'note')
      const childKey = Object.keys(firstChild)[0];
      return hasAddMoreType(firstChild[childKey], false);
    }
    return false;
  }

  // Check direct FieldDefinition
  if (obj.FieldDefinition?.type) {
    const types = Array.isArray(obj.FieldDefinition.type)
      ? obj.FieldDefinition.type
      : [obj.FieldDefinition.type];
    if (types.includes(ELenderAPIType.AddMore)) {
      return true;
    }
  }

  // Recursively check nested objects
  if (typeof obj === 'object') {
    return Object.values(obj).some(
      (value) =>
        typeof value === 'object' &&
        value !== null &&
        hasAddMoreType(value, false)
    );
  }

  return false;
};
