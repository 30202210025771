import React from 'react';
import { SubmitRule } from 'redux/database';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';
import * as gtag from 'Utils/gtag';

import { IUseAuditLog, saveAuditLog } from 'hooks/useDeals/useAuditLog';
import firebase from 'firebase';
import RuleConfirmation from './components/RuleConfirmation';

// Lender connector API
import { usePostToNewWindow } from 'hooks/usePostToNewWindow';
import { notify } from 'components/Notifications/HotToastNotifications';

export const extractGroupName = (
  description: string | undefined
): string | undefined => {
  if (!description || typeof description !== 'string') {
    return undefined;
  }

  const parts = description.split(',');
  const groupPart = parts.find((part) => part.trim().startsWith('group#'));

  return groupPart ? groupPart.split('#')[1] : undefined;
};

export default ({
  open,
  handleClose,
  actions,
  selectedKey,
  refreshDealData,
  InteractiveRuleDict
}: {
  open: boolean;
  handleClose: () => void;
  actions: {};
  selectedKey: number;
  refreshDealData: ({ activeStep, move }) => void;
  InteractiveRuleDict: {};
}) => {
  const postToNewWindow = usePostToNewWindow();
  const dispatch = useDispatch();
  const { currentDeal, currentProcess } = useTypedSelector((s) => s.process);
  const { user } = useTypedSelector((s) => s.user);
  const [loading, setLoading] = React.useState(false);
  const dealSummary = useTypedSelector((s) => s.dealSummary.deal);
  const loggedInUser = useTypedSelector((state) => state.user.user);
  const token = useTypedSelector((s) => s.user.auth.token);

  const updateLenderDecisionIds = [1285, 1460, 1461, 1462, 1463, 1464, 1636];
  const updateStatus: number[] = [...updateLenderDecisionIds];

  const dispatchUpdateLenderDecision = async () => {
    const isAdmin = user.SystemAccess >= 12;
    const dealOwner =
      currentDeal.ProcessInstance.UserInstanceId === loggedInUser.Id;

    if (isAdmin && !dealOwner) {
      const adminName = loggedInUser.Title;
      const notificationMessage = `Lender Decision for Deal Id (${currentDeal.ProcessInstance.Id}) has been updated by ${adminName}.`;
      const notification = {
        ProcessInstanceTitle: currentDeal.ProcessInstance.Title,
        id: '',
        msg: notificationMessage,
        pdid: currentDeal.ProcessInstance.ProcessDefinitionId,
        piid: currentDeal.ProcessInstance.Id,
        psdid: currentDeal.ProcessInstance.ProcessStepDefinitionId,
        read: false,
        ts: firebase.firestore.FieldValue.serverTimestamp(),
        type: 'deal'
      };

      try {
        const dealOwnerId = currentDeal.ProcessInstance.UserInstanceId;
        await firebase
          .firestore()
          .collection('userAccount')
          .doc(dealOwnerId.toString())
          .collection('notifications')
          .add(notification);
      } catch (e) {
        console.error('Error adding notification: ', e);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const RuleId = selectedKey;
    const ProcessInstanceId = currentDeal.ProcessInstance.Id;
    const res = await SubmitRule({ RuleId, ProcessInstanceId });

    if (res.status === 200) {
      setLoading(false);
      handleClose();
      if (InteractiveRuleDict[selectedKey] !== undefined) {
        const title = InteractiveRuleDict[selectedKey].Title;
        notify.success(`${title} Success`);

        const move2 = InteractiveRuleDict[selectedKey].ActionValue;
        let move = false;
        if (
          InteractiveRuleDict[selectedKey].ActionFunction ===
          'Change process step'
        )
          move = true;
        dispatch({
          type: 'SET_CURRENT_STEP_ID',
          payload: move2
        });

        if (refreshDealData) refreshDealData({ activeStep: move2, move }); // Refresh Deal Data ...
      }
      let receiverId: string | number = user.Id;
      const extractedName = extractGroupName(
        InteractiveRuleDict[selectedKey].Description
      );

      if (extractedName === 'Customer') {
        if (dealSummary?.primaryContact) {
          receiverId = dealSummary?.primaryContact?.Id;
        }
      }
      if (extractedName === 'Lender') {
        if (dealSummary?.lenders && dealSummary?.lenders?.selected) {
          receiverId = Object.keys(dealSummary?.lenders?.selected ?? '')[0];
        }
      }
      if (extractedName === 'Accounts') {
        if (dealSummary?.accounts && dealSummary?.accounts?.all) {
          receiverId = Object.keys(dealSummary?.accounts?.all ?? '')[0];
        }
      }

      const customPayload: IUseAuditLog = {
        actionFunction: InteractiveRuleDict[selectedKey].ActionFunction,
        payload: InteractiveRuleDict[selectedKey].ActionValue,
        firebaseStatus: 'Sent',
        action: InteractiveRuleDict[selectedKey].Title,
        timestamp: new Date(),
        userInstanceId: receiverId,
        userInstanceEmail: user.UserInstanceEmail,
        dealId: currentDeal.ProcessInstance.Id,
        ProcessDefinitionId: currentProcess.ProcessDefinition.Id
      };

      const firebaseAuditLog = await saveAuditLog(customPayload);
      if (firebaseAuditLog) {
        gtag.event({
          feature: 'Message Hub',
          action: 'System Send',
          message: `Action ${InteractiveRuleDict[selectedKey].Title}`
        });
      }

      if (updateStatus.includes(RuleId)) {
        dispatchUpdateLenderDecision();
      }

      // Post data to cosnetics API connector v1.0
      if (res.headers.nexturl)
        postToNewWindow(
          res.headers.nexturl,
          res.data,
          currentDeal.ProcessInstance.Id,
          token
        );
    } else {
      setLoading(false);
    }
  };

  const action: any = Object.values(actions).find(
    (action: any) => action.Id === selectedKey
  );

  const isMandatory = action?.Instruction.toLowerCase().includes('mandatory');

  if (!action) return <div />;
  return (
    <RuleConfirmation
      open={open}
      handleClose={handleClose}
      action={action}
      onSubmit={handleSubmit}
      loading={loading}
      ruleId={selectedKey}
    />
  );
};
