import React, { useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import { ReactComponent as ReactLogo } from 'assets/Icons/svg/calculator.svg';
import { FieldDefinition, CompleteObjectInstance } from 'types/interfaces';
import { useObject } from 'hooks/useObject';
import { CommissionSortUI } from './components/CommissionSortUI';
import { useProcess } from 'hooks/useProcess';
import { getObjectInstanceDocument } from 'redux/database';
import { StepperContext } from 'components/Stepper/context';
import {
  CalculatorObjectLayout,
  IndividualCalculator
} from './components/CalculatorObject';
import { IProps } from './interfaces';
import { animated, useSpring } from 'react-spring';
import QuoteObjectProvider, {
  QuoteObjectContext
} from './context/QuoteObjectContext';
import QuoteCreationProvider, {
  QuoteCreationContext
} from './context/QuoteCreationContext';
import { Alert, AlertTitle } from '@material-ui/lab';
import isPreviewMode from '../helper/previewMode';
import HelpButton from 'components/Help/HelpButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.primary.main}`,
    opacity: 0.5,
    transition: 'all 0.3s ease-in-out',
    color: 'white',
    '&:hover': {
      opacity: 1,
      borderRight: `50px solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.light,
      cursor: 'pointer',
      '& > div > div': {
        color: theme.palette.primary.main,
        background: 'transparent'
      }
    }
  },
  calculatorOutside: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.main
  },
  calctextbtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textColour: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  displayBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.contrastText
  },
  displayBoxMetric: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  commissionIndex: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    color: 'white',
    height: 38,
    padding: '0 10px',
    margin: 1
  },
  display: {
    display: 'flex',
    justifyContent: 'space-around',
    background: theme.palette.primary.main,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  }
}));

const ParentCalculatorObject = ({ props }: { props: IProps }) => {
  return (
    <QuoteCreationProvider props={props}>
      <QuoteObjectProvider props={props}>
        <CalculatorObject props={props} />
      </QuoteObjectProvider>
    </QuoteCreationProvider>
  );
};

const CalculatorObject = ({ props }: { props: IProps }) => {
  const {
    openBooleans,
    setOpenBooleans,
    canAddMore,
    SortByLastModified,
    objectsToShow
  } = useContext(QuoteCreationContext);

  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const { springProps, newObjectRef } = useContext(QuoteObjectContext);

  const { currentDeal, user, stepdefdict, landingpage } = useProcess();
  const classes = useStyles();

  //* isPreview will only work if the magic link has been injected with preview.
  const isPreview = isPreviewMode(window.location.href, landingpage);

  //? IsDealClosed is hiding "CommissionSortUI" & "Adding More Quotes" Button.
  //? ObjectsToShow or SortByLastModified is empty then show Alert.

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {!landingpage && objectsToShow.length === 0 && (
        <Alert severity="warning">
          <AlertTitle>
            {dealCondition
              ? `No ${props.ObjectDefinition.Title} added`
              : isPreview
              ? 'Landing Page Preview Mode'
              : 'No Quotes Found'}
          </AlertTitle>
          <Typography variant="h6" color="textSecondary">
            {dealCondition
              ? `Please note that this deal is ${
                  isDealTransferred ? 'transferred' : 'closed'
                }. You cannot add ${props.ObjectDefinition.Title}.`
              : isPreview
              ? `Please note that this Landing Page is in Preview mode. You cannot add ${props.ObjectDefinition.Title} at this time.`
              : 'Please add a new quote to continue with the process.'}
          </Typography>
        </Alert>
      )}

      {!dealCondition && (
        <>
          <CommissionSortUI
            openBooleans={openBooleans}
            setOpenBooleans={setOpenBooleans}
          />
          <HelpButton
            objectId={props.ObjectDefinition.Id}
            objectTitle={props.ObjectDefinition.Title}
          />
          {props.display === 'systemView' && stepdefdict && canAddMore && (
            <div data-cy="create-new-quote-btn" className={classes.root}>
              <AddFieldAndObjectButton
                Fields={Object.values(props.FieldDefinitionDict)}
                ObjectDefinition={props.ObjectDefinition}
                ProcessInstance={currentDeal.ProcessInstance}
                ProcessInstanceId={currentDeal.ProcessInstance.Id}
                ProcessStepSelectionType={
                  stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
                }
                UserInstance={user}
                label="Create New Quote">
                <CreateCalculatorButton />
              </AddFieldAndObjectButton>
            </div>
          )}
          {console.log('props.objectDefinition', props.ObjectDefinition.Id)}
          <Grid container>
            {Object.values(SortByLastModified).map(
              (object: CompleteObjectInstance) => (
                <Grid
                  item
                  key={object.ObjectInstance.Id}
                  style={{ padding: 8 }}
                  xs={props.display === 'clientView' ? 6 : 12}>
                  <CalculatorObjectLayout
                    readOnly={dealCondition}
                    CompleteObjectInstance={object}
                    props={props}>
                    <IndividualCalculator
                      {...props}
                      readOnly={dealCondition}
                      CompleteObjectInstanceDict={[]}
                      ObjectInstance={object}
                      ProcessInstance={currentDeal.ProcessInstance}
                      ProcessInstanceId={
                        object.ObjectInstance.ProcessInstanceId
                      }
                      index={-1}
                      key={object.ObjectInstance.Id}
                    />
                  </CalculatorObjectLayout>
                </Grid>
              )
            )}
          </Grid>
        </>
      )}

      <animated.div style={springProps}>
        <Grid container>
          {Object.values(objectsToShow).map(
            (object: CompleteObjectInstance) => (
              <div
                ref={newObjectRef}
                key={object.ObjectInstance.Id}
                style={{ width: '100%' }}>
                <CalculatorObjectLayout
                  readOnly={dealCondition}
                  CompleteObjectInstance={object}
                  props={props}>
                  <IndividualCalculator
                    {...props}
                    readOnly={dealCondition}
                    CompleteObjectInstanceDict={[]}
                    ObjectInstance={object}
                    ProcessInstance={currentDeal.ProcessInstance}
                    ProcessInstanceId={object.ObjectInstance.ProcessInstanceId}
                    index={object.ObjectInstance.ItemOrder}
                    key={object.ObjectInstance.Id}
                  />
                </CalculatorObjectLayout>
              </div>
            )
          )}
        </Grid>
      </animated.div>
    </div>
  );
};

export default ParentCalculatorObject;

const CreateCalculatorButton = () => {
  const classes = useStyles();
  return (
    <div className={classes.calculatorOutside}>
      <ReactLogo
        fill="#D8D8D8"
        height="24"
        style={{ marginRight: 15 }}
        width="24"
      />
      <div className={classes.calctextbtn}>
        <p>+ Create New Quote</p>
      </div>
    </div>
  );
};
