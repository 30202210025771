/**user summary component */
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';
import UserForm from './UserForm';
import { useStyles } from './styles';
import {
  UserInstance,
  CompleteUserInstance,
  CompleteObjectDefinition,
  CompleteObjectInstance
} from 'types/interfaces';
import { Grid } from '@material-ui/core';
import DetailsForm from './DetailsForm';
import DoNotDeal from 'components/DoNotDeal';
import { BugTracker } from 'Utils/Bugtracker';

// Permission Logic for updating a user
import { determineIfUserCanEdit } from 'helpers/permissions';

// useQuery test imports
import { useQuery } from '@apollo/client';
import { GET_COMPLETE_USER_INSTANCE_DETAIL } from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { apolloClient } from 'graphql/apolloClient';
import { TextSlide } from 'common/Animations';
import { getFieldInstanceValue } from 'Utils';

const companyTypes = [
  'Limited Company',
  'PLC',
  'Limited Liability Partnership'
];

const User = ({
  data,
  getData,
  editable = true,
  CODL,
  CompleteObjectInstanceList,
  showOnlyHistoryButton = false
}: {
  data: UserInstance;
  getData?: () => void;
  editable?: boolean;
  entityType?: boolean;
  CODL?: CompleteObjectDefinition[];
  CompleteObjectInstanceList?: CompleteObjectInstance[];
  showOnlyHistoryButton?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const loggedInUser = useTypedSelector((s) => s.user.user);
  let canEditUser = determineIfUserCanEdit({
    loggedInUser,
    targetUser: data
  });
  const UserDefinitionId = data?.UserDefinitionId;
  const isBroker = UserDefinitionId === 522;

  // Implementing Apollo useQuery instead of useEffect
  const {
    data: cachedUser,
    error,
    loading
  } = useQuery(GET_COMPLETE_USER_INSTANCE_DETAIL, {
    variables: { baseUrl, userInstanceId: parseInt(data?.Id.toString()) },
    client: apolloClient,
    skip: !data?.Id, // Skip if no Id
    onError: (error) => {
      BugTracker.notify(
        'src/components/User/Profile, Failed to fetch user details:',
        error
      );
    }
  });
  const user = cachedUser?.GetUserInstanceDetail?.UserInstance || data || null;
  // END

  // Slide effect for the secondary text
  const secondaryText = [
    user?.UserInstanceEmail?.split('-')[0] || 'Email Address',
    user?.TelephoneNumber?.split('-')[0] || '+44 (0) XXXX XXX XXX'
  ];

  const { opacity, currentIndex, transform } = TextSlide(secondaryText);
  // END

  if (!data) return <div className={classes.outsideroot}>No data</div>;

  const getEntityType = (): string | undefined => {
    if (!CODL || !CompleteObjectInstanceList) return undefined;
    const entityType = getFieldInstanceValue({
      CODL,
      COIL: CompleteObjectInstanceList,
      DefinitionTitle: 'Entity',
      FieldTitle: 'Entity Type'
    });

    return entityType;
  };

  const isEntityType = getEntityType();
  const isCompany = companyTypes.includes(isEntityType || '');
  const shouldRenderDoNotDeal = !isCompany;

  return (
    <div className={classes.container}>
      <Grid container className={classes.root} direction="column">
        <Grid item>
          <div
            className={classes.avatar}
            onClick={() => {
              history.push(
                `/UserDefinition/${user?.UserDefinitionId}/${user?.Id}`
              );
            }}>
            {data?.ProfilePicture === '' ? (
              <Avatar />
            ) : (
              <img
                height="40"
                src={data?.ProfilePicture}
                style={{ borderRadius: '50%' }}
                width="40"
              />
            )}
          </div>
        </Grid>

        <Grid item>
          <Typography
            variant="h4"
            style={{ color: theme.palette.primary.contrastText }}>
            {user?.NickName || user?.Title || 'Title'}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            align="left"
            variant="h6"
            style={{
              opacity: opacity,
              transform: transform,
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              color: `${theme.palette.primary.contrastText}66`
            }}>
            {secondaryText[currentIndex]}
          </Typography>
        </Grid>
      </Grid>
      {shouldRenderDoNotDeal && !isBroker && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: '20px'
          }}>
          <DoNotDeal
            name={user?.Title}
            email={user?.UserInstanceEmail}
            UserDefinitionId={user?.UserDefinitionId}
            type="contact"
            showOnly={showOnlyHistoryButton ? 'history' : 'all'}
          />
        </div>
      )}

      {editable && canEditUser && (
        <>
          <div style={{ height: 1, width: '100%', backgroundColor: 'grey' }} />
          <UserForm data={user} classes={classes} />
          <div style={{ height: 1, width: '100%', backgroundColor: 'grey' }} />
          <DetailsForm data={user} classes={classes} />
        </>
      )}
    </div>
  );
}; //END User

export default User;
