import { ApolloError, DocumentNode } from '@apollo/client';
import { ELender } from 'components/Stepper/components/LenderAPI/interface';
import { apolloClient } from 'graphql/apolloClient';
import {
  ADD_FLEXIMIZE_DOCUMENTS_TO_PROPOSAL,
  CREATE_FLEXIMIZE_PROPOSAL,
  GENERATE_PROPOSAL_DOCUMENT,
  UPDATE_PROPOSAL_STATUS,
  CREATE_ALDERMORE_PROPOSAL,
  CREATE_INVESTEC_QUANTUM_PROPOSAL,
  CREATE_ULTIMATE_FINANCE_PROPOSAL,
  CREATE_CAS_PROPOSAL,
  CREATE_PRAETURA_PROPOSAL,
  ADD_PRAETURA_DOCUMENTS_TO_PROPOSAL,
  CREATE_NORTHRIDGE_PROPOSAL,
  CREATE_FUNDING_CIRCLE_PROPOSAL,
  ADD_FUNDING_CIRCLE_DOCUMENTS_TO_PROPOSAL,
  CREATE_DLL_PROPOSAL,
  ADD_DLL_DOCUMENTS_TO_PROPOSAL,
  CREATE_NOVUNA_PROPOSAL,
  CREATE_PREMIUM_CREDIT_PROPOSAL,
  ADD_ULTIMATE_FINANCE_DOCUMENTS_TO_PROPOSAL
} from 'graphql/LenderAPI/mutations';
import { BugTracker } from 'Utils/Bugtracker';
import { removeTypenames } from '../User Instance API/mutations';
import {
  IAddDocumentsResponse,
  ICosmeticsBadRequestResponse,
  ICosmeticsCreateProposalResponse,
  IDocumentAuth,
  IUpdateProposalStatus,
  TPossibleLenderAPITypes,
  IUpdateProposalResponse,
  IUploadDocumentIds,
  IGenerateProposalsDocuments,
  ICreateProposal
} from './interface';

/**
 * Creates a Fleximize proposal by sending a GraphQL mutation.
 *
 * @param {ICreateFleximizeProposal} input - The input data required to create a Fleximize proposal.
 * @returns {Promise<TPossibleLenderAPITypes | ApolloError>} - A promise that resolves to the proposal response or an ApolloError.
 */

type TLenderProposalResponse = {
  [K in ELender as `Create${Capitalize<Lowercase<K>>}Proposal`]:
    | ICosmeticsCreateProposalResponse
    | ICosmeticsBadRequestResponse;
};
export const CreateProposal = async (
  input: ICreateProposal
): Promise<TPossibleLenderAPITypes | ApolloError> => {
  try {
    const getProposalGQL = (): DocumentNode => {
      switch (input.ProposalMetadata.meta._lender) {
        case ELender.Fleximize:
          return CREATE_FLEXIMIZE_PROPOSAL;
        case ELender.Aldermore:
          return CREATE_ALDERMORE_PROPOSAL;
        case ELender.Investec:
        case ELender.Quantum:
          return CREATE_INVESTEC_QUANTUM_PROPOSAL;
        case ELender.UltimateFinance:
          return CREATE_ULTIMATE_FINANCE_PROPOSAL;
        case ELender.CAS:
          return CREATE_CAS_PROPOSAL;
        case ELender.Praetura:
          return CREATE_PRAETURA_PROPOSAL;
        case ELender.Northridge:
          return CREATE_NORTHRIDGE_PROPOSAL;
        case ELender.FundingCircle:
          return CREATE_FUNDING_CIRCLE_PROPOSAL;
        case ELender.DLL:
          return CREATE_DLL_PROPOSAL;
        case ELender.Novuna:
          return CREATE_NOVUNA_PROPOSAL;
        case ELender.PremiumCredit:
          return CREATE_PREMIUM_CREDIT_PROPOSAL;
        default:
          return CREATE_FLEXIMIZE_PROPOSAL;
      }
    };

    const { data, errors } = await apolloClient.mutate({
      mutation: getProposalGQL(),
      variables: {
        input
      }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const lender = input.ProposalMetadata.meta._lender;
    const responseKey =
      `Create${lender}Proposal` as keyof TLenderProposalResponse;

    const responsePayload = data[responseKey];
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Adds documents to a Fleximize proposal by sending a GraphQL mutation.
 *
 * @param {IAddDocuments} input - The input data containing document IDs and related information.
 * @returns {Promise<IAddDocumentsResponse | ApolloError>} - A promise that resolves to the add documents response or an ApolloError.
 */

export const AddDocumentsToProposal = async (
  input: IUploadDocumentIds,
  auth: IDocumentAuth,
  lender: ELender
): Promise<IAddDocumentsResponse[] | ApolloError> => {
  try {
    const newData = removeTypenames(input);
    const getProposalGQL = (): DocumentNode => {
      switch (lender) {
        case ELender.Fleximize:
          return ADD_FLEXIMIZE_DOCUMENTS_TO_PROPOSAL;
        case ELender.Praetura:
          return ADD_PRAETURA_DOCUMENTS_TO_PROPOSAL;
        case ELender.FundingCircle:
          return ADD_FUNDING_CIRCLE_DOCUMENTS_TO_PROPOSAL;
        case ELender.DLL:
          return ADD_DLL_DOCUMENTS_TO_PROPOSAL;
        case ELender.UltimateFinance:
          return ADD_ULTIMATE_FINANCE_DOCUMENTS_TO_PROPOSAL;
        default:
          return ADD_FLEXIMIZE_DOCUMENTS_TO_PROPOSAL;
      }
    };

    const { data, errors } = await apolloClient.mutate({
      mutation: getProposalGQL(),
      variables: {
        input: newData
      },
      context: {
        headers: {
          CosneticsToken: auth.CosneticsToken,
          FirebaseToken: auth.FirebaseToken
        }
      }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responseKey =
      `AddDocumentsTo${lender}Proposal` as keyof TLenderProposalResponse;

    const responsePayload = data[responseKey];
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

export const UpdateProposalStatus = async (
  input: IUpdateProposalStatus
): Promise<IUpdateProposalResponse | ApolloError> => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_PROPOSAL_STATUS,
      variables: {
        input
      }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responsePayload = data.UpdateProposalStatus;
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

export const GenerateProposalDocument = async (
  input: IUploadDocumentIds
): Promise<string | ApolloError> => {
  const newData = removeTypenames(input);
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: GENERATE_PROPOSAL_DOCUMENT,
      variables: {
        input: newData
      }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responsePayload = data.GenerateProposalDocument;
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};
