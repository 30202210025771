import { firebase } from 'redux/firebase';
import { AxiosRequestConfig } from 'axios';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { store } from 'redux/store';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { notify } from 'components/Notifications/HotToastNotifications';

export const VRM_API = async ({ query }) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const reg = query.replace(/ /g, '');
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${process.env.REACT_APP_PROXY}/vrm/lookup`,
    data: { reg },
    headers: { token }
  };

  return config;
};

interface IMergedVRMData {
  co2Emissions: number;
  engineCapacity: number;
  yearOfManufacture: number;
  markedForExport: boolean;
  colour: string;
  dateOfLastV5CIssued: string;
  firstUsedDate: string;
  fuelType: string;
  make: string;
  model: string;
  monthOfFirstRegistration: string;
  motExpiryDate: string;
  motStatus: string;
  primaryColour: string;
  registration: string;
  registrationNumber: string;
  taxDueDate: string;
  taxStatus: string;
  typeApproval: string;
  wheelplan: string;
  motTests: {
    completedDate: string;
    expiryDate: string;
    motTestNumber: string;
    odometerUnit: string;
    odometerValue: string;
    rfrAndComments: {
      text: string;
      type: string;
    };
    testResult: string;
  };
}

export const FindingAssetDetails = async ({
  object_one,
  object_two,
  CompleteObjectInstance,
  FieldDefinitionList,
  dispatch
}: {
  object_one;
  object_two;
  CompleteObjectInstance: CompleteObjectInstance;
  FieldDefinitionList?: FieldDefinition[];
  dispatch: (event: unknown) => void;
}) => {
  const baseUrl = store.getState().config.baseURL;
  const comparedTitles: string[] = ['Annual Mileage', 'Current Mileage'];
  const merged: IMergedVRMData = { ...object_one, ...object_two };
  const vrmTitles: string[] = Object.keys(merged).map((item: any) => {
    const result =
      item.charAt(0).toUpperCase() +
      item
        .slice(1)
        .match(/([A-Z]?[^A-Z]*)/g)
        .slice(0, -1)
        .join(' ');
    return result;
  });

  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  if (merged && merged?.motTests) {
    let comparedFields: FieldDefinition[] = [];
    comparedTitles.forEach((element) => {
      const FindComparedTitles =
        FieldDefinitionList &&
        FieldDefinitionList.find((el) => el.Title === element);

      if (FindComparedTitles !== undefined)
        comparedFields.push(FindComparedTitles);
    });

    for (const key in comparedFields) {
      const keyValue = comparedFields[key];
      const FoundField: FieldInstance | undefined = FieldInstanceList.find(
        (el: FieldInstance) => el.FieldDefinitionId === keyValue.Id
      );

      if (FoundField) {
        const year = Math.abs(
          new Date().getFullYear() - merged.yearOfManufacture
        );

        if (keyValue.Title.includes('Annual Mileage')) {
          // They Do Not Want To Display AnnualMileage As Stated In Issue FIN-1337
          // ----
          //  let annualMileage = merged.motTests[0].odometerValue / year;
          //  let mathRound = (Math.round(annualMileage * 100) / 100).toFixed(2);
          //  FoundField.FieldValue = mathRound + ' Miles';
          FoundField.FieldValue = '';
        }

        if (keyValue.Title.includes('Current Mileage')) {
          // They Do Not Want To Display Current Mileage As Stated In Issue FIN-1337
          // ----
          // FoundField.FieldValue = merged.motTests[0].odometerValue + ' Miles';
        }

        await updateFieldInstance({
          baseUrl,
          data: FoundField,
          ProcessInstanceId: FoundField.ProcessInstanceId
        });
      }
    }
  }

  interface ICustomFieldTitles {
    fieldDefinitionTitle: string;
    mergedValue: string;
  }
  // If we need Custom Fields Types for VRM Add Here:
  const customFieldTitles: ICustomFieldTitles[] = [
    {
      fieldDefinitionTitle: 'Date 1st Registered',
      mergedValue: 'monthOfFirstRegistration'
    }
  ];

  const getMatchingFieldDefinitions = (
    vrmTitles: string[],
    definitionList: FieldDefinition[]
  ) => {
    const newFieldDefinitions: FieldDefinition[] = [];

    customFieldTitles.forEach((element: ICustomFieldTitles) => {
      const findDefinition = definitionList.find((el: FieldDefinition) =>
        el.Title.includes(element.fieldDefinitionTitle)
      );
      if (findDefinition !== undefined) {
        newFieldDefinitions.push(findDefinition);
      }
    });

    vrmTitles.forEach((element: string) => {
      const findDefinition = definitionList.find((el: FieldDefinition) =>
        el.Title.includes(element)
      );
      if (findDefinition !== undefined) {
        newFieldDefinitions.push(findDefinition);
      }
    });

    return newFieldDefinitions;
  };

  const getFieldValueMatchingDefinition = (
    fieldDict: FieldInstance[],
    definition: FieldDefinition
  ) => {
    return fieldDict.find(
      (el: FieldInstance) => el.FieldDefinitionId === definition.Id
    );
  };

  const getMergedKeyFromTitle = (
    vrmTitles: string[],
    merged: IMergedVRMData,
    definition: FieldDefinition
  ) => {
    let mergedKey: string = '';
    customFieldTitles.forEach((element: ICustomFieldTitles) => {
      if (definition.Title.includes(element.fieldDefinitionTitle)) {
        mergedKey = element.mergedValue;
        return;
      }

      const unknown: any = vrmTitles.find((el: string) =>
        el.includes(definition.Title)
      );
      const key: string =
        unknown.charAt(0).toLowerCase() + unknown.slice(1).replace(/ /g, '');

      // Giving Merge key a "Value"
      mergedKey = key;
    });

    return merged[mergedKey];
  };

  // Grabbing Titles That Are Connected To Admin Panel Names
  if (FieldDefinitionList) {
    const matchingDefinitions = getMatchingFieldDefinitions(
      vrmTitles,
      FieldDefinitionList
    );
    for (const definition of matchingDefinitions) {
      const foundField = getFieldValueMatchingDefinition(
        FieldInstanceList,
        definition
      );
      if (foundField) {
        const fieldValue: string = getMergedKeyFromTitle(
          vrmTitles,
          merged,
          definition
        );
        foundField.FieldValue = fieldValue;
        try {
          await updateFieldInstance({
            baseUrl,
            data: foundField,
            ProcessInstanceId: foundField.ProcessInstanceId
          });

          dispatch({ type: 'UPDATING' });
        } catch (e) {
          BugTracker.notify(e);
        }
      } else {
        notify.error(
          `Couldn't Populate Some Fields Because Cannot Match Keywords`
        );

        break;
      }
    }
  }
};
