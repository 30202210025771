import {
  CompleteObjectInstance,
  CompleteObjectDefinition,
  FieldInstance,
  FieldDefinition
} from 'types/interfaces';
import { INIT_STATE } from 'components/Calculator/Utils/calculations';
import { getFieldInstances } from './FieldInstanceChecker';
import { notify } from 'components/Notifications/HotToastNotifications';

/**
 * CONVERT OBJECT INSTANCE TO CALCULATION
 * @description This method converts an Object Instance into a Calculation Object
 * @param ObjectInstance
 * @param CompleteObjectDefinition
 * @returns Calculation
 */
export const Convert_ObjectInstance_To_Calculation = (
  ObjectInstance: CompleteObjectInstance,
  CompleteObjectDefinition: CompleteObjectDefinition
) => {
  let Calculation = JSON.parse(JSON.stringify(INIT_STATE));
  // for each field instance we want to grab the field definition title
  const FieldInstanceList = getFieldInstances(ObjectInstance);
  FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
    const { FieldDefinitionId, FieldValue } = FieldInstance;
    if (FieldValue.length && FieldValue.length > 0) {
      const match =
        CompleteObjectDefinition.FieldDefinitionDict[FieldDefinitionId];

      if (match) {
        const { Title, FieldType } = match;
        if (Title.charAt(0) === '_') {
          Calculation[Title] = JSON.parse(decodeURIComponent(FieldValue));
        } else {
          Calculation[Title] = convertType(FieldValue, FieldType);
        }
      } else {
        // console.log(
        //   "This field instance doesn't have a matching field definition: ",
        //   FieldInstance,
        //   'Likilihood is that it was deleted in the process configuration '
        // );
      }
    }
  });

  // console.log('Convert_ObjectInstance_To_Calculation', {
  //   FieldInstanceLISTORDICT,
  //   Calculation,
  //   ObjectInstance,
  //   CompleteObjectDefinition
  // });

  return Calculation;
};

export const convertType = (value, type) => {
  switch (type) {
    case 'Text':
      if (value.charAt(0) === '[') return JSON.parse(value);
      return value;
    case 'Whole Number':
      return parseInt(value);
    case 'Decimal':
      return parseFloat(value);
    default:
      return value;
  }
};

export const copy2clipboard = (value, document) => {
  const fakeElement = document.createElement('input');
  fakeElement.value = value;
  document.body.appendChild(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  document.body.removeChild(fakeElement);
  notify.success('Copied to clipboard');
};

interface IGetFieldInstanceProps {
  CODL: CompleteObjectDefinition[];
  COIL: CompleteObjectInstance[];
  DefinitionTitle: string;
  FieldTitle: string;
}

export const getFieldInstanceValue = ({
  CODL,
  COIL,
  DefinitionTitle,
  FieldTitle
}: IGetFieldInstanceProps): string | undefined => {
  const EntityDefinition = CODL.find(
    (i) => i.ObjectDefinition?.Title === DefinitionTitle
  );

  if (EntityDefinition && EntityDefinition.FieldDefinitionList) {
    const EntityDefinitionId = EntityDefinition.ObjectDefinition.Id;
    const CompleteObjectInstance = COIL.find(
      (instance) =>
        instance.ObjectInstance.ObjectDefinitionId === EntityDefinitionId
    );

    const EntityFieldDefinition = EntityDefinition.FieldDefinitionList.find(
      (fieldDef) => fieldDef.Title === FieldTitle
    );

    if (EntityFieldDefinition && CompleteObjectInstance) {
      const EntityFieldDefinitionId = EntityFieldDefinition.Id;
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      const FieldInstance = FieldInstanceList.find(
        (instance) => instance.FieldDefinitionId === EntityFieldDefinitionId
      );

      return FieldInstance?.FieldValue;
    }
  }
  return undefined;
};

export const truncateString = (str, maxLength = 45) => {
  if (str.length <= maxLength) return str;

  const partLength = 20;
  const start = str.substring(0, partLength);
  const end = str.substring(str.length - partLength);

  return `${start}...${end}`;
};

export const newGetFieldInstanceValue = ({
  CODL,
  COIL,
  DefinitionTitle,
  FieldTitle
}: {
  CODL: CompleteObjectDefinition[];
  COIL: CompleteObjectInstance[];
  DefinitionTitle: string;
  FieldTitle: string;
}): string | undefined => {
  const EntityDefinition: CompleteObjectDefinition | undefined = CODL.find(
    (CompleteObjectDefinition: CompleteObjectDefinition) =>
      CompleteObjectDefinition.ObjectDefinition.Title === DefinitionTitle
  );

  if (EntityDefinition) {
    const EntityDefinitionId = EntityDefinition?.ObjectDefinition?.Id;
    const CompleteObjectInstanceList: CompleteObjectInstance | undefined =
      COIL.find(
        (CompleteObjectInstanceList: CompleteObjectInstance) =>
          CompleteObjectInstanceList.ObjectInstance.ObjectDefinitionId ===
          EntityDefinitionId
      );
    if (CompleteObjectInstanceList) {
      const EntityFieldDefinition = Object.values(
        EntityDefinition && EntityDefinition.FieldDefinitionDict
      ).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title === FieldTitle
      );

      if (EntityFieldDefinition) {
        const EntityFieldDefinitionId = EntityFieldDefinition?.Id;
        const FieldInstance: FieldInstance | undefined =
          CompleteObjectInstanceList.FieldInstanceList.find(
            (FieldInstance: FieldInstance) =>
              FieldInstance.FieldDefinitionId === EntityFieldDefinitionId
          );

        if (FieldInstance) return FieldInstance.FieldValue;
      }
    }
  }
  return EntityDefinition?.ObjectDefinition.Title;
};

interface Timestamp {
  nanoseconds: number;
  seconds: number;
}

export const formatDate = (d: string | number | Timestamp) => {
  // sometimes date is a timestamp object
  const date =
    typeof d === 'object'
      ? new Date(d.seconds * 1000 + d.nanoseconds / 1000000)
      : new Date(d);

  // DD-MM-YY
  return date.toLocaleDateString('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });
};

export const capitalizeAndSpace = (str) => {
  if (!str) return '';

  // Capitalize the first letter
  let result = str.charAt(0).toUpperCase();

  // Loop through the rest of the string
  for (let i = 1; i < str.length; i++) {
    if (
      str.charAt(i) === str.charAt(i).toUpperCase() &&
      str.charAt(i) !== ' '
    ) {
      result += ' ' + str.charAt(i);
    } else {
      result += str.charAt(i);
    }
  }

  return result;
};

export const snakeToTitleCase = (snakeStr: string): string => {
  return snakeStr
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words with spaces
};
