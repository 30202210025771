import React, { useEffect, useMemo, useState } from 'react';
import { tableIcons } from '../../../ProcessTable/tableIcons';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import StartDealButton from 'layouts/Dashboard/components/Topbar/components/StartDealButton';
import Dialog from 'components/Stepper/components/Overview/Entry';
import { useProcess } from 'hooks/useProcess';
import { getDealData } from 'redux/actions/processes/getDealData';
import { useTypedSelector } from 'redux/reducers';
import { ProcessSummary } from 'types/interfaces';
import { GetProcessByUserFinishedOrUnfinished } from 'redux/database';
import { BugTracker } from 'Utils/Bugtracker';
import { Button } from '@material-ui/core';
import {
  displayDealListData,
  SelectByDealStatus
} from 'components/ProcessTable/functions/Actions';

const Table = ({ data, columns, getDeals, userInstance }) => {
  const { currentProcess } = useProcess();
  const user = useTypedSelector((s) => s.user.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const atLeastSubSystemUser = user.SystemAccess >= 4;

  const [isActiveDealList, setIsActiveDealList] = useState<boolean>(true);
  const [closedData, setClosedData] = useState<ProcessSummary[]>(
    [] as ProcessSummary[]
  );

  const fetchFinishedProcessByUser = async () => {
    try {
      const res = await GetProcessByUserFinishedOrUnfinished({
        Id: userInstance.UserInstance.Id.toString(),
        finishedDeal: true
      });

      if (res.status === 200) {
        const closedProcessSummaries = res.data as ProcessSummary[];
        setClosedData(closedProcessSummaries);
        setLoading(false);
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  useEffect(() => {
    fetchFinishedProcessByUser();
  }, []);

  const getProcesses = async ({ rowData }) => {
    setLoading(true);
    const { ProcessInstanceId, ProcessStepDefinitionId, ProcessDefinitionId } =
      rowData;
    await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });
    setLoading(false);
    return setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    if (value.includes('Active Deals')) {
      setIsActiveDealList(true);
    } else if (value.includes('Closed Deals')) {
      setIsActiveDealList(false);
    }
  };

  const newData = useMemo(() => {
    return displayDealListData({
      isActiveDealList,
      processSummaries: data,
      closedData
    });
  }, [isActiveDealList, data, closedData]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        handleClose={() => {
          getDeals();
          setOpen(false);
        }}
      />
      <MaterialTable
        title=""
        isLoading={loading}
        icons={tableIcons}
        data={newData}
        columns={columns}
        options={{ search: true, headerStyle: { zIndex: 1 }, filtering: true }}
        onRowClick={async (event, rowData, togglePanel) => {
          getProcesses({ rowData });
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ position: 'relative' }}>
              {atLeastSubSystemUser && (
                <div
                  style={{
                    position: 'relative',
                    top: 5,
                    left: 5,
                    zIndex: 1,
                    width: '99%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <StartDealButton userInstance={userInstance} />
                </div>
              )}
              <div
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 140,
                  zIndex: 1,
                  width: '75%',
                  marginLeft: '90px'
                }}>
                <Button>
                  <SelectByDealStatus
                    isActiveDealList={isActiveDealList}
                    handleChange={handleChange}
                  />
                </Button>
              </div>
              <MTableToolbar {...props} />
            </div>
          )
        }}
      />
    </React.Fragment>
  );
};

export default Table;
