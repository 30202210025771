import {
  Asset,
  Calculation,
  CashFlowItem,
  ExtraPayment,
  Init_Config,
  _AdditionalInputs,
  _Balloon,
  _Commission,
  _DocumentFee,
  _NetAdvance,
  PaymentFrequency,
  OverridePayment
} from 'types/calculatorInterfaces';

export const INIT_CONFIG: Init_Config = {
  Calculation: { show: false },
  OutputMessage: { show: false },
  OutputStatus: { show: false },
  Counting: { show: false },
  Amount: { show: false },
  MonthlyPayment: { show: false },
  VATPercentage: { show: false },
  AmountIncludesVAT: { show: false },
  Deposit: { show: false },
  InitialRentals: { show: false },
  IntroducerFee: { show: false },
  IntroducerFeePercentOfCommission: { show: false },
  CommissionPercentage: { show: false },
  DocFee: { show: false },
  StartDate: { show: false },
  Term: { show: false },
  OptionFee: { show: false },
  TargetYield: { show: false },
  CommissionAmount: { show: false },
  TargetRate: { show: false },
  APR: { show: false },
  TargetAPR: { show: false },
  EXtraPayments: { show: false },
  OverridePayments: { show: false },
  CashFlow: { show: false },
  FlatRate: { show: false },
  NominalFlatRate: { show: false },
  FundingRate: { show: false },
  Yield: { show: false },
  MarginRate: { show: false },
  CustomerYield: { show: false },
  InitialPayment: { show: false },
  CashFlowAsText: { show: false },
  NonVATableItemsCost: { show: false },
  BalloonRepaymentValue: { show: false },
  Balloon: { show: false },
  FundingProfile: { show: false }
};

export default {
  0: {
    MonthlyPayment: { show: true },
    APR: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: false },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    FundingProfile: { show: true }
  },
  1: {
    MonthlyPayment: { show: true },
    FlatRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    FundingProfile: { show: true },
    Balloon: { show: true } // Changed from False to True Request By Shamila
  },

  2: {
    MonthlyPayment: { show: true },
    MarginRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: false },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    FundingProfile: { show: true }
  },
  3: {
    // AMOUNT FROM YIELD
    MonthlyPayment: { show: true },
    Yield: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: false },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  4: {
    Amount: { show: true },
    MonthlyPayment: { show: true },
    MarginRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: false },
    InitialRentals: { show: true },
    Deposit: { show: true },
    FundingProfile: { show: true }
  },
  5: {
    Amount: { show: true },
    MonthlyPayment: { show: true },
    Yield: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: false },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  6: {
    // PAYMENT FROM APR
    Amount: { show: true },
    APR: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  7: {
    // PAYMENT FROM FLATRATE
    Amount: { show: true },
    FlatRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  8: {
    // PAYMENT FROM MARGIN
    Amount: { show: true },
    MarginRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  9: {
    // PAYMENT FROM YIELD
    MonthlyPayment: { show: false },
    Amount: { show: true },
    Deposit: { show: true },
    InitialRentals: { show: true },
    Term: { show: true },
    Yield: { show: true },
    AmountIncludesVAT: { show: false }, // testing
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    VATPercentage: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  10: {
    MonthlyPayment: { show: true },
    Amount: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  11: {
    Amount: { show: true },
    APR: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true }, // Changed from False to True Request By Shamila
    FundingProfile: { show: true }, // Changed from False to True Request By Shamila
    Deposit: { show: true },
    Balloon: { show: false },
    BalloonRepaymentValue: { show: true }
  },
  12: {
    Amount: { show: true },
    FlatRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true }, // Changed from False to True Request By Shamila
    FundingProfile: { show: true }, // Changed from False to True Request By Shamila
    Deposit: { show: true },
    Balloon: { show: false },
    BalloonRepaymentValue: { show: true }
  },
  13: {
    Amount: { show: true },
    MarginRate: { show: true },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true }, // Changed from False to True Request By Shamila
    FundingProfile: { show: true }, // Changed from False to True Request By Shamila
    Deposit: { show: true },
    Balloon: { show: false },
    BalloonRepaymentValue: { show: true }
  },
  14: {
    Amount: { show: true },
    Term: { show: true },
    Yield: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true }, // Changed from False to True Request By Shamila
    FundingProfile: { show: true }, // Changed from False to True Request By Shamila
    Deposit: { show: true },
    Balloon: { show: false },
    BalloonRepaymentValue: { show: true }
  },
  15: {
    // FOR PAYMENT FROM NOMINAL FLAT RATE
    Amount: { show: true },
    NominalFlatRate: { show: true },
    FlatRate: { show: false },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  },
  16: {
    // FOR AMOUNT FROM NOMINAL FLAT RATE
    Amount: { show: true },
    NominalFlatRate: { show: true },
    FlatRate: { show: false },
    Term: { show: true },
    StartDate: { show: true },
    Counting: { show: true },
    DocFee: { show: true },
    OptionFee: { show: true },
    CommissionPercentage: { show: true },
    CommissionAmount: { show: true },
    InitialRentals: { show: true },
    Deposit: { show: true },
    Balloon: { show: true },
    FundingProfile: { show: true }
  }
};

const INIT_DOCUMENTFEE: _DocumentFee = {
  DocFee: 0,
  DocFeeUpsell: 0,
  DocFee_VatRate: 0, // Request by Shamila to turn to 0
  DocFee_Vat: 0,
  DocFee_PaymentDate: 'FIRST_PAYMENT',
  TotalDocFee: 0,
  OptionFee: 0,
  OptionFee_VatRate: 0, // Request by Shamila to turn to 0
  OptionFee_Vat: 0,
  TotalOptionFee: 0,
  AnnualServiceFee: 0,
  AnnualServiceFee_Vat: 0,
  AnnualServiceFee_VatRate: 0 // Request by Shamila to turn to 0
};

export const INIT_ADDITIOANLINPUTS: _AdditionalInputs = {
  RefundOfSalesProceeds: 97.5,
  SecondaryPeriodRental: 0,
  CustomerType: '',
  BaseRate: 'LIBOR',
  MinimumBaseRatePerAnnum: 0,
  ManufacturerSubsidy: 0,
  ManufacturerSubsidyDate: new Date(),
  DealersSubsidyAmount: 0,
  DealersSubsidyAmountDate: new Date()
};

export const INIT_NETADVANCE: _NetAdvance = {
  Purchase_Price: 0,
  Purchase_VatRate: 20,
  Purchase_Vat: 0,
  NonVATableItemsCost: 0,
  Deposit: 0,
  Deposit_VatRate: 0,
  Deposit_Vat: 0,
  Settlement: 0,
  Settlement_VatRate: 20,
  Settlement_Vat: 0,
  Part_Exchange: 0,
  Part_Exchange_VatRate: 20,
  Part_Exchange_Vat: 0,
  Subsidy: 0,
  Subsidy_VatRate: 20,
  Subsidy_Vat: 0,
  NetAdvance: 0,
  Vat_Payment: 'VAT_UPFRONT',
  VatTotal: 0,
  VatDeferral: 0,
  TotalDeposit: 0,
  // Temp State To House Changing Of Importing Assets
  isLoading: false
};

export const INIT_COMMISSION: _Commission = {
  Broker_Commission: 0,
  Broker_Rate: 0,
  Broker_VatRate: 0,
  Broker_Vat: 0,

  Introducer_Fee: 0,
  Introducer_VatRate: 0,
  Introducer_Vat: 0,

  Funder_Fee: 0,
  Funder_VatRate: 0,
  Funder_Vat: 0,

  Evo_Upsell_Fee: 0,
  Evo_Upsell_VatRate: 0,
  Evo_Upsell_Vat: 0,

  TotalPayable: 0,
  TotalPayable_IncVat: 0
};

export const INIT_BALLOON: _Balloon = {
  Balloon: 0,
  // They Wanted To Set Balloon VAT to "0" CSM-203
  Balloon_VatRate: 0,
  Balloon_Vat: 0,

  ContractPeriod: 0,
  TotalBalloon: 0
};

export const INIT_STATE: Calculation = {
  Title: '',
  Assets: [] as Asset[],
  _AdditionalInputs: INIT_ADDITIOANLINPUTS,
  _Balloon: INIT_BALLOON,
  _Commission: INIT_COMMISSION,
  _DocumentFee: INIT_DOCUMENTFEE,
  _NetAdvance: INIT_NETADVANCE,
  Amount: 0,
  AmountIncludesVAT: false,
  APR: 0,
  Calculation: 9,
  CashFlow: [] as CashFlowItem[],
  CashFlowAsText: '',
  CommissionAmount: 0.0,
  CommissionPercentage: 0,
  Counting: 'Actual365',
  CustomerYield: 0.0,
  Debug: '',
  Deposit: 0,
  DocFee: 0,
  DocFeeBase: 0,
  DocFeeUpsell: 0,
  ExecutionTime: 0,
  ExtraPayments: [] as ExtraPayment[],
  FinanceProduct: '',
  FlatRate: 0,
  NominalFlatRate: 0,
  FundingRate: 0,
  Halves: 0,
  InitialPayment: 0.0,
  InitialRentals: 0,
  RatePerThousand: 0,
  Interest: 0,
  IntroducerFee: 0,
  IntroducerFeePercentOfCommission: 0.0,
  MarginRate: 0,
  MonthlyPayment: 0,
  NonVATableItemsCost: 0,
  Notes: '',
  NPV: 0,
  OptionFee: 0,
  OutputMessage: '',
  OutputStatus: 0,
  OverridePayments: [] as OverridePayment[],
  PaymentFrequency: PaymentFrequency.Monthly,
  RateOfInterestPerAnnum: 0,
  StartDate: new Date(),
  Term: 36,
  Thirds: 0,
  VAT: 0,
  VATPercentage: 20.0,
  Yield: 0,
  FundingProfile: 36,
  FinanceType: '',
  DiscretionaryType: ''
};
