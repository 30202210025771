import { DateParse } from 'common/dateParse';
import { IColumn, IColumnOrder } from 'components/ProcessTable/interface';
import { ProcessSummary } from 'types/interfaces';
import {
  CustomStatusRender,
  DealCustomRender,
  DealValueRender,
  UserRender
} from './rendering';

import { CustomUserSearch } from '../Actions';

export const columns: IColumn[] = [
  {
    title: 'Deal Id',
    field: 'ProcessInstanceId',
    render: (value: ProcessSummary, renderType: 'row' | 'group') =>
      DealCustomRender({ value, renderType }),
    cellStyle: {
      textAlign: 'left'
    },
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Name',
    field: 'ProcessInstanceTitle',
    cellStyle: {
      textAlign: 'left'
    },
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Product',
    field: 'ProcessDefinitionTitle',
    cellStyle: {
      textAlign: 'left'
    },
    hidden: true,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Last Modified',
    field: 'LastModified',
    type: 'date',
    render: (props) => <DateParse date={props.LastModified} />,

    cellStyle: {
      textAlign: 'left'
    },
    hidden: true,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Owner',
    field: 'UserInstanceTitle',
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Tags',
    field: 'Tag',
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Deal Paid',
    field: 'ClosedOn',
    type: 'date',
    render: (rowData: ProcessSummary) => {
      if (!rowData.ClosedOn || rowData.ClosedOn === '1970-01-01T00:00:00') {
        return null;
      }
      return <DateParse date={rowData.ClosedOn} />;
    },
    hidden: true,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Status',
    render: (value) => CustomStatusRender({ value }),
    cellStyle: { textAlign: 'left' },
    sorting: false,
    hidden: false,
    minWidth: 80,
    width: '80px',
    maxWidth: 80
  },
  {
    title: 'Deal Status',
    render: (value) => NameConvertor({ value }),
    hidden: true,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Customer',
    render: (v, t) =>
      UserRender({ v, t, type: ['Customers', 'Regulated Party'] }),
    customFilterAndSearch: (term, rowData) =>
      CustomUserSearch({
        term,
        rowData,
        type: 'Customers'
      }),
    cellStyle: { textAlign: 'left' },
    sorting: false,
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },

  {
    title: 'Contacts',
    render: (v, t) => UserRender({ v, t, type: 'Contacts' }),
    customFilterAndSearch: (term, rowData) =>
      CustomUserSearch({ term, rowData, type: 'Contacts' }),
    cellStyle: { textAlign: 'left' },
    sorting: false,
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Lenders',
    render: (v, t) => UserRender({ v, t, type: 'Lenders' }),
    customFilterAndSearch: (term, rowData) =>
      CustomUserSearch({ term, rowData, type: 'Lenders' }),
    cellStyle: { textAlign: 'left' },
    sorting: false,
    hidden: false,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  },
  {
    title: 'Supplier',
    render: (v, t) => UserRender({ v, t, type: 'Suppliers' }),
    customFilterAndSearch: (term, rowData) =>
      CustomUserSearch({ term, rowData, type: 'Suppliers' }),
    cellStyle: { textAlign: 'left' },
    sorting: false,
    hidden: true,
    minWidth: 10,
    width: '10px',
    maxWidth: 10
  }
];

const NameConvertor = ({ value }) => {
  switch (value.ProcessStepDefinitionTitle) {
    case 'Closed Lost':
      return 'Deal Not Taken Up';
    case 'Closed Won':
      return 'Paid Out';
    case 'Abandoned':
      return 'Declined';
    default:
      return value.ProcessStepDefinitionTitle;
  }
};

export const handleColumnDrag = ({
  saveColumnLayout,
  setColumnOrder,
  setSaveJSON
}: {
  saveColumnLayout: IColumn[];
  setColumnOrder: (value: IColumn[]) => void;
  setSaveJSON: (value: IColumnOrder[]) => void;
}) => {
  const updatedColumns = [...saveColumnLayout].sort((a, b) => {
    const orderA = a.tableData?.columnOrder ?? 0;
    const orderB = b.tableData?.columnOrder ?? 0;
    return orderA - orderB;
  });

  setColumnOrder(updatedColumns);
  const columnOrderToSave = updatedColumns.map((column) => ({
    title: column.title,
    columnOrder: column.tableData?.columnOrder ?? 0,
    hidden: column.hidden ? column.hidden : false
  }));

  setSaveJSON(columnOrderToSave);
};
