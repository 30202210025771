/**repeatableObject is the same as single objects but has multiple  values for the same field, which is more suited for tables */
import React, { useContext, useEffect } from 'react';
import Table from './Table';
import { useTheme, Grid, Typography } from '@material-ui/core';
import InlineTextField from 'components/Fields/InlineTextField';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import { useObject } from 'hooks/useObject';
import { getDealData } from 'redux/actions/processes/getDealData';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance
} from 'types/interfaces';
import { Alert } from '@material-ui/lab';

import { objectType } from '../Forms/FormContent';
import { useMobile } from 'hooks';
import { useTypedSelector } from 'redux/reducers';
import { StepperContext } from 'components/Stepper/context';
import { CustomDeletionDialog } from 'common/Dialog';

interface IRowData {
  isOpen: boolean;
  rowData: any;
}

const Component = (props) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);

  const { FieldDefinitionList, ObjectDefinition, stepdefdict } = props;
  const currentDeal = useTypedSelector((s) => s.process.currentDeal);
  const UserInstance = useTypedSelector((s) => s.user.user);
  const [open, setOpen] = React.useState<IRowData>({
    isOpen: false,
    rowData: {}
  });

  let columns: any = [];
  let data: any = [];

  // Deletion
  const [refreshingDeal, setRefreshingDeal] = React.useState(false);
  const ProcessInstanceId = currentDeal.ProcessInstance.Id;
  const ProcessStepDefinitionId =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;

  const dealCondition = isDealClosed || isDealTransferred;
  const { deleteObject, publishObject } = useObject({
    ObjectDefinition,
    UserInstance,
    ProcessInstanceId,
    Fields: FieldDefinitionList,
    ProcessStepSelectionType:
      stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
  });

  const deleteObjectAndRefresh = async () => {
    const deleted = await deleteObject(open.rowData?.ObjectInstance?.Id);
    deleted && refreshDealData();
  };

  const refreshDealData = async () => {
    setRefreshingDeal(true);
    const ProcessDefinitionId = currentDeal.ProcessInstance.ProcessDefinitionId;
    const res = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });
    if (res) setRefreshingDeal(false);
  };

  const isReadOnly = () =>
    ReadOnlyRedflag({ ObjectDefinition: props.ObjectDefinition });

  /**
   * COLUMNS
   */
  FieldDefinitionList.filter(
    (el: FieldDefinition) => !el.FieldType.includes('Information')
  ).forEach((FieldDefinition: FieldDefinition) => {
    const { Title, FieldType, Id } = FieldDefinition;
    columns.push({
      title: Title,
      field: Id.toString(),
      render: (rowData) => {
        const isReadOnly: boolean = ReadOnlyRedflag({
          ObjectDefinition: props.ObjectDefinition,
          FieldDefinition
        });

        const FieldInstance: any = Object.values(
          rowData.FieldInstanceDict
        ).find((item: any) => item.FieldDefinitionId === Id);

        return (
          <InlineTextField
            readOnly={isReadOnly || dealCondition}
            FieldDefinition={FieldDefinition}
            FieldInstance={FieldInstance}
            ObjectDefinition={ObjectDefinition}
            ObjectInstance={rowData.ObjectInstance}
            ProcessInstance={currentDeal.ProcessInstance}
            ProcessStepSelectionType={
              stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
            }
            UserInstance={UserInstance}
            fields={FieldDefinitionList}
            type={FieldType}
            value={FieldInstance && FieldInstance.FieldValue}
          />
        );
      }
    });
  });

  /**
   * DATA
   */
  // This will be any objects matching the object definition Id of the currently selected object props.objectdef.id
  const groups: any = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).filter(
    (item: any) =>
      item.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
  );
  let group: any = {};
  for (const key in groups) {
    group = groups[key];
    data.push(group);
  }

  const actions = [
    {
      icon: () => (
        <div>
          {!isReadOnly() && !dealCondition && (
            <AddFieldAndObjectButton
              Fields={FieldDefinitionList}
              ObjectDefinition={ObjectDefinition}
              ProcessInstance={currentDeal.ProcessInstance}
              ProcessInstanceId={currentDeal.ProcessInstance.Id}
              ProcessStepSelectionType={
                stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
              }
              UserInstance={UserInstance}
              label={''}
            />
          )}
        </div>
      ),
      tooltip: 'Add Record',
      isFreeAction: true
    },
    {
      icon: 'delete',
      disabled: isReadOnly(),
      tooltip: isReadOnly() ? 'Delete disabled' : 'Delete row',
      onClick: (event, rowData) =>
        setOpen({ ...open, isOpen: true, rowData: rowData })
    }
  ];

  const canAddMore =
    ObjectDefinition.ObjectRepeat === 0
      ? true
      : data.length < ObjectDefinition.ObjectRepeat;
  const title =
    ObjectDefinition.ObjectRepeat === 0
      ? `${ObjectDefinition.Title}`
      : `${ObjectDefinition.Title} : ${data.length} / ${ObjectDefinition.ObjectRepeat}`;

  /**set local repeatable object data values before updating the objectData state within parent component */
  const localRepeatableObjectData: unknown[] = [];

  // create the data table for the repeatable object
  data.forEach((objectDataValue) => {
    let perData = {};

    columns.forEach((objectHeading) => {
      for (const property in objectDataValue.FieldInstanceDict) {
        if (
          objectHeading.field ==
          objectDataValue.FieldInstanceDict[property]['FieldDefinitionId']
        ) {
          perData[objectHeading.title] =
            objectDataValue.FieldInstanceDict[property]['FieldValue'];
        }
      }
    });

    localRepeatableObjectData.push(perData);
  });

  // update objectData state with the repeatableObject data which will be used for the pdf file if the user wanted to download file
  useEffect(() => {
    props.setCurrentObjectType(objectType.repeatableObject);

    props.setObjectData([...localRepeatableObjectData]);

    return () => {
      props.setCurrentObjectType(objectType.nullObject);
      props.setObjectData([]);
    };
  }, [props.currentObjectType, props.ObjectDefinition.Title]);

  if (open.isOpen) {
    return (
      <CustomDeletionDialog
        type="delete"
        open={open.isOpen}
        setOpen={setOpen}
        confirmedDeleted={deleteObjectAndRefresh}
        title={title}
      />
    );
  }

  return (
    <Grid container direction="column" spacing={1}>
      {FieldDefinitionList.filter((el: FieldDefinition) =>
        el.FieldType.includes('Information')
      ).map((el: FieldDefinition) => (
        <Grid item key={el.Id} xs={12}>
          <Alert severity="info" elevation={1}>
            <Typography>{el.Title}</Typography>
          </Alert>
        </Grid>
      ))}

      <Grid item xs={12}>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table
            actions={canAddMore && actions}
            columns={columns.map((column) => {
              const customPadding = isMobile ? '30px' : '40px';

              return {
                ...column,
                width: isMobile ? '20px' : '50px',
                headerStyle: {
                  paddingRight: customPadding,
                  paddingLeft: customPadding
                }
              };
            })}
            data={data}
            title={title}
            options={{
              rowStyle: (props: CompleteObjectInstance) => {
                if (!props.FieldInstanceDict) {
                  return {};
                }

                const numberOfFields = Object.values(
                  props.FieldInstanceDict
                ).length;

                const emptyfields = Object.values(
                  props.FieldInstanceDict
                ).filter((i: FieldInstance) => i.FieldValue === '').length;

                if (numberOfFields === emptyfields) {
                  return { background: `${theme.palette.primary.light}66` };
                }

                return {};
              },
              tableLayout: 'fixed'
            }}
            editable={undefined}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Component;
