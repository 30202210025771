import {
  TextField,
  MenuItem,
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { Event, Settings } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getEventColor } from 'components/Calendar/functions/mapEventData';
import { useProcess } from 'hooks';
import { useContext, useState } from 'react';
import { theme } from 'theme';
import { CalendarContext } from '../../context/CalendarContext';
import { EventContext } from '../../context/EventContext';
import { useCalendarConfig } from 'components/Stepper/components/ActionMenu/CalendarConfigContext';
import { TEventType } from 'components/Calendar/interface';

interface EventOption {
  value: TEventType;
  label: string;
  color: string;
}

const CalendarSubject = () => {
  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
      }}>
      <CalendarTitle />
      <div style={{ paddingBottom: theme.spacing(1) }} />
      <CalendarType />
    </div>
  );
};

export default CalendarSubject;

const CalendarTitle = () => {
  const { currentDeal } = useProcess();
  const { handleInputChange } = useContext(EventContext);
  const { formValues, error, setError } = useContext(CalendarContext);

  // if the input is not empty, remove the error message
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e);
    if (e.target.name === 'title' && e.target.value.trim() !== '') {
      if (error.component === 'Title' && error.mark) {
        setError({ reason: '', mark: false, component: 'None' });
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label="Event Title"
          placeholder="Event Title"
          variant="outlined"
          fullWidth
          required
          error={error.component === 'Title' ? error.mark : false}
          helperText={error.component === 'Title' ? error.reason : ''}
          value={formValues.title}
          name="title"
          onChange={onTitleChange}
          margin="normal"
          InputProps={{
            startAdornment: (
              <Event
                fontSize="medium"
                style={{ paddingRight: theme.spacing(1) }}
              />
            )
          }}
        />
      </Grid>
      {Object.values(currentDeal).length > 0 && (
        <Grid item xs={12} style={{ paddingBottom: theme.spacing(1) }}>
          <Alert>
            <AlertTitle>
              Deal Linked: {currentDeal.ProcessInstance.Id}
            </AlertTitle>
            <Typography variant="h6" color="textSecondary">
              This event will be linked in your calendar, allowing you to easily
              navigate to the deal through the provided link.
            </Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

const CalendarType = () => {
  const { formValues } = useContext(CalendarContext);
  const { handleSelectChange } = useContext(EventContext);
  const eventColor = getEventColor(formValues.eventType);
  const { allowedEventTypes } = useCalendarConfig();

  const eventOptions: EventOption[] = [
    { value: 'Call', label: 'Call', color: `${theme.palette.primary.light}66` },
    { value: 'Meeting', label: 'Meeting', color: '#0d730d' },
    { value: 'Notes', label: 'Notes', color: '#e69e19' },
    { value: 'Personal_Note', label: 'Personal Note', color: '#FFFF00' },
    { value: 'Birthdays', label: 'Birthdays', color: '#05696B' },
    { value: 'Deal_Expiry_Date', label: 'Deal Expiry Date', color: '#800080' },
    {
      value: 'End_of_Lease_Date',
      label: 'End of Lease Date',
      color: '#FF0000'
    },
    {
      value: 'Passing_Time_Date',
      label: 'Passing Time Date',
      color: '#ff007f'
    },
    { value: 'Annual_Leave', label: 'Annual Leave', color: '#0096FF' },
    {
      value: 'Buying_Appointment',
      label: 'Buying Appointment (forward buy date)',
      color: '#557397'
    },
    { value: 'TODO', label: 'TODO', color: '#245985' },
    { value: 'Other', label: 'Other', color: 'grey' }
  ];

  const filteredOptions = allowedEventTypes
    ? eventOptions.filter((option) => allowedEventTypes.includes(option.value))
    : eventOptions;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
      <div
        style={{
          width: '20px',
          height: '20px',
          borderRadius: '25%',
          backgroundColor: eventColor
        }}
      />
      <TextField
        data-cy="event-type"
        variant="outlined"
        fullWidth
        select
        label="Event"
        value={formValues.eventType}
        onChange={handleSelectChange}
        InputProps={{
          startAdornment: <Settings style={{ paddingRight: 8 }} />
        }}>
        <MenuItem disabled value={''}>
          Select Event Type
        </MenuItem>
        {filteredOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {formValues.eventType !== option.value && (
              <ColoredCircle color={option.color} />
            )}
            <div style={{ paddingLeft: 8 }} />
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

const ColoredMenuItem = ({
  value,
  color,
  label
}: {
  value: string;
  color: string;
  label: string;
}) => (
  <MenuItem value={value}>
    <ColoredCircle color={color} />
    <div style={{ paddingLeft: 8 }} />
    {label}
  </MenuItem>
);

const ColoredCircle = ({ color }: { color: string }) => {
  return (
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: color
      }}
    />
  );
};
