import { useState, useEffect, useContext } from 'react';
import { GetStepDocumentAsHTML } from 'redux/database';
import { useProcess } from 'hooks/useProcess';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  styled,
  useTheme
} from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useGlobal, useMobile } from 'hooks';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  CompleteObjectDefinitionDict,
  CompleteObjectInstance,
  CompleteObjectInstanceDict,
  FieldInstance
} from 'types/interfaces';
import { theme } from 'theme';
import { getCalculatorObjectsForProcess } from 'redux/actions/Calculator';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import isPreviewMode from '../Steps/ActiveStep/helper/previewMode';
import { Skeleton } from '@material-ui/lab';
import { QuoteObjectContext } from '../Steps/ActiveStep/CalculatorObject/context/QuoteObjectContext';
import { BugTracker } from 'Utils/Bugtracker';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'auto',
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paper: {
    padding: 20,
    borderRadius: theme.shape.borderRadius,
    margin: '20px 0',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '210mm',
    minHeight: '297mm',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
    '@media print': {
      boxShadow: 'none',
      margin: 0,
      padding: 0
    }
  },
  skeleton: {
    width: '100%',
    maxWidth: '210mm',
    height: '297mm',
    margin: '20px 0',
    boxSizing: 'border-box',
    backgroundColor: '#e0e0e0'
  }
}));

const StyledPaper = styled(Paper)(
  ({ isSelected }: { isSelected: boolean }) => ({
    transform: 'ease',
    background: isSelected
      ? theme.palette.success.main
      : theme.palette.grey[300],
    color: theme.palette.primary.contrastText,
    height: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: 10,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: isSelected
        ? theme.palette.success.dark
        : theme.palette.grey[300],
      cursor: 'pointer'
    }
  })
);

const StepDocumentHTML = ({
  downloadableIcon,
  customTitle,
  isQuote = false,
  object
}: {
  downloadableIcon?: boolean;
  customTitle?: boolean;
  isQuote?: boolean;
  object?: CompleteObjectInstance;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentStepId } = useTypedSelector((s) => s.process);

  const isMobile = useMobile();
  const { stepdefdict, landingpage, currentDeal, getSysDoc } = useProcess();
  const { downloadObject, selectObject } = useContext(QuoteObjectContext);

  const [html, setHtml] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSystemDoc, setLoadingSystemDoc] = useState<boolean>(false);

  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id;
  const ProcessStepDefinitionId = landingpage
    ? stepdefdict?.ProcessStepDefinition?.Id
    : currentStepId; // System Page : current selected step

  const getFile = async () => {
    if (!ProcessStepDefinitionId) return;

    try {
      setLoading(true);

      const res = await GetStepDocumentAsHTML({
        ProcessInstanceId,
        ProcessStepDefinitionId
      });

      if (res.data) {
        setHtml(res.data);
      } else {
        setHtml('<h1>Oh no, looks like no document is available</h1>');
      }
    } catch (e) {
      BugTracker.notify(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ProcessStepDefinitionId) getFile();
  }, [ProcessStepDefinitionId]);

  const QuoteObjectProcessStepDefinitionId = [1502, 1738];
  interface ILPC {
    CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
    CompleteObjectInstanceDict: CompleteObjectInstanceDict;
  }

  const handlePreviewQuote = async () => {
    const hasIndicativeQuoteSelection: CompleteObjectInstance = Object.values(
      currentDeal.CompleteObjectInstanceDict
    ).find((CompleteObjectInstance: CompleteObjectInstance) => {
      return CompleteObjectInstance.ObjectInstance.ObjectDefinitionId === 3613;
    });

    if (hasIndicativeQuoteSelection) {
      const SelectIndicativeQuoteFieldDefinition = 22712;
      const FieldInstanceList = getFieldInstances(hasIndicativeQuoteSelection);
      const isSelected = FieldInstanceList.find(
        (FieldInstance: FieldInstance) => {
          return (
            FieldInstance.FieldDefinitionId ===
            SelectIndicativeQuoteFieldDefinition
          );
        }
      );

      return isSelected;
    }

    const ProcessStepDefinition = QuoteObjectProcessStepDefinitionId.includes(
      stepdefdict.ProcessStepDefinition.Id
    );

    if (ProcessStepDefinition) {
      const res: ILPC | null = await getCalculatorObjectsForProcess({
        ProcessInstanceId: currentDeal.ProcessInstance.Id
      });

      if (res) {
        const isSelected = Object.values(res.CompleteObjectInstanceDict).find(
          (CompleteObjectInstance: CompleteObjectInstance) => {
            return CompleteObjectInstance.ObjectInstance.Selected;
          }
        );

        if (isSelected) return true;
        else return false;
      }
    }
  };

  if (downloadableIcon) {
    const checkIsSelected = async () => {
      if (isQuote) {
        const ProcessStepDefinition =
          QuoteObjectProcessStepDefinitionId.includes(
            stepdefdict.ProcessStepDefinition.Id
          );

        if (ProcessStepDefinition) {
          const hasSelectedQuote = await handlePreviewQuote();
          return hasSelectedQuote;
        }
      }
      return true;
    };

    const shouldHandleGettingSystemDoc = async () => {
      const ProcessStepDefinition = QuoteObjectProcessStepDefinitionId.includes(
        stepdefdict.ProcessStepDefinition.Id
      );

      if (!ProcessStepDefinition) return true;
      else if (object) return true;
      else {
        const hasSelectedQuote = await handlePreviewQuote();
        return hasSelectedQuote;
      }
    };

    const [isSelected, setIsSelected] = useState<boolean>(false);

    //* isPreview will only work if the magic link has been injected with preview.
    const isPreview = isPreviewMode(window.location.href, landingpage);
    useEffect(() => {
      if (!isPreview) {
        const fetchIsSelected = async () => {
          const result = await checkIsSelected();
          setIsSelected(!!result);
        };

        fetchIsSelected();
      } else setIsSelected(true);
    }, [currentDeal.CompleteObjectInstanceList]);

    if (loadingSystemDoc) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <StyledPaper
        data-cy="get-sys-doc"
        isSelected={isSelected}
        elevation={1}
        onClick={async () => {
          const shouldGetDocs = await shouldHandleGettingSystemDoc();
          if (!shouldGetDocs) return;

          try {
            setLoadingSystemDoc(true);
            if (!object || !customTitle) {
              await getSysDoc();
              return;
            }

            // Find currently selected object if any
            const res: ILPC | null = await getCalculatorObjectsForProcess({
              ProcessInstanceId: currentDeal.ProcessInstance.Id
            });

            if (!res) return;
            const originallySelectedObject = Object.values(
              res.CompleteObjectInstanceDict
            ).find(
              (CompleteObjectInstance: CompleteObjectInstance) =>
                CompleteObjectInstance.ObjectInstance.Selected
            );

            // If we need to select our target object and it's not already selected
            const needToSelectTargetObject = !object.ObjectInstance.Selected;
            if (needToSelectTargetObject) {
              await selectObject(object);
            }

            // Perform the main operation
            await getSysDoc();

            // Restore the selection states
            if (needToSelectTargetObject) {
              await selectObject(object); // Deselect our target object
            }

            // If there was a different object selected originally, reselect it
            if (
              originallySelectedObject &&
              originallySelectedObject.ObjectInstance.Id !==
                object.ObjectInstance.Id
            ) {
              await selectObject(originallySelectedObject);
            }
          } finally {
            setLoadingSystemDoc(false);
          }
        }}>
        <PictureAsPdfIcon />
        {customTitle
          ? `View ${stepdefdict.ProcessStepDefinition.Title}`
          : `${stepdefdict.ProcessStepDefinition.Title} Preview`}
      </StyledPaper>
    );
  }

  const transform = (node, index) => {
    const { name, type } = node;
    const h1 = 'font-size:50px;';
    const h2 = 'font-size:40px;';
    const h3 = 'font-size:18px;';
    const h4 = 'font-size:15px;';
    const h5 = 'font-size:12px;';

    if (['img'].includes(name) && node.parent) {
      // if (['img'].includes(node.children?.[0]?.name)) {
      node.attribs.style = 'height:100px;';
    }

    if (['h1', 'h2', 'h3', 'h4', 'h5', 'b'].includes(name)) {
      const base = 'color:#0066CC;text-align:center;';
      node.attribs.style = base;
      if (name === 'h1') node.attribs.style = `${base}${h1}`;
      if (name === 'h2') node.attribs.style = `${base}${h2}`;
      if (name === 'h3') node.attribs.style = `${base}${h3}`;
      if (name === 'h4') node.attribs.style = `${base}${h4}`;
      if (name === 'h5') node.attribs.style = `${base}${h5}`;
    }

    if (['li'].includes(name)) {
      const base = 'list-style-position: inside;';
      node.attribs.style = base;
    }

    if (['a'].includes(name)) {
      const base = 'text-decoration-line: underline;';
      node.attribs.style = base;
    }

    if (['table'].includes(name)) {
      node.attribs.style = 'width:100%;';
    }

    if (node.name === 'tr') {
      if (index / 2 == 0) {
        node.attribs.style = 'background:white';
      } else {
        if (index % 2 == 0) {
          node.attribs.style = 'background:#f8f8f8;';
        } else {
          node.attribs.style = 'background:white';
        }
      }
    }

    if (node.name === 'p') {
      node.attribs.style = 'margin-top:10px';
    }

    return convertNodeToElement(node, index, transform);
  };

  return (
    <Grid
      className={classes.container}
      spacing={1}
      container
      direction="column">
      <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
        <ButtonGroup>
          <Button onClick={getSysDoc} variant="outlined" color="primary">
            Download document
            <CloudDownloadIcon style={{ marginLeft: 8 }} />
          </Button>

          <Button variant="outlined" color="primary" onClick={getFile}>
            Refresh Data
            {loading ? (
              <CircularProgress size={20} style={{ marginLeft: 8 }} />
            ) : (
              <RefreshIcon style={{ marginLeft: 8 }} />
            )}
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <div style={{ paddingTop: theme.spacing(1) }}>
            <Skeleton variant="rect" width={'210mm'} height={2000} />
          </div>
        ) : (
          <Paper elevation={3} className={classes.paper}>
            {ReactHtmlParser(html, { transform })}
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default StepDocumentHTML;
