/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import { handleUpdateField } from '../../functions';
import DOMPurify from 'dompurify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Typography, Tooltip, Grid, ButtonGroup } from '@material-ui/core';
import './my-ckeditor-style.css';
import { useTypedSelector } from 'redux/reducers';
import { IFieldsProps } from '../../interface';
import EditIcon from '@material-ui/icons/Edit';
import { theme } from 'theme';
import { useDeviceType } from 'hooks/useDeviceType';
import { Alert, Color } from '@material-ui/lab';
import MoreOptions from './MoreOptions';
import firebase from 'firebase';
import SavedDraftsDialog from './SaveDraftDialog';

const useStyles = makeStyles((theme) => ({
  text: {
    transition: 'all 0.3s ease-in-out',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    width: '100%',
    height: '100%',
    color: (props: any) =>
      props.type === 'email' ? theme.palette.primary.main : 'inherit',
    cursor: (props: any) => (props.type === 'email' ? 'pointer' : 'default'),
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      color: (props: any) =>
        props.type === 'email' ? theme.palette.primary.contrastText : 'inherit',
      border: `1px ${theme.palette.primary.light} dashed`,
      padding: 15
    }
  },
  fieldInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    height: '100%',
    minHeight: '35px',
    width: '100%',
    background: (props: any) => {
      return props.parentsIsFormComponent
        ? theme.palette.background.default
        : '';
    },
    borderRadius: theme.shape.borderRadius
  },
  editorContainer: {
    width: '100%',
    '& .ck-editor__editable': {
      minHeight: '100px',
      padding: '10px',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`
    }
  },
  buttonContainer: {
    margin: theme.spacing(1),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const useEditorStyles = makeStyles((theme) => ({
  contentWrapper: {
    width: '100%',
    overflowWrap: 'break-word',
    '& div': {
      overflowWrap: 'break-word',
      inlineSize: '100%'
    }
  },
  editIcon: {
    marginRight: '10px',
    marginLeft: '5px'
  }
}));

const safeDecodeURIComponent = (value: string) => {
  try {
    return value ? decodeURIComponent(value) : '';
  } catch (e) {
    console.error('URI malformed', e);
    return '';
  }
};

const Component = (props: IFieldsProps) => {
  const theme = useTheme();
  const currentDevice = useDeviceType();
  const { value } = props;
  const editorRef = useRef<ClassicEditor | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [edit, setEdit] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [editorData, setEditorData] = useState(safeDecodeURIComponent(value));
  const [closeAlert, setCloseAlert] = useState(false);
  const [draftDialogOpen, setDraftDialogOpen] = useState(false);
  const user = useTypedSelector((s) => s.user.user);
  const saveDraftFieldIds = [24399, 24400, 24401, 24402, 24479, 24480, 24481];

  useEffect(() => {
    if (props.isDetails || props.isRepeatable) {
      setEditorData(safeDecodeURIComponent(value));
    }
  }, [value]);

  useEffect(() => {
    if (
      props.FieldDefinition &&
      saveDraftFieldIds.includes(props.FieldDefinition.Id)
    ) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        if (edit) {
          handleSave();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [edit, props.FieldDefinition]);

  const classesEditor = useEditorStyles();
  const classes = useStyles({
    instance: props.FieldInstance,
    edit,
    parentsIsFormComponent: props.parentsIsFormComponent
  });

  const token = useTypedSelector((s) => s.user.auth.token);
  const handleInteraction = () => {
    if (!props.readOnly) {
      setEdit(!edit);
    }
  };

  const onChange = handleUpdateField({ setUpdating, token, props });
  const handleSave = async () => {
    const {
      FieldDefinition,
      ObjectDefinition,
      ObjectInstance,
      refresh,
      handleObjectSaving
    } = props;

    setEdit(false);
    if (editorRef.current) {
      const data = editorRef.current.getData();
      if (onChange) {
        await onChange(encodeURIComponent(data));
        refresh && refresh();

        if (handleObjectSaving) {
          await handleObjectSaving({
            FieldDefinition,
            ObjectDefinition,
            ObjectInstance,
            comment: encodeURIComponent(data)
          });
        }
      }
    }
  };

  const handleSaveDraft = async () => {
    if (editorRef.current) {
      const data = editorRef.current.getData();
      const essentialData = {
        draftComment: encodeURIComponent(data),
        createdAt: new Date().toISOString()
      };

      try {
        await firebase
          .firestore()
          .collection('userAccount')
          .doc(user.Id.toString())
          .collection('savedProposalCommentsDrafts')
          .add(essentialData);
        //setEditorData('');
        setEdit(false);
      } catch (e) {
        console.error('Error saving draft to Firestore', e);
      }
    }
  };

  const handleViewSavedDrafts = async () => {
    setDraftDialogOpen(true);
  };

  const handleDraftSelection = (draft) => {
    setEditorData(decodeURIComponent(draft.draftComment));
    setDraftDialogOpen(false);
  };

  const interactionHandler =
    currentDevice === 'mobile' || currentDevice === 'tablet'
      ? { onTouchStart: handleInteraction }
      : { onClick: handleInteraction };

  const shouldShowAlertSeverity = (): Color | undefined => {
    if (!props.firestoreComment) return 'error';
    return props.firestoreComment.parentSection.comment === value
      ? 'info'
      : 'warning';
  };

  const shouldShowAlertText = (): string => {
    if (!props.firestoreComment) return 'Unknown Error Please Report';
    return props.firestoreComment.parentSection.comment === value
      ? 'The Content In Your Editor Matches Your Saved Draft. No Changes Detected.'
      : 'It Appears You Have a Saved Draft For Your Proposal Comments. Would You Like To Import This?';
  };

  const shouldHideButtons = (): boolean => {
    if (!props.firestoreComment) return true;
    return props.firestoreComment.parentSection.comment === value;
  };

  const handleAlertClose = () => setCloseAlert(true);
  const handleEditorImport = () => {
    if (props.firestoreComment && props.firestoreComment.parentSection) {
      const { comment } = props.firestoreComment.parentSection;
      setEditorData(decodeURIComponent(comment));
    }
    handleAlertClose();
  };

  if (updating) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </div>
    );
  }

  const sanitizedHTML = DOMPurify.sanitize(editorData);
  return (
    <div className={classes.fieldInput} ref={containerRef}>
      {!edit ? (
        <div
          {...interactionHandler}
          className={props.readOnly ? undefined : classes.text}>
          {!props.readOnly ? (
            <EditIcon fontSize="inherit" className={classesEditor.editIcon} />
          ) : null}

          <ContentWrapper>
            <div
              style={{ overflowWrap: 'break-word', inlineSize: '100%' }}
              dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            />
          </ContentWrapper>
        </div>
      ) : (
        <div className={classes.editorContainer}>
          {!closeAlert && props.firestoreComment && (
            <Alert
              style={{ borderEndStartRadius: 0, borderEndEndRadius: 0 }}
              severity={shouldShowAlertSeverity()}>
              <Grid container direction="column">
                <Grid item>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {shouldShowAlertText()}
                  </Typography>
                </Grid>
                {!shouldHideButtons() && (
                  <Grid item style={{ paddingTop: theme.spacing(0.5) }}>
                    <Button color="primary" onClick={handleAlertClose}>
                      Dismiss
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditorImport}>
                      Import {props.ObjectDefinition.Title}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Alert>
          )}
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            config={{
              toolbar: ['bold', 'italic', 'bulletedList'],
              language: 'en',
              placeholder: 'Enter Information Here...',
              table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
              }
            }}
            onReady={(editor) => {
              editorRef.current = editor;
              editor.editing.view.change((writer) => {
                const root = editor.editing.view.document.getRoot();
                if (root) {
                  writer.setStyle(
                    'border',
                    `1px solid ${theme.palette.divider}`,
                    root
                  );
                  writer.setStyle('padding-left', '20px', root);
                }
              });
            }}
            onChange={(_, editor) => {
              setEditorData(editor.getData());
            }}
          />
          <div className={classes.buttonContainer}>
            <Button color="secondary" onClick={() => setEdit(false)}>
              Cancel
            </Button>

            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            {props.FieldDefinition &&
              saveDraftFieldIds.includes(props.FieldDefinition.Id) && (
                <MoreOptions
                  onSaveDraft={handleSaveDraft}
                  onViewSavedDrafts={handleViewSavedDrafts}
                />
              )}
          </div>
        </div>
      )}
      {draftDialogOpen && (
        <SavedDraftsDialog
          open={draftDialogOpen}
          handleClose={() => setDraftDialogOpen(false)}
          userId={user.Id}
          onSelectDraft={handleDraftSelection}
        />
      )}
    </div>
  );
};

export default Component;

export const ContentWrapper = React.memo(({ children }) => {
  const classes = useEditorStyles();
  return (
    <div
      className={`${classes.contentWrapper} my-custom-editor ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline`}>
      {children}
    </div>
  );
});
