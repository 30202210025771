import { Dashboard as DashboardLayout } from '../../layouts';
import FAQAndGuides from 'components/FAQ/faqAndGuides';

const BipsGuideAndVideos = () => {
  return (
    <DashboardLayout title="FAQ Guide and Videos">
      <FAQAndGuides />
    </DashboardLayout>
  );
};
export default BipsGuideAndVideos;
