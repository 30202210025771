import { useCalendarStyles } from './styles';
import { useContext, useEffect, useRef } from 'react';
import { CalendarContext } from '../context/CalendarContext';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import multiMonthPlugin from '@fullcalendar/multimonth';
import CalendarModule from './CalendarModules';
import EventModules from './EventModules';

import { renderToString } from 'react-dom/server';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CallIcon from '@material-ui/icons/Call';
import NoteIcon from '@material-ui/icons/Note';
import { IEvent, TEventType } from '../interface';
import { UserInstance } from 'types/interfaces';
import {
  BeachAccess,
  Cake,
  DateRange,
  EventNote,
  Timelapse,
  PermPhoneMsg
} from '@material-ui/icons';
import alertSounds from '../../../assets/sounds/alertSound1.ogg';
import firebase from 'firebase';
import { useTypedSelector } from 'redux/reducers';
import { BugTracker } from 'Utils/Bugtracker';
import { notify } from 'components/Notifications/HotToastNotifications';

// Used For Testing Event Sounds
// const testEvents = [
//   {
//     id: '1',
//     title: 'Meeting with Bob',
//     start: new Date(Date.now() + 20000), // 5 minutes from now
//     eventType: 'Meeting',
//     extendedProps: { notification: true }
//   },
//   {
//     id: '2',
//     title: 'Call with Alice',
//     start: new Date(Date.now() + 15000), // 15 minutes from now
//     eventType: 'Call',
//     extendedProps: { notification: true }
//   },
//   {
//     id: '3',
//     title: 'Immediate Test Event',
//     start: new Date(Date.now() + 10000), // 5 seconds from now
//     eventType: 'Test',
//     extendedProps: { notification: true }
//   }
// ];

interface ICalendarProps {
  timelineEvents:
    | { UserInstance: UserInstance | undefined; Event: IEvent[] }
    | undefined;
  validRange?: { start?: Date; end?: Date };
  hideCalendarTimeBody?: boolean;
}

const Calendar = ({
  timelineEvents,
  validRange,
  hideCalendarTimeBody
}: ICalendarProps) => {
  const classes = useCalendarStyles();
  const {
    calendarRef,
    handleEventOpen,
    isSelectedDate,
    key,
    allEvents,
    setEventDialogOpen,
    eventDialogOpen
  } = useContext(CalendarContext);
  const { user } = useTypedSelector((s) => s.user);
  const listenerEventIds = useRef<Set<string>>(new Set());

  const getEventIcon = (eventType: TEventType) => {
    const iconStyle = 'style="fill: white; color: white;"';

    switch (eventType) {
      case 'Call':
        return renderToString(<CallIcon fontSize="small" htmlColor="white" />);
      case 'Meeting':
        return renderToString(
          <MeetingRoomIcon fontSize="small" htmlColor="white" />
        );
      case 'Notes':
        return renderToString(<NoteIcon fontSize="small" htmlColor="white" />);
      case 'Deal_Expiry_Date':
        return renderToString(<DateRange fontSize="small" htmlColor="white" />);
      case 'End_of_Lease_Date':
        return renderToString(<DateRange fontSize="small" htmlColor="white" />);
      case 'Passing_Time_Date':
        return renderToString(<Timelapse fontSize="small" htmlColor="white" />);
      case 'Personal_Note':
        return renderToString(<EventNote fontSize="small" htmlColor="white" />);
      case 'Annual_Leave':
        return renderToString(
          <BeachAccess fontSize="small" htmlColor="white" />
        );
      case 'Buying_Appointment':
        return renderToString(
          <PermPhoneMsg fontSize="small" htmlColor="white" />
        );
      case 'Birthdays':
        return renderToString(<Cake fontSize="small" htmlColor="white" />);
      default:
        return renderToString(
          <FormatListBulletedIcon fontSize="small" htmlColor="white" />
        );
    }
  };
  useEffect(() => {
    const Setup = async () => {
      const cleanup = await setupEventNotifications(
        allEvents,
        calendarRef,
        user,
        listenerEventIds.current
      );
      return cleanup;
    };

    Setup();
  }, [allEvents]);

  return (
    <div className={classes.calendar}>
      <FullCalendar
        key={key}
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin,
          multiMonthPlugin,
          rrulePlugin
        ]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next monthButton,multiMonthButton',
          center: 'title',
          right: 'monthListButton,weekButton,dayButton,agendaButton'
        }}
        validRange={validRange}
        views={{
          multiMonth: {
            type: 'multiMonth',
            duration: { months: 6 },
            buttonText: 'Multi-month'
          }
        }}
        customButtons={{
          multiMonthButton: {
            text: 'Multi-month',
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('multiMonth');
            }
          },
          monthButton: {
            text: 'Today',
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('dayGridMonth');
              calendarApi.today();
            }
          },
          agendaButton: {
            text: 'Agenda',
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('listMonth');
            }
          },
          dayButton: {
            text: 'Day',
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('timeGridDay');
            }
          },
          weekButton: {
            text: 'Week',
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('timeGridWeek');
            }
          },
          monthListButton: {
            text: 'Month',
            click: () => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.changeView('dayGridMonth');
            }
          }
        }}
        eventContent={(renderInfo) => {
          const icon = getEventIcon(renderInfo.event.extendedProps.eventType);
          const backgroundColor = renderInfo.event.backgroundColor || '#e69e19';

          return {
            html: `
              <div style="
                background-color: ${backgroundColor};
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 4px 8px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border-radius: 3px;
              ">
                ${icon}
                <span style="color: white; margin-left: 4px;">
                  ${renderInfo.event.title}
                </span>
              </div>
            `
          };
        }}
        dayMaxEventRows
        events={
          timelineEvents && timelineEvents.Event.length >= 0
            ? timelineEvents.Event
            : allEvents
        }
        eventClick={(info) => {
          setEventDialogOpen({
            ...eventDialogOpen,
            event: info.event,
            [info.event.id]: true
          });
        }}
        selectable
        select={(selectInfo) => handleEventOpen(selectInfo)}
        dayCellContent={(args) => args.date.getDate()}
        dayCellClassNames={(args) => {
          const isSelected = isSelectedDate(args.date);
          return isSelected ? [classes.selected] : [];
        }}
      />

      <CalendarModule hideCalendarTimeBody={hideCalendarTimeBody} />
      <EventModules />
    </div>
  );
};

export default Calendar;

const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const setupEventNotifications = async (
  allEvents: IEvent[],
  calendarRef: React.MutableRefObject<any>,
  user: UserInstance,
  listenerEventIds: Set<string>
): Promise<(() => void) | undefined> => {
  const now = new Date();
  const todayEvents = allEvents.filter((event: IEvent) => {
    const eventDate = new Date(event.start);
    const isEventToday = isToday(eventDate);
    const isFutureEvent = eventDate.getTime() > now.getTime();
    return isEventToday && isFutureEvent;
  });

  if (todayEvents.length === 0) return undefined;
  const UserInstanceId = user.Id.toString();
  let notificationSound, notificationTime;

  try {
    const doc = await firebase
      .firestore()
      .collection('userAccount')
      .doc(UserInstanceId)
      .get();

    if (doc.exists) {
      const data = doc.data();
      notificationSound = data?.notification.fileRoot || alertSounds;
      const tenMinutes = 10;
      const notificationTimeInMinutes = data?.notificationTime || tenMinutes;
      notificationTime = notificationTimeInMinutes * 60000;
    }
  } catch (e) {
    BugTracker.notify(e);
  }

  if (notificationSound && notificationTime) {
    const audioRef = new Audio(notificationSound);
    let notificationCount = 0;

    const updateTabTitle = (showNotification: boolean, eventTitle: string) => {
      if (showNotification && notificationCount > 0) {
        document.title = `🔴 Upcoming Event - ${eventTitle}`;
      } else {
        document.title = 'Bips';
      }
    };

    const handleEventAlert = (event: IEvent) => {
      const eventTime = new Date(event.start).getTime();
      const now = new Date().getTime();
      const notificationTriggerTime = eventTime - notificationTime;
      const timeDifference = notificationTriggerTime - now;

      if (timeDifference > 0) {
        setTimeout(() => {
          audioRef
            .play()
            .catch((error) => console.error('Audio Player Error:', error));

          notificationCount += 1;
          updateTabTitle(true, event.title);

          notify.warning(`🔴 Upcoming Event - ${event.title}`);
        }, timeDifference);
      }
    };

    const handleEvents = (events: IEvent[]) => {
      events.forEach((event) => {
        if (
          event.extendedProps.notification &&
          !listenerEventIds.has(event.id || '')
        ) {
          handleEventAlert(event);
          listenerEventIds.add(event.id || '');
        }
      });
    };

    handleEvents(todayEvents);

    const calendarApi = calendarRef.current.getApi();
    const handleEventAdd = (info: { event: IEvent }) => {
      if (
        info.event.extendedProps.notification &&
        !listenerEventIds.has(info.event.id || '')
      ) {
        handleEventAlert(info.event);
        listenerEventIds.add(info.event.id || '');
      }
    };

    calendarApi.on('eventAdd', handleEventAdd);
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        notificationCount = 0;
        updateTabTitle(false, 'Bips');
      }
    });

    return () => {
      calendarApi.off('eventAdd', handleEventAdd);
    };
  }
};
