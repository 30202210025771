import { CompleteObjectDefinition } from 'types/interfaces';
import { isShown } from '../Forms/helpers';

/**
 * Type definition for regulation status
 */
type RegulationStatus = {
  hasEntity?: boolean;
  isRegulatedParty?: boolean;
};

/**
 * Object description markers for different regulation types
 */
const REGULATION_MARKERS = {
  REGULATED: 'regulated',
  NON_REGULATED: 'nonreg',
  CUSTOM_NON_REGULATED: 'customNonReg',
  BOTH: 'both'
} as const;

/**
 * Filters objects based on their regulation status and entity type
 *
 * @param {Object} params - Filter parameters
 * @param {CompleteObjectDefinition} params.item - The object definition to check
 * @param {string} params.entityType - The type of entity being processed
 * @param {RegulationStatus} params.regulated - Object containing regulation status flags
 * @param {boolean} [params.regulated.hasEntity] - Whether the entity exists
 * @param {boolean} [params.regulated.isRegulatedParty] - Whether the party is regulated
 *
 * @returns {boolean} Returns true if the object should be included based on regulation rules
 *
 * @description
 * This function implements the following logic:
 * 1. Checks object descriptions for regulation markers
 * 2. Handles special cases for partnerships
 * 3. Processes regulated and non-regulated parties differently
 * 4. Supports "both" marker for objects that work with any regulation status
 *
 * Regulation Rules:
 * - Objects marked as "both" are always included
 * - Custom non-regulated objects are included for partnerships
 * - For regulated parties, only non-regulated objects are excluded
 * - For non-regulated parties, only regulated objects are excluded
 */
export const regulatedFilter = ({
  item,
  entityType,
  regulated = {}
}: {
  item: CompleteObjectDefinition;
  entityType: string;
  regulated: RegulationStatus;
}): boolean => {
  // Extract object description, defaulting to empty string if undefined
  const ObjectDescription = item?.ObjectDefinition?.ObjectDescription || '';

  // Check for different regulation markers in the object description
  const isRegObject = ObjectDescription.includes(REGULATION_MARKERS.REGULATED);
  const isUnRegObject =
    ObjectDescription.includes(REGULATION_MARKERS.NON_REGULATED) &&
    !ObjectDescription.includes(REGULATION_MARKERS.CUSTOM_NON_REGULATED);

  const isNonRegCustomObject = ObjectDescription.includes(
    REGULATION_MARKERS.CUSTOM_NON_REGULATED
  );

  // Entity type checks
  const isPartnership = entityType === 'Partnership (4+)';
  const isRegParty = regulated?.isRegulatedParty;
  const showAllowed = isShown(item, entityType);

  // Special case for partnerships with custom non-regulated objects
  if (isNonRegCustomObject && isPartnership) {
    return true;
  }

  if (isPartnership && showAllowed) {
    return true;
  }

  // For regulated parties, include everything except explicitly non-regulated objects
  if (isRegParty) {
    return !isUnRegObject;
  }

  // For non-regulated parties, exclude regulated objects
  return !isRegObject;
};

/**
 * Example usage:
 *
 * const result = regulatedFilter({
 *   item: {
 *     ObjectDefinition: {
 *       ObjectDescription: 'regulated customer-info'
 *     }
 *   },
 *   entityType: 'Partnership (4+)',
 *   regulated: {
 *     isRegulatedParty: true
 *   }
 * });
 */
