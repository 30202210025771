import { FbFileRef } from 'types/interfaces';

export enum CustomerType {
  Prospect = 'Prospect',
  NewCustomer = 'New Customer',
  ExistingCustomer = 'Existing Customer',
  PotentialLead = 'Potential Lead',
  HotLead = 'Hot Lead',
  Referral = 'Referral',
  None = 'None'
}

export type EventType = 'Customer Type' | 'Calendar Events' | 'Email Events';

export interface StateType {
  'Customer Type': boolean;
  'Calendar Event': boolean;
  'Marketing Campaign': boolean;
  log: boolean;
  'File Note': boolean;
  'Deal Note': boolean;
}

export interface HistoryEvent {
  name: CustomerType | string;
  timestamp: number;
  prevType: CustomerType | string;
  notes?: string;
  attachments?: FbFileRef[];
}

export interface INestedEventType {
  history: HistoryEvent[];
  selected?: CustomerType;
  note?: string;
}

export interface FirestoreDocType {
  'Customer Type': INestedEventType;
  Notes: string;
  TODO: string;
  Call: string;
  Birthdays: string;
  Deal_Expiry_Date: string;
  others: string;
  log: string;
  open: string;
  click: string;
  'File Note': INestedEventType;
  'Deal Note': string;
}
