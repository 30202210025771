import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import MainCalendar from 'components/Calendar';
import CalendarConfigContext from './CalendarConfigContext';
import { INIT_FORM } from 'components/Calendar/interface';

const DealPaidOutCalendarDialog = ({ open, onClose, onAccept }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <CalendarConfigContext.Provider
        value={{
          allowedEventTypes: [
            'Deal_Expiry_Date',
            'End_of_Lease_Date',
            'Passing_Time_Date'
          ]
        }}>
        <DialogTitle>Select a Date for the Event</DialogTitle>
        <DialogContent>
          <MainCalendar
            validRange={{ start: new Date('2000-01-01'), end: new Date() }}
            defaultFormValues={{ ...INIT_FORM, eventType: 'Deal_Expiry_Date' }}
            hideCalendarTimeBody
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              onAccept(selectedDate);
            }}>
            Accept
          </Button>
        </DialogActions>
      </CalendarConfigContext.Provider>
    </Dialog>
  );
};

export default DealPaidOutCalendarDialog;
