import React from 'react';
import {
  Button,
  Drawer,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Toolbar,
  useTheme,
  DialogContent
} from '@material-ui/core';
import DetailView from '../Overview/components/DetailView';
import ActivityLog from '../Overview/components/ActivityLog';
import { BsUiChecksGrid } from 'react-icons/bs';
import { useTypedSelector } from 'redux/reducers';
import CloseIcon from '@material-ui/icons/Close';
import { CustomDialog } from 'common/Dialog';
import FAQAndGuides from 'components/FAQ/faqAndGuides';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      color: theme.palette.primary.contrastText
    },
    header: {
      color: theme.palette.primary.contrastText
    },
    title: {
      padding: theme.spacing(2),
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    icon: {
      fontSize: 20
    },
    text: { color: 'white', fontWeight: 'bold' },
    dealProgressOverview: {
      backgroundColor: theme.palette.primary.light,
      justifyContent: 'flex-start',
      textTransform: 'none',
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        borderRight: `50px solid ${theme.palette.primary.main}`,
        background: theme.palette.grey[300],
        color: theme.palette.grey[800],

        cursor: 'pointer',
        '& > p': {
          color: theme.palette.grey[800]
        },
        '& > svg': {
          color: theme.palette.grey[800]
        }
      },
      color: 'white'
    }
  })
);

const OverviewDrawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentProcess, currentOverView } = useTypedSelector(
    (s) => s.process
  );
  const [state, setState] = React.useState(false);
  const toggleDrawer = () => setState(!state);
  const { user } = useTypedSelector((s) => s.user);
  const isSubSystemUser = user?.SystemAccess <= 4;
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);

  return (
    <div>
      {!isSubSystemUser && (
        <div className={classes.dealProgressOverview} onClick={toggleDrawer}>
          <BsUiChecksGrid className={classes.icon} style={{ marginRight: 8 }} />
          <Typography className={classes.text}>Activity Log</Typography>
        </div>
      )}

      <Drawer
        anchor="left"
        open={state}
        onClose={() => setState(false)}
        PaperProps={{ elevation: 10, style: { background: 'transparent' } }}>
        <div
          style={{
            width: '60vw',
            backdropFilter: `blur(3px)`,
            minHeight: '100vh'
          }}>
          <div className={classes.header}>
            <Toolbar>
              <div style={{ flexGrow: 1 }} />
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setState(false)}>
                <CloseIcon />
              </Button>
            </Toolbar>
          </div>

          {!isSubSystemUser && (
            <div className={classes.section}>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10 }}
                onClick={() => setHelpDialogOpen(true)}>
                <HelpIcon style={{ marginRight: 8 }} />
                Help
              </Button>
              <div className={classes.title}>
                <Typography variant="h3" color="inherit">
                  Activity Log
                </Typography>
              </div>
              <ActivityLog
                ProcessInstance={currentOverView}
                ProcessDefinition={currentProcess}
              />
            </div>
          )}
        </div>
        <CustomDialog
          maxSize="md"
          handleClose={() => setHelpDialogOpen(false)}
          open={helpDialogOpen}
          staticPosition
          alert={{
            title: 'Help',
            description: `Need help with Activity Log? Here are some guides and videos to help you.`,
            type: 'info'
          }}>
          <DialogContent style={{ minHeight: 250, minWidth: 500 }}>
            <FAQAndGuides folderName="Activity Log" tags={['Activity Log']} />
          </DialogContent>
        </CustomDialog>
      </Drawer>
    </div>
  );
};

export default OverviewDrawer;
