import {
  Cake,
  DateRange,
  EmojiEmotions,
  Help,
  InsertLink,
  NewReleases,
  PeopleAlt,
  Whatshot
} from '@material-ui/icons';
import MouseIcon from '@material-ui/icons/Mouse';
import DraftsIcon from '@material-ui/icons/Drafts';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ErrorIcon from '@material-ui/icons/Error';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArchiveIcon from '@material-ui/icons/Archive';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import NoteIcon from '@material-ui/icons/Note';
import PhoneIcon from '@material-ui/icons/Phone';
import WebIcon from '@material-ui/icons/Web';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const iconMap = {
  click: <MouseIcon />,
  open: <DraftsIcon />,
  delivered: <AllInboxIcon />,
  bounce: <ErrorIcon />,
  Unsubscribe: <UnsubscribeIcon />,
  spam_report: <NotInterestedIcon />,
  dropped: <ArchiveIcon />,
  Meeting: <MeetingRoomIcon />,
  Call: <PhoneIcon />,
  'landing page email': <WebIcon />,
  Notes: <NoteIcon />,
  TODO: <FormatListBulletedIcon />,
  Deal_Expiry_Date: <DateRange />,
  Birthdays: <Cake />,
  log: <TrackChangesIcon />,
  'File Note': <NoteIcon />,
  'Existing Customer': <PeopleAlt />,
  'Hot Lead': <Whatshot />,
  'New Customer': <NewReleases />,
  'Potential Lead': <Help />,
  Prospect: <EmojiEmotions />,
  Referral: <InsertLink />
};

export const IconSelector = ({ type }) => {
  return iconMap[type] || <DraftsIcon />;
};
