import axios from 'axios';
import { notify } from 'components/Notifications/HotToastNotifications';
import { firebase } from 'redux/firebase';

export const fetchListContactCount = async ({ list_id }) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/lists/${list_id}/contacts/count`;

  return axios
    .get(url, { headers: { token } })
    .then((res) => res.data.contact_count)
    .catch((e) => {
      notify.error(
        'Looks like you have deleted the list that was attached to this email'
      );
    });
};
