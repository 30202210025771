import React from 'react';
import { store } from 'redux/store';
import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createBrowserHistory } from 'history';
import { Notify } from 'react-redux-notify';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { theme as lightTheme } from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import 'react-redux-notify/dist/ReactReduxNotify.css';
import Routes from './Routes';
import { ErrorView } from './views/ErrorView';
import { BugTracker } from 'Utils/Bugtracker';
import LegacyDialog from '../src/components/LegacyDealsViewer/components/LegacyDialog';
import { UPDATE_DARK_MODE } from 'redux/actions/types';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './services/microsoft/authConfig';
import { apolloClient } from 'graphql/apolloClient';
import { Toaster } from 'react-hot-toast';

const msalInstance = new PublicClientApplication(msalConfig);
const ErrorBoundary = BugTracker.getPlugin('react').createErrorBoundary(React);
const browserHistory = createBrowserHistory();

const App = ({ reloadTheme, userSocket }: any) => {
  const dispatch = useDispatch();
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const updatedTheme = store.getState().config.theme;
    setTheme(updatedTheme);
  }, [reloadTheme]);

  useEffect(() => {
    dispatch({
      type: UPDATE_DARK_MODE,
      payload: userSocket && userSocket?.darkMode ? 'dark' : 'light'
    });
  }, [userSocket]);

  (window as any).__APOLLO_CLIENT__ = apolloClient;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <ErrorBoundary FallbackComponent={ErrorView} onError={console.log}>
            <Router history={browserHistory}>
              <LegacyDialog />
              <Routes />
              <Notify customStyles={{ background: 'red', color: 'yellow' }} />
              <Toaster />
            </Router>
          </ErrorBoundary>
        </MsalProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  reloadTheme: state.config.theme.palette,
  userSocket: state.fb.proxy_settings
});

export default connect(mapStateToProps)(App);

export const RedflagClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://azp-primary-api.azurewebsites.net/graphql`,
  connectToDevTools: true
});
