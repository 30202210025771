import DLLProposal from '.';
import { ELender, ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';

export const getDLLIds = () => {
  return createTypeSafeIds(DLLProposal, (_) => ({
    financeProduct: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Dropdown,
        options: ['hire-purchase', 'financial-lease']
      }
    },
    externalReference: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'ProcessInstanceId'
    },
    lessee: {
      id: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Hide
        }
      },
      legalEntity: {
        id: {
          FieldDefinitionId: 21745,
          isReadonly: false,
          info: 'id of the legal entity',
          required: true
        }
      },

      preferredLocale: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'en-GB'
      },
      uniqueIdentifier: {
        FieldDefinitionId: 18315,
        isReadonly: true
      },
      uniqueIdentifierType: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'organization-number'
      },
      countryOfRegistration: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'GB'
      },
      businessType: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Hide
        }
        // FieldDefinition: {
        //   type: [ELenderAPIType.Dropdown, ELenderAPIType.EntityChange],
        //   options: [
        //     'association-of-physicians',
        //     'government',
        //     'private-physicians',
        //     'other'
        //   ]
        // }
      },
      businessActivity: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Hide
        }
        // FieldDefinition: {
        //   type: ELenderAPIType.Dropdown,
        //   options: [
        //     'biotechnology-company',
        //     'city-hospital',
        //     'consultant',
        //     'dental-laboratory',
        //     'dental-specialist',
        //     'dentist',
        //     'diagnostic-center',
        //     'general-practitioner',
        //     'medical-care-center',
        //     'ophthalmologist',
        //     'optician',
        //     'private-hospital',
        //     'radiologist',
        //     'veterinarians',
        //     'other'
        //   ]
        // }
      },
      // externalIdentifiers: [
      //   {
      //     type: {
      //       FieldDefinitionId: 0,
      //       isReadonly: false,
      //       FieldDefinition: {
      //         type: ELenderAPIType.Dropdown,
      //         options: ['medical', 'dental', 'public-service-number', 'other']
      //       }
      //     },

      //     id: {
      //       FieldDefinitionId: 0,
      //       isReadonly: false,
      //       info: 'The registration number or identity number as per the records of authority mentioned in the type.'
      //     }
      //   }
      // ],
      phoneNumbers: [
        {
          type: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['mobile', 'home', 'business', 'other']
            }
          },
          number: {
            FieldDefinitionId: 0,
            required: true,
            isReadonly: false,
            info: 'The actual phone number. This should include country code, area code and number',
            FieldDefinition: {
              type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
              newIdList: [
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 23625
                },

                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21874
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21874
                }
              ]
            }
          }
        }
      ],
      email: {
        FieldDefinitionId: 18525,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
          requirement: {
            maxLength: 255,
            pattern:
              '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            message: 'Please enter a valid email address'
          },
          newIdList: [
            {
              entityType: 'Sole Trader',
              FieldDefinitionId: 21873
            },
            {
              entityType: 'Partnership',
              FieldDefinitionId: 21873
            }
          ]
        }
      },
      organization: {
        name: {
          FieldDefinitionId: 18317,
          isReadonly: false,
          required: true,
          info: 'The commercial name for the company',
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 21866
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 21866
              }
            ]
          }
        },
        establishmentDate: {
          FieldDefinitionId: 18450,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        cityOfRegistration: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'UK'
        }
      },
      individual: {
        givenName: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        familyName: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 21810
              }
            ]
          }
        },
        birthDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [
              ELenderAPIType.Date,
              ELenderAPIType.Requirement,
              ELenderAPIType.EntityChange
            ],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            },
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 21810
              }
            ]
          }
        },
        sex: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Dropdown],
            options: ['female', 'male', 'would-rather-not-say']
          }
        }
      },
      address: {
        street: {
          FieldDefinitionId: 18431,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 23962
              },

              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 23662
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 23662
              }
            ]
          }
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: `The official (ISO) two-letter country code, e.g "gb"`,
          forcedValue: 'gb'
        },
        postalCode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        },
        city: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          required: true
        }
      },
      invoiceAddress: {
        street: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: `The official (ISO) two-letter country code, e.g "gb"`,
          forcedValue: 'gb'
        },
        postalCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        },
        city: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        email: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
            requirement: {
              maxLength: 255,
              pattern:
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
              message: 'Please enter a valid email address'
            }
          }
        }
      },
      previousAddress: {
        street: {
          FieldDefinitionId: 0,
          isReadonly: false
        },
        postalCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        },
        city: {
          FieldDefinitionId: 0,
          isReadonly: false
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: `The official (ISO) two-letter country code, e.g "gb"`
        }
      },
      contacts: [
        {
          name: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'Name of the contact',
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 21810
                },

                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 23976
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 23976
                }
              ]
            }
          },
          email: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              },
              newIdList: [
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 21810
                },

                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 23976
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 23976
                }
              ]
            }
          },
          phoneNumbers: [
            {
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['mobile', 'home', 'business', 'other']
                }
              },
              number: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'The actual phone number. This should include country code, area code and number',
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              }
            }
          ]
        }
      ],
      bankDetails: {
        accountNumber: {
          FieldDefinitionId: 21803,
          isReadonly: false,
          info: 'The account number at the given bank; required if paymentMethod = direct-debit'
        },
        accountNumberType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['iban', 'bban']
          }
        },
        bankIdentifierType: {
          FieldDefinitionId: 21804,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Dropdown],
            options: ['bic', 'sort-code']
          }
        },
        paymentMethod: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'standing-order',
              'direct-debit',
              'cheque',
              'state-payment',
              'telegraphic-transfer'
            ]
          }
        }
      },
      partners: [
        {
          givenName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'The given name of the individual'
          },
          familyName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'The family name of the individual'
          },

          birthDate: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          mobileNumber: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          address: {
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: `The official (ISO) two-letter country code, e.g "gb"`,
              forcedValue: 'gb'
            },
            postalCode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            city: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          }
          // externalIdentifiers: [
          //   {
          //     id: {
          //       FieldDefinitionId: 0,
          //       isReadonly: false,
          //       required: true,
          //       info: 'The registration number or identity number as per the records of authority mentioned in the type.'
          //     },
          //     type: {
          //       FieldDefinitionId: 0,
          //       isReadonly: false,
          //       required: true,
          //       FieldDefinition: {
          //         type: ELenderAPIType.Dropdown,
          //         options: [
          //           'medical',
          //           'dental',
          //           'public-service-number',
          //           'other'
          //         ]
          //       },
          //       info: 'The registration number or identity number as per the records of authority mentioned in the type.'
          //     }
          //   }
          // ]
        }
      ],
      directors: [
        {
          givenName: {
            FieldDefinitionId: 18427,
            isReadonly: false,
            required: true,
            info: 'The given name of the individual'
          },
          familyName: {
            FieldDefinitionId: 18420,
            isReadonly: false,
            required: true,
            info: 'The family name of the individual'
          },

          birthDate: {
            FieldDefinitionId: 18416,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          }
        }
      ]
    },
    proposals: [
      {
        startDate: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'currentDate',
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        endDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        preferred: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },

        calculation: {
          financeInput: {
            financeStructure: {
              numberOfMonths: {
                FieldDefinitionId: 23271,
                isReadonly: false,
                info: 'The fixed number of months to be calculated. When omitted, the numberOfPayments parameter is expected. By specifying this parameter, the number of payments is determined dynamically. Note that a consumer must provide either the numberOfpayments or numberOfMonths parameter. If neither is provided, or if both are provided, an error response is returned.',
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              numberOfPayments: {
                FieldDefinitionId: 23905,
                isReadonly: false,
                info: 'The fixed number of payments to be calculated. When omitted, the numberOfMonths parameter is expected. By specifying this parameter, the contract term in months is determined dynamically. Note that a consumer must provide either the numberOfpayments or numberOfMonths parameter. If neither is provided or if both are provided, an error response is returned.',
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              delayPayment: [
                {
                  type: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    info: 'The type of payments to be delayed: regular or all regular, all',
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['regular', 'all']
                    }
                  },
                  initialPause: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'Specifies an initial pause in terms of initialPauseUnit. This is an alternative for a delayed startDate. If both provided, error is returned. If paymentType = all, this is the duration relative to the contract startDate in which there is not any payment at all. If paymentType = regular, this is the duration in which there is no regular payments.',
                    FieldDefinition: {
                      type: ELenderAPIType.Number
                    }
                  },
                  initialPauseUnit: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    info: 'The unit in which the initialPause is expressed. Mandatory if initialPause is provided. days, months',
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['days', 'months']
                    }
                  },
                  startDate: {
                    FieldDefinitionId: 0,
                    isReadonly: true,
                    info: 'The delayed payment start date for the finance application. This is an alternative for an initialPause. If both provided, error is returned. If paymentType = regular, this is the date of the first regular payment. If paymentType = all, this is the date of the first payment for the finance application. Currenty, postponing the first (initial) payment using a delayed startDate is not possible.',
                    forcedValue: 'currentDate',
                    FieldDefinition: {
                      type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                      requirement: {
                        pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                      }
                    }
                  }
                }
              ],
              terminalPauseRegularPayments: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Specifies the terminal pause in months at the end of the contract in which there no regular payments',
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              paymentFrequency: {
                FieldDefinitionId: 18333,
                isReadonly: false,
                required: true,
                info: 'The frequency in which payments are made. The default is monthly monthly, quarterly, semi-annually, annually',
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['monthly', 'quarterly', 'semi-annually', 'annually']
                }
              },
              paymentTiming: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Specifies when payments are due each period. The default is in-advance. in-advance, in-arrears',
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['in-advance', 'in-arrears']
                }
              },
              paymentMethod: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'The default means of payment used by the party to compensate the lender standing-order, direct-debit, cheque, state-paymentk, telegraphic-transfer',
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'standing-order',
                    'direct-debit',
                    'cheque',
                    'state-payment',
                    'telegraphic-transfer'
                  ]
                }
              },
              delayVatPayment: {
                FieldDefinitionId: 23839,
                isReadonly: false,
                info: 'Specifies the delay in months after the date of the first payment when a deferred VAT amount must be paid. Omit this parameter if no VAT deferred payment needs to occur. When specified, the the financial product must support vatFinancing.',
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              finance: {
                financeAmount: {
                  FieldDefinitionId: 23958,
                  isReadonly: false,
                  info: 'The total finance amount for the finance contract. If assets are provided, it will be ignored.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                initialPaymentMultiplier: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Specifies a multiple of regular payments paid as the first payment. Note that if initialPaymentAmount and initialPaymentMultiplier are both provided, error is returned.',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                initialPaymentAmount: {
                  FieldDefinitionId: 23957,
                  isReadonly: false,
                  info: 'Specifies the amount of the first rental payment in the (amortized) payment schedule. Note that if initialPaymentAmount and initialPaymentMultiplier are both provided, error is returned.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                balloon: {
                  FieldDefinitionId: 21325,
                  isReadonly: false,
                  info: 'Specifies a repayment of the outstanding principal sum made at the end of a loan period.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                netDeposit: {
                  amount: {
                    FieldDefinitionId: 23820,
                    isReadonly: false,
                    info: 'The netDeposit amount (downPayment + tradeIn - settlement)',
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  downPayment: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The total of payments made at a time of finalizing the contract in order to lower the finance amount.',
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  settlement: {
                    FieldDefinitionId: 23827,
                    isReadonly: false,
                    info: 'The residual value of an earlier financial contract that the lessee wishes to include in the finance. This increases the finance amount.',
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  settlementContractType: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The type of an earlier financial contract that the lessee wishes to include in the finance. It is mandatory to be provided if the financial product has settlementContractType required = true and a settlement amount is provided for the finance application. financial-lease, operating-lease, loan, renting, loan-with-pledge, contract-hire, hire-purchase, fair-market-value',
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: [
                        'financial-lease',
                        'operating-lease',
                        'loan',
                        'renting',
                        'loan-with-pledge',
                        'contract-hire',
                        'hire-purchase',
                        'fair-market-value'
                      ]
                    }
                  },
                  tradeIn: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The value of any trade-in asset that the lessee wishes to lower the finance amount.',
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                },
                payments: {
                  custom: [
                    {
                      paymentDate: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        info: 'the date of the payment',
                        FieldDefinition: {
                          type: [
                            ELenderAPIType.Date,
                            ELenderAPIType.Requirement
                          ],
                          requirement: {
                            pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                          }
                        }
                      },
                      type: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        info: "Indicates the payment type. 'regular' indicates the regular payments(other than the initial and the balloon), which can be expressed as a fixed 'amount' or a 'multiplier' of regular payments that will be calculated, or a 'percentageOf' another finance attribute. 'non-regular-initial' indicates the first payment or initial payment, which can be expressed as a fixed 'amount' or a 'multiplier' of regular payments that will be calculated, or a 'percentageOf' another finance attribute. 'non-regular-balloon' indicates the balloon payment at the end of the contract, which can only be expressed as a fixed 'amount'. regular, non-regular-initial, non-regular-balloon",
                        FieldDefinition: {
                          type: ELenderAPIType.Dropdown,
                          options: [
                            'regular',
                            'non-regular-initial',
                            'non-regular-balloon'
                          ]
                        }
                      },
                      amount: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        info: 'Indicates a fixed payment amount for any type of payment-regular, non-regular-initial, non-regular-balloon. An amount = 0 should be indicated if a zero payment has to be fixed.',
                        FieldDefinition: {
                          type: ELenderAPIType.Currency
                        }
                      },
                      multiplier: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        info: 'Indicates a multiplier value when payment is to be calculated. This payment is then the multiple (times) of the regular payment that gets calculated.',
                        FieldDefinition: {
                          type: ELenderAPIType.Number
                        }
                      },
                      percentage: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        info: 'Indicates a percentage when payment is to be calculated. Required if percentageOf attribute is provided.',
                        FieldDefinition: {
                          type: ELenderAPIType.Percentage
                        }
                      },
                      percentageOf: {
                        FieldDefinitionId: 0,
                        isReadonly: true,
                        info: 'Indicates the percentage of what. Required if percentage attribute is provided. sale-price',
                        required: true,
                        forcedValue: 'sale-price'
                      }
                    }
                  ]
                },
                rates: {
                  effectiveCustomerRate: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'annual effective (compounded) customer rate percentage or APR(Annual Percentage Rate) taking into consideration of subsidies and commissions with inclusion of the influence of fees.',
                    FieldDefinition: {
                      type: ELenderAPIType.Percentage
                    }
                  },
                  calculate: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'this determines what subsidy type or margin will be calculated to compensate for the difference between the yield and customerRate. It is required when effectiveCustomerRate is provided as input. The provided value should match the targetCustomerRate, if configured on the financial product. vendor-subsidy, dealer-subsidy, manufacturer-subsidy, margin',
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: [
                        'vendor-subsidy',
                        'dealer-subsidy',
                        'manufacturer-subsidy',
                        'margin'
                      ]
                    }
                  },
                  variableNominalInterestRate: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'Indicates whether a variable or fixed nominal interest rate is to be used for calculations. The variableNominalInterestRate can be true only if allowed as per the financial product configuration. If true, there will be a recalculation on the contract upon every change in the nominal interest rate, and will apply for the next invoice.',
                    FieldDefinition: {
                      type: ELenderAPIType.Boolean
                    }
                  }
                },
                paymentProfile: {
                  type: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The payment profile type. For the payment patterns (seasonal, stepped) seasonal-pattern, stepped-pattern',
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['seasonal-pattern', 'stepped-pattern']
                    }
                  },
                  id: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Hide
                    }
                  }
                },
                commissions: [
                  {
                    amount: {
                      FieldDefinitionId: 18335,
                      isReadonly: false,
                      info: 'The identifier of the particular id in the Programs resource',
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },
                    id: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Hide
                      }
                    }
                  }
                ],
                subsidies: [
                  {
                    type: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      info: 'Subsidy provided by the program vendor or dealer or manufacturer vendor-subsidy, dealer-subsidy, manufacturer-subsidy',
                      FieldDefinition: {
                        type: ELenderAPIType.Dropdown,
                        options: [
                          'vendor-subsidy',
                          'dealer-subsidy',
                          'manufacturer-subsidy'
                        ]
                      }
                    },
                    amount: {
                      FieldDefinitionId: 23833,
                      isReadonly: false,
                      info: 'amount of the subsidy',
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },
                    id: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Hide
                      }
                    }
                  }
                ]
              }
            },
            fees: [
              {
                amount: {
                  FieldDefinitionId: 18327,
                  isReadonly: false,
                  info: 'The cash flow amount as provided by the caller. When this amount is provided, the lookups can be omitted. The calculation engine will use the provided amount as the cash flow amount. When both the lookups and the amount parameters have been specified, the lookups take precedence and the value parameter is used only when no lookup is successful.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                description: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The identifier of the particular fee in the financial product.'
                },
                id: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Hide
                  }
                }
              }
            ],
            insurances: [
              {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The annual Premium Amount',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                insuranceTaxPercentage: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The identifier of the Insurance Tax Percentage, which is used for insurance amount calculation.',
                  FieldDefinition: {
                    type: ELenderAPIType.Percentage
                  }
                },
                id: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Hide
                  }
                },
                description: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The identifier of the particular insurance in the financial product.'
                }
              }
            ],
            maintenance: [
              {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The annual cost amount',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                quantity: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  forcedValue: '1',
                  info: "Indicates the quantity of the soft asset associated with the cash flow. If the value is not provided, it's defaulted to '1'.",
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                id: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Hide
                  }
                }
              }
            ],
            assets: [
              {
                id: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Hide
                  }
                },
                modelIdentifier: {
                  masterAssetTypeName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The Master Asset Type of the selected asset'
                  },
                  categoryName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The Object Category of the selected asset'
                  },
                  typeName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The Asset Type of the selected asset'
                  },
                  brandName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The Brand of the selected asset'
                  },
                  modelName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The model name of the asset associated with the Finance Application'
                  }
                },
                description: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'A textual description to describe the asset; mandatory if a new asset needs to be created; Description exceeding 30 characters will be truncated.'
                },
                quantity: {
                  FieldDefinitionId: 18313,
                  isReadonly: false,
                  forcedValue: '1',
                  info: 'The quantity of this asset associated with the Application or Contract',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                salePrice: {
                  FieldDefinitionId: 18314,
                  isReadonly: false,
                  info: 'The sale price of the asset to be financed.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                listPrice: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The list price or asking price of the asset to be financed. When omitted, it is defaulted to the list price on the asset catalog, if available. Else, defaulted to the salePrice on the proposal.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                vatPercentage: {
                  FieldDefinitionId: 20693,
                  isReadonly: false,
                  info: 'The VAT percentage that is applicable to the asset',
                  FieldDefinition: {
                    type: ELenderAPIType.Percentage
                  }
                },
                vatAmount: {
                  FieldDefinitionId: 21166,
                  isReadonly: false,
                  info: 'The VAT amount that is on the asset',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                condition: {
                  FieldDefinitionId: 18311,
                  isReadonly: false,
                  required: true,
                  info: 'Indicates the condition (new, used or ex-demo) of the asset. Used to lookup insurance premium.',
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['new', 'used', 'ex-demo']
                  }
                },
                yearOfManufacturing: {
                  FieldDefinitionId: 18312,
                  isReadonly: false,
                  required: true,
                  info: 'The year in which the asset was manufactured. If not provided, will be defaulted to current year.',
                  FieldDefinition: {
                    type: [ELenderAPIType.Number]
                  }
                },
                subLeased: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Indicates whether the asset was subleased. Used to lookup insurance premium.',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                meterRead: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The total amount of usage units of the asset (e.g. total number of kilometers) since it was manufactured',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                usageUnitsPerPeriod: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: "The agreed usage units per period for this asset. The default period is 'year'. The usageUnits field indicates what type of units are involved (e.g. copies, kilometers, miles, or hours per year)",
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                usageEnvironment: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The type of usage environment for this asset.Examples are sand, water, or rocks, which all have their specific impact on the residual value of the asset.'
                },
                residualValue: {
                  value: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The residual value',
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  id: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Hide
                    }
                  }
                },
                repurchaseAmount: {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    info: 'The repurchase amount',
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  id: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Hide
                    }
                  }
                },
                excessiveUsageLimit: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The usage in excess of the current tier of usage units of the asset.',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                excessiveUsagePenaltyAmount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'The penalty amount that applies when exceeding the current tier of usage units of the asset (normally amount per excessive kilometer).',
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                fees: [
                  {
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ],
                subsidies: [
                  {
                    type: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Dropdown,
                        options: [
                          'vendor-subsidy',
                          'dealer-subsidy',
                          'manufacturer-subsidy'
                        ]
                      }
                    },
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },
                    id: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Hide
                      }
                    }
                  }
                ],
                insurances: [
                  {
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },
                    id: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Hide
                      }
                    },
                    insuranceTaxPercentage: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Percentage
                      }
                    },
                    replacement: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Boolean
                      }
                    },
                    excessAmount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ],
                maintenance: [
                  {
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },

                    quantity: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      forcedValue: '1',
                      FieldDefinition: {
                        type: ELenderAPIType.Number
                      }
                    },
                    id: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Hide
                      }
                    }
                  }
                ],
                instances: [
                  {
                    serialNumber: {
                      FieldDefinitionId: 18454,
                      isReadonly: false
                    },
                    chassisNumber: {
                      FieldDefinitionId: 18454,
                      isReadonly: false
                    },
                    licenseNumber: {
                      FieldDefinitionId: 21938,
                      isReadonly: false
                    },
                    location: {
                      street: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true
                      },
                      country: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        forcedValue: 'GB'
                      },
                      postalCode: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        FieldDefinition: {
                          type: ELenderAPIType.Requirement,
                          requirement: {
                            maxLength: 12,
                            pattern:
                              '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                            message:
                              "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                          }
                        }
                      },
                      city: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true
                      }
                    }
                  }
                ]
              }
            ]
          },
          financeCalculation: {
            financeAmount: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            financeDeviation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            futureValue: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            netPresentValue: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            rates: {
              nominalInterestRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'annual nominal interest rate percentage. The nominal interest rate refers to the stated interest rate on a loan or other financial product, without taking into account any subsidies, commissions, fees or compounding of interest.',
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Percentage
                }
              },
              effectiveInterestRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'annual effective (compounded) interest rate percentage. The effective annual interest rate (EIR) is the interest rate that is actually earned or paid on an investment, loan or other financial product due to the result of compounding over a given time period.',
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Percentage
                }
              },
              nominalCustomerRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'annual nominal interest rate percentage taking into consideration of subsidies and commissions, with no compounding of interest.',
                FieldDefinition: {
                  type: ELenderAPIType.Percentage
                }
              },
              nominalCustomerRateIncludingFees: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'annual nominal interest rate percentage taking into consideration of subsidies and commissions, with no compounding of interest, with inclusion of the influence of fees.',
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              effectiveCustomerRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'annual effective (compounded) customer rate percentage or APR(Annual Percentage Rate) taking into consideration of subsidies and commissions with exclusion of the influence of fees.',
                FieldDefinition: {
                  type: ELenderAPIType.Percentage
                }
              },
              effectiveCustomerRateIncludingFees: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'annual effective (compounded) interest rate percentage or APR(Annual Percentage Rate) taking into consideration of subsidies and commissions with inclusion of the influence of fees.',
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              compoundingFrequency: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'Indicates the frequency at which a nominal rate is compounded (interest on interest) to obtain the effective rate. monthly, quarterly, semi-annually, annually',
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['monthly', 'quarterly', 'semi-annually', 'annually']
                }
              }
            }
          },
          consolidatedPayments: [
            {
              numberOfPayments: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'the number of payments the amount occurs in a row',
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              amount: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'the periodic (monthly, quarterly, semi-annually, annually) total amount of the payment',
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'The payment type. Possible types are: regular, non-regular-initial (first payment from input), non-regular-balloon (last payment from input). regular, non-regular-initial, non-regular-balloon',
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'regular',
                    'non-regular-initial',
                    'non-regular-balloon'
                  ]
                }
              },
              startDate: {
                FieldDefinitionId: 0,
                isReadonly: true,
                required: true,
                info: 'the date the payments start',
                forcedValue: 'currentDate',
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            }
          ],
          commissions: [
            {
              amount: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              id: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Hide
                }
              }
            }
          ],
          subsidies: [
            {
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'vendor-subsidy',
                    'dealer-subsidy',
                    'manufacturer-subsidy'
                  ]
                }
              },
              amount: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              id: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Hide
                }
              }
            }
          ],
          payments: [
            {
              paymentDate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              },
              balanceOutstanding: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              rentalPayment: {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                principal: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                interestAmount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                }
              },
              vatPayment: {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                }
              },
              fees: [
                {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  vatAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                }
              ],
              insurances: [
                {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  vatAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  insuranceTaxAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  id: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Hide
                    }
                  }
                }
              ],
              maintenance: [
                {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  vatAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  id: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Hide
                    }
                  }
                }
              ]
            }
          ]
        }
      }
    ],
    notes: [
      {
        note: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.AddMore
          }
        }
      }
    ]
  }));
};
