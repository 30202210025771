import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import { Grid, Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import RedFlag from 'components/RedFlag';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';

import { StyledSelect } from 'common';

import { Alert, AlertTitle } from '@material-ui/lab';
import { IRedflagAPIData, IDirector } from 'types/redflagInterfaces';

import {
  UserDefinition,
  CompleteObjectDefinition,
  UserInstance
} from 'types/interfaces';

import { EntityTypes } from 'components/User/NewUserButton/functions/entityTypes';
import { EntityType } from 'types/interfaces';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { IUpdateUserInstance } from 'redux/actions/GraphQlActions/interface';
import { useTypedSelector } from 'redux/reducers';
import { notify } from 'components/Notifications/HotToastNotifications';

const useStyles = makeStyles((theme) => ({
  plugins: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const INIT_NEWUSER = {
  Title: '',
  UserInstanceEmail: '',
  UserDefinitionId: ''
};

interface Props {
  UserDefinitionId: string;
  inDialog: boolean;
  loading: boolean;
  refreshData: () => void;
  setInDialog: (status: boolean) => void;
}

export interface ChildUser {
  UserDefinition?: UserDefinition;
  CompleteObjectDefinitionList?: CompleteObjectDefinition[];
  Meta?: IDirector[];
  ProcessToState?: { label: string; value: string };
}

interface UserCreationObject {
  ParentUser?:
    | {
        UserDefinition?: UserDefinition;
        CompleteObjectDefinitionList?: CompleteObjectDefinition[];
      }
    | {};
  ChildUser?: ChildUser;
  LoggedInUser?: UserInstance;
  RedflagAPIData?: IRedflagAPIData;
  Token?: string;
  baseURL?: string;
}

const CreateUserDialog = (props: Props) => {
  const [state, setState] = React.useState<UserCreationObject>({
    ParentUser: {},
    ChildUser: {}
  });
  const [options, setOptions] = React.useState<any>(false);

  const setParentUserDefinition = (v) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState['ParentUser']['UserDefinition'] = v;
    setState(newState);
  };

  const UserDefinitionList = useTypedSelector(
    (s) => s.config.settings.UserDefinitionList
  );

  const UserDefinition = UserDefinitionList.find(
    (el) => el.Id === parseInt(props.UserDefinitionId)
  );

  React.useEffect(() => {
    let options: any = [];
    UserDefinitionList?.forEach((item: any) => {
      const search = ',';
      const keywords = item.Description.split(search);
      // if (keywords.includes(props?.data?.type)) {
      item.value = item.Id;
      item.label = item.Title;
      options.push(item);
      // }
    });
    setOptions(options);
  }, []);

  React.useEffect(() => {
    if (UserDefinition?.Description === 'calendar') {
      // We want to show a date time picker for the event and hash:
      // start, end, description, type,
    }
  }, []);

  const INIT_USERINSTANCE = {
    Title: '',
    UserDefinitionId: props.UserDefinitionId,
    UserInstanceEmail:
      UserDefinition?.Description === 'no-email' ? `${Date.now()}` : '', // REQUIRED
    UserInstancePassword: ''
  };
  const [newUser, setNewUser] = React.useState<any>(INIT_USERINSTANCE);
  const [redflagSelected, setRedflagSelected] = React.useState<any>();

  const EntityName =
    redflagSelected?.data?.type === 'company'
      ? redflagSelected?.data?.attributes?.name
      : `${redflagSelected?.attributes?.first_name} ${redflagSelected?.attributes?.surname}`;

  const handleClose = async () => {
    props.setInDialog(false);
    setNewUser(INIT_NEWUSER);
    // getOptions({ token });
  };

  // console.log('CreateUserDialog', { props });

  const handleChange = (name, value) => {
    setNewUser({
      ...newUser,
      [name]: value,
      UserDefinitionId: UserDefinition?.Id
    });
  };

  return (
    <>
      <IconButton onClick={() => props.setInDialog(true)}>
        <AddIcon />
      </IconButton>
      {/* Allow props to be passed into Dialog function (Find out how this would affect the bool on StaticTable) */}
      <Dialog fullScreen maxWidth={'xl'} open={props.inDialog}>
        <Alert severity="success" variant="filled" style={{ borderRadius: 0 }}>
          <AlertTitle>{EntityName}</AlertTitle>
          <Typography>Warning</Typography>
        </Alert>
        <DialogTitle onClose={handleClose}>Create New User</DialogTitle>
        <DialogContent>
          <Content
            handleChange={handleChange}
            newUser={newUser}
            UserDefinition={UserDefinition}
            redflagSelected={redflagSelected}
            setRedflagSelected={setRedflagSelected}
            // openPlugins={openPlugins}
            // setOpenPlugins={setOpenPlugins}
            handleClose={handleClose}
            options={options}
            setParentUserDefinition={setParentUserDefinition}
            {...props}
          />
        </DialogContent>

        {/* <DialogActions>
          <Button variant="outlined" color="primary">
            Cancel
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default CreateUserDialog;

export const Content = ({
  handleChange,
  newUser,
  UserDefinition,
  redflagSelected,
  setRedflagSelected,
  // openPlugins,
  // setOpenPlugins,
  refreshData,
  handleClose,

  options,
  setParentUserDefinition
}) => {
  const classes = useStyles();

  const currentProcess = useTypedSelector((s) => s.process.currentProcess);
  const token = useTypedSelector((s) => s.user.auth.token);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [customerType, setCustomerType] = React.useState<{
    label: EntityType;
    value: EntityType;
  }>({
    label: '' as EntityType,
    value: '' as EntityType
  });

  const [loadnewuser, setLoadnewuser] = React.useState(false);

  const redFlag_able_List: EntityType[] = [
    'Limited Company',
    'Limited Liability Partnership',
    'PLC',
    'Private Individual'
  ];
  const is_company_type_user_definition: boolean = redFlag_able_List.includes(
    customerType?.value
  );

  // const proxy_is_mandatory = UserDefinition.Description.includes('company');

  const createUser = async () => {
    const data = newUser;
    setLoadnewuser(true);

    // Create User
    const processdefinitionid: number = currentProcess?.ProcessDefinition?.Id
      ? currentProcess.ProcessDefinition.Id
      : 0;

    const typedData: IUpdateUserInstance = {
      ...data,
      UserDefinitionId: parseInt(data.UserDefinitionId.toString()),
      IsPublished: true
    };

    const response = await getQuickLiteUser({
      baseUrl,
      ProcessDefinitionId: processdefinitionid,
      data: typedData,
      action: 'UPDATE'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) {
      await refreshData({ token, UserDefinitionId: UserDefinition.Id });
      handleClose();

      notify.success(`New User Added to Assignee List`);
      setLoadnewuser(false);
    }
  };

  const StyledSelectParentUserProps = {
    onChange: (v: { label: string; value: string }) =>
      setParentUserDefinition(v),
    options: options,
    placeholder: 'Customer Type',
    value: '',
    useMaterialUI: false
  };

  const StyledSelectCustomerTypeProps = {
    onChange: (v: { label: EntityType; value: EntityType }) =>
      setCustomerType(v),
    options: EntityTypes,
    placeholder: 'Customer Type',
    value: customerType,
    useMaterialUI: false
  };

  return (
    <div>
      <Divider />
      <br />

      <>
        <Typography variant="h3">User Group</Typography>
        <Typography variant="h6">User Definition</Typography>
        <Typography variant="caption">
          Choose the type of user you want to create
        </Typography>
        <br />
        <br />
      </>

      <StyledSelect {...StyledSelectParentUserProps} />

      <br />

      <Divider />

      <br />
      <>
        <Typography variant="h3">Customer Type</Typography>
        <Typography variant="h6">Choose Type of user</Typography>
        <Typography variant="caption">
          Choose the type of user you want to create
        </Typography>
        <br />
        <br />
      </>

      <StyledSelect {...StyledSelectCustomerTypeProps} />

      <br />

      <Divider />

      <br />
      <>
        <Typography variant="h3">Create Method</Typography>
        <Typography variant="h6">Choose Type of user</Typography>
        <Typography variant="caption">
          Choose the type of user you want to create
        </Typography>
        <br />
        <br />
      </>

      {/* <DialogContentText>You can create a new user</DialogContentText> */}

      {!is_company_type_user_definition && (
        <div className={classes.plugins}>
          <Grid
            alignItems="center"
            container
            justifyContent="flex-start"
            spacing={2}>
            <Grid item>
              <TextField
                margin="dense"
                name="Title"
                onChange={(e) => handleChange('Title', e.target.value)}
                placeholder="Enter name ..."
                type="text"
                value={newUser.Title}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                name="UserInstanceEmail"
                onChange={(e) =>
                  handleChange('UserInstanceEmail', e.target.value)
                }
                placeholder="Enter email ..."
                type="email"
                value={newUser.UserInstanceEmail}
                variant="outlined"
              />
            </Grid>

            <Grid item>
              {loadnewuser ? (
                <CircularProgress />
              ) : (
                <Button
                  color="primary"
                  onClick={() => createUser()}
                  variant="contained">
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      )}

      <br />
      {UserDefinition.HasRedFlag && is_company_type_user_definition && (
        <Plugins
          customerType={customerType}
          redflagSelected={redflagSelected}
          setRedflagSelected={setRedflagSelected}
        />
      )}
    </div>
  );
};

const Plugins = ({ customerType, setRedflagSelected, redflagSelected }) => {
  const classes = useStyles();
  const [openPlugins, setOpenPlugins] = React.useState(true);
  const [showDirectorCompany, setShowDirectorCompany] = React.useState({
    company: true,
    director: true
  });

  React.useEffect(() => {
    if (
      ['Limited Company', 'Limited liability Partnership', 'PLC'].includes(
        customerType.value
      )
    ) {
      setShowDirectorCompany({ company: true, director: false });
    }
    if (['Private Individual'].includes(customerType.value)) {
      setShowDirectorCompany({ company: false, director: true });
    }
  }, [customerType]);

  return (
    <div className={classes.plugins}>
      <FormControlLabel
        control={
          <Switch
            checked={openPlugins}
            name="checkedA"
            onChange={() => setOpenPlugins(!openPlugins)}
          />
        }
        label={`Company Navigator Search`}
      />
      <Collapse in={openPlugins}>
        <RedFlag
          ProcessDefinitionId={undefined}
          action={(res: any) => setRedflagSelected(res)}
          showDirectorCompany={showDirectorCompany}
          hideCreateUser
        />
      </Collapse>
    </div>
  );
};

interface DialogTitleProps {
  classes: any;
  children: any;
  onClose: any;
}

const DialogTitle = withStyles(({ spacing, palette }) => ({
  root: {
    margin: 0,
    padding: spacing(2),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    color: palette.grey[500]
  }
}))((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
