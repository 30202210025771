import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { useProcess } from 'hooks';
import { useEffect, useState } from 'react';
import { Rule } from 'types/interfaces';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { getAuditLogs, IFirestoreAuditLog } from 'hooks/useDeals/useAuditLog';
import { getFilteredAndGroupedRules } from './getFilteredGroupRules';
import React from 'react';
import { useTypedSelector } from 'redux/reducers';
import { IDisabledLite, useRuleSchema } from 'hooks/useDeals/useRuleSchema';
import useChecklist from 'hooks/useDeals/useChecklist';
import { Save } from '@material-ui/icons';
import { notify } from 'components/Notifications/HotToastNotifications';

/**
 * @typedef {Object} IGroupedEntries
 * @property {number|null} id - The rule ID
 * @property {IFirestoreAuditLog} firebaseAuditLog - The associated audit log
 */
interface IGroupedEntries {
  id: number | null;
  firebaseAuditLog: IFirestoreAuditLog;
}

/**
 * Checklist component that displays a list of rules and their status
 * @component
 * @param {Object} props - Component props
 * @param {boolean} [props.open] - Whether the checklist is open or not
 * @returns {JSX.Element} The rendered Checklist component
 */
const Checklist = ({ open }: { open?: boolean }) => {
  const { currentDeal, currentStep } = useProcess();

  const [ruleIds, setRuleIds] = useState<IGroupedEntries[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const processEntries = async () => {
      setLoading(true);
      try {
        const auditLogEntries: IFirestoreAuditLog[] = await getAuditLogs(
          currentDeal?.ProcessInstance?.Id
        );

        if (auditLogEntries?.length > 0) {
          let groupedEntries: Record<number, IFirestoreAuditLog> = {};
          auditLogEntries.forEach((entry) => {
            // Add null check for currentStep
            if (!currentStep?.InteractiveRuleDict) return;

            const rule = Object.values(currentStep.InteractiveRuleDict).find(
              (r) => r?.ActionValue?.includes(entry.payload)
            );

            if (rule) {
              const existingEntry = groupedEntries[rule.Id];

              const isNewer =
                existingEntry && entry.timestamp > existingEntry.timestamp;

              const shouldUpdateEntry =
                !existingEntry ||
                (isNewer &&
                  (entry.firebaseStatus === 'Sent' ||
                    (entry.firebaseStatus === 'Completed' &&
                      existingEntry.firebaseStatus !== 'Completed') ||
                    (entry.firebaseStatus === 'Rejected' &&
                      existingEntry.firebaseStatus === 'Sent')));

              if (shouldUpdateEntry) {
                groupedEntries[rule.Id] = entry;
              }

              // Special case: If payload ID is 2248, update rule 1345's status
              // That's because the Lender Proposal & Lender Proposal to Contact are the same Landing Page
              const payloadToRuleIdMapping = {
                '2248': 1345,
                '1501': 1233,
                '1548': 51,
                '2216': 1298,
                '1608': 150,
                '2287': 1427,
                '2414': 1761
              };

              const mappedRuleId = payloadToRuleIdMapping[entry.payload];
              if (mappedRuleId) {
                const ruleToUpdate = Object.values(
                  currentStep.InteractiveRuleDict
                ).find((r) => r?.Id === mappedRuleId);
                if (ruleToUpdate) {
                  groupedEntries[mappedRuleId] = {
                    ...entry,
                    payload: ruleToUpdate.ActionValue
                  };
                }
              }
            }
          });

          const validRuleIds = Object.entries(groupedEntries).map(
            ([mappedId, firebaseAuditLog]) => ({
              id: parseInt(mappedId),
              firebaseAuditLog
            })
          );

          setRuleIds(validRuleIds);
        }
      } catch (e: any) {
        notify.error(`Error Fetching Audit Logs: ${e.message}`);
      }

      setLoading(false);
    };

    processEntries();
  }, [open]);

  return <RuleStatusList ruleIds={ruleIds} loading={loading} />;
};

export default Checklist;

const RuleStatusList = ({
  ruleIds,
  loading,
  isAdminOrProcessOwner
}: {
  ruleIds: IGroupedEntries[];
  loading: boolean;
  isAdminOrProcessOwner?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const {
    handleChecklistUpdate,
    saveCommentsToFirestore,
    fetchCommentsFromFirestore
  } = useChecklist();
  const { getLiteRuleRequirements } = useRuleSchema();

  const { currentProcess, regulatedStatus } = useTypedSelector(
    (s) => s.process
  );

  const { checklistRules: reduxChecklistRules } = useTypedSelector(
    (s) => s.schema
  );

  const { currentStep, user } = useProcess();
  const { InteractiveRuleDict } = currentStep || {};

  const [ruleStatuses, setRuleStatuses] = useState<{
    [key: string]: IDisabledLite;
  }>({});

  const [comments, setComments] = useState<{
    [key: string]: {
      comments?: string;
      regulatedComment?: string;
      nonRegulatedComment?: string;
    };
  }>({});

  const [unsavedComments, setUnsavedComments] = useState<{
    [key: string]: {
      comments?: string;
      regulatedComment?: string;
      nonRegulatedComment?: string;
    };
  }>({});

  const dealTypeId = currentProcess?.ProcessDefinition?.Id?.toString() || '';
  const ruleGroups = InteractiveRuleDict
    ? getFilteredAndGroupedRules(
        InteractiveRuleDict as { [key: number]: Rule },
        isAdminOrProcessOwner ?? false
      )
    : {};

  const checklistRules =
    reduxChecklistRules?.[currentProcess?.ProcessDefinition?.Id] || {};

  const handleRegulationTypeChange = (
    ruleId: string,
    value: 'Regulated' | 'Non-Regulated' | 'Both'
  ) => {
    const currentRule = checklistRules?.[ruleId] || {
      isMandatory: false,
      regulationType: 'Both'
    };

    handleChecklistUpdate({
      ruleId,
      isMandatory: currentRule.isMandatory,
      regulationType: value,
      dealTypeId
    });
  };

  const handleCommentChange = (
    ruleId: string,
    value: string,
    regulationType: string
  ) => {
    setUnsavedComments((prev) => ({
      ...prev,
      [ruleId]: {
        ...(prev?.[ruleId] || {}),
        ...(regulationType === 'Non-Regulated'
          ? { nonRegulatedComment: value }
          : regulationType === 'Regulated'
          ? { regulatedComment: value }
          : { comments: value })
      }
    }));
  };

  const handleSaveComment = async (ruleId: string, regulationType: string) => {
    const value = getDisplayUnsavedComment(ruleId, regulationType);
    await saveCommentsToFirestore(dealTypeId, ruleId, value ?? '');

    setComments((prev) => ({
      ...prev,
      [ruleId]: {
        ...(prev?.[ruleId] || {}),
        ...(regulationType === 'Non-Regulated'
          ? { nonRegulatedComment: value }
          : regulationType === 'Regulated'
          ? { regulatedComment: value }
          : { comments: value })
      }
    }));

    setUnsavedComments((prev) => {
      const newState = { ...prev };
      delete newState[ruleId];
      return newState;
    });
  };

  const hasActualChanges = (ruleId: string, regulationType: string) => {
    const unsavedValue = getDisplayUnsavedComment(ruleId, regulationType);
    const savedValue = getDisplayComment(ruleId, regulationType);

    return unsavedValue !== savedValue;
  };

  const handleBlur = (ruleId: string, regulationType: string) => {
    if (!hasActualChanges(ruleId, regulationType)) {
      setUnsavedComments((prev) => {
        const newState = { ...prev };
        delete newState[ruleId];
        return newState;
      });
    }
  };

  useEffect(() => {
    const fetchRuleStatuses = async () => {
      const newRuleStatuses: { [key: string]: IDisabledLite } = {};

      const groupEntries = Object.entries(ruleGroups || {});
      await Promise.all(
        groupEntries.map(async ([group, rules]) => {
          await Promise.all(
            rules.map(async (rule: Rule) => {
              if (!rule || !rule.Id) return;

              const getLiteRule = await getLiteRuleRequirements(rule);
              newRuleStatuses[rule.Id] = getLiteRule;
            })
          );
        })
      );

      setRuleStatuses(newRuleStatuses);
    };

    fetchRuleStatuses();
  }, [checklistRules]);

  useEffect(() => {
    const fetchComments = async () => {
      const fetchedComments = await fetchCommentsFromFirestore(dealTypeId);
      setComments(fetchedComments || {});
    };
    fetchComments();
  }, [dealTypeId]);

  const getDisplayComment = (ruleId: string, regulationType: string) => {
    const ruleComments = comments?.[ruleId] || {};
    const userAccess = user?.SystemAccess || 0;

    if (userAccess >= 15) {
      if (regulationType === 'Both') {
        return ruleComments.comments || '';
      }
      if (regulationType === 'Non-Regulated') {
        return ruleComments.nonRegulatedComment || '';
      }
      if (regulationType === 'Regulated') {
        return ruleComments.regulatedComment || '';
      }
    } else {
      if (regulationType === 'Both') {
        return ruleComments.comments || '';
      }
      if (regulationType === 'Non-Regulated' && !regulatedStatus) {
        return ruleComments.nonRegulatedComment || '';
      }
      if (regulationType === 'Regulated' && regulatedStatus) {
        return ruleComments.regulatedComment || '';
      }
    }

    return '';
  };

  const getDisplayUnsavedComment = (ruleId: string, regulationType: string) => {
    const unsavedRuleComments = unsavedComments?.[ruleId];

    if (unsavedRuleComments) {
      if (regulationType === 'Both') {
        return unsavedRuleComments.comments;
      }
      if (regulationType === 'Non-Regulated') {
        return unsavedRuleComments.nonRegulatedComment;
      }
      if (regulationType === 'Regulated') {
        return unsavedRuleComments.regulatedComment;
      }
    }

    return getDisplayComment(ruleId, regulationType);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper} elevation={3}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: '1rem', width: 180 }}>
                Required Steps
              </TableCell>
              <TableCell style={{ fontSize: '1rem', width: 180 }}>
                Comments
              </TableCell>
              <TableCell align="right" style={{ fontSize: '1rem' }}>
                Action
              </TableCell>
              <TableCell align="right" style={{ fontSize: '1rem' }}>
                Status
              </TableCell>
              <TableCell align="right" style={{ fontSize: '1rem' }}>
                User Id
              </TableCell>
              <TableCell align="right" style={{ fontSize: '1rem' }}>
                Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(ruleGroups || {}).map(([group, rules]) => (
              <React.Fragment key={group}>
                {rules.map((rule: Rule) => {
                  if (!rule || !rule.Id) return null;

                  const getLiteRule = ruleStatuses?.[rule.Id];

                  if (!getLiteRule) return <div key={rule.Id} />;

                  const hasStatus =
                    getLiteRule &&
                    Object.values(getLiteRule.status || {}).length > 0;
                  if (hasStatus) {
                    if (
                      (regulatedStatus && getLiteRule.status?.isNonRegulated) ||
                      (!regulatedStatus && getLiteRule.status?.isRegulated)
                    ) {
                      return <div key={rule.Id} />;
                    }
                  }

                  if (rule.Title?.includes('GDPR'))
                    return <div key={rule.Id} />;

                  const findStatus = ruleIds?.find((id) => id?.id === rule.Id);
                  const isCompleted =
                    findStatus?.firebaseAuditLog?.firebaseStatus ===
                    'Completed';
                  const isSent =
                    findStatus?.firebaseAuditLog?.firebaseStatus === 'Sent';
                  const isRejected =
                    findStatus?.firebaseAuditLog?.firebaseStatus === 'Rejected';

                  const isMandatory = (() => {
                    if (!checklistRules || !checklistRules[rule.Id])
                      return false;

                    const { isMandatory, regulationType } =
                      checklistRules[rule.Id];

                    if (regulationType === 'Both') {
                      return isMandatory;
                    }
                    if (regulationType === 'Regulated' && regulatedStatus) {
                      return isMandatory;
                    }
                    if (
                      regulationType === 'Non-Regulated' &&
                      !regulatedStatus
                    ) {
                      return isMandatory;
                    }
                    return false;
                  })();

                  const ruleRegulationType =
                    checklistRules?.[rule.Id]?.regulationType || 'Both';

                  return (
                    <TableRow
                      key={rule.Id}
                      style={{
                        backgroundColor: isRejected
                          ? theme.palette.error.main
                          : isSent && !isCompleted
                          ? theme.palette.warning.main
                          : isCompleted
                          ? theme.palette.success.main
                          : 'initial'
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: isMandatory ? 'bold' : 'inherit'
                        }}>
                        {user?.SystemAccess >= 15 ? (
                          <Grid container spacing={1}>
                            <Grid item xs={11}>
                              <Select
                                value={ruleRegulationType}
                                onChange={(e) => {
                                  if (checklistRules?.[rule.Id]?.isMandatory) {
                                    handleRegulationTypeChange(
                                      rule.Id.toString(),
                                      e.target.value as
                                        | 'Regulated'
                                        | 'Non-Regulated'
                                        | 'Both'
                                    );
                                  } else {
                                    notify.warning(
                                      'Please make this Rule Mandatory before changing the Regulation Type.'
                                    );
                                  }
                                }}>
                                <MenuItem value="Regulated">Regulated</MenuItem>
                                <MenuItem value="Non-Regulated">
                                  Non-Regulated
                                </MenuItem>
                                <MenuItem value="Both">Both</MenuItem>
                              </Select>
                            </Grid>

                            <Grid item xs={1}>
                              <Checkbox
                                checked={
                                  checklistRules?.[rule.Id]?.isMandatory ||
                                  false
                                }
                                onChange={(event) =>
                                  handleChecklistUpdate({
                                    ruleId: rule.Id.toString(),
                                    isMandatory: event.target.checked,
                                    regulationType:
                                      checklistRules?.[rule.Id]
                                        ?.regulationType || 'Both',
                                    dealTypeId
                                  })
                                }
                                color="primary"
                              />
                            </Grid>
                          </Grid>
                        ) : isMandatory ? (
                          'Mandatory *'
                        ) : (
                          'Optional'
                        )}{' '}
                      </TableCell>

                      <TableCell align="left" style={{ fontSize: '0.875rem' }}>
                        {user?.SystemAccess >= 15 ? (
                          <Box display="flex" alignItems="center">
                            <TextField
                              placeholder="Enter Comments"
                              fullWidth
                              size="small"
                              value={getDisplayUnsavedComment(
                                rule.Id.toString(),
                                checklistRules?.[rule.Id]?.regulationType ||
                                  'Both'
                              )}
                              onChange={(e) =>
                                handleCommentChange(
                                  rule.Id.toString(),
                                  e.target.value,
                                  checklistRules?.[rule.Id]?.regulationType ||
                                    'Both'
                                )
                              }
                              onBlur={() =>
                                handleBlur(
                                  rule.Id.toString(),
                                  checklistRules?.[rule.Id]?.regulationType ||
                                    'Both'
                                )
                              }
                              InputProps={{
                                readOnly: (user?.SystemAccess || 0) < 15,
                                style: { color: 'black' }
                              }}
                            />
                            {unsavedComments?.[rule.Id.toString()] && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleSaveComment(
                                    rule.Id.toString(),
                                    checklistRules?.[rule.Id]?.regulationType ||
                                      'Both'
                                  )
                                }
                                style={{ marginLeft: 8 }}>
                                <Save />
                              </IconButton>
                            )}
                          </Box>
                        ) : (
                          <Typography>
                            {getDisplayComment(
                              rule.Id.toString(),
                              checklistRules?.[rule.Id]?.regulationType ||
                                'Both'
                            )}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell
                        align="right"
                        component="th"
                        scope="row"
                        style={{ fontSize: '0.875rem' }}>
                        {rule.Title || ''}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: '0.875rem',
                          fontWeight:
                            isSent || isCompleted || isRejected
                              ? 'bold'
                              : 'initial'
                        }}>
                        {isRejected
                          ? 'Rule Rejected By Landing Page User'
                          : isSent && !isCompleted
                          ? `Rule Successfully Sent`
                          : isCompleted
                          ? 'Landing Page Accepted By Landing Page User'
                          : 'Waiting To Be Sent'}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '0.875rem' }}>
                        {findStatus ? (
                          <QuickUserFromId
                            UserInstanceId={
                              findStatus.firebaseAuditLog?.userInstanceId?.toString() ||
                              ''
                            }
                          />
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: '0.875rem',
                          fontWeight:
                            isSent || isCompleted || isRejected
                              ? 'bold'
                              : 'initial'
                        }}>
                        {findStatus &&
                        findStatus.firebaseAuditLog?.timestamp?.seconds
                          ? new Date(
                              findStatus.firebaseAuditLog.timestamp.seconds *
                                1000
                            ).toLocaleString()
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
