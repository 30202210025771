import React, { useState, forwardRef } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import FileStorageView from '../views';
import { FileStorage } from '..';
import { IFieldsProps } from 'components/Fields/InlineTextField/interface';
import { FbFileRef } from 'types/interfaces';
import FileExplorer from 'components/FileExplorer';
import UploadMethodPopover from 'components/FilePond/components/UploadMethodPopover';
import { CustomDialog } from 'common/Dialog';
import { theme } from 'theme';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IFileStoragePickerProps {
  onSelect?: ({
    url,
    item,
    type
  }: {
    url: string;
    item: FbFileRef | File | any;
    type?: 'File Explorer' | 'File Storage';
  }) => Promise<void>;
  inlineFieldProps?: IFieldsProps;
  title: string;
  plugin: boolean;
  bordered?: boolean;
  isDealSummary?: boolean;
  isDisabled?: boolean;
}

const FileStoragePicker = ({
  onSelect,
  inlineFieldProps,
  title,
  plugin,
  bordered,
  isDealSummary,
  isDisabled
}: IFileStoragePickerProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogContent, setDialogContent] = useState<
    'fileExplorer' | 'fileStorage' | null
  >(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileExplorerOpen = () => {
    setDialogContent('fileExplorer');
    setOpen(true);
    handleMenuClose();
  };

  const handleFileStorageOpen = () => {
    setDialogContent('fileStorage');
    setOpen(true);
    handleMenuClose();
  };

  const handleClose = () => {
    setOpen(false);
    setDialogContent(null);
  };

  return (
    <>
      <Button
        data-cy="file-storage-picker-btn"
        style={
          isDealSummary
            ? { flex: '0.5' }
            : {
                backgroundColor: isDisabled
                  ? theme.palette.action.disabledBackground
                  : bordered
                  ? theme.palette.info.main
                  : theme.palette.primary.contrastText
              }
        }
        size={bordered ? 'small' : 'medium'}
        color={isDealSummary ? 'inherit' : 'primary'}
        variant={bordered ? 'contained' : 'text'}
        disabled={isDisabled}
        onClick={handleButtonClick}>
        {title}
      </Button>

      <UploadMethodPopover
        inlineFieldProps={inlineFieldProps}
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        handleFileExplorerOpen={handleFileExplorerOpen}
        handleLocalDiskBrowse={handleFileStorageOpen}
      />

      <CustomDialog
        maxSize="lg"
        open={open}
        handleClose={handleClose}
        alert={{
          title:
            dialogContent === 'fileExplorer' ? 'File Explorer' : 'File Storage',
          description:
            dialogContent === 'fileExplorer'
              ? 'Here is an Overview of File Explorer.'
              : 'Here is an Overview of File Storage.',
          type: 'info'
        }}>
        <DialogContent>
          {dialogContent === 'fileExplorer' && open ? (
            <FileExplorer
              isImport
              fileSelect={async (file: File, url: string) => {
                onSelect &&
                  (await onSelect({ url, item: file, type: 'File Explorer' }));
              }}
            />
          ) : plugin ? (
            <FileStorage
              global
              onSelect={async (params) => {
                if (onSelect) {
                  await onSelect(params);
                  handleClose();
                }
              }}
            />
          ) : (
            <FileStorageView
              bordered={bordered}
              isDealSummary={isDealSummary}
              onSelect={onSelect}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="file-cancel-btn"
            onClick={handleClose}
            color="primary">
            Cancel
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default FileStoragePicker;
