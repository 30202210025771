import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { handleUpdateField } from '../../functions';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import './styles.css';
import { useTypedSelector } from 'redux/reducers';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import { theme } from 'theme';
import { notify } from 'components/Notifications/HotToastNotifications';

const useStyles = makeStyles((theme) =>
  createStyles({
    toedit: {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      width: '100%',
      '&:hover': {
        background: (props) => theme?.palette?.primary?.contrastText,
        borderRadius: theme.shape.borderRadius
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    root: {
      width: '100%',
      border: '1px solid lightgrey',
      borderRadius: theme.shape.borderRadius
    },
    btn: { marginTop: theme.spacing(2) },
    add: {
      color: 'primary',
      cursor: 'pointer'
    },
    fileInput: {
      display: 'none'
    },
    imgView: {
      maxWidth: '100%',
      maxHeight: '100px',
      padding: theme.spacing(0.5)
    },
    textPreview: {
      maxWidth: '100%',
      wordWrap: 'break-word',
      overflowWrap: 'break-word'
    },
    imgPreview: {
      maxHeight: '100px',
      maxWidth: '100%'
    }
  })
);

const Component = (props) => {
  const { title, value, instance } = props;

  const dispatch = useDispatch();
  const token = useTypedSelector((s) => s.user.auth.token);
  const classes = useStyles({ instance });

  const ref = React.useRef<SignatureCanvas>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [edit, setEdit] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const onChange = handleUpdateField({ setUpdating, token, props });

  const noValue = value === '' || value === undefined;
  const isImage = value && value.startsWith('data:image');

  const [showAlert, setShowAlert] = React.useState(false);
  const [showSave, setShowSave] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [openTextfield, setOpenTextfield] = React.useState(false);
  const [selectedFont, setSelectedFont] = React.useState('Dancing Script');

  const handleStrokeEnd = () => {
    setShowAlert(true);
    setShowSave(true);
  };

  const handleTextfieldChange = () => {
    if (inputValue === '') {
      setOpenTextfield(false);
    }

    const textImage = convertTextToImage(inputValue, selectedFont);
    if (typeof onChange === 'function') {
      onChange(textImage);
    }

    setEdit(false);
    setShowEdit(false);
    setShowAlert(false);
    setShowSave(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        notify.warning('Only PNG and JPEG files are allowed');
        return;
      }

      const maxFileSize = 2 * 1024 * 1024;
      if (file.size > maxFileSize) {
        notify.warning('File Size Is Too Large, Maximum Size Is 2MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (typeof onChange === 'function') {
          onChange(e.target?.result as string);
        }
        setEdit(false);
        setShowEdit(false);
        setShowAlert(false);
        setShowSave(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const convertTextToImage = (text: string, font: string): string => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const fontSize = 30;
      const padding = 5;
      ctx.font = `${fontSize}px ${font}`;
      const textWidth = ctx.measureText(text).width;
      canvas.width = textWidth + padding * 2;
      canvas.height = fontSize * 2;

      ctx.font = `${fontSize}px ${font}`;
      ctx.textBaseline = 'middle';
      ctx.fillText(text, padding, fontSize);

      return canvas.toDataURL();
    }
    return '';
  };

  if (updating) return <CircularProgress />;
  if (!edit)
    return (
      <div
        style={{ width: '100%' }}
        onMouseEnter={() => !props.readOnly && setShowEdit(true)}
        onMouseLeave={() => !props.readOnly && setShowEdit(false)}
        onClick={() => showEdit && setEdit(true)}>
        {value ? (
          <div className={!props.readOnly ? classes.toedit : ''}>
            {isImage ? (
              <img className={classes.imgPreview} src={value} alt="sig prev" />
            ) : (
              <Typography className={classes.textPreview}>{value}</Typography>
            )}
            <div style={{ flexGrow: 1 }} />
          </div>
        ) : (
          <Link
            data-cy="add-signature"
            className={classes.add}
            onClick={() => !props.readOnly && setEdit(true)}>
            {props.readOnly ? '' : '+ Add Signature'}
          </Link>
        )}
      </div>
    );

  return (
    <div className={classes.container}>
      <div style={{ height: 10 }} />

      {showAlert && (
        <Alert severity="warning" variant="outlined">
          <AlertTitle>Not Saved</AlertTitle>
          <Typography>
            Please click the save button to save your signature
          </Typography>
        </Alert>
      )}

      <br />

      <ButtonGroup fullWidth color="primary">
        <Button
          onClick={() => {
            setEdit(false);
            setShowAlert(false);
            setOpenTextfield(false);
          }}>
          Cancel
        </Button>

        {/* <Tooltip title="Upload Signature">
          <Button component="label">
            Upload
            <input
              type="file"
              hidden
              accept="image/png, image/jpeg"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="Type Signature">
          <Button component="label" onClick={() => setOpenTextfield(true)}>
            Text
          </Button>
        </Tooltip> */}

        {showSave && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              ref.current?.clear();
              setShowAlert(false);
              setShowSave(false);
            }}>
            Clear
          </Button>
        )}

        {/* {showSave && ( */}
        <Button
          data-cy="save-signature"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const signatureImage = ref.current?.getTrimmedCanvas().toDataURL();
            if (typeof onChange === 'function') {
              onChange(signatureImage || '');
            }
            setEdit(false);
            setShowEdit(false);
            setShowAlert(false);
            setShowSave(false);
          }}>
          Save
        </Button>
        {/* )} */}
      </ButtonGroup>

      <br />

      {/* {openTextfield ? (
        <> */}
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="font-select-label">Font</InputLabel>
        <Select
          labelId="font-select-label"
          value={selectedFont}
          onChange={(e) => setSelectedFont(e.target.value as string)}
          label="Font">
          <MenuItem
            value="Dancing Script"
            style={{ fontFamily: 'Dancing Script, cursive' }}>
            Dancing Script
          </MenuItem>
          <MenuItem
            value="Great vibes"
            style={{ fontFamily: 'Great Vibes, cursive' }}>
            Great Vibes
          </MenuItem>
          <MenuItem
            value="Playwrite CA"
            style={{ fontFamily: 'Playwrite CA, cursive' }}>
            Playwrite Canada
          </MenuItem>
          <MenuItem
            value="Playwrite MX"
            style={{ fontFamily: 'Playwrite MX, cursive' }}>
            Playwrite MX
          </MenuItem>
          <MenuItem value="Satisfy" style={{ fontFamily: 'Satisfy, cursive' }}>
            Satisfy
          </MenuItem>
        </Select>
      </FormControl>
      <br />
      <TextField
        label="Signature"
        variant="outlined"
        fullWidth
        value={inputValue}
        onBlur={handleTextfieldChange}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleTextfieldChange()}
      />
      {/* </>
      ) : (
        <div className="container" ref={containerRef}>
          <div className="sigContainer">
            <SignatureCanvas
              ref={ref}
              penColor="black"
              clearOnResize={false}
              canvasProps={{
                height: 200,
                className: 'sigPad'
              }}
              onEnd={handleStrokeEnd}
            />
          </div>
        </div>
      )} */}

      <br />

      {isImage ? (
        <div>
          {!noValue && (
            <img className={classes.imgView} src={value} alt="sig" />
          )}
        </div>
      ) : (
        <div>
          {!noValue && (
            <Typography>{value || 'No signature provided'}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default Component;
