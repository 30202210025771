import { useMemo } from 'react';
import firebase from 'firebase';
import { notify } from 'components/Notifications/HotToastNotifications';

export const useColumnSaver = (Id, saveJSON) => {
  return useMemo(() => {
    const updateSaveLayout = async (columnLayout, Id) => {
      return firebase
        .firestore()
        .collection('userAccount')
        .doc(Id.toString())
        .update({ column_layout: columnLayout });
    };

    const handleColumnSaving = () => {
      const stringId = Id.toString();
      if (saveJSON.length === 0) return;
      if (saveJSON !== undefined) {
        updateSaveLayout(JSON.stringify(saveJSON), stringId);
        notify.success(`Successfully Saved Table Layout`);
      }
    };

    return { handleColumnSaving };
  }, [Id, saveJSON]);
};
