import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated
} from '@azure/msal-react';
import { loginRequest } from 'services/microsoft/authConfig';
import { callMsGraph } from 'services/microsoft/graph';
import { ConnectAccountButton } from './ConnectAccountButton';
import { InteractionStatus } from '@azure/msal-browser';
import { BugTracker } from 'Utils/Bugtracker';
import firebase from 'firebase';
import { useTypedSelector } from 'redux/reducers';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import NotificationSettings from './NotificationSettings';
import { theme } from 'theme';
import NoticeBoard from 'views/Profile/NoticeBoard';
import { notify } from 'components/Notifications/HotToastNotifications';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '400px'
    },
    fullWidthPaper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%'
    },
    microsoftButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    },
    emailList: {
      maxHeight: '200px',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  })
);

interface MicrosoftUser {
  userPrincipalName: string;
}

interface MicrosoftMessages {
  value: MSmessages[];
}

interface MSmessages {
  subject: string;
  receivedDateTime: string;
  from: {
    emailAddress: {
      address: string;
      name: string;
    };
  };
}

const Settings = () => {
  const classes = useStyles();
  const { instance, accounts, inProgress } = useMsal();
  const { user } = useTypedSelector((s) => s.user);

  const [graphData, setGraphData] = React.useState<MicrosoftUser | null>(null);
  const [messages, setMessages] = React.useState<MicrosoftMessages | null>(
    null
  );

  const isAuth = useIsAuthenticated();

  const getValidAccessToken = () => {
    if (!isAuth && inProgress === InteractionStatus.None) return '';
    const account = accounts[0];
    const accessTokenRequest = { ...loginRequest, account };
    return instance
      .acquireTokenSilent(accessTokenRequest)
      .then(({ accessToken }: { accessToken: string }) => accessToken)
      .catch((e) => {
        BugTracker.notify(e);

        return instance
          .acquireTokenPopup(accessTokenRequest)
          .then(({ accessToken }: { accessToken: string }) => accessToken)
          .catch((e) => {
            BugTracker.notify(e);
            return '';
          });
      });
  };

  const getRefreshEmails = async () => {
    try {
      const accessToken = await getValidAccessToken();
      const messages = await callMsGraph({ accessToken, action: 'messages' });
      if (messages) {
        notify.success('Successfully Refreshed Emails');

        return setMessages(messages);
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const getFromGraph = async () => {
    try {
      const accessToken = await getValidAccessToken();
      const res = await callMsGraph({
        accessToken: accessToken,
        action: 'graphMeEndpoint'
      });
      const messages = await callMsGraph({
        accessToken: accessToken,
        action: 'messages'
      });

      setMessages(messages);
      return setGraphData(res);
    } catch (e) {
      BugTracker.notify(e);
      return console.log({ e });
    }
  };

  React.useEffect(() => {
    getFromGraph();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <NotificationSettings />
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Alert severity="info">
              <AlertTitle>Microsoft 365 Quick View</AlertTitle>
              <Typography variant="h6" color="textSecondary">
                Upon logging into Office 365, you will have a brief overview of
                your emails, displaying only the top 10 messages. To view
                additional emails, please utilize the Inbox plugin.
              </Typography>

              <AuthenticatedTemplate>
                <Typography
                  style={{ paddingTop: theme.spacing(1) }}
                  variant="h6"
                  color="textSecondary">
                  You are logged in as{' '}
                  <span style={{ color: 'blue' }}>
                    {graphData?.userPrincipalName}
                  </span>
                </Typography>
              </AuthenticatedTemplate>
            </Alert>
            <UnauthenticatedTemplate>
              <div className={classes.microsoftButtonContainer}>
                <ConnectAccountButton />
              </div>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <div className={classes.emailList}>
                <List>
                  {messages?.value?.map((message, idx) => (
                    <ListItem key={idx} className={classes.listItem}>
                      <ListItemText
                        primary={message.subject}
                        secondary={`${
                          message.from?.emailAddress?.name || ''
                        } - ${new Date(
                          message.receivedDateTime
                        ).toLocaleString()}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
              <Button
                variant="contained"
                color="secondary"
                onClick={getRefreshEmails}>
                Refresh Emails
              </Button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Typography variant="h6" color="textSecondary">
                You are logged out
              </Typography>
            </UnauthenticatedTemplate>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.fullWidthPaper}>
            <NoticeBoard />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;

// // const getTokenFromProxy = () => {
// //   const token = await firebase.auth().currentUser?.getIdToken();
// //   const url = `${process.env.REACT_APP_PROXY}/mo/refresh`;
// //   const config: AxiosRequestConfig = {
// //     method: 'POST',
// //     url,
// //     headers: { token },
// //     data: { account: accounts[0], instance }
// //   };
// //   axios(config)
// //     .then((res) => {
// //       return console.log({ res });
// //     })
// //     .catch((e) => {
// //       return console.log({ e });
// //     });
// // };
