import { CompleteObjectDefinition } from 'types/interfaces';

export const arrayContains = ({ query, description }) => {
  const keywords = description.split(',');
  if (keywords.includes(query)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Determines Whether a Given Entity Should be Hidden based on the Object Description.
 * The Function Checks if the 'ObjectDescription' Contains the Keyword 'hide', Followed by a List of Names in Brackets.
 * If the Entity is Found in this List, the Function Returns True, Indicating it Should be Hidden.
 *
 * @param {CompleteObjectDefinition} CompleteObjectDefinition
 * @param {string} Entity
 * @returns {boolean}
 */

export const isHidden = (
  CompleteObjectDefinition: CompleteObjectDefinition,
  Entity: string
) => {
  const objectDescription =
    CompleteObjectDefinition.ObjectDefinition.ObjectDescription;

  if (objectDescription.includes('hide-')) {
    const startIndex = objectDescription.indexOf('hide-') + 5;
    let endIndex = objectDescription.indexOf(']', startIndex);

    if (startIndex > -1 && endIndex > -1) {
      let namesString = objectDescription
        .substring(startIndex, endIndex)
        .trim();
      namesString = namesString.startsWith('[')
        ? namesString.substring(1)
        : namesString;

      const extractedNames = namesString.split(',').map((name) => name.trim());
      return extractedNames.includes(Entity);
    }
  }

  return false;
};

export const isShown = (
  CompleteObjectDefinition: CompleteObjectDefinition,
  Entity: string
) => {
  const objectDescription =
    CompleteObjectDefinition.ObjectDefinition.ObjectDescription;
  if (objectDescription.includes('show-')) {
    const startIndex = objectDescription.indexOf('show-') + 5;
    let endIndex = objectDescription.indexOf(']', startIndex);
    if (startIndex > -1 && endIndex > -1) {
      let namesString = objectDescription
        .substring(startIndex, endIndex)
        .trim();
      namesString = namesString.startsWith('[')
        ? namesString.substring(1)
        : namesString;

      const extractedNames = namesString.split(',').map((name) => name.trim());
      return extractedNames.includes(Entity);
    }
  }
  return false;
};
