import React, { createContext, useState } from 'react';
import { firebase } from 'redux/firebase';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import { GetAssignedUsersByProcess } from 'redux/database';
import LeftNavigationMenu from './views/LeftNavigationMenu';
import RightContent from './views/RightContent';
import { useTypedSelector } from 'redux/reducers';
import {
  getAndFilterConversations,
  sendEmail,
  getAllEmailsWithConversationId
} from './functions';
import { Message } from '@microsoft/microsoft-graph-types';
import { useToken } from './hooks';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { callMsGraph } from 'services/microsoft/graph';
import { EditorState } from 'draft-js';
import { BugTracker } from 'Utils/Bugtracker';
import {
  IMessageHubProvider,
  IFile,
  ThreadDict,
  ThreadExtension,
  IUser,
  IEmailObject
} from './interfaces';
import { threadCreate } from './functions/handleThreadsMerge';
import { emailByProcess } from 'redux/actions/email';
import { PostAudit } from 'redux/database/Audit API';
import { IUseAuditLog, saveAuditLog } from 'hooks/useDeals/useAuditLog';
import { Rule } from 'types/interfaces';
import HTMLAnalyzer from './HTMLAnalyzer';
import { notify } from 'components/Notifications/HotToastNotifications';

export const MessageHubProvider = createContext<IMessageHubProvider>({
  clearEditorState: null,
  conversation: null,
  editorState: null,
  files: [] as IFile[],
  getConversation: null,
  getRefreshEmails: null,
  handleHTML: null,
  loading: false,
  messages: null,
  selectedThreadKey: null,
  selectedUserInstanceId: null,
  selectThread: null,
  sendEmailToUser: null,
  setEditorState: null,
  setFiles: null,
  setSelectedThreadKey: null,
  setSelectedUserInstanceId: null,
  setSubject: null,
  subject: null,
  syncThread: null,
  threads: null,
  userDefinitionsList: null,
  users: [] as IUser[],
  threadsSearchComplete: false,
  systemEmailLog: {} as IEmailObject,
  getSystemEmails: () => null,
  openDialog: { open: false, ruleId: 0 },
  setOpenDialog: () => {},
  PopulatedEmail: undefined,
  setPopulatedEmail: () => undefined,
  setSelectedRule: () => {},
  setEmails: () => {},
  emails: []
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { minHeight: '93vh' },
    nav: {
      backgroundColor: `${theme.palette.primary.light}66`,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius
    },
    content: { padding: theme.spacing(2) }
  })
);

const MessageHub = ({ isOpen }: { isOpen?: boolean }) => {
  const classes = useStyles();
  const { Id } = useTypedSelector((s) => s.user.user);
  const [processAssignedUsers, setProcessAssignedUsers] = React.useState<
    IUser[]
  >([] as IUser[]);
  const [selectedUserInstanceId, setSelectedUserInstanceId] = React.useState(
    []
  );
  const {
    ProcessInstanceId,
    user,
    currentProcess,
    currentDeal,
    stepdefdict,
    currentOverView
  } = useProcess();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const dealSummary = useTypedSelector((s) => s.dealSummary.deal);

  const { currentStep } = useTypedSelector((s) => s.process);
  const { InteractiveRuleDict } = currentStep;
  const subSystemUser = user.SystemAccess <= 3;
  const isASystemUser = user.SystemAccess >= 5;
  const isProcessOwner =
    currentDeal?.ProcessInstance?.UserInstanceId === user.Id;
  const isSystemUserAndNotProcessOwner = isASystemUser && !isProcessOwner;
  const userDefinitionsList: {} | { [key: string]: string } = {};
  const [threads, setThreads] = React.useState<ThreadDict | null>(null);
  const [threadsSearchComplete, setThreadSearchComplete] =
    React.useState(false);
  const [selectedThreadKey, setSelectedThreadKey] = React.useState<
    string | null
  >(null);
  const [messages, setMessages] = React.useState<Message[] | null>(null);
  const [html, setHtml] = React.useState('');
  const [conversation, setConversation] = React.useState<Message[] | null>(
    null
  );

  const [emails, setEmails] = useState<string[]>([]);
  const [selectedRule, setSelectedRule] = useState<Rule>();
  const [PopulatedEmail, setPopulatedEmail] = React.useState<
    { Subject: string; Body: string; Attachment: string | null } | undefined
  >(undefined);

  const [openDialog, setOpenDialog] = React.useState({
    open: false,
    ruleId: 0
  });
  const [systemEmailLog, setSystemEmailLog] = React.useState<IEmailObject>(
    {} as IEmailObject
  );
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState<IFile[]>([]);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [subject, setSubject] = React.useState('');
  const canFetchAssignedUsers = () => {
    return !(subSystemUser || isSystemUserAndNotProcessOwner);
  };

  const getThreads = async () => {
    setLoading(true);
    try {
      await handleThreadMerge();
    } catch (e) {
      console.log('Error fetching threads:', e);
      BugTracker.notify(e);
    } finally {
      setLoading(false);
    }
  };

  const clearEditorState = () => {
    setEditorState(EditorState.createEmpty());
    setSubject('');
    setEmails([]);
    setFiles([]);
  };
  React.useEffect(() => {
    clearEditorState();
  }, [selectedThreadKey]);

  const handleHTML = (html: string) => setHtml(html);
  const sendEmailToUser = async () => {
    setLoading(true);
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });

    if (!accessToken || threads === null || !selectedThreadKey) {
      return alert('No accessToken or thread');
    }

    await sendEmail({
      accessToken,
      html,
      selectedThreadKey,
      subject,
      threads,
      conversation,
      files,
      currentOverView,
      ccUsers: emails
    })
      .then(async (res) => {
        clearEditorState();
        if (PopulatedEmail) setPopulatedEmail(undefined);

        setTimeout(async () => {
          await syncThread();
          setLoading(false);
        }, 3000);

        const AuditUserInstanceId = threads[selectedThreadKey].members.find(
          (member: string) => member !== user.Id.toString()
        );

        const data = {
          TimeStamp: new Date().toISOString(),
          UserInstanceId: user.Id,
          UserInstanceEmail: user.UserInstanceEmail,
          Payload: conversation ? JSON.stringify(conversation[0]) : subject,
          AuditUserInstanceId: conversation
            ? parseInt(AuditUserInstanceId || '0')
            : 0,
          AuditProcessInstanceId: ProcessInstanceId
        };

        if (selectedRule) {
          // PostAudit to Offkey API
          const customPayload: IUseAuditLog = {
            actionFunction: InteractiveRuleDict[selectedRule.Id].ActionFunction,
            payload: InteractiveRuleDict[selectedRule.Id].ActionValue,
            firebaseStatus: 'Sent',
            action: InteractiveRuleDict[selectedRule.Id].Title,
            timestamp: new Date(),
            userInstanceId:
              threads[selectedThreadKey]?.fixedTo !== undefined
                ? (threads[selectedThreadKey].fixedTo as number)
                : threads[selectedThreadKey].members[1],
            userInstanceEmail: user.UserInstanceEmail,
            dealId: parseInt(
              threads[selectedThreadKey].ProcessInstanceId.toString()
            ),
            ProcessDefinitionId: currentProcess.ProcessDefinition.Id
          };

          const firebaseAuditLog = await saveAuditLog(customPayload);
          if (firebaseAuditLog) {
            // PostAudit To Cosmetics API
            await PostAudit({ data });
          }

          setOpenDialog({ open: false, ruleId: 0 });
          notify.success('Email sent.');
          return;
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        notify.error(e.message);
        return;
      });
  };

  const isAuth = useIsAuthenticated();
  const { accounts, instance, inProgress } = useMsal();

  const syncThread = async () => {
    await getSystemEmails();
    if (!selectedThreadKey) return;
    if (!threads) return;
    const thread: { conversationId?: string } = threads[selectedThreadKey];
    const conversationId = thread.conversationId;
    if (!conversationId) return;
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });
    if (accessToken) {
      try {
        await getAllEmailsWithConversationId({
          accessToken,
          key: selectedThreadKey,
          conversationId
        });
        notify.success('Emails Synced.');
        return;
      } catch (e) {
        BugTracker.notify(e);
        notify.error('Failed Sync.');
        return;
      }
    }
  };

  const getRefreshEmails = async () => {
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });
    if (accessToken) {
      try {
        const res = await callMsGraph({
          accessToken,
          action: 'messages'
        });

        let messages: Message[] = res.value;

        setMessages(messages);

        notify.success('Emails Refreshed.');
        await getAndFilterConversations({
          accessToken,
          threads
        });
      } catch (e) {
        BugTracker.notify(e);
      }
    }
  };

  const getAssignedUsers = async () => {
    const res = await GetAssignedUsersByProcess({ Id: ProcessInstanceId });
    const data = res.data as IUser[];
    if (typeof data !== 'string') setProcessAssignedUsers(data);
  };

  React.useEffect(() => {
    const ref = firebase
      .firestore()
      .collection('thread')
      .where('ProcessInstanceId', '==', ProcessInstanceId.toString())
      .where('members', 'array-contains', Id.toString());

    const unsubscribe = ref.onSnapshot(async (snap) => {
      if (!snap.empty) {
        let messages = {} as ThreadDict;

        try {
          const dealRef = firebase
            .firestore()
            .collection('deal')
            .doc(currentOverView.ProcessInstanceId.toString());
          const dealDoc = await dealRef.get();
          if (!dealDoc.exists) {
            console.error('Deal document does not exist');
            return;
          }
          const dealData = dealDoc.data()?.ruleConversations || {};
          snap.forEach((doc) => {
            const threadData = doc.data() as ThreadExtension;
            const { type, conversationId, members } = threadData;
            if (type && conversationId && processAssignedUsers.length > 0) {
              const isBrokerOrIntroducer = ['broker.contact'].includes(type);
              if (isBrokerOrIntroducer) {
                if (!dealData) {
                  console.error('ruleConversations is not defined in dealData');
                  return;
                }
                if (!dealData[members[0]]) {
                  dealData[members[0]] = {};
                }
                if (!dealData[members[1]]) {
                  dealData[members[1]] = {};
                }
                dealData[members[0]][type] = conversationId;
                dealData[members[1]][type] = conversationId;
                dealRef.update({ ruleConversations: dealData });
              }
              const matchingRule = processAssignedUsers.find(
                (user) => user.UserInstanceId === parseInt(members[1])
              )?.ruleConversations?.[type];
              if (matchingRule && matchingRule !== conversationId) {
                threadData.conversationId = matchingRule;
              }
            }
            messages[doc.id] = threadData;
          });
          setThreads(messages);
        } catch (error) {
          console.error('Error processing threads:', error);
        }
      } else {
        console.log('No threads found');
      }
      setThreadSearchComplete(true);
    });
    return () => unsubscribe();
  }, [processAssignedUsers, currentOverView, ProcessInstanceId, Id]);

  const handleThreadMerge = async () => {
    await threadCreate({
      currentDeal,
      currentProcess,
      ProcessInstanceId,
      stepdefdict,
      threads,
      user,
      dealSummary,
      baseUrl,
      processAssignedUsers
    });
  };

  React.useEffect(() => {
    threadsSearchComplete && getAssignedUsers();
  }, [threadsSearchComplete]);

  React.useEffect(() => {
    console.log({ isOpen });
    if (processAssignedUsers?.length > 0 && isOpen) {
      getThreads();
    }
  }, [processAssignedUsers, isOpen]);

  processAssignedUsers &&
    processAssignedUsers.forEach((element: IUser) => {
      return (userDefinitionsList[element.UserDefinitionId.toString()] =
        element.UserDefinitionTitle);
    });

  const selectThread = (threadKey: string) => {
    if (threads) setSelectedThreadKey(threadKey);
  };

  const getSystemEmails = async () => {
    const emailLog: IEmailObject = await emailByProcess({ ProcessInstanceId });
    return setSystemEmailLog(emailLog);
  };

  React.useEffect(() => {
    if (isOpen) getSystemEmails();
  }, [isOpen]);

  const getConversation = async () => {
    await getSystemEmails();

    if (selectedThreadKey) {
      const unsubscribe = firebase
        .firestore()
        .collection('thread')
        .doc(selectedThreadKey)
        .collection('messages')
        .orderBy('sentDateTime')
        .onSnapshot((querySnapshot) => {
          let messages: Message[] = [];
          querySnapshot.forEach((doc) => messages.push(doc.data()));
          setConversation(messages.reverse());
        });

      return unsubscribe;
    }
  };
  const value = {
    clearEditorState,
    conversation,
    editorState,
    files,
    getConversation,
    getRefreshEmails,
    handleHTML,
    loading,
    messages,
    selectedThreadKey,
    selectedUserInstanceId,
    selectThread,
    sendEmailToUser,
    setEditorState,
    setFiles,
    setSelectedThreadKey,
    setSelectedUserInstanceId,
    setSubject,
    subject,
    syncThread,
    systemEmailLog,
    threads,
    threadsSearchComplete,
    userDefinitionsList,
    users: processAssignedUsers,
    getSystemEmails,
    openDialog,
    setOpenDialog,
    PopulatedEmail,
    setPopulatedEmail,
    setEmails,
    emails,
    setSelectedRule
  };

  return (
    <MessageHubProvider.Provider value={value}>
      <Grid container className={classes.container}>
        <Grid item md={3} className={classes.nav}>
          <LeftNavigationMenu />
        </Grid>
        <Grid item md={9} className={classes.content}>
          <RightContent />
        </Grid>
      </Grid>
    </MessageHubProvider.Provider>
  );
};

export default MessageHub;
