import { useEffect, useRef, useState } from 'react';
import { useProcess, useFileStorage } from 'hooks';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import DocumentTypeDialog from './components';
import { EDocumentType } from 'graphql/FileStorageAPI/interface';
import { FilePondFile } from 'filepond';
import { BugTracker } from 'Utils/Bugtracker';
import { Button, makeStyles } from '@material-ui/core';
import { theme } from 'theme';
import {
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop
} from './functions/dragDrop';
import { CloudUpload } from '@material-ui/icons';
import { CustomDialog } from 'common/Dialog';
import FileExplorer from 'components/FileExplorer';
import UploadMethodPopover from './components/UploadMethodPopover';
import { IFieldsProps } from 'components/Fields/InlineTextField/interface';
import { SET_DEAL_FILES } from 'redux/actions/types';
import { useTypedSelector } from 'redux/reducers';
import { notify } from 'components/Notifications/HotToastNotifications';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const MAX_FILE_SIZE = 30 * 1024 * 1024;
const isFileSizeValid = (file: File | FilePondFile): boolean => {
  const fileSize = 'size' in file ? file.size : file.fileSize;
  return fileSize <= MAX_FILE_SIZE;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    border: '2px dashed #c1c1c1',
    borderRadius: '4px',
    backgroundColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#e8e8e8'
    }
  },
  customButton: {
    width: '100%',
    padding: theme.spacing(2),
    color: '#0077c0',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 119, 192, 0.1)'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  filePondContainer: {
    '& .filepond--drop-label': {
      display: 'none'
    },
    '& .filepond--panel-root': {
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .filepond--root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  dropOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 119, 192, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    pointerEvents: 'none'
  },
  popover: {
    marginTop: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 2)
  },
  listItemIcon: {
    minWidth: 40
  }
}));

export const CustomFilePond = ({
  global,
  inlineFieldProps,
  refresh
}: {
  global: boolean | undefined;
  inlineFieldProps?: IFieldsProps;
  refresh: () => void;
}) => {
  const classes = useStyles();
  const { currentDeal, user, landingpage } = useProcess();
  const { uploadPondFiles } = useFileStorage();

  const ProcessInstanceId = global ? 0 : currentDeal?.ProcessInstance?.Id || 0;

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [isDragging, setIsDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const rootRef = useRef<HTMLDivElement>(null);

  const [fileExplorerOpen, setFileExplorerOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filePondRef = useRef<FilePond>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [currentFileUploads, setCurrentFileUploads] = useState<
    {
      file: File;
      load: (id: string) => void;
      abort: () => void;
      progress: (percent: number) => void;
      documentType: EDocumentType | null;
    }[]
  >([]);

  /**
   * Handles closing of the document type dialog
   */
  const handleDialogClose = () => {
    setDialogOpen(false);
    currentFileUploads.forEach((item) => item.abort());
    setCurrentFileUploads([]);
    setFiles([]);
  };

  /**
   * Handles confirmation of file uploads in the document type dialog
   */
  const handleDialogConfirm = async () => {
    setLoading(true);
    const uploadPromises = currentFileUploads.map(async (item, index) => {
      if (item.documentType) {
        const uploadControl = uploadPondFiles({
          file: item.file,
          ProcessInstanceId,
          user,
          load: item.load,
          abort: item.abort,
          progress: item.progress,
          global,
          landingpage,
          documentType: item.documentType,
          updateStatus: (status) => {
            setCurrentFileUploads((prevUploads) =>
              prevUploads.map((upload, i) =>
                i === index ? { ...upload, status } : upload
              )
            );
          }
        });
        await uploadControl.start();
      }
    });

    try {
      await Promise.all(uploadPromises);
      notify.success('All File(s) Uploaded Successfully');

      setCurrentFileUploads([]);
      setFiles([]);
      refresh();
    } catch (e) {
      BugTracker.notify(e);
    }

    setLoading(false);
    setDialogOpen(false);
  };

  /**
   * Handles change of document type for a file
   * @param {number} index - Index of the file in the currentFileUploads array
   * @param {EDocumentType} documentType - New document type
   */
  const handleDocumentTypeChange = (
    index: number,
    documentType: EDocumentType
  ) => {
    setCurrentFileUploads((prevUploads) =>
      prevUploads.map((item, i) =>
        i === index ? { ...item, documentType } : item
      )
    );
  };

  const isValidFileType = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension !== 'exe';
  };

  const handleUpload = async (files: File[], documentType: EDocumentType) => {
    setLoading(true);
    const uploadPromises = files.map(async (file) => {
      const uploadControl = uploadPondFiles({
        file,
        ProcessInstanceId,
        user,
        load: () => {},
        abort: () => {},
        progress: () => {},
        global,
        landingpage,
        documentType,
        updateStatus: () => {}
      });
      await uploadControl.start();
    });

    try {
      await Promise.all(uploadPromises);
      notify.success('All File(s) Uploaded Successfully');

      setCurrentFileUploads([]);
      setFiles([]);
      refresh();
    } catch (e) {
      BugTracker.notify(e);
    }

    setLoading(false);
  };

  /**
   * Processes a file upload
   * @param {string} fieldName - Name of the field
   * @param {File} file - File to be processed
   * @param {any} metadata - File metadata
   * @param {(id: string) => void} load - Function to call when load is complete
   * @param {(errorText: string) => void} error - Function to call on error
   * @param {(progress: number) => void} progress - Function to update progress
   * @param {() => void} abort - Function to abort the process
   * @param {Function} transfer - Transfer function
   * @param {any} options - Additional options
   * @returns {Object} Object with abort function
   */
  const process = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    if (!isFileSizeValid(file)) {
      error(`File size exceeds the ${MAX_FILE_SIZE}MB limit`);
      return {
        abort: () => {}
      };
    }

    if (landingpage) {
      // If on landing page, immediately upload with document type "OTHER"
      await handleUpload([file], EDocumentType.OTHER);
      load(file.name);
    } else {
      setCurrentFileUploads((prevUploads) => [
        ...prevUploads,
        { file, load, abort, progress, documentType: null }
      ]);
    }

    const simulateProcessing = () => {
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 10;
        progress(progressValue);
        if (progressValue >= 100) {
          clearInterval(interval);
          load(file.name);
        }
      }, 200);

      return interval;
    };

    const processingInterval = simulateProcessing();
    return {
      abort: () => {
        clearInterval(processingInterval);
        setCurrentFileUploads((prevUploads) =>
          prevUploads.filter((item) => item.file !== file)
        );
        abort();
      }
    };
  };

  /**
   * Handles updating of files in FilePond
   * @param {FilePondFile[]} fileItems - Array of FilePond file items
   */
  const handleUpdateFiles = (fileItems: FilePondFile[]) => {
    const validFiles = fileItems.filter(isFileSizeValid);
    setFiles(validFiles.map((fileItem) => fileItem.file as File));

    setCurrentFileUploads((prevUploads) => {
      const newUploads = prevUploads.filter((upload) =>
        validFiles.some((fileItem) => fileItem.file.name === upload.file.name)
      );
      return newUploads;
    });
  };

  const handleBrowseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileExplorerOpen = () => {
    setFileExplorerOpen(true);
    handleMenuClose();
  };

  const handleLocalDiskBrowse = () => {
    handleMenuClose();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (selectedFile: File) => {
    if (filePondRef.current) {
      filePondRef.current.addFile(selectedFile);
    }

    setFileExplorerOpen(false);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      Array.from(files).forEach((file) => {
        if (filePondRef.current && isValidFileType(file)) {
          const uniqueFile = new File([file], `${Date.now()}-${file.name}`, {
            type: file.type
          });
          filePondRef.current.addFile(uniqueFile);
        } else if (!isValidFileType(file)) {
          notify.warning(
            `Waring: ${file.name} is not allowed. EXE files are prohibited.`
          );
        }
      });
    }
  };

  useEffect(() => {
    const root = rootRef.current;
    3;
    if (root) {
      const createSyntheticEvent = (
        nativeEvent: DragEvent
      ): React.DragEvent<Element> => {
        const syntheticEvent =
          nativeEvent as unknown as React.DragEvent<Element>;
        syntheticEvent.persist = () => {};
        syntheticEvent.isPropagationStopped = () => false;
        syntheticEvent.isDefaultPrevented = () => false;
        return syntheticEvent;
      };

      const dragEnterHandler = (e: DragEvent) => {
        handleDragEnter(createSyntheticEvent(e), setIsDragging);
      };

      const dragLeaveHandler = (e: DragEvent) => {
        handleDragLeave(createSyntheticEvent(e), rootRef, setIsDragging);
      };

      const dragOverHandler = (e: DragEvent) => {
        handleDragOver(createSyntheticEvent(e));
      };

      const dropHandler = (e: DragEvent) => {
        handleDrop(createSyntheticEvent(e), setIsDragging, filePondRef);
      };

      root.addEventListener('dragenter', dragEnterHandler);
      root.addEventListener('dragleave', dragLeaveHandler);
      root.addEventListener('dragover', dragOverHandler);
      root.addEventListener('drop', dropHandler);

      return () => {
        root.removeEventListener('dragenter', dragEnterHandler);
        root.removeEventListener('dragleave', dragLeaveHandler);
        root.removeEventListener('dragover', dragOverHandler);
        root.removeEventListener('drop', dropHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (
      !landingpage &&
      currentFileUploads.length > 0 &&
      currentFileUploads.length === files.length &&
      !dialogOpen
    ) {
      setDialogOpen(true);
    }
  }, [currentFileUploads, files, dialogOpen]);

  return (
    <div ref={rootRef} className={classes.root}>
      {isDragging && <div className={classes.dropOverlay}>Drop files here</div>}
      <br />
      <div
        style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
        <Button className={classes.customButton} onClick={handleBrowseClick}>
          <CloudUpload className={classes.icon} />
          Drag & Drop your files or Browse
        </Button>
      </div>
      <div className={classes.filePondContainer}>
        <FilePond
          ref={filePondRef}
          files={files}
          onupdatefiles={handleUpdateFiles}
          allowMultiple
          maxFiles={30}
          credits={false}
          server={{
            process,
            revert: (uniqueFileId, load, error) => {
              setCurrentFileUploads((prevUploads) => {
                const newUploads = prevUploads.filter(
                  (upload) => upload.file.name !== uniqueFileId
                );
                return newUploads;
              });
              load();
            }
          }}
          onaddfilestart={(file: FilePondFile) => {
            if (!isFileSizeValid(file)) {
              notify.warning(
                `Warning: ${file.filename} exceeds the ${MAX_FILE_SIZE}MB file size limit.`
              );

              return false;
            }

            if (!isValidFileType(file.file as File)) {
              notify.warning(
                `Warning: ${file.filename} is not allowed. EXE files are prohibited.`
              );

              return false;
            }
            return true;
          }}
          labelIdle=""
          allowDrop={false}
          allowPaste={false}
          allowBrowse={false}
          allowRevert
        />
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
        multiple
      />
      <UploadMethodPopover
        inlineFieldProps={inlineFieldProps}
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        handleFileExplorerOpen={handleFileExplorerOpen}
        handleLocalDiskBrowse={handleLocalDiskBrowse}
      />
      <CustomDialog
        open={fileExplorerOpen}
        handleClose={() => setFileExplorerOpen(false)}
        alert={{
          title: 'File Explorer Selector',
          description: 'Please Select the File Which you Wish to Upload',
          type: 'info'
        }}>
        {fileExplorerOpen && (
          <FileExplorer isImport fileSelect={handleFileSelect} />
        )}
      </CustomDialog>
      <DocumentTypeDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        files={currentFileUploads}
        loading={loading}
        onDocumentTypeChange={handleDocumentTypeChange}
      />
    </div>
  );
};
