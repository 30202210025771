import { Dashboard as DashboardLayout } from 'layouts';
// import { NoticeBoard } from './components/notices';
import {
  PlatformSettings,
  AdminRelationships,
  PluginManager,
  RuleSchema,
  LenderConfigurationManager
} from './components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'hooks';
import { useTypedSelector } from 'redux/reducers';
import { XpansionIsolated } from 'common/Xpansion';
import { Typography } from '@material-ui/core';

const AdminView = () => {
  const { JediUsers, config } = useGlobal();
  const history = useHistory();
  const { SystemAccess, Id } = useTypedSelector((s) => s.user.user);
  const isAdmin = SystemAccess >= 10;
  const Jedi = JediUsers.includes(Id);
  const isSuperAdmin = config.SuperAdmins.includes(Id.toString());

  console.log({ JediUsers });

  useEffect(() => {
    if (!isAdmin) history.push('dashboard');
  }, []);

  return (
    <DashboardLayout title="Admin Area [ADMIN ACCESS ONLY]">
      {/* <NoticeBoard /> */}
      {Jedi && (
        <XpansionIsolated
          expanded={false}
          summary={<Typography variant="h4">Jedi View Manager</Typography>}>
          <PlatformSettings />
        </XpansionIsolated>
      )}

      {/* {Jedi && (
        <XpansionIsolated
          expanded={false}
          summary={<Typography variant="h4">Lender API Manager</Typography>}>
          <LenderConfigurationManager />
        </XpansionIsolated>
      )} */}

      {Jedi && (
        <XpansionIsolated
          expanded={false}
          summary={<Typography variant="h4">Plugin Manager</Typography>}>
          <PluginManager />
        </XpansionIsolated>
      )}

      {Jedi && (
        <XpansionIsolated
          expanded={false}
          summary={<Typography variant="h4">Rule Schema</Typography>}>
          <RuleSchema />
        </XpansionIsolated>
      )}
      {/* {isSuperAdmin && <AdminRelationships />} */}
    </DashboardLayout>
  );
};

export default AdminView;
