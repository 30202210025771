import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  Button,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Box
} from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { CustomDialog } from 'common/Dialog';

const SavedDraftsDialog = ({ open, handleClose, userId, onSelectDraft }) => {
  const [drafts, setDrafts] = useState<
    { id: string; draftComment?: string; createdAt?: number }[]
  >([]);
  const [isFetchingDrafts, setIsFetchingDrafts] = useState(true);

  useEffect(() => {
    if (open) {
      setIsFetchingDrafts(true);
      const unsubscribe = firebase
        .firestore()
        .collection('userAccount')
        .doc(userId.toString())
        .collection('savedProposalCommentsDrafts')
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          (snapshot) => {
            const fetchedDrafts = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }));
            setDrafts(fetchedDrafts);
            setIsFetchingDrafts(false);
          },
          (error) => {
            console.error('Error fetching drafts:', error);
            setIsFetchingDrafts(false);
          }
        );

      return () => unsubscribe();
    }
  }, [open, userId]);

  const handleDraftSelect = (draft: any) => {
    onSelectDraft(draft);
    handleClose();
  };

  const handleDraftDelete = async (draftId: any) => {
    try {
      await firebase
        .firestore()
        .collection('userAccount')
        .doc(userId.toString())
        .collection('savedProposalCommentsDrafts')
        .doc(draftId)
        .delete();
    } catch (error) {
      console.error('Error deleting draft:', error);
    }
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      maxSize="md"
      alert={{
        title: 'Saved Drafts',
        description:
          'Select the draft you would like to load into the editor or delete.',
        type: 'info'
      }}>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          View Saved Drafts
        </Typography>
        {isFetchingDrafts ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        ) : drafts.length > 0 ? (
          drafts.map((draft) => (
            <Paper key={draft.id} style={{ padding: 16, marginBottom: 8 }}>
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word'
                    }}>
                    {draft.draftComment
                      ? decodeURIComponent(draft.draftComment).replace(
                          /<\/?p>/g,
                          ''
                        )
                      : 'Empty Draft'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {draft.createdAt
                      ? new Date(draft.createdAt).toLocaleString()
                      : ''}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="flex-end"
                  spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDraftSelect(draft)}>
                      Select
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDraftDelete(draft.id)}>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography>No saved drafts found.</Typography>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default SavedDraftsDialog;
