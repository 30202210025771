import { TumbleDryer } from 'mdi-material-ui';
import NovunaProposal from '.';
import { ELender, ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';

export const getNovunaIds = () => {
  return createTypeSafeIds(NovunaProposal, (_) => ({
    proposal: {
      interfaceid: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'AFS'
      },
      externalreferenceid: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'ProcessInstanceId'
      },
      funderid: {
        FieldDefinitionId: 0,
        isReadonly: true,
        info: 'Novuna will provide this ID.'
      },
      introducer: {
        introducercode: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: '545000'
        },
        introducerbranch: {
          FieldDefinitionId: 24489,
          isReadonly: true
        },
        salespersoncode: {
          FieldDefinitionId: 24488,
          isReadonly: true
        }
      },
      source: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: [
            'Existing Customer',
            'Internal Referral',
            'Marketing Source',
            'New Customer',
            'Recommended by Existing Customer',
            'Supplier Dealer'
          ]
        }
      },
      customer: {
        id: {
          FieldDefinitionId: 18317,
          isReadonly: true,
          forcedValue: '12345',
          info: 'Generated Id On Bips'
        },
        companytype: {
          FieldDefinitionId: 21881,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'Limited Company',
              'Unlimited Company',
              'Sole Trader',
              'Partnership 3 or less',
              'Partnership 4 +',
              'Private Individual',
              'Private Individual HNW',
              'Limited Liability Partnership',
              'PLC',
              'Government Body',
              'Non-Profit',
              'Local Authority',
              'Other Regulated',
              'Other Non-Regulated',
              'Unincorporated Business'
            ]
          }
        },
        individual: {
          individualname: {
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
              }
            },

            forename: {
              FieldDefinitionId: 18420,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 18427,
              isReadonly: false,
              required: true
            }
          },
          dateofbirth: {
            FieldDefinitionId: 18416,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          }
        },
        company: {
          companyname: {
            FieldDefinitionId: 18317,
            isReadonly: false,
            required: true
          },
          companyregnum: {
            FieldDefinitionId: 18315,
            isReadonly: false,
            required: true
          },
          dateestablished: {
            FieldDefinitionId: 18450,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          contactname: {
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
              }
            },
            forename: {
              FieldDefinitionId: 18420,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 18427,
              isReadonly: false,
              required: true
            },
            telephonenumber: {
              FieldDefinitionId: 18525,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            mobilenumber: {
              FieldDefinitionId: 18525,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            emailaddress: {
              FieldDefinitionId: 18452,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        },
        industrytype: {
          FieldDefinitionId: 21881,
          isReadonly: false,
          required: true,
          info: "The industry the customer's company operates in. Please send the relevant 2007 SIC code as agreed in the Parameter Translation Mapping document."
        },
        addresses: [
          {
            address: {
              town: {
                FieldDefinitionId: 18435,
                isReadonly: false,
                required: true
              },
              poboxnumber: {
                FieldDefinitionId: 18438,
                isReadonly: false
              },
              flatnumber: {
                FieldDefinitionId: 18431,
                isReadonly: false
              },
              buildingnumber: {
                FieldDefinitionId: 18431,
                isReadonly: false
              },
              buildingname: {
                FieldDefinitionId: 18431,
                isReadonly: false
              },
              streetname: {
                FieldDefinitionId: 18432,
                isReadonly: false
              },
              postcode: {
                FieldDefinitionId: 18438,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    maxLength: 12,
                    pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                    message:
                      "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                  }
                }
              },
              county: {
                FieldDefinitionId: 18436,
                isReadonly: false
              },
              phonenumber: {
                FieldDefinitionId: 18525,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              },
              mobilenumber: {
                FieldDefinitionId: 18525,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              },
              emailaddress: {
                FieldDefinitionId: 18452,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    maxLength: 255,
                    pattern:
                      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                    message: 'Please enter a valid email address'
                  }
                }
              },
              moveindate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            }
          }
        ],
        otherparties: [
          {
            otherparty: {
              id: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'External system generated ID for the other party.'
              },
              companytype: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'Limited Company',
                    'Unlimited Company',
                    'Sole Trader',
                    'Partnership 3 or less',
                    'Partnership 4 +',
                    'Private Individual',
                    'Private Individual HNW',
                    'Limited Liability Partnership',
                    'PLC',
                    'Government Body',
                    'Non-Profit',
                    'Local Authority',
                    'Other Regulated',
                    'Other Non-Regulated',
                    'Unincorporated Business'
                  ]
                }
              },
              individual: {
                individualname: {
                  title: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
                    }
                  },
                  forename: {
                    FieldDefinitionId: 18420,
                    isReadonly: false,
                    required: true
                  },
                  surname: {
                    FieldDefinitionId: 18427,
                    isReadonly: false,
                    required: true
                  }
                },
                dateofbirth: {
                  FieldDefinitionId: 18416,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                }
              },
              company: {
                companyname: {
                  FieldDefinitionId: 18317,
                  isReadonly: false,
                  required: true
                },
                companyregnum: {
                  FieldDefinitionId: 18315,
                  isReadonly: false,
                  required: true
                },
                dateestablished: {
                  FieldDefinitionId: 18450,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                },
                contactname: {
                  title: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
                    }
                  },
                  forename: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  surname: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  telephonenumber: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Phone
                    }
                  },
                  mobilenumber: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Phone
                    }
                  },
                  emailaddress: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Requirement,
                      requirement: {
                        maxLength: 255,
                        pattern:
                          '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                        message: 'Please enter a valid email address'
                      }
                    }
                  }
                }
              },
              industrytype: {
                FieldDefinitionId: 21881,
                isReadonly: false,
                required: true,
                info: "The industry the customer's company operates in. Please send the relevant 2007 SIC code as agreed in the Parameter Translation Mapping document."
              },
              address: {
                town: {
                  FieldDefinitionId: 18435,
                  isReadonly: false,
                  required: true
                },
                flatnumber: {
                  FieldDefinitionId: 18431,
                  isReadonly: false
                },
                buildingnumber: {
                  FieldDefinitionId: 18431,
                  isReadonly: false
                },
                buildingname: {
                  FieldDefinitionId: 18432,
                  isReadonly: false
                },
                county: {
                  FieldDefinitionId: 18436,
                  isReadonly: false
                },
                streetname: {
                  FieldDefinitionId: 18433,
                  isReadonly: false
                },
                country: {
                  FieldDefinitionId: 18437,
                  isReadonly: false
                },
                postcode: {
                  FieldDefinitionId: 18438,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 12,
                      pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                      message:
                        "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                    }
                  }
                },
                phonenumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                },
                mobilenumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                },
                emailaddress: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 255,
                      pattern:
                        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                      message: 'Please enter a valid email address'
                    }
                  }
                },
                moveindate: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                }
              },

              marketinganddatausage: {
                marketingbyemail: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via email from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                marketingbypost: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via post from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                marketingbysms: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via sms from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                marketingbyphone: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via phone from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                expectedchangeincircumstances: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Do you expect the customer to have an expected change in circumstances?'
                },
                reasonforexpectedchangeincircumstances: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Please detail the reason/s for the expected change in circumstances.'
                }
              }
            }
          }
        ],
        privateaddress: {
          town: {
            FieldDefinitionId: 0,
            isReadonly: false
          },
          postcode: {
            FieldDefinitionId: 0,
            isReadonly: false,

            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 12,
                pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                message:
                  "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
              }
            }
          },
          phonenumber: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          mobilenumber: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          emailaddress: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          },
          moveindate: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          }
        },
        marketinganddatausage: {
          marketingbyemail: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via email from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          marketingbypost: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via post from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          marketingbysms: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via sms from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          marketingbyphone: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via phone from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          expectedchangeincircumstances: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Do you expect the customer to have an expected change in circumstances?'
          },
          reasonforexpectedchangeincircumstances: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Please detail the reason/s for the expected change in circumstances.'
          }
        }
      },
      assets: [
        {
          asset: {
            numberofassets: {
              FieldDefinitionId: 18313,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
                requirement: {
                  minAmount: 1
                }
              }
            },
            cost: {
              FieldDefinitionId: 18314,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Currency, ELenderAPIType.Requirement],
                requirement: {
                  minAmount: 100
                }
              }
            },
            vattreatment: {
              FieldDefinitionId: 21166,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: ['VAT', 'Exempt']
              }
            },
            description: {
              FieldDefinitionId: 18310,
              isReadonly: false,
              required: true
            },
            type: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'Vehicle Access Platforms',
                  'Vehicle Ambulances',
                  'Vehicle Buses',
                  'Vehicle Cars',
                  'Vehicle Classic Cars',
                  'Vehicle Coaches',
                  'Vehicle Commercial Heavy',
                  'Vehicle Commercial Light',
                  'Vehicle Funeral Vehicles',
                  'Vehicle Horse Boxes',
                  'Vehicle Mini Buses',
                  'Vehicle Motorcycles',
                  'Vehicle Motorhomes',
                  'Vehicle Road Sweepers',
                  'Vehicle Taxi Cabs',
                  'Vehicle Hire Cars',
                  'Vehicle Hire CVs',
                  'Equipment Access Platforms',
                  'Equipment Agriculture',
                  'Equipment Air Conditioning',
                  'Equipment Aircraft',
                  'Equipment Biomass Boilers',
                  'Equipment Catering',
                  'Equipment CCTV',
                  'Equipment Construction',
                  'Equipment Conveyors',
                  'Equipment Cranes Tower',
                  'Equipment Engineering',
                  'Equipment Epos',
                  'Equipment Food Processing',
                  'Equipment Forestry',
                  'Equipment Furniture',
                  'Equipment Garage Equipment',
                  'Equipment Generators',
                  'Equipment Gym Equipment',
                  'Equipment Hair & Beauty',
                  'Equipment Horse Boxes',
                  'Equipment IT Hardware',
                  'Equipment Lawn Mowers',
                  'Equipment Lighting',
                  'Equipment Machine Tools',
                  'Equipment Manufacturing',
                  'Equipment Materials Handling',
                  'Equipment Media Equipment',
                  'Equipment Medical',
                  'Equipment Office Equipment',
                  'Equipment Packaging',
                  'Equipment Plant Hire',
                  'Equipment Plastic Machinery',
                  'Equipment Portable Buildings',
                  'Equipment Printing',
                  'Equipment Recreation & Leisure',
                  'Equipment Reprographic',
                  'Equipment Scaffolding',
                  'Equipment Shop Fittings',
                  'Equipment Software',
                  'Equipment Solar Panels',
                  'Equipment Storage Containers',
                  'Equipment Surveying Equipment',
                  'Equipment Textile Machinery',
                  'Equipment Tracking Equipment',
                  'Equipment Trailers',
                  'Equipment Vending Machines',
                  'Equipment Waste & Recycling',
                  'Equipment Wind Turbine',
                  'Equipment Woodworking',
                  'Other'
                ]
              }
            },
            deposit: {
              FieldDefinitionId: 23824,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            residualvalue: {
              FieldDefinitionId: 23949,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            isnew: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            },
            ageatstartdate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            model: {
              FieldDefinitionId: 18310,
              isReadonly: false
            },
            make: {
              FieldDefinitionId: 18309,
              isReadonly: false
            },
            supplier: {
              id: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'External system generated ID for the other party.'
              },
              companytype: {
                FieldDefinitionId: 21944,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'Limited Company',
                    'Unlimited Company',
                    'Sole Trader',
                    'Partnership 3 or less',
                    'Partnership 4 +',
                    'Private Individual',
                    'Private Individual HNW',
                    'Limited Liability Partnership',
                    'PLC',
                    'Government Body',
                    'Non-Profit',
                    'Local Authority',
                    'Other Regulated',
                    'Other Non-Regulated',
                    'Unincorporated Business'
                  ]
                }
              },
              individual: {
                individualname: {
                  title: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
                    }
                  },
                  forename: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  surname: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  }
                },
                dateofbirth: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                }
              },
              company: {
                companyname: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                },
                companyregnum: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                },
                dateestablished: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                },
                contactname: {
                  title: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
                    }
                  },
                  forename: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  surname: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  telephonenumber: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Phone
                    }
                  },
                  mobilenumber: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Phone
                    }
                  },
                  emailaddress: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Requirement,
                      requirement: {
                        maxLength: 255,
                        pattern:
                          '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                        message: 'Please enter a valid email address'
                      }
                    }
                  }
                }
              },
              industrytype: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: "The industry the customer's company operates in. Please send the relevant 2007 SIC code as agreed in the Parameter Translation Mapping document."
              },
              address: {
                town: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                },
                postcode: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 12,
                      pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                      message:
                        "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                    }
                  }
                },
                phonenumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                },
                mobilenumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                },
                emailaddress: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 255,
                      pattern:
                        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                      message: 'Please enter a valid email address'
                    }
                  }
                },
                moveindate: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    }
                  }
                }
              },

              marketinganddatausage: {
                marketingbyemail: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via email from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                marketingbypost: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via post from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                marketingbysms: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via sms from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                marketingbyphone: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Does the customer/s wish to receive marketing information via phone from Novuna Business Finance?',
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                expectedchangeincircumstances: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Do you expect the customer to have an expected change in circumstances?'
                },
                reasonforexpectedchangeincircumstances: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: 'Please detail the reason/s for the expected change in circumstances.'
                }
              }
            }
          }
        }
      ],
      financials: {
        agreementstartdate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'Date the customer wishes the agreement to start on. E.g. if the customer wished the finance agreement to start 25th April 2024, this would be sent as 2024-04-25',
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        settlementcompanyname: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'If the customer wants to include a Novuna settlement in the new proposal. The customer can include the settlement company name here.'
        },
        settlementagreementnumber: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'If the customer wants to include a Novuna settlement in the new proposal. The customer can include the settlement agreement number here.'
        },
        term: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'Term of the finance proposal in a numerical format. E.g. 60 = 5 year finance proposal.',
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        advancedrentalamount: {
          FieldDefinitionId: 18348,
          isReadonly: false,
          info: 'Value of the advanced rental if selected.',
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        numberofadvancedrentals: {
          FieldDefinitionId: 0,
          isReadonly: false,
          info: 'If selected the number of the advanced rentals the customer wishes to select.',
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        regularrentalfrequency: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.OptionsList,
            options: [
              { value: 1, label: 'Monthly' },
              { value: 3, label: 'Quarterly' },
              { value: 6, label: 'Half-Yearly' },
              { value: 12, label: 'Annually' }
            ]
          }
        },
        totalnumberofrentals: {
          FieldDefinitionId: 18332,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
            requirement: {
              minAmount: 1
            }
          }
        },
        paymentmethod: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.OptionsList,
            options: [{ value: 'DD', label: 'Direct Debit' }]
          }
        },
        producttype: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'Motor HP Reg',
              'Motor Lease Purchase',
              'Motor Lease Purchase Reg',
              'Fixed Rate HP',
              'Fixed Rate HP Reg',
              'Lease Purchase',
              'Lease Purchase Reg',
              'Sale HP Back',
              'Sale HP Back Reg',
              'VR Balanced Payments',
              'Finance Lease',
              'Finance Lease Reg',
              'Fixed Term Lease',
              'Fixed Term Lease Reg',
              'Min Period Lease',
              'Min Period Lease Reg',
              'Operating Lease',
              'Operating Lease Reg',
              'Sale Lease Back',
              'Sale Lease Back Reg',
              'Loan Commercial',
              'Loan Commercial Reg'
            ]
          }
        },
        balloonpayment: {
          FieldDefinitionId: 23949,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        paymentprofiles: {
          paymentprofile: {
            startdate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: 'Start date of the finance proposal. E.g. if the customer wished the finance agreement to start 25th April 2024, this would be sent as 2024-04-25',
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            rentalfrequency: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  { value: '1', label: 'Monthly' },
                  { value: '3', label: 'Quarterly' },
                  { value: '6', label: 'Half-Yearly' },
                  { value: '12', label: 'Annually' }
                ]
              }
            },
            numberofrentals: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            isadvance: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: 'Is there an advance payment?',
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            },
            rentalpaymentcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [{ value: 'DD', label: 'Direct Debit' }]
              }
            },
            rentalamount: {
              FieldDefinitionId: 23891,
              isReadonly: false,
              required: true,
              info: 'The customer can specify the rental amount they wish to pay.',
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            }
          }
        }
      },
      repurchaser: {
        id: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: 'External system generated ID for the other party.'
        },
        companytype: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'Limited Company',
              'Unlimited Company',
              'Sole Trader',
              'Partnership 3 or less',
              'Partnership 4 +',
              'Private Individual',
              'Private Individual HNW',
              'Limited Liability Partnership',
              'PLC',
              'Government Body',
              'Non-Profit',
              'Local Authority',
              'Other Regulated',
              'Other Non-Regulated',
              'Unincorporated Business'
            ]
          }
        },
        individual: {
          individualname: {
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
              }
            },
            forename: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          },
          dateofbirth: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          }
        },
        company: {
          companyname: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          companyregnum: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          dateestablished: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          contactname: {
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
              }
            },
            forename: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            telephonenumber: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            mobilenumber: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            emailaddress: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        },
        industrytype: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: "The industry the customer's company operates in. Please send the relevant 2007 SIC code as agreed in the Parameter Translation Mapping document."
        },
        address: {
          town: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          postcode: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 12,
                pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                message:
                  "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
              }
            }
          },
          phonenumber: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          mobilenumber: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          emailaddress: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          },
          moveindate: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          }
        },

        marketinganddatausage: {
          marketingbyemail: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via email from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          marketingbypost: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via post from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          marketingbysms: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via sms from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          marketingbyphone: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Does the customer/s wish to receive marketing information via phone from Novuna Business Finance?',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          expectedchangeincircumstances: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Do you expect the customer to have an expected change in circumstances?'
          },
          reasonforexpectedchangeincircumstances: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Please detail the reason/s for the expected change in circumstances.'
          }
        }
      },
      guarantors: [
        {
          guarantor: {
            id: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: 'External system generated ID for the other party.'
            },
            companytype: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: ['Personal Guarantee', 'Business Guarantee']
              }
            },
            individual: {
              individualname: {
                title: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
                  }
                },
                forename: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                },
                surname: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                }
              },
              dateofbirth: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            },
            company: {
              companyname: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              companyregnum: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              dateestablished: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              },
              contactname: {
                title: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms', 'Mx']
                  }
                },
                forename: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                },
                surname: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true
                },
                telephonenumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                },
                mobilenumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Phone
                  }
                },
                emailaddress: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 255,
                      pattern:
                        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                      message: 'Please enter a valid email address'
                    }
                  }
                }
              }
            },
            industrytype: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: "The industry the customer's company operates in. Please send the relevant 2007 SIC code as agreed in the Parameter Translation Mapping document."
            },

            address: {
              town: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    maxLength: 12,
                    pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                    message:
                      "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                  }
                }
              },
              phonenumber: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              },
              mobilenumber: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              },
              emailaddress: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    maxLength: 255,
                    pattern:
                      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                    message: 'Please enter a valid email address'
                  }
                }
              },
              moveindate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            },

            marketinganddatausage: {
              marketingbyemail: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Does the customer/s wish to receive marketing information via email from Novuna Business Finance?',
                FieldDefinition: {
                  type: ELenderAPIType.Boolean
                }
              },
              marketingbypost: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Does the customer/s wish to receive marketing information via post from Novuna Business Finance?',
                FieldDefinition: {
                  type: ELenderAPIType.Boolean
                }
              },
              marketingbysms: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Does the customer/s wish to receive marketing information via sms from Novuna Business Finance?',
                FieldDefinition: {
                  type: ELenderAPIType.Boolean
                }
              },
              marketingbyphone: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Does the customer/s wish to receive marketing information via phone from Novuna Business Finance?',
                FieldDefinition: {
                  type: ELenderAPIType.Boolean
                }
              },
              expectedchangeincircumstances: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Do you expect the customer to have an expected change in circumstances?'
              },
              reasonforexpectedchangeincircumstances: {
                FieldDefinitionId: 0,
                isReadonly: false,
                info: 'Please detail the reason/s for the expected change in circumstances.'
              }
            }
          }
        }
      ],
      notes: [
        {
          note: {
            notedetail: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.AddMore
              }
            }
          }
        }
      ],
      attachments: [
        {
          attachment: {
            name: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.AddMore
              }
            },
            data: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: 'Acceptable file types: ppptx, ppt, docx, doc, xls, xlsx, txt, csv, pdf, gif, bmp, png, tif, tiff, jpeg and jpg.',
              FieldDefinition: {
                type: [ELenderAPIType.AddMore, ELenderAPIType.Document]
              }
            }
          }
        }
      ]
    }
  }));
};
