import NorthridgeProposal from '.';
import { ELender, ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';
import { countryOptions } from './helper/countryOptions';

export const getNorthridgeIds = () => {
  return createTypeSafeIds(NorthridgeProposal, (_) => ({
    username: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'BrokerName'
    },
    otheR1: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'BrokerNickname'
    },
    otheR2: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'Asset Finance Solutions UK Ltd'
    },
    adS_REQUEST: {
      loaN_PROPOSAL: {
        control: {
          dealerref: {
            FieldDefinitionId: 0,
            isReadonly: true,
            required: true,
            forcedValue: 'ProcessInstanceId'
          },
          cusT_ID1: {
            FieldDefinitionId: 18317,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 21810
                }
              ]
            }
          },
          action: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          replY_EMAIL: {
            FieldDefinitionId: 0,
            isReadonly: true,
            required: true,
            forcedValue: 'BrokerEmail',
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          }
        },
        finance: {
          financetype: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Hire Purchase',
                  value: 'HP'
                },
                {
                  label: 'Personal Loan',
                  value: 'PL'
                },
                {
                  label: 'Leasing',
                  value: 'LS'
                }
              ]
            }
          },
          templatelist: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Standard HP APR',
                  value: 'HP CAR APR'
                },
                {
                  label: 'Standard PCP APR',
                  value: 'PCP CAR APR'
                },
                {
                  label: 'Commercial HP APR',
                  value: 'HP COMM APR'
                },
                {
                  label: 'Commercial PCP APR',
                  value: 'PCP COMM APR'
                }
              ]
            }
          },
          invoiceprice: {
            FieldDefinitionId: 23836,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          },
          settlementamount: {
            FieldDefinitionId: 23827,
            isReadonly: false
          },
          term: {
            FieldDefinitionId: 18332,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
              requirement: {
                maxAmount: 84,
                minAmount: 12
              }
            }
          },
          instalment: {
            FieldDefinitionId: 0,
            isReadonly: false
          },
          flatrate: {
            FieldDefinitionId: 23956,
            isReadonly: false
          },
          deposit: {
            FieldDefinitionId: 23956,
            isReadonly: false
          },
          ppp: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['1', '2', '3', '4']
            }
          },
          parT_EXCHANGE: {
            FieldDefinitionId: 23830,
            isReadonly: false
          },
          residual: {
            FieldDefinitionId: 23949,
            isReadonly: false
          },
          residuaL_DATE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          optioN_FEE: {
            FieldDefinitionId: 22740,
            isReadonly: false
          },
          iniT_RENT_VAT: {
            FieldDefinitionId: 18348,
            isReadonly: false
          },
          advancE_PAYMTS: {
            FieldDefinitionId: 18348,
            isReadonly: false
          },
          esT_ANNUAL_MILEAGE: {
            FieldDefinitionId: 18537,
            isReadonly: false
          },
          paY_FREQ: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Annually',
                  value: 'A'
                },
                {
                  label: 'Half-Yearly',
                  value: 'H'
                },
                {
                  label: 'Quarterly',
                  value: 'Q'
                },
                {
                  label: 'Monthly',
                  value: 'M'
                }
              ]
            }
          },
          breakmtH1: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'January',
                  value: '01'
                },
                {
                  label: 'February',
                  value: '02'
                },
                {
                  label: 'March',
                  value: '03'
                },
                {
                  label: 'April',
                  value: '04'
                },
                {
                  label: 'May',
                  value: '05'
                },
                {
                  label: 'June',
                  value: '06'
                },
                {
                  label: 'July',
                  value: '07'
                },
                {
                  label: 'August',
                  value: '08'
                },
                {
                  label: 'September',
                  value: '09'
                },
                {
                  label: 'October',
                  value: '10'
                },
                {
                  label: 'November',
                  value: '11'
                },
                {
                  label: 'December',
                  value: '12'
                }
              ]
            }
          },
          breakmtH2: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'January',
                  value: '01'
                },
                {
                  label: 'February',
                  value: '02'
                },
                {
                  label: 'March',
                  value: '03'
                },
                {
                  label: 'April',
                  value: '04'
                },
                {
                  label: 'May',
                  value: '05'
                },
                {
                  label: 'June',
                  value: '06'
                },
                {
                  label: 'July',
                  value: '07'
                },
                {
                  label: 'August',
                  value: '08'
                },
                {
                  label: 'September',
                  value: '09'
                },
                {
                  label: 'October',
                  value: '10'
                },
                {
                  label: 'November',
                  value: '11'
                },
                {
                  label: 'December',
                  value: '12'
                }
              ]
            }
          }
        },
        loaN_PURPOSE: {
          vehicle: {
            merchcode: {
              FieldDefinitionId: 21290,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  { label: 'Cars 0 N', value: '01' },
                  { label: 'Cars 3-5 years U', value: '03' },
                  { label: 'Cars 5+ years U', value: '04' },
                  { label: 'Motorcycles N', value: '05' },
                  { label: 'Motorcycles U', value: '06' },
                  { label: 'Residual Re-Finance', value: '07' },
                  { label: 'Cars 0-1 years U', value: '32' },
                  { label: 'Cars 1-3 years U', value: '33' },
                  { label: 'Commercial Veh Light N', value: '39' },
                  { label: 'Commercial Veh Light U', value: '40' },
                  { label: 'Commercial Veh Heavy N', value: '41' },
                  { label: 'Commercial Veh Heavy U', value: '42' },
                  { label: 'Agric Tractors N', value: '47' },
                  { label: 'Agric Tractors U', value: '48' },
                  { label: 'Motor homes 0 New', value: '75' },
                  { label: 'Motor homes 0-5 Used', value: '77' },
                  { label: 'Motor homes 6-10 Used', value: '78' },
                  { label: 'Motor homes 11-15 Used', value: '82' }
                ]
              }
            },
            make: {
              FieldDefinitionId: 18309,
              isReadonly: false,
              required: true
            },
            model: {
              FieldDefinitionId: 18310,
              isReadonly: false,
              required: true
            },
            reG_DATE: {
              FieldDefinitionId: 20697,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            reG_NO: {
              FieldDefinitionId: 21938,
              isReadonly: false
            },
            chassisno: {
              FieldDefinitionId: 18454,
              isReadonly: false,
              required: true
            },
            enginE_SIZE: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            mileage: {
              FieldDefinitionId: 18454,
              isReadonly: false
            },
            neW_USED: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'New',
                    value: 'N'
                  },
                  {
                    label: 'Used',
                    value: 'U'
                  }
                ]
              }
            },
            manuaL_AUTO: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Manual',
                    value: 'M'
                  },
                  {
                    label: 'Automatic',
                    value: 'A'
                  }
                ]
              }
            },
            petroL_DIESEL: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Petrol',
                    value: 'P'
                  },
                  {
                    label: 'Diesel',
                    value: 'D'
                  },
                  {
                    label: 'Electric',
                    value: 'E'
                  },
                  {
                    label: 'Petrol/Bio Ethanol (E85)',
                    value: 'F'
                  },
                  {
                    label: 'Petrol/CNG',
                    value: 'G'
                  },
                  {
                    label: 'Hybrid',
                    value: 'H'
                  },
                  {
                    label: 'Petrol/Plugin Electric',
                    value: 'X'
                  },
                  {
                    label: 'Diesel/Electric',
                    value: 'Y'
                  },
                  {
                    label: 'Diesel/Plugin Electric',
                    value: 'Z'
                  },
                  {
                    label: 'Bi Fuel:Petrol/LPG',
                    value: 'B'
                  }
                ]
              }
            },
            commercial: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            vaT_QUALIFYING: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            }
          },
          nonvehicle: {
            merchcode: {
              FieldDefinitionId: 18309,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  { label: 'Insurance Premium', value: '08' },
                  { label: 'Professional Fees', value: '09' },
                  { label: 'Contractors Plant N', value: '11' },
                  { label: 'Contractors Plant U', value: '12' },
                  { label: 'Shop Fittings / Equip N', value: '13' },
                  { label: 'Shop Fittings / Equip U', value: '14' },
                  { label: 'Home Improve-Furnishings', value: '20' },
                  { label: 'Home Improve-C.Heating', value: '22' },
                  { label: 'Vehicle - Car', value: '23' },
                  { label: 'Vehicle - Other', value: '24' },
                  { label: 'Quad Bike/ATV N', value: '25' },
                  { label: 'Quad Bike/ATV U', value: '26' },
                  { label: 'Other Asset', value: '28' },
                  { label: 'Printing Machinery N', value: '29' },
                  { label: 'Printing Machinery U', value: '30' },
                  { label: 'Purchase Licenced Prem', value: '31' },
                  { label: 'Portable Buildings', value: '34' },
                  { label: 'Caravans Touring 0-3 U', value: '36' },
                  { label: 'Caravans Touring 3-5 U', value: '37' },
                  { label: 'Caravans Touring 5+yrs U', value: '38' },
                  { label: 'Plant / Equip N', value: '43' },
                  { label: 'Plant / Equip U', value: '44' },
                  { label: 'Agric Equip (ex tract) N', value: '45' },
                  { label: 'Agric Equip (ex tract) U', value: '46' },
                  { label: 'Cash Registers', value: '49' },
                  { label: 'Equip Office Photocopier', value: '50' },
                  { label: 'Equip Office Furnishings', value: '51' },
                  { label: 'Equip Office Other', value: '52' },
                  { label: 'Computer', value: '53' },
                  { label: 'Auto Word Processors', value: '54' },
                  { label: 'Agric Other', value: '55' },
                  { label: 'Home Improve-Garage/Ext', value: '56' },
                  { label: 'Home Imrpove-D.Glazing', value: '57' },
                  { label: 'Home Improve-Kitchens', value: '58' },
                  { label: 'Home Improve-Other', value: '59' },
                  { label: 'Prop Purchase Commercial', value: '61' },
                  { label: 'Prop Purchase Private', value: '62' },
                  { label: 'Bridging Finance Comm', value: '63' },
                  { label: 'Bridging Finance Private', value: '64' },
                  { label: 'Elect Music Centre/Hi Fi', value: '65' },
                  { label: 'Electrical TV', value: '66' },
                  { label: 'Electrical Video Recorde', value: '67' },
                  { label: 'Elect Fridge/Freezer/Was', value: '68' },
                  { label: 'Boats / Equip N', value: '69' },
                  { label: 'Boats / Equip U', value: '70' },
                  { label: 'Equip Garage', value: '71' },
                  { label: 'Equip Refrigeration', value: '73' },
                  { label: 'Equip Catering', value: '74' },
                  { label: 'Equip Vending', value: '76' },
                  { label: 'Share Purchase', value: '79' },
                  { label: 'Equip Disco', value: '80' },
                  { label: 'Agric Livestock', value: '81' },
                  { label: 'Holidays', value: '83' },
                  { label: 'Fees-Med/Edu/Profes/Site', value: '72' },
                  { label: 'Works/Services', value: '84' },
                  { label: 'Caravans Mobile Homes N', value: '86' },
                  { label: 'Caravans Mobile Homes U', value: '87' },
                  { label: 'Agric Land Improvement', value: '88' },
                  { label: 'Working Capital', value: '89' },
                  { label: 'Commercial Loans', value: '90' },
                  { label: 'Multi Assets', value: '91' },
                  { label: 'Rescheduled Loan', value: '92' },
                  { label: 'Stocking Cars', value: '93' },
                  { label: 'Stocking Caravans', value: '94' },
                  { label: 'Stocking Electrical', value: '95' },
                  { label: 'Stocking Computers', value: '96' },
                  { label: 'Stocking Industrial', value: '97' },
                  { label: 'Stocking Other', value: '98' },
                  { label: 'Other - Personal Loan', value: '99' },
                  { label: 'Car Insurance:AXA Direct', value: 'AA' },
                  { label: 'Car Insurance:AXA Dealer', value: 'AB' },
                  { label: 'Gap Insurance:Direct', value: 'AC' },
                  { label: 'Gap Insurance:Dealer', value: 'AD' },
                  { label: 'Road Tax:', value: 'AE' },
                  { label: 'Gap Insurance:3rd Party', value: 'AF' },
                  { label: 'Gap', value: 'DA' }
                ]
              }
            },
            descriptioN1: {
              FieldDefinitionId: 18310,
              isReadonly: false,
              required: true
            },
            serialno: {
              FieldDefinitionId: 18454,
              isReadonly: false
            },
            age: {
              FieldDefinitionId: 18312,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          }
        },
        contracT_HIRE_DETAILS: {
          contracT_HIRE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          contracT_HIRE_TEMPLATE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          roaD_FUND_LICENCE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          maintenance: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          relieF_VEHICLE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          roaD_SIDE_ASSISTANCE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          }
        },
        hireR_DETAILS: {
          address: {
            facetoface: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            title: {
              FieldDefinitionId: 18428,
              isReadonly: false,
              required: true
            },
            forename: {
              FieldDefinitionId: 18420,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 18427,
              isReadonly: false,
              required: true
            },
            postcode: {
              FieldDefinitionId: 18425,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            telephone: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            email: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          },
          prevaddr: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR2: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR3: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR4: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          personal: {
            dateofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Date
              }
            },
            gender: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Male',
                    value: 'M'
                  },
                  {
                    label: 'Female',
                    value: 'F'
                  }
                ]
              }
            },
            marital: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            residential: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Homeowner',
                    value: '0'
                  },
                  {
                    label: 'Tenant',
                    value: 'T'
                  },
                  {
                    label: 'With Parents',
                    value: 'P'
                  },
                  {
                    label: 'Work provided/other',
                    value: 'W'
                  }
                ]
              }
            },
            countryofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          }
        },
        jointhireR_DETAILS: {
          address: {
            facetoface: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            title: {
              FieldDefinitionId: 18428,
              isReadonly: false,
              required: true
            },
            forename: {
              FieldDefinitionId: 18420,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 18427,
              isReadonly: false,
              required: true
            },
            houseno: {
              FieldDefinitionId: 18414,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23984
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23984
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            postcode: {
              FieldDefinitionId: 18425,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 18414,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 18429,
              isReadonly: false,
              required: true
            },
            county: {
              FieldDefinitionId: 18417,
              isReadonly: false
            },
            country: {
              FieldDefinitionId: 18437,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            mnths: {
              FieldDefinitionId: 23700,
              isReadonly: false
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            telephone: {
              FieldDefinitionId: 23704,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23979
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23979
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            mobile: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23970
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23624
                  }
                ]
              }
            },
            email: {
              FieldDefinitionId: 18452,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                },
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23499
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 21873
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            }
          },
          prevaddr: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23973
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23965
                  }
                ]
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR2: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23973
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23965
                  }
                ]
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR3: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR4: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          personal: {
            dateofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            gender: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Male',
                    value: 'M'
                  },
                  {
                    label: 'Female',
                    value: 'F'
                  }
                ]
              }
            },
            marital: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            residential: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Homeowner',
                    value: 'O'
                  },
                  {
                    label: 'Tenant',
                    value: 'T'
                  },
                  {
                    label: 'With Parents',
                    value: 'P'
                  },

                  {
                    label: 'Work provided/other',
                    value: 'W'
                  }
                ]
              }
            },
            countryofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            nationality: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          },
          occupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              forcedValue: 'Director',
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0,
                    forcedValue: 'Proprietor'
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0,
                    forcedValue: 'Partner'
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21786
                  }
                ]
              }
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21783
                  }
                ]
              }
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23681
                  }
                ]
              }
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23681
                  }
                ]
              }
            },
            employertelephone: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.EntityChange, ELenderAPIType.Phone],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21784
                  }
                ]
              }
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.EntityChange, ELenderAPIType.Number],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21788
                  }
                ]
              }
            }
          },
          prevoccupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          bank: {
            sortcode: {
              FieldDefinitionId: 21804,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement, ELenderAPIType.OnBlur],
                requirement: {
                  pattern: '^[0-9][0-9]-?[0-9][0-9]-?[0-9][0-9]$'
                }
              }
            },
            accountname: {
              FieldDefinitionId: 23448,
              isReadonly: false,
              required: true
            },
            accountno: {
              FieldDefinitionId: 21803,
              isReadonly: false,
              required: true
            },
            bankname: {
              FieldDefinitionId: 23447,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            chequE_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            visA_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            mastercard: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            amex: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            other: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            }
          },
          income: {
            salarymandated: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            salarydate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        },
        guarantoR_DETAILS: {
          address: {
            facetoface: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            forename: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            telephone: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            mobile: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            email: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          },
          prevaddr: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR2: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR3: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR4: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          personal: {
            dateofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            gender: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Male',
                    value: 'M'
                  },
                  {
                    label: 'Female',
                    value: 'F'
                  }
                ]
              }
            },
            marital: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            residential: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Homeowner',
                    value: 'O'
                  },
                  {
                    label: 'Tenant',
                    value: 'T'
                  },
                  {
                    label: 'With Parents',
                    value: 'P'
                  },

                  {
                    label: 'Work provided/other',
                    value: 'W'
                  }
                ]
              }
            },
            countryofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            nationality: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          },
          occupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevoccupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          bank: {
            sortcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement, ELenderAPIType.OnBlur],
                requirement: {
                  pattern: '^[0-9][0-9]-?[0-9][0-9]-?[0-9][0-9]$'
                }
              }
            },
            accountname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            accountno: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            bankname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            chequE_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            visA_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            mastercard: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            amex: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            other: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            }
          },
          income: {
            salarymandated: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            salarydate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        },
        business: {
          businesS_TYPE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Sole Trader',
                  value: 'ST'
                },
                {
                  label: 'Limited Company',
                  value: 'CO'
                },
                {
                  label: 'Partnership',
                  value: 'PS'
                }
              ]
            }
          },
          businesS_DETAILS: {
            tradinG_NAME: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            businesS_DESCRIPTION: {
              FieldDefinitionId: 21881,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 21867
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            yearS_TRADING: {
              FieldDefinitionId: 18450,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.EntityChange, ELenderAPIType.Number],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23533
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            vaT_NO: {
              FieldDefinitionId: 23547,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 21875
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            companY_REGNO: {
              FieldDefinitionId: 18315,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            contacT_NAME: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23976
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            tradinG_ADDRESS: {
              houseno: {
                FieldDefinitionId: 18431,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.EntityChange,
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23662
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 0
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 0
                    }
                  ]
                }
              },
              postcode: {
                FieldDefinitionId: 18438,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 18432,
                isReadonly: false,
                required: true
              },
              posttown: {
                FieldDefinitionId: 18435,
                isReadonly: false
              },
              county: {
                FieldDefinitionId: 18436,
                isReadonly: false
              },
              country: {
                FieldDefinitionId: 18437,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: countryOptions
                }
              },
              yrs: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              telephone: {
                FieldDefinitionId: 18525,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              }
            },
            tradinG_PREVADDR: {
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              country: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: countryOptions
                }
              },
              yrs: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              telephone: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              }
            },
            businesS_BANK: {
              sortcode: {
                FieldDefinitionId: 21804,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Requirement, ELenderAPIType.OnBlur],
                  requirement: {
                    pattern: '^[0-9][0-9]-?[0-9][0-9]-?[0-9][0-9]$'
                  }
                }
              },
              accountname: {
                FieldDefinitionId: 23448,
                isReadonly: false,
                required: true
              },
              accountno: {
                FieldDefinitionId: 21803,
                isReadonly: false,
                required: true
              },
              bankname: {
                FieldDefinitionId: 23447,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              yrs: {
                FieldDefinitionId: 23969,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              mths: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              chequE_CARD: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              visA_CARD: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              mastercard: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              amex: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              other: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              }
            }
          }
        },
        supplieR_DETAILS: {
          supplier: {
            FieldDefinitionId: 18412,
            isReadonly: false,
            required: true
          },
          addresS1: {
            FieldDefinitionId: 20699,
            isReadonly: false
          },
          posttown: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          telephone: {
            FieldDefinitionId: 20702,
            isReadonly: false
          },
          status: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Franchised',
                  value: 'F'
                },
                {
                  label: 'Fide',
                  value: 'B'
                },
                {
                  label: 'Unknown to broker',
                  value: 'U'
                }
              ]
            }
          },
          detailstofollow: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          }
        },
        agrI_DETAILS: {
          faD_HAS_HORSES: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_PIGS: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_POULTRY: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_HORTICULTURAL: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_FRUIT: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_HAULAGE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_BEEF: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_SHEEP: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_DAIRY: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_VEGETABLES: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_IS_CONTRACTOR: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_IS_DEALER: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          }
        }
      }
    }
  }));
};
