/**parent badge (using chip component) of users. Also click & view summary of user */
import React, { useEffect, useRef, useState } from 'react';
import { UserInstance } from 'types/interfaces';
import QuickUser from './UserLozenge';
import { LinearProgress, Typography } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { BugTracker } from 'Utils/Bugtracker';

// useQuery test imports
import { useQuery } from '@apollo/client';
import { GET_COMPLETE_USER_INSTANCE_DETAIL } from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { apolloClient } from 'graphql/apolloClient';

interface IProps {
  deletable?: boolean;
  deleteFunction?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  HandleLandingPageUser?: (user) => void;
  handleParentClickEvent?: (event: React.MouseEvent<HTMLDivElement>) => void;
  loginUser?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  UserInstanceId: string;
}

const QuickUserFromId = (props: IProps) => {
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const {
    deletable,
    deleteFunction,
    HandleLandingPageUser,
    handleParentClickEvent,
    loginUser,
    setEdit,
    UserInstanceId
  } = props;

  if (!UserInstanceId) {
    BugTracker.notify(
      'src/components/User/QuickUserFromId: Missing UserInstanceId',
      new Error('UserInstanceId is required')
    );
    return <Typography color="error">Invalid user ID</Typography>;
  }

  // Validate UserInstanceId before parsing
  const userInstanceId = parseInt(UserInstanceId);

  // Implementing Apollo useQuery instead of useEffect
  const { data, error, loading } = useQuery(GET_COMPLETE_USER_INSTANCE_DETAIL, {
    variables: { baseUrl, userInstanceId: parseInt(userInstanceId.toString()) },
    client: apolloClient,
    skip: !userInstanceId, // Skip if no valid ID
    onError: (error) => {
      BugTracker.notify(
        'src/components/User/QuickUserFromId, Failed to fetch user details:',
        error
      );
    }
  });
  const user = data?.GetUserInstanceDetail?.UserInstance;
  // END

  // Handle different states
  if (loading) return <LinearProgress variant="indeterminate" />;
  if (error) return <Typography color="error">Failed to load user</Typography>;
  if (!user)
    return <Typography color="textSecondary">No user found</Typography>;
  return (
    <QuickUser
      deletable={deletable}
      deleteFunction={deleteFunction}
      HandleLandingPageUser={HandleLandingPageUser}
      handleParentClickEvent={handleParentClickEvent}
      loginUser={loginUser}
      setEdit={setEdit}
      user={user}
    />
  );
};

export default QuickUserFromId;

// Updated hook with better error handling and types
export const useUserFromId = ({
  UserInstanceId,
  baseUrl
}: {
  UserInstanceId: number;
  baseUrl: string;
}): UserInstance => {
  const [user, setUser] = useState<UserInstance>({} as UserInstance);

  useEffect(() => {
    const getUser = async () => {
      if (!UserInstanceId) return;

      try {
        const response = await getQuickLiteUser({
          baseUrl,
          UserInstanceId,
          action: 'GET'
        });

        if (response?.UserInstance) {
          setUser(response.UserInstance);
        }
      } catch (err) {
        BugTracker.notify(
          'useUserFromId hook, Failed to fetch user details:',
          err
        );
      }
    };

    getUser();
  }, [UserInstanceId, baseUrl]);

  return user;
};
