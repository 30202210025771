import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { BugTracker } from 'Utils/Bugtracker';
import { notify } from 'components/Notifications/HotToastNotifications';

interface IInstanceBuilderData extends IInstanceBase {
  image?: string | null;
  title?: string | null;
}

interface IInstanceBase {
  text: string;
  timestamp: string;
}

export const useNoticeboardBuilder = () => {
  const { InstanceNoticeBoard, BrandList, BrandNoticeBoard } = useTypedSelector(
    (s) => s.config.settings
  );
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const [history, setHistory] = useState<IInstanceBuilderData[]>([]);
  const [data, setData] = useState<IInstanceBuilderData>({
    image: null,
    title: null,
    text: '',
    timestamp: ''
  });

  const [dialogOpen, setDialogOpen] = useState<boolean>(true);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [builderType, setBuilderType] = useState<string>('');

  const handleGlobalBuilder = () => {
    setBuilderType('global');
    setDialogOpen(false);
  };
  const handleInstanceBuilder = () => {
    setBuilderType('instance');
    setDialogOpen(false);
  };

  const handleBrandSelect = () => {
    if (BrandList) {
      const brandsArray = BrandList.split(',');
      setAvailableBrands(brandsArray);
      setDialogOpen(true);
      setBuilderType('brand');
    } else {
      notify.error(
        'No Brands Available. Please Add Brands To The Configurations'
      );
    }
  };

  const handleSaveBrand = async (brand: string) => {
    if (!availableBrands.includes(brand)) {
      notify.error('No Brand selected');
      return;
    }
    setSelectedBrand(brand);
    setBuilderType('brand');
    setDialogOpen(false);
  };

  // to re render the history when the brand is selected to load the history boards
  useEffect(() => {
    if (builderType === 'brand' && selectedBrand) {
      const unsubscribe = firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data && data[selectedBrand] && data[selectedBrand].history) {
              const brandHistory = data[selectedBrand].history.map(
                (item: IInstanceBuilderData) => ({
                  image: item.image || null,
                  text: item.text,
                  timestamp: item.timestamp,
                  title: item.title || null
                })
              );
              setHistory(brandHistory);
            } else {
              setHistory([]);
            }
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [builderType, selectedBrand]);

  const saveToGlobalHistory = async (historyEntry: {
    text: string;
    timestamp: string;
  }) => {
    setData({ image: null, text: '', timestamp: '', title: null });

    firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .set(
        { history: firebase.firestore.FieldValue.arrayUnion(historyEntry) },
        { merge: true }
      )
      .catch((e) => BugTracker.notify(e));
  };

  const saveToInstanceHistory = async (
    historyEntry: {
      text: string;
      timestamp: string;
    },
    isNoticeboard: boolean
  ) => {
    setData({ image: null, text: '', timestamp: '', title: null });

    const instanceKey = hostname;
    const instanceData = {
      currentNoticeboardInstance: !isNoticeboard
        ? historyEntry
        : InstanceNoticeBoard || historyEntry,
      history: firebase.firestore.FieldValue.arrayUnion(historyEntry)
    };

    const instanceUpdate = {
      [instanceKey]: instanceData
    };

    firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .set(instanceUpdate, { merge: true })
      .catch((e) => {
        console.log('Error saving to Instance History', e);
        BugTracker.notify(e);
      });
    setData({ image: null, text: '', timestamp: '', title: null });

    notify.success(`Successfully saved to ${instanceKey} instance noticeboard`);
  };

  const saveToBrandHistory = async (
    historyEntry: {
      text: string;
      timestamp: string;
      title: string | null;
    },
    isNoticeboard: boolean
  ) => {
    const brandKey = selectedBrand;

    if (!brandKey) {
      throw new Error('No brand selected');
    }

    const brandData = {
      currentNoticeboardInstance: !isNoticeboard
        ? historyEntry
        : BrandNoticeBoard || historyEntry,
      history: firebase.firestore.FieldValue.arrayUnion(historyEntry)
    };

    const brandUpdate = { [brandKey]: brandData };

    await firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .set(brandUpdate, { merge: true });

    setData({ image: null, text: '', timestamp: '', title: null });
    notify.success(`Successfully saved to ${brandKey} brand noticeboard`);
  };

  const saveToHistory = async () => {
    if (builderType === 'global') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: { text: string; timestamp: string } = {
        ...data,
        timestamp: historyEntryTimestamp
      };

      await saveToGlobalHistory(historyEntry);
      notify.success(`Successfully Created A Bips Global History`);
    } else if (builderType === 'instance') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };
      await saveToInstanceHistory(historyEntry, false);
      notify.success(`Successfully Created A Bips Instance History`);
    } else if (builderType === 'brand') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };
      await saveToBrandHistory(historyEntry, false);
      notify.success(`Successfully Created A Bips Brand History`);
    } else console.error('Builder Type Is Not Set.');
  };

  const handleSelectLayout = () => {
    if (builderType === 'global') {
      const createdTime = new Date().toISOString();
      const historyEntry = {
        text: data.text,
        timestamp: createdTime
      };

      return firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .get()
        .then((doc) => {
          const currentData = doc.data();
          const currentHistory = currentData?.history || [];

          const isDuplicate = currentHistory.some(
            (item) => item.text === historyEntry.text
          );

          const globalUpdate = {
            currentNoticeboardInstance: historyEntry,
            ...(isDuplicate
              ? {}
              : {
                  history:
                    firebase.firestore.FieldValue.arrayUnion(historyEntry)
                })
          };

          return firebase
            .firestore()
            .collection('globalSetting')
            .doc('noticeboard_instance')
            .update(globalUpdate);
        })
        .then(() => {
          notify.success(
            'The Bips Global Noticeboard Has Been Updated Successfully!'
          );
        })
        .catch((e) => BugTracker.notify(e));
    } else if (builderType === 'instance') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };

      return firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .get()
        .then((doc) => {
          const currentData = doc.data();
          const currentHistory = currentData?.[hostname]?.history || [];

          const isDuplicate = currentHistory.some(
            (item) =>
              item.text === historyEntry.text &&
              item.title === historyEntry.title
          );

          const instanceUpdate = {
            [`${hostname}.currentNoticeboardInstance`]: historyEntry,
            ...(isDuplicate
              ? {}
              : {
                  [`${hostname}.history`]:
                    firebase.firestore.FieldValue.arrayUnion(historyEntry)
                })
          };

          return firebase
            .firestore()
            .collection('globalSetting')
            .doc('noticeboard_instance')
            .update(instanceUpdate);
        })
        .then(() => {
          notify.success(
            `The Bips Instance Noticeboard Has Been Updated Successfully!`
          );
        })
        .catch((e) => BugTracker.notify(e));
    } else if (builderType === 'brand') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };

      return firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .get()
        .then((doc) => {
          const currentData = doc.data();
          const currentHistory = currentData?.[selectedBrand]?.history || [];
          const isDuplicate = currentHistory.some(
            (item) =>
              item.text === historyEntry.text &&
              item.title === historyEntry.title
          );

          const brandUpdate = {
            [`${selectedBrand}.currentNoticeboardInstance`]: historyEntry,
            ...(isDuplicate
              ? {}
              : {
                  [`${selectedBrand}.history`]:
                    firebase.firestore.FieldValue.arrayUnion(historyEntry)
                })
          };

          return firebase
            .firestore()
            .collection('globalSetting')
            .doc('noticeboard_instance')
            .update(brandUpdate);
        })
        .then(() => {
          notify.success(
            `The Bips Brand Noticeboard Has Been Updated Successfully!`
          );
        })
        .catch((e) => BugTracker.notify(e));
    } else console.error('Builder Type Is Not Set.');
  };

  const deleteHistoryItem = async (itemToDelete: IInstanceBuilderData) => {
    try {
      const docRef = firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance');

      if (builderType === 'global') {
        await docRef.update({
          history: firebase.firestore.FieldValue.arrayRemove({
            text: itemToDelete.text,
            timestamp: itemToDelete.timestamp,
            ...(itemToDelete.image && { image: itemToDelete.image }),
            ...(itemToDelete.title && { title: itemToDelete.title })
          })
        });
      } else if (builderType === 'instance') {
        const doc = await docRef.get();
        const data = doc.data();
        const instanceData = data?.[hostname] || {};
        const currentHistory = instanceData.history || [];

        const updatedHistory = currentHistory.filter(
          (item) => item.timestamp !== itemToDelete.timestamp
        );

        await docRef.update({
          [`${hostname}.history`]: updatedHistory
        });
      } else if (builderType === 'brand' && selectedBrand) {
        const doc = await docRef.get();
        const data = doc.data();
        const brandData = data?.[selectedBrand] || {};
        const currentHistory = brandData.history || [];

        const updatedHistory = currentHistory.filter(
          (item) => item.timestamp !== itemToDelete.timestamp
        );

        await docRef.update({
          [`${selectedBrand}.history`]: updatedHistory
        });
      }

      if (itemToDelete.image) {
        const storageRef = firebase.storage().refFromURL(itemToDelete.image);
        await storageRef.delete();
      }

      setHistory((currentHistory) =>
        currentHistory.filter(
          (item) => item.timestamp !== itemToDelete.timestamp
        )
      );

      notify.success('Item deleted successfully');
    } catch (error) {
      notify.error('Failed to delete item');
      BugTracker.notify(error);
    }
  };

  return {
    saveToHistory,
    handleSelectLayout,
    handleGlobalBuilder,
    handleInstanceBuilder,
    handleSaveBrand,
    handleBrandSelect,
    deleteHistoryItem,
    history,
    data,
    dialogOpen,
    builderType,
    setData,
    setDialogOpen,
    availableBrands,
    setSelectedBrand,
    selectedBrand,
    setHistory
  };
};
