import React from 'react';
import { Button, DialogContent } from '@material-ui/core';
import { CustomDialog } from 'common/Dialog';
import FAQAndGuides from 'components/FAQ/faqAndGuides';
import HelpIcon from '@material-ui/icons/Help';

const HelpButton = ({ objectId, objectTitle }) => {
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const content = helpContentMapping.find((item) =>
    item.id.includes(objectId)
  ) || { folderName: 'Default', tags: ['Default'] };
  const helpTitle = objectTitle || content.folderName;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 5,
        marginBottom: 5
      }}>
      <Button
        variant="outlined"
        color="secondary"
        style={{ marginLeft: 10 }}
        onClick={() => setHelpDialogOpen(true)}>
        <HelpIcon style={{ marginRight: 8 }} />
        Help
      </Button>
      <CustomDialog
        maxSize="md"
        handleClose={() => setHelpDialogOpen(false)}
        open={helpDialogOpen}
        staticPosition
        alert={{
          title: 'Help',
          description: `Need help with ${helpTitle}? Here are some guides and videos to help you.`,
          type: 'info'
        }}>
        <DialogContent style={{ minHeight: 250, minWidth: 500 }}>
          <FAQAndGuides folderName={content.folderName} tags={content.tags} />
        </DialogContent>
      </CustomDialog>
    </div>
  );
};

export default HelpButton;

export const helpContentMapping = [
  { id: [2838], folderName: 'Asset Details', tags: ['Asset Details'] },
  { id: [3397], folderName: 'Proposal', tags: ['Proposal'] },
  { id: [3625], folderName: 'Primary Contact', tags: ['Primary Contact'] },
  { id: [2840], folderName: 'Quotes', tags: ['Quote'] },
  { id: [3388], folderName: 'GDPR', tags: ['GDPR'] },
  { id: [2854], folderName: 'Invoices', tags: ['Borrower'] },
  {
    id: [3591, 3739],
    folderName: 'Commission Transparency',
    tags: ['Commission Transparency']
  }
];
